import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux'; 
import swal from "sweetalert";
import Select, { createFilter } from "react-select";
import { api } from "../../../utils/api";
import SortingTable from "../../components/SortingTable";
import moment from 'moment';
import { Table } from "react-bootstrap";


const GoldStandards = (props) => {
    const history = useHistory();
    const [newGoldData, setNewGoldData] = useState({
        newName: "",
    })
    const [addFormData, setAddFormData] = useState({
        name: 0, 
        rate: 0,
        buyBackRate: 0,
        finalAmount: 0.00,
        buyBackFinalAmount: 0.00,
    }); //Add data 
    const [cardToggle, setCardToggle] = useState([{
        addNew: false,
        adjust: false,
    }])
    const [goldType, setGoldTypes] = useState([]); // options
    const [marketGold, setMarketGold] = useState(0);
    const [error, setError] = useState({ name: "", rate: "", buyBackRate: "", finalAmount: "" });  
    const [errorNewGold, setErrorNewGold] = useState({ name: "" });  
    const [loading, setLoading] = useState(false);
    const [removeLoading, setRemoveLoading] = useState(false);
    const [list, setList] = useState([]);
    
    useEffect(()=>{
        getList();
        fetch(api.getMarketPrice, {
            method:"GET",
            headers:new Headers({
                "Content-Type":"application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        }).then(response => response.json())
        .then(responseJson => {
            if(responseJson.status === "success"){
                setMarketGold(responseJson.data.gsell);
                let temp = {...addFormData};
                temp.finalAmount = (responseJson.data.sellGM2 * (temp.rate ?? 0)).toFixed(2);
                temp.buyBackFinalAmount = (responseJson.data.sellGM2 * (temp.buyBackRate ?? 0)).toFixed(2);
                setAddFormData(temp);
            }
        }).catch(error => {
            console.log("Error",error);
        });
    },[props.auth.auth.token])

    // useEffect(()=>{
    //     getList();
    // },[props.auth.auth.token])

    function getList() {
        setLoading(true);
        fetch(api.goldPrice + `/standards`, {
            method:"GET",
            headers:new Headers({
                "Content-Type":"application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        }).then(response => response.json())
        .then(responseJson => {
            setLoading(false);
            if(responseJson.status === 'success'){
                setList(responseJson.data);
                let temp = [];
                responseJson.data.forEach(g => {
                    temp.push({
                        value: g.id,
                        label: g.type
                    });
                })
                setGoldTypes(temp);
            }
        }).catch(error => {
            console.log("Error",error);
        });
    }

    function resetForm() {
        setAddFormData({
            name: 0,
            rate: 0.00,
            buyBackRate: 0.00,
            finalAmount: 0.00,
            buyBackFinalAmount: 0.00,
        })
    }

    const setGoldPrice = (id) => {
        if (id === 0) return;
        let temp = list.find(i=>i.id === id);
        console.log(temp, list, id);
        setAddFormData({
            name: temp.id,
            rate: temp.rate ?? 0,
            finalAmount: (marketGold * (temp.rate ?? 0)).toFixed(2),
            buyBackRate: temp.buyBackRate ?? 0,
            buyBackFinalAmount: (marketGold * (temp.buyBackRate ?? 0)).toFixed(2),
        });
    }

    const toggleCard = (event, name) => {
        console.log(name);
        let temp = {...cardToggle};
        temp[name] = !temp[name];
        setCardToggle(temp);
    }

    const onNewGoldChange = (e) => {
        setErrorNewGold({name:""});
        setNewGoldData({newName: e.target.value});
    }

    const onInputChange = (e, name) => {
        let final = marketGold * e.target.value;
        let temp = {...addFormData};
        if (name === 'buy') {
            temp.rate = e.target.value;
            temp.finalAmount = final.toFixed(2);
        }
        if (name === 'buyback') {
            temp.buyBackRate = e.target.value;
            temp.buyBackFinalAmount = final.toFixed(2);
        }
        setAddFormData(temp);
    }

    const handleOptionSelected = (e, name) => {
        const { label, value } = e;
        const info = {...addFormData};
        info[name] = value;
        setGoldPrice(value)
        // setAddFormData(info);
    }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const newFormData = { ...addFormData };
        newFormData[name] = value;
        // console.log(newFormData,"Form")
        // if(newFormData.markingAmount>0 || newFormData.markingPercent >0){
        //     let newPriceInPercentage = parseFloat(newFormData.marketPrice)* parseFloat(newFormData.markingPercent)/100;
        //     console.log(newPriceInPercentage)
        //     let newPriceInAmount = parseFloat(newFormData.markingAmount);
        //     newFormData.markingPrice = parseFloat(newFormData.marketPrice) + parseFloat(newPriceInPercentage);
        //     newFormData.markingPrice = newFormData.markingPrice +  + newPriceInAmount;
        // }else{
        //     newFormData.markingPrice = parseFloat(newFormData.marketPrice);
        // }
        // if(name == 'markingAmount'){
        //     if(inputValue){
        //         newFormData.markingAmount = inputValue;
        //     }
        //     newPriceInAmount = Number(inputValue);
        // }
        // if(name == 'markingPercent'){
        //     if(inputValue){
        //         newFormData.markingPercent = inputValue;
        //     }
        //     newPriceInPercentage = Number(newFormData.marketPrice)*(Number(newFormData.markingPercent)/100);
        // }
        
        console.log(newFormData.markingPercent,"Amount")
        let percent= newFormData.markingPercent;
        let amount= newFormData.markingAmount;
        if(!percent){
            percent = 0;
        }
        if(!amount){
            amount = 0;
        }
        // newFormData.markingPrice = parseFloat(newFormData.marketPrice) + (parseFloat(newFormData.marketPrice) * parseFloat(typeof newFormData.markingPercent == "number" ? newFormData.markingPercent : 0 ) / 100) + parseFloat(typeof newFormData.markingAmount =='number' ? newFormData.markingAmount : 0);
        newFormData.markingPrice = parseFloat(newFormData.marketPrice) + ((parseFloat(newFormData.marketPrice) * parseFloat(percent) / 100))+ parseFloat(amount);
        
        if(isNaN(newFormData.markingPrice)){
            newFormData.markingPrice = newFormData.marketPrice;
        }
        // newFormData.markingPrice = newFormData.marketPrice + newFormData.markingAmount;
        // console.log("market price",Number(newFormData.marketPrice));
        // console.log("markingAmount",Number(newFormData.markingAmount));
        // if(newFormData.markingPercent>0){
        //     newFormData.markingPrice = Number(newFormData.markingPrice) + (Number(newFormData.markingPrice)*Number(newFormData.markingPercent)/100);
        // }
        console.log("markingPercent",Number(newFormData.markingPercent));
        console.log("markingPrice",Number(newFormData.markingPrice));
        
        
        // (+newFormData.marketPrice) + newPriceInPercentage + newFormData.markingAmount;

        // if(name === 'markingAmount'){
        //     newFormData.markingAmount =value;
        //     if(value === ""){
        //         newFormData.markingPrice = parseFloat(newFormData.marketPrice);
        //     }else{
        //         newFormData.markingPercent = "";
        //         newFormData.markingPrice = (parseFloat(newFormData.marketPrice) + parseFloat(value)).toFixed(2);
        //     }
        // }
        // if(name === 'markingPercent'){
        //     newFormData.markingPercent = value;
        //     if(value === ""){
        //         newFormData.markingPrice = parseFloat(newFormData.marketPrice).toFixed(2);
        //     }else{
        //         newFormData.markingAmount = "";
        //         let newPrice = parseFloat(newFormData.marketPrice) + (parseFloat(newFormData.marketPrice)*value/100);
        //         newFormData.markingPrice = newPrice.toFixed(2);
        //     }
        // }

        newFormData.markingPrice =  Math.ceil(newFormData.markingPrice);
        console.log("round up markingPrice",Number(newFormData.markingPrice));
        setAddFormData(newFormData)
    };

    const handleAddNewGoldSubmit = (event) => {
        event.preventDefault();
        let err = { name: "", rate: "", buyBackRate: ""};
        let check = true;
        if (!newGoldData.newName) {
            check = false;
            err.name = "Field is required"
        }
        setErrorNewGold(err);
        if (check) {
            let formData = {
                "type": newGoldData.newName,
            }
            setLoading(true)
            fetch(api.goldPrice + '/standards', {
                method:"POST",
                headers:new Headers({
                    "Content-Type":"application/JSON",
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(formData)
            }).then(response => response.json())
            .then(responseJson => {
                setLoading(false);
                if(responseJson.status === 'success'){
                    swal(responseJson.message, {
                        icon: "success",
                    });
                    setNewGoldData({newName: ""})
                    getList();
                }else{
                    swal(responseJson.message, {
                        icon: "error",
                    });
                }
            }).catch(error => {
                    console.log("Error",error);
            });
        }
    };

    const handleRemoveStandard = (event, id) => {
        swal({
            title: "Delete confirmation",
            text:
              "Once deleted, you will require to add again!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                setRemoveLoading(true);
                fetch(api.goldPrice + `/standards/${id}/remove`, {
                    method:"PUT",
                    headers:new Headers({
                        "Content-Type":"application/JSON",
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                }).then(response => response.json())
                .then(responseJson => {
                    setRemoveLoading(false);
                    if(responseJson.status === 'success'){
                        swal(responseJson.message, {
                            icon: "success",
                        });
                        getList();
                    }else{
                        swal(responseJson.message, {
                            icon: "error",
                        });
                    }
                }).catch(error => {
                    console.log("Error",error);
                });
            } 
        })
    };

    //Add Submit data
    const handleAddFormSubmit = (event) => {
        event.preventDefault();
        swal({
            title: "Confirm changes?",
            text:
              "Once changes, you will not longer using previous amount!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((action) => {
            if (!action) { return false }
            let errors = { name: "", rate: "", buyBackRate: "" };
            let check = true;
            if(addFormData.name === 0){
                check = false;
                errors.type = "Please choose one gold type"
            }
            if (!parseFloat(addFormData.rate) < 0) {
                check = false;
                errors.rate = "Rate must be more than 0"
            }
            if (!parseFloat(addFormData.buyBackRate) < 0) {
                check = false;
                errors.rate = "Rate must be more than 0"
            }
            setError(errors);
            if(check){
                let formData = {
                    "rate": addFormData.rate,
                    "buyBackRate": addFormData.buyBackRate,
                    "marketPrice": marketGold,
                    "finalAmount": addFormData.finalAmount,
                }
                setLoading(true)
                fetch(api.goldPrice + `/standards/${addFormData.name}`, {
                    method:"PUT",
                    headers:new Headers({
                        "Content-Type":"application/JSON",
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                    body: JSON.stringify(formData)
                }).then(response => response.json())
                .then(responseJson => {
                    if(responseJson.status === 'success'){
                        swal(responseJson.message, {
                            icon: "success",
                        });
                        // setGoldPrice();
                        getList();
                        setAddFormData({
                            name: 0,
                            rate: 0.00,
                            buyBackRate: 0.00,
                            finalAmount: 0.00,
                            buyBackFinalAmount: 0.00,
                        })
                    }else{
                        swal(responseJson.message, {
                            icon: "error",
                        });
                    }
                }).catch(error => {
                        console.log("Error",error);
                });
                setLoading(false);

            }
        })
    };

    return (
        <>
            <div className="col-12">
                <div className="row page-titles mx-0 shadow">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active">
                            <span>Gold</span>
                        </li>
                        <li className="breadcrumb-item">
                            <span>Standards</span>
                        </li>
                    </ol>
                </div>
                <div className="card shadow">
                    <div className={`card-header ${cardToggle.addNew ? "" : "border-0"}`} onClick={(e) => toggleCard(e, 'addNew')}>
                        <span className="h4">New Gold Type</span>
                        <span className="float-end">
                            {!cardToggle.addNew ? <i class="fas fa-chevron-down fa-2x"></i>
                            :<i class="fas fa-chevron-up fa-2x"></i>}
                        </span>
                    </div>
                    <div className={`card-body ${cardToggle.addNew ? "show" : "d-none"}`}>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-3 col-form-label">New Gold Type</label>
                            <div className="col-sm-9">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Name"
                                    name="newName"
                                    value={newGoldData.newName}
                                    onChange={(e)=> onNewGoldChange(e)}
                                />
                                <span className="error">{errorNewGold.name}</span>
                            </div>
                        </div>
                    </div>
                    <div className={`card-footer ${cardToggle.addNew ? "show" : "d-none"}`}>
                        <button type="button" className="btn btn-primary btn-sm me-2" onClick={handleAddNewGoldSubmit} disabled={loading} >Submit</button>
                    </div>
                </div>
                <div className="card shadow">
                    <div className={`card-header ${cardToggle.adjust ? "" : "border-0"}`} onClick={(e) => toggleCard(e, 'adjust')}>
                        <span className="h4">Gold rate adjustment</span>
                        <span className="float-end">
                            {!cardToggle.adjust ? (<i class="fas fa-chevron-down fa-2x"></i>)
                            :(<i class="fas fa-chevron-up fa-2x"></i>)}
                        </span>
                    </div>
                    <div className={`card-body ${cardToggle.adjust ? "show" : "d-none"}`}>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-3 col-form-label">Gold Type</label>
                            <div className="col-sm-9">
                                <Select
                                    className="form-control"
                                    options={goldType}
                                    placeholder="- Select -"
                                    style={{
                                        lineHeight: "40px",
                                        color: "#7e7e7e",
                                        paddingLeft: " 15px",
                                        borderWidth: 0,
                                    }}
                                    value={goldType.filter(o => o.value === addFormData.name)}
                                    onChange={e=> handleOptionSelected(e, 'name')}
                                />
                                <span className="error">{error.name}</span>
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-3 col-form-label">Current Gold Price</label>
                            <div className="col-sm-9">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="amount"
                                    name="marketPrice"
                                    value={marketGold}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-3 col-form-label">Rate</label>
                            <div className="col-sm-9">
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="rate"
                                    name="rate"
                                    max="1"
                                    step="0.001"
                                    value={addFormData.rate}
                                    onChange={(e)=> onInputChange(e, 'buy')}
                                />
                                <span className="error">{error.rate}</span>
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-3 col-form-label">Final Amount</label>
                            <div className="col-sm-9">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Final Amount"
                                    name="finalAmount"
                                    value={addFormData.finalAmount}
                                    readOnly
                                />
                                <span className="error">{error.finalAmount}</span>
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-3 col-form-label">Buy Back Rate</label>
                            <div className="col-sm-9">
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Enter Buyback rate"
                                    name="buyBackRate"
                                    max="1"
                                    step="0.001"
                                    value={addFormData.buyBackRate}
                                    onChange={(e)=> onInputChange(e, 'buyback')}
                                />
                                <span className="error">{error.buyBackRate}</span>
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-3 col-form-label">Buy Back Final Amount</label>
                            <div className="col-sm-9">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Final Amount"
                                    name="buyBackFinalAmount"
                                    value={addFormData.buyBackFinalAmount}
                                    readOnly
                                />
                                <span className="error">{error.buyBackFinalAmount}</span>
                            </div>
                        </div>
                    </div>
                    <div className={`card-footer ${cardToggle.adjust ? "show" : "d-none"}`}>
                        <button type="button" className="btn btn-primary btn-sm me-2" onClick={(e) => handleAddFormSubmit(e)} disabled={loading} >Submit</button>
                        <button type="button" onClick={resetForm} className="btn btn-danger btn-sm"> Discard</button>
                    </div>
                </div>
                <div className="card shadow">
                    <div className="card-header">
                        <span className="h3 mb-0">Price List</span>
                    </div>
                    <div className="card-body">
                        <Table responsive className="w-100">
                            <div className="dataTables_wrapper">
                                <table
                                    id="example"
                                    className="display w-100 dataTable"
                                    role="grid"
                                    aria-describedby="example_info"
                                >
                                    <thead>
                                        <th className="text-center">Type</th>
                                        <th className="text-center">Rate</th>
                                        <th className="text-center">Buy Back Rate</th>
                                        <th className="text-center">Status</th>
                                        <th className="text-center">Action</th>
                                    </thead>
                                    <tbody>
                                    {list.map((d, i) => (
                                        <tr key={i}>
                                            <td className="text-center">{d.type}</td>
                                            <td className="text-center">{d.rate}</td>
                                            <td className="text-center">{d.buyBackRate ?? 0}</td>
                                            <td className="text-center">{d.status}</td>
                                            <td className="text-center"><span className="icon-action remove" onClick={(e) => handleRemoveStandard(e, d.id)} disabled={removeLoading}><i className="fas fa-trash"></i></span></td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(GoldStandards);