import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux'; 
import swal from "sweetalert";
import Select, { createFilter } from "react-select";
import { api, url } from "../../../utils/api";
import SortingTable from "../../components/SortingTable";
import moment from 'moment';
import { Table } from "react-bootstrap";
import { termLists } from "../../../utils/function";

const TermList = (props) => {
    const history = useHistory();
    const [list, setList] = useState(termLists());
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState([]);
    const [error, setError] = useState({
        tnc: "", usage: "",
    });

    useEffect(()=>{
        getList();
    },[props.auth.auth.token]);

    function getList(){
        setLoading(true);
        fetch(api.users + `/tnc`, {
            method:"GET",
            headers:new Headers({
                "Content-Type":"application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        }).then(response => response.json())
        .then(responseJson => {
            setLoading(false);
            if(responseJson.status === 'success'){
                let temp = responseJson.data.map(item => {
                    return {
                        id: item.id,
                        label: item.usage,
                        value: item.usageCode,
                        path: item.path,
                        fileName: item.fileName,
                        isSelected: false,
                    }
                })
                setList(temp);
            }
        }).catch(error => {
            setLoading(false);
            console.log("Error",error);
        });
    }

    const onFileChange = (event) => {
        setFile(event.target.files);
    }

    const onOptionSelected = (e) => {
        const { label, value } = e;
        let temp = list;
        temp = temp.map(item => {item.value !== value ? item.isSelected = false : item.isSelected = true; return item;})
        setList(temp)
    }

    const uploadNewTNC = (e) => {
        let err = {tnc: "", usage: ""};
        swal({
            title: "Confirm changes?",
            text: "Once changes, you will not longer using previous Terms!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((action) => {
            if (!action) { return false }
            // console.log(file);
            let check = true;
            let selected = list.filter(item => item.isSelected);
            if (selected.length < 1) {
                console.log(selected.length);
                err.usage = "Title is required";
                check = false;
            }
            if (!file || file.length < 1) {
                err.tnc = "Attachment is required";
                check = false;
            }
            if (!file || file.length > 1) {
                err.tnc = "Only one attachment allowed";
                check = false;
            }
            if (file.length > 1 && file[0].type !== 'application/pdf') {
                err.tnc = "Only PDF is accepted";
                check = false;
            }
            setError(err);
            if(check){
                let formData = new FormData();
                for(let i=0;i<file.length;i++) {
                    formData.append(
                        "files",
                        file[i],
                        file[i].name
                    );
                };
                setLoading(true)
                fetch(api.users + `/tnc/${selected[0].id}`, {
                    method:"PUT",
                    headers:new Headers({
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                    body: formData
                }).then(response => response.json())
                .then(responseJson => {
                    setLoading(false);
                    if(responseJson.status === 'success'){
                        swal(responseJson.message, {
                            icon: "success",
                        });
                        document.getElementById('tncFile').value = "";
                        // resetForm();
                        getList();
                    }else{
                        swal(responseJson.message, {
                            icon: "error",
                        });
                    }
                }).catch(error => {
                        console.log("Error",error);
                });
            }
        })
    }

    //Add Submit data
    // const handleAddFormSubmit = (event) => {
    //     event.preventDefault();
    //     swal({
    //         title: "Confirm changes?",
    //         text:
    //           "Once changes, you will not longer using previous amount!",
    //         icon: "warning",
    //         buttons: true,
    //         dangerMode: true,
    //     }).then((action) => {
    //         if (!action) { return false }

    //         let errors = { weight: "", wCharges: "",};
    //         let check = true;
    //         if(!addFormData.weight){
    //             check = false;
    //             errors.weight = "Field is required"
    //         }
    //         if(!addFormData.wCharges){
    //             check = false;
    //             errors.wCharges = "This Field is required"
    //         }
    //         setError(errors);
    //         if(check){
    //             let formData = {
    //                 "weight": addFormData.weight,
    //                 "wCharges": addFormData.wCharges,
    //             };
    //             setLoading(true)
    //             fetch(api.goldPrice + `/premium`, {
    //                 method:"POST",
    //                 headers:new Headers({
    //                     "Content-Type":"application/JSON",
    //                     "Authorization": "Bearer " + props.auth.auth.token
    //                 }),
    //                 body: JSON.stringify(formData)
    //             }).then(response => response.json())
    //             .then(responseJson => {
    //                 setLoading(false);
    //                 if(responseJson.status === 'success'){
    //                     swal(responseJson.message, {
    //                         icon: "success",
    //                     });
    //                     resetForm();
    //                     getList();
    //                 }else{
    //                     swal(responseJson.message, {
    //                         icon: "error",
    //                     });
    //                 }
    //             }).catch(error => {
    //                     console.log("Error",error);
    //             });
    //         }
    //     })
    // };

    return (
        <>
            <div className="col-12">
                <div className="row page-titles mx-0 shadow">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active">
                            <span>Term and Conditions</span>
                        </li>
                        <li className="breadcrumb-item">
                            <span>List</span>
                        </li>
                    </ol>
                </div>
                <div className="d-flex flex-column">
                    <div className="card col-12 shadow">
                        <div className="card-header">
                            <span className="h4">Terms and Conditions</span>
                        </div>
                        <div className="card-body">
                            <div className="mb-3 row-cols-1 align-items-center">
                                <label className="col-form-label">Title</label>
                                <div className="">
                                    <Select
                                        className="form-control"
                                        options={list}
                                        placeholder="- Select -"
                                        style={{
                                            lineHeight: "40px",
                                            color: "#7e7e7e",
                                            paddingLeft: " 15px",
                                            borderWidth: 0,
                                        }}
                                        value={list.filter(o => o.isSelected)}
                                        onChange={e=> onOptionSelected(e)}
                                    />
                                    <span className="error">{error.usage}</span>
                                </div>
                            </div>
                            <div className="mb-3 row-cols-1 align-items-center">
                                <label className="col-form-label">Attachment</label>
                                <div className="">
                                    <input
                                        className="form-control"
                                        id="tncFile"
                                        type="file"
                                        placeholder=""
                                        name="attachment"
                                        onChange={(e) => onFileChange(e)}
                                    />
                                    <span className="error">{error.tnc}</span>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <button type="submit" className="btn btn-primary btn-sm me-2" disabled={loading} onClick={(e) => uploadNewTNC(e)}>Update</button>

                            {/* <button type="submit" className="btn btn-primary btn-sm me-2" onClick={handleAddFormSubmit} disabled={loading} >Submit</button>
                            <button type="button" onClick={resetForm} className="btn btn-danger btn-sm"> Discard</button> */}
                        </div>
                    </div>
                    <div className="card col-12 shadow">
                        <div className="card-header">
                            <span className="h3 mb-0">Terms List</span>
                        </div>
                        <div className="card-body p-0">
                            <Table responsive className="w-100 m-0">
                                <div className="dataTables_wrapper">
                                    <table
                                        id="example"
                                        className="display w-100 dataTable p-0"
                                        role="grid"
                                        aria-describedby="example_info"
                                    >
                                        <thead>
                                            {/* <th className="text-center">Title</th>
                                            <th className="">Attachment</th> */}
                                            {/* <th className="text-center">Action</th> */}
                                        </thead>
                                        <tbody>
                                        {list.map((d, i) => (
                                            <tr key={i}>
                                                <td className="border-1 border-right">{d.label}</td>
                                                <td className=""><a href={`${url}${d.path}`} download={d.fileName} target="_blank">{d.fileName}</a></td>
                                                {/* <td className="text-center">
                                                    <input
                                                        size={10}
                                                        className=""
                                                        id="tncFile"
                                                        type="file"
                                                        placeholder=""
                                                        name="attachment"
                                                        onChange={(e) => uploadNewTNC(e, d.id)}
                                                    />
                                                    <button type="button" onClick={(e) => uploadNewTNC(e, d.id)}><i className="fas fa-upload"></i></button>
                                                </td> */}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(TermList);