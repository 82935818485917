import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import { connect } from 'react-redux';
import {  api } from "../../../utils/api";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import SortingTable from "../../components/SortingTable";

const DigitalInvestment = (props) => {
    const COLUMNS = [
        {
            Header : 'No.',
            accessor: (row, i) => {
                return i+1;
             }
        },
        {
            Header : 'Reference No',
            accessor: 'investmentNo'

        },
        {
            Header : 'Gold Weight(gram)',
            accessor: 'weight'
        },
        {
            Header : 'Created Datetime',
            accessor: (row) => {
               return moment(row.createdAt).format('YYYY-MM-DD HH:MM:ss')
            }
        },
        {
            Header : 'Status',
            accessor: (row) => {
                return <span className="text-capitalize">{row.status}</span>
            }
        },
        {
            Header : 'Action',
            accessor: (row) => {
                return (
                    <>
                        <Link to={"/investment/details/" + row.id} className="text-primary">
                            <i className="fas fa-eye"></i>
                        </Link>
                    </>
                )
            }
        },
    ];

    const [loading, setLoading] = useState(false);
    const [contents, setContents] = useState([]);
    const [filterOption, setFilterOption] = useState("all");
    const [allData, setAllData] = useState([]);

    useEffect(() => {
        if(allData.length>0){
            if(filterOption === 'all'){
                setContents(allData);
            }else{
                let list = allData.filter(arr => arr.status === filterOption);
                setContents(list);
            }
        }
    }, [filterOption]);

    useEffect(() => {
        getList();
    }, [props.auth]);

    const getList = () => {
        setLoading(true);
        fetch(api.investment + `?type=digital`, {
            method:"GET",
            headers:new Headers({
                "Content-Type":"application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        }).then(response => response.json())
        .then(responseJson => {
            setLoading(false);
            console.log(responseJson) 
            if(responseJson.data){
                let filter = new URLSearchParams(props.location.search).get('filter');
                if (filter) {
                    responseJson.data = responseJson.data.filter(i => i.status === filter);
                    console.log(responseJson.data);
                }
                setContents(responseJson.data);
            }
            
        }).catch(error => { 
            console.log("Error",error);
        });
    }

    return (
        <>
            <div className="row page-titles mx-0 shadow">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                        <span>Gold Storage Program + </span>
                    </li>
                    <li className="breadcrumb-item">
                        <span>e-Gold List</span>
                    </li>
                </ol>
            </div>
            <div className="card shadow card-black">
                <div className="card-body">
                    <div className="row align-items-center mb-3">
                        <div className="col-md-2">
                            <span className="text-nowrap">Filter by delivery status:</span>
                        </div>
                        <div className="col-md-8">
                            <button className={`${filterOption === 'all' ? "btn-primary btn btn-sm me-2" : "btn-outline-primary btn btn-sm me-2"}`} onClick={() => setFilterOption('all')}>All</button>
                            <button className={`${filterOption === 'pending' ? "btn-primary btn btn-sm me-2" : "btn-outline-primary btn btn-sm me-2"}`} onClick={() => setFilterOption('pending')}>Pending</button>
                            <button className={`${filterOption === 'shipped' ? "btn-primary btn btn-sm me-2" : "btn-outline-primary btn btn-sm me-2"}`} onClick={() => setFilterOption('shipped')}>Shipped</button>
                            <button className={`${filterOption === 'completed' ? "btn-primary btn btn-sm me-2" : "btn-outline-primary btn btn-sm me-2"}`} onClick={() => setFilterOption('completed')}>Completed</button>
                        </div>
                        <div className="col-md-2">
                            <Link className="btn btn-outline-primary btn-sm float-right" to="/investment/list">View Gold List</Link>
                        </div>
                    </div>

                    {loading ? (
                    <div className="text-center">
                        <i className="fas fa-spinner fa-pulse fa-3x"></i>
                    </div>
                    ) : (
                    <div className="">
                        <div className="dataTables_wrapper" id="table_data">
                            <SortingTable columns={COLUMNS} data={contents} />
                        </div>
                    </div>
                    )}
                </div>
            </div>
        </>
   );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(DigitalInvestment);
