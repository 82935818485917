import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import { connect } from 'react-redux';
import {  api } from "../../../utils/api";
import moment from "moment";
import { Button, Dropdown, Modal } from "react-bootstrap";
import Select from "react-select";
import SortingTable from "../../components/SortingTable";
import { Checkbox } from "material-ui";

const RestockList = (props) => {
    const COLUMNS = [
        {
            Header : ' ',
            accessor: (row, i) => {
                return (
                <>
                    <input type="checkbox" style={{width: "25px", height: "25px"}} onClick={(e) => selectRestock(e, row)} checked={restockList.some(re => re === row.purchaseId)}/> 
                </>);
             }
        },
        {
            Header : 'No.',
            accessor: (row, i) => {
                return i+1;
             }
        },
        {
            Header : 'Ref no.',
            accessor: (row) => {
                return row.purchaseId
            }
        },
        {
            Header : 'Customer Name',
            accessor: (row) => {
                return row.user.fullName;
            }
        },
        {
            Header : 'Amount',
            accessor: (row) => {
                return (row.amount ?? 0) + "g";
            }
        },
        {
            Header : 'Type',
            accessor: (row) => {
                return row.type === 'egold' ? 'GSP' : 'FGR';
            }
        },
        {
            Header : 'Collect Method',
            accessor: (row) => {
                return row.delivery === 'delivery' ? 'Delivery' : row.delivery === 'selfCollect' ? 'Self Collect' : 'Wallet'
            }
        },
        {
            Header : 'Created Datetime',
            accessor: (row) => {
               return moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss')
            }
        },
    ];

    const [loading, setLoading] = useState(false);
    const [contents, setContents] = useState([]);
    const [filterOption, setFilterOption] = useState("all");
    const [allData, setAllData] = useState([]); 
    const [restockList, setRestockList] = useState([]);

    useEffect(() => {
        if(allData.length>0){
            if(filterOption === 'all'){
                setContents(allData);
            }else{
                let list = allData.filter(arr => arr.deliveryStatus === filterOption);
                setContents(list);
            }
        }
    }, [filterOption]);
  
    useEffect(() => {
        getList();
    }, [props.auth]);

    const getList = () => {
        setLoading(true);
        fetch(api.users + `/golds/purchase`, {
            method:"GET",
            headers:new Headers({
                "Content-Type":"application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        }).then(response => response.json())
        .then(responseJson => {
            setLoading(false);
            // console.log(responseJson) 
            if(responseJson.data){
                // let filter = new URLSearchParams(props.location.search).get('filter');
                // if (filter) {
                //     responseJson.data = responseJson.data.filter(i => i.status === filter);
                //     console.log(responseJson.data);
                // }
                setAllData(responseJson.data);
                setContents(responseJson.data);
            }
            
        }).catch(error => { 
            setLoading(false);
            console.log("Error",error);
        });
    }

    const selectRestock = (e, row) => {
        let tempList = [...restockList];
        
        if (e.target.checked) {
            tempList.push(row.purchaseId)
           
        } else {
            tempList = tempList.filter(tmp => tmp !== row.purchaseId)
        }
        setRestockList([...tempList]);
        console.log("restock list", tempList);
    }

    return (
        <>
            <div className="row page-titles mx-0 shadow">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                        <span>Restock</span>
                    </li>
                    <li className="breadcrumb-item">
                        <span>List</span>
                    </li>
                    <Link to="/purchase/list" className="ms-auto btn btn-sm btn-primary text-white">Go To Purchase List</Link>
                </ol>
            </div>
            <div className="card shadow card-black">
                <div className="card-body">
                    {/* <div className="row align-items-center mb-3">
                        <div className="col-md-2">
                            <span className="text-nowrap">Filter by amount:</span>
                        </div>
                        <div className="col-md-10">
                            <button className={`${filterOption === 'all' ? "btn-primary btn btn-sm me-2" : "btn-outline-primary btn btn-sm me-2"}`} onClick={() => setFilterOption('all')}>All</button>
                            <button className={`${filterOption === 'pending' ? "btn-primary btn btn-sm me-2" : "btn-outline-primary btn btn-sm me-2"}`} onClick={() => setFilterOption('pending')}>Pending</button>
                            <button className={`${filterOption === 'shipped' ? "btn-primary btn btn-sm me-2" : "btn-outline-primary btn btn-sm me-2"}`} onClick={() => setFilterOption('shipped')}>Shipped</button>
                            <button className={`${filterOption === 'completed' ? "btn-primary btn btn-sm me-2" : "btn-outline-primary btn btn-sm me-2"}`} onClick={() => setFilterOption('completed')}>Completed</button>
                        </div>
                    </div> */}
                    {loading ? (
                    <div className="text-center">
                        <i className="fas fa-spinner fa-pulse fa-3x"></i>
                    </div>
                    ) : (
                    <div className="">
                        <div className="dataTables_wrapper" id="table_data">
                            <SortingTable columns={COLUMNS} data={contents} />
                        </div>
                    </div>
                    )}
                </div>
            </div>
        </>
   );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(RestockList);
