import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import swal from "sweetalert";
import { imageUrl, api } from "../../../utils/api";
import { goldWeightType } from "../../../utils/function";

const InvestmentDetails = (props) => {
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const [formData, setFormData] = useState({
        weight: null,
        contract: null,
        golds: null,
        status: null,
    });
    const [investment, setInvestment] = useState(null);
    const [goldWeights, setGoldWeights] = useState(goldWeightType);

    useEffect(() => {
        getDetails();
    }, [props.auth]);

    const getDetails = () => {
        setLoading(true);
        fetch(api.investment + "?id=" + id, {
            method:"GET",
            headers:new Headers({
                "Content-Type":"application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        }).then(response => response.json())
        .then(responseJson => {
            setLoading(false);
            console.log(responseJson.data) 
            if(responseJson.data){ 
                let data = {
                    weight: responseJson.data[0].weight,
                    contract: responseJson.data[0].investments_contract,
                    golds:  responseJson.data[0].investments_golds,
                    status: responseJson.data[0].status,
                }
                setInvestment(responseJson.data[0]);
                setFormData(data)
            }
            
        }).catch(error => { 
            console.log("Error",error);
        });
    }


    const approveRequest = () => {
        swal({
            title: "Approve this request?",
            text:
              "Once approved, you will not be able to revert this action anymore!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                setLoading(true);
                let url = api.updateInvestmentRequest;
                url = url.replace(':id', id);
                let data = {
                    "action": "approved"
                }
                fetch(url, {
                    method:"PUT",
                    headers:new Headers({
                        "Content-Type":"application/JSON",
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                    body: JSON.stringify(data)
                }).then(response => response.json())
                .then(responseJson => {
                    setLoading(false);
                    console.log(responseJson) 
                    if(responseJson.status === 'success'){ 
                        getDetails();
                        swal("Investment has been approved.", {
                            icon: "success",
                        });
                    }else{
                        swal(responseJson.message, {
                            icon: "error",
                        });
                    }
                    
                }).catch(error => { 
                    console.log("Error",error);
                });
            } 
        })
    }

    const rejectRequest = () => {
        swal({
            title: "Reject this request?",
            text:
              "Once rejected, you will not be able to revert this action anymore!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                setLoading(true);
                let url = api.updateInvestmentRequest;
                url = url.replace(':id', id);
                let data = {
                    "action": "rejected"
                }
                fetch(url, {
                    method:"PUT",
                    headers:new Headers({
                        "Content-Type":"application/JSON",
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                    body: JSON.stringify(data)
                }).then(response => response.json())
                .then(responseJson => {
                    setLoading(false);
                    console.log(responseJson) 
                    if(responseJson.status === 'success'){ 
                        getDetails();
                        swal("Investment has been rejected.", {
                            icon: "success",
                        });
                    }else{
                        swal(responseJson.message, {
                            icon: "error",
                        });
                    }
                    
                }).catch(error => { 
                    console.log("Error",error);
                });
            } 
        })
    }

    const approveGold = () => {
        swal({
            title: "Confirm this program?",
            text:
              "Once confirmed, you will not be able to revert this action anymore!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                setLoading(true);
                let url = api.verifyInvestmentGold;
                url = url.replace(':id', id);
                let data = {
                    "action": "approved"
                }
                fetch(url, {
                    method:"PUT",
                    headers:new Headers({
                        "Content-Type":"application/JSON",
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                    body: JSON.stringify(data)
                }).then(response => response.json())
                .then(responseJson => {
                    setLoading(false);
                    console.log(responseJson) 
                    if(responseJson.status === 'success'){ 
                        getDetails();
                        swal("Investment has been confirmed.", {
                            icon: "success",
                        });
                    }else{
                        swal(responseJson.message, {
                            icon: "error",
                        });
                    }
                    
                }).catch(error => { 
                    console.log("Error",error);
                });
            }
        })
    }

    const rejectGold = () => {
        swal({
            title: "Reject this program?",
            text:
              "Once rejected, you will not be able to revert this action anymore!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                setLoading(true);
                let url = api.verifyInvestmentGold;
                url = url.replace(':id', id);
                let data = {
                    "action": "rejected"
                }
                fetch(url, {
                    method:"PUT",
                    headers:new Headers({
                        "Content-Type":"application/JSON",
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                    body: JSON.stringify(data)
                }).then(response => response.json())
                .then(responseJson => {
                    setLoading(false);
                    console.log(responseJson) 
                    if(responseJson.status === 'success'){ 
                        getDetails();
                        swal("Investment has been rejected.", {
                            icon: "success",
                        });
                    }else{
                        swal(responseJson.message, {
                            icon: "error",
                        });
                    }
                    
                }).catch(error => { 
                    console.log("Error",error);
                });
            }
        })
    }

    return (
        <>
            <div className="row page-titles mx-0 shadow">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                        <span>Gold Storage Program</span>
                    </li>
                    <li className="breadcrumb-item">
                        <span>Details</span>
                    </li>
                </ol>
            </div>
            <div className="card shadow">
                <div className="card-body">
                    <div className="mb-3 row align-items-center">
                        <label className="col-sm-3 col-form-label">Reference No.</label>
                        <div className="col-sm-8">
                            <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="investmentNo"
                            value={investment ? investment.investmentNo : ""}
                            readOnly
                            />
                        </div>
                    </div>
                    {/* <div className="mb-3 row align-items-center">
                        <label className="col-sm-3 col-form-label">GSP Type</label>
                        <div className="col-sm-8">
                            <input
                            type="text"
                            className="form-control text-capitalize"
                            placeholder=""
                            name="contractType"
                            value={investment ? investment.investments_contract.type : ""}
                            readOnly
                            />
                        </div>
                    </div> */}
                    {investment && investment.investments_contract.type === 'digital' ? <></> : 
                    (<div className="mb-3 row align-items-center">
                        <label className="col-sm-3 col-form-label">Branch</label>
                        <div className="col-sm-8">
                            <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="branch"
                            value={investment ? investment.investments_contract.branch.name : ""}
                            readOnly
                            />
                        </div>
                    </div>)}
                    <div className="mb-3 row align-items-center">
                        <label className="col-sm-3 col-form-label">Program Period (month)</label>
                        <div className="col-sm-8">
                            <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="contract"
                            value={formData.contract ? formData.contract.period : ""}
                            readOnly
                            />
                        </div>
                    </div>
                    <div className="mb-3 row align-items-center">
                        <label className="col-sm-3 col-form-label">Gold Amount</label>
                        <div className="col-sm-8">
                            <input
                            type="text"
                            className="form-control"
                            placeholder="Example: 5g"
                            name="weight"
                            value={formData.weight ? goldWeights.find(w => w.value === formData.weight) ? goldWeights.find(w => w.value === formData.weight).label : (formData.weight + 'g') : ""}
                            readOnly
                            />
                        </div>
                    </div>
                    <div className="mb-3 row align-items-center">
                        <label className="col-sm-3 col-form-label">Deposit Type</label>
                        <div className="col-sm-8">
                            <input
                            type="text"
                            className="form-control"
                            placeholder="serial number"
                            name="serialNumber"
                            value={investment && investment.investments_contract.type === 'digital' ? "GSP Wallet":
                                    formData.golds && formData.golds[0] ? "Physical Gold" : "Gold Wallet"}
                            readOnly
                            />
                        </div>
                    </div>
                    {investment && investment.investments_contract.type === 'digital' ? <></> :
                    formData.golds && formData.golds[0] ? 
                    (<>
                    <div className="mb-3 row align-items-center">
                        <label className="col-sm-3 col-form-label">Serial Number</label>
                        <div className="col-sm-8">
                            <input
                            type="text"
                            className="form-control"
                            placeholder="serial number"
                            name="serialNumber"
                            value={formData.golds && formData.golds[0] ? formData.golds[0].serialNumber : ""}
                            readOnly
                            />
                        </div>
                    </div>
                    <div className="mb-3 row align-items-center">
                        <label className="col-sm-3 col-form-label">Photo</label>
                        <div className="col-sm-8">
                            {formData.golds && formData.golds[0] ? (
                                JSON.parse(formData.golds[0].path).map((item, i)=>(
                                    <a href={imageUrl + "/" + item} target="_blank">
                                        <img src={imageUrl + "/" + item} className="thumbnail" />
                                    </a>
                                ))
                            ) : null}
                        </div>
                    </div></>):
                    (<></>)}
                    <div className="mb-3 row align-items-center">
                        <label className="col-sm-3 col-form-label">Full Name as IC</label>
                        <div className="col-sm-8">
                            <input
                            type="text"
                            className="form-control"
                            name="Full Name"
                            value={investment ? investment.user.fullName : ""}
                            readOnly
                            />
                        </div>
                    </div>
                    <div className="mb-3 row align-items-center">
                        <label className="col-sm-3 col-form-label">IC Number</label>
                        <div className="col-sm-8">
                            <input
                            type="text"
                            className="form-control"
                            name="ic"
                            value={investment ? investment.user.identityCard : ""}
                            readOnly
                            />
                        </div>
                    </div>
                    <div className="mb-3 row align-items-center">
                        <label className="col-sm-3 col-form-label">Email Address</label>
                        <div className="col-sm-8">
                            <input
                            type="text"
                            className="form-control"
                            name="email"
                            value={investment ? investment.user.email : ""}
                            readOnly
                            />
                        </div>
                    </div>
                    <div className="mb-3 row align-items-center">
                        <label className="col-sm-3 col-form-label">Phone No.</label>
                        <div className="col-sm-8">
                            <input
                            type="text"
                            className="form-control"
                            name="phone"
                            value={investment ? investment.user.phone : ""}
                            readOnly
                            />
                        </div>
                    </div>
                    <div className="mb-3 row align-items-center">
                        <label className="col-sm-3 col-form-label">Status</label>
                        <div className="col-sm-8">
                            <input
                            type="text"
                            className="form-control text-capitalize"
                            name="status"
                            value={investment ? investment.status : ""}
                            readOnly
                            />
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    {formData.status === 'pending' ? (
                        <>
                            <button className="btn btn-primary btn-sm me-2" disabled={loading} onClick={approveRequest}>Approve</button>
                            <button className="btn btn-danger btn-sm me-2" disabled={loading} onClick={rejectRequest}>Reject</button>
                        </>
                    ): null}
                    {formData.status === "awaiting delivery"? (
                        <>
                            <button className="btn btn-primary btn-sm me-2" disabled={loading} onClick={approveGold}>Confirm</button>
                            <button className="btn btn-danger btn-sm me-2" disabled={loading} onClick={rejectGold}>Reject</button>
                        </>
                    ) : null}
                    {investment && investment.investments_contract.type === 'digital' ? 
                    (
                        <Link className="btn btn-dark profile-button btn-sm me-2" to="/investment/digital">Cancel</Link>
                    ): 
                    (
                        <Link className="btn btn-dark profile-button btn-sm me-2" to="/investment/list">Cancel</Link>
                    )
                    }
                </div>
            </div>
        </>
   );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(InvestmentDetails);

