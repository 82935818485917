import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import swal from "sweetalert";
import { imageUrl, api } from "../../../utils/api";

const PurchaseInfo = (props) => {
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const [formData, setFormData] = useState({
        purchaseId: "",
        customer: "",
        weight: "",
        fee:  "",
        totalPayment:  "",
        type: "",
        paymentMethod: "",
        collectMethod: "",
        address1: "",
        address2: "",
        phone: "",
        country: "",
        state: "",
        city: "",
        postCode: "",
        deliveryStatus: "",
        remarks: "",
        trackingNo: "",
        trackingCode: "",
        adminRemarks: "",
        programStatus: "",
        programReferId: "",
    });
    const [error, setError] = useState({
        trackingNo: "",
        trackingCode: "",
        adminRemarks: "",
    });
    const [investment, setInvestment] = useState(null)

    useEffect(() => {
        getDetails();
    }, [props.auth]);

    const getDetails = () => {
        setLoading(true);
        fetch(api.users + "/golds/purchase?id=" + id, {
            method:"GET",
            headers:new Headers({
                "Content-Type":"application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        }).then(response => response.json())
        .then(responseJson => {
            setLoading(false);
            if(responseJson.data){ 
                let data = {
                    purchaseId: responseJson.data[0].purchaseId,
                    customer: responseJson.data[0].user.fullName,
                    weight: responseJson.data[0].amount,
                    fee:  responseJson.data[0].fee,
                    totalPayment:  responseJson.data[0].totalPrice,
                    type: responseJson.data[0].type,
                    paymentMethod: responseJson.data[0].paymentType,
                    collectMethod: responseJson.data[0].delivery,
                    address1: responseJson.data[0].address1,
                    address2: responseJson.data[0].address2,
                    phone: responseJson.data[0].user.phone,
                    country: responseJson.data[0].country,
                    state: responseJson.data[0].state,
                    city: responseJson.data[0].city,
                    postCode: responseJson.data[0].postCode,
                    deliveryStatus: responseJson.data[0].deliveryStatus,
                    remarks: responseJson.data[0].remarks,
                    adminRemarks: responseJson.data[0].adminRemarks,
                    trackingNo: responseJson.data[0].trackingNo,
                    programStatus: responseJson.data[0].programStatus,
                    programReferId: responseJson.data[0].programReferId,
                }
                // setInvestment(responseJson.data[0]);
                setFormData(data)
            }
            
        }).catch(error => { 
            setLoading(false);
            console.log("Error",error);
        });
    }
    
    

   

    return (
        <>
            <div className="row page-titles mx-0 shadow">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                        <span>Purchase</span>
                    </li>
                    <li className="breadcrumb-item">
                        <span>Details</span>
                    </li>
                </ol>
            </div>
            <div className="card shadow">
                <div className="card-body">
                    <div className="mb-3 row align-items-center">
                        <label className="col-sm-2 col-form-label">Purchase Id</label>
                        <div className="col-sm-8">
                            <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="purchaseId"
                            value={formData.purchaseId}
                            readOnly
                            />
                        </div>
                    </div>
                    <div className="mb-3 row align-items-center">
                        <label className="col-sm-2 col-form-label">Customer</label>
                        <div className="col-sm-8">
                            <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="name"
                            value={formData.customer}
                            readOnly
                            />
                        </div>
                    </div>
                    <div className="mb-3 row align-items-center">
                        <label className="col-sm-2 col-form-label">Contact Number</label>
                        <div className="col-sm-8">
                            <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="name"
                            value={formData.phone}
                            readOnly
                            />
                        </div>
                    </div>
                    <div className="mb-3 row align-items-center">
                        <label className="col-sm-2 col-form-label">Purchase Type</label>
                        <div className="col-sm-8">
                            <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="type"
                            value={formData.type === 'egold' ? 'Digital gold' : 'Gold'}
                            readOnly
                            />
                        </div>
                    </div>
                    <div className="mb-3 row align-items-center">
                        <label className="col-sm-2 col-form-label">Gold Weight</label>
                        <div className="col-sm-8">
                            <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="weight"
                            value={formData.weight}
                            readOnly
                            />
                        </div>
                    </div>
                    <div className="mb-3 row align-items-center">
                        <label className="col-sm-2 col-form-label">Fee</label>
                        <div className="col-sm-8">
                            <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="fee"
                            value={formData.fee}
                            readOnly
                            />
                        </div>
                    </div>
                    <div className="mb-3 row align-items-center">
                        <label className="col-sm-2 col-form-label">Total Payment</label>
                        <div className="col-sm-8">
                            <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="totalPayment"
                            value={formData.totalPayment}
                            readOnly
                            />
                        </div>
                    </div>
                    <div className="mb-3 row align-items-center">
                        <label className="col-sm-2 col-form-label">Payment Method</label>
                        <div className="col-sm-8">
                            <input
                            type="text"
                            className="form-control text-capitalize"
                            placeholder=""
                            name="paymentMethod"
                            value={formData.paymentMethod === "onlineAndPoint" ? "Online and Point" : formData.paymentMethod}
                            readOnly
                            />
                        </div>
                    </div>
                    {formData.type === 'gold' ?
                    <>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label">Collect Method</label>
                            <div className="col-sm-8">
                                <input
                                type="text"
                                className="form-control"
                                name="collectMethod"
                                value={formData.collectMethod === "delivery" ? "Delivery" : formData.collectMethod === "investment" ? "Wallet" : "Self Collect"}
                                readOnly
                                />
                            </div>
                        </div>
                        {formData.collectMethod !== 'investment' ? 
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label">{formData.collectMethod === 'delivery' ? 'Delivery' : 'Collect'} Status</label>
                            <div className="col-sm-8">
                                {/* <select
                                    defaultValue={"option"}
                                    className="form-control form-control-lg">
                                <option>Pending</option>
                                <option>Shipped</option>
                                <option>Received</option>
                                </select> */}
                                <input
                                    type="text"
                                    className="form-control text-capitalize"
                                    placeholder=""
                                    name="deliveryStatus"
                                    value={formData.deliveryStatus }
                                    readOnly
                                    />
                            </div>
                        </div>: <></>}
                    </>: <></>}
                    {
                        formData.collectMethod === 'delivery' ? 
                        <>
                        <div className="mb-3 row align-items-center">
                        <label className="col-sm-2 col-form-label">Shipping Address 1</label>
                            <div className="col-sm-8">
                                <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                name="address1"
                                value={formData.address1}
                                readOnly
                                />
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label">Shipping Address 2</label>
                            <div className="col-sm-8">
                                <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                name="address2"
                                value={formData.address2}
                                readOnly
                                />
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label">Country</label>
                            <div className="col-sm-8">
                                <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                name="country"
                                value={formData.country}
                                readOnly
                                />
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label">State</label>
                            <div className="col-sm-8">
                                <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                name="state"
                                value={formData.state}
                                readOnly
                                />
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label">City</label>
                            <div className="col-sm-8">
                                <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                name="city"
                                value={formData.city}
                                readOnly
                                />
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label">Postcode</label>
                            <div className="col-sm-8">
                                <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                name="postCode"
                                value={formData.postCode}
                                readOnly
                                />
                            </div>
                        </div>
                    </>: <></>
                    }
                    <div className="mb-3 row align-items-center">
                        <label className="col-sm-2 col-form-label">Remark</label>
                        <div className="col-sm-8">
                            <input
                            type="text"
                            className="form-control"
                            name="remark"
                            value={formData.remarks}
                            // onChange={(e) => setFormData({...formData, remarks: e.target.value})}
                            readOnly
                            />
                        </div>
                    </div>
                    
                    
                    
                </div>
                <div className="card-footer d-flex flex-row">
                        
                  
                        <Link className="btn btn-dark profile-button btn-sm me-2" to="/inventory/list">Back</Link>
                    
                                      
                </div>
            </div>
        </>
   );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(PurchaseInfo);

