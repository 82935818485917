import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { connect } from 'react-redux';
import Select, { createFilter } from "react-select";
import swal from "sweetalert";
import moment from "moment";
import { url, api } from "../../../utils/api";

function WithdrawDetails(props) {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState(null);
    const { id } = useParams();
    const [file, setFile] = useState([]);
    const [error, setError] = useState({
        trackingNo: "",
        trackingCode: "",
        adminRemarks: "",
        slipAttachments: "",
    });
    const inputEl = useRef(null);
    const [remarks, setRemarks] = useState(null);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [trackingNo, setTrackingNo] = useState(null);
    const [trackingCode, setTrackingCode] = useState(null);
    
    useEffect(() => {
        getDetail();
    }, [props.auth]);

    const getDetail = () => {
        setLoading(true);
        fetch(api.getWithdraw + "/?id=" + id, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        }).then(response => response.json())
            .then(responseJson => {
                setLoading(false);
                // console.log(responseJson)
                if (responseJson.status === 'success') {
                    responseJson.data[0].method = responseJson.data[0].method === 'bankIn' ? 'Bank In' : responseJson.data[0].method
                    responseJson.data[0].slipAttachments = JSON.parse(responseJson.data[0].slipAttachments)
                    responseJson.data[0].trackingCode = responseJson.data[0].deliveryStatus === 'pending' ? "" : responseJson.data[0].trackingCode;
                    responseJson.data[0].trackingNo = responseJson.data[0].deliveryStatus === 'pending' ? "" : responseJson.data[0].trackingNo;
                    //console.log(responseJson.data[0].slipAttachments, '123');
                    //console.log('tracking Code: ', trackingCode);
                    setContent(responseJson.data[0]);


                } else {
                    setContent(null);
                }
            }).catch(error => {
                setLoading(false);
                console.log("Error", error);
            });
    }

    const approveRequest = () => {
        let error = "";
        let check = true;
        swal({
            title: "Approve this request?",
            text:
                "Once approved, you will not be able to revert this action anymore!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                setLoading(true);
                let formData = new FormData();
                if (content.method === "Bank In") {
                    if (!file || file.length < 1) {
                        error = "Field is required";
                        check = false;
                    }
                    if (check) {
                        for (let i = 0; i < file.length; i++) {
                            formData.append(
                                "files",
                                file[i],
                                file[i].name
                            );
                        }
                    }
                }
                // console.log(content.method,"metho")
                formData.append("action", "approved")
                formData.append("remark", remarks)
                if (content.method === 'gold') {
                    // console.log('test', trackingNo);
                    formData.append("trackingNo", trackingNo);
                }
                // let data = {
                //     action: "approved",
                // }
                setError(error);
                if (check) {
                    fetch(api.getWithdraw + "/" + id, {
                        method: "PUT",
                        headers: new Headers({
                            //"Content-Type":"application/JSON",
                            "Authorization": "Bearer " + props.auth.auth.token
                        }),
                        body: formData,
                    }).then(response => response.json())
                        .then(responseJson => {
                            setLoading(false);
                            // console.log(responseJson)
                            if (responseJson.status === 'success') {
                                swal("Request approved.", {
                                    icon: "success",
                                });
                                history.push('/withdraw/list');
                            } else {
                                swal(responseJson.message, {
                                    icon: "error",
                                });
                            }
                        }).catch(error => {
                            console.log("Error", error);
                        });
                }
            }
            setLoading(false);
        })
    }

    const rejectRequest = () => {
        swal({
            title: "Reject this request?",
            text:
                "Once rejected, you will not be able to revert this action anymore!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                setLoading(true);
                let data = {
                    action: "rejected",
                    remark: remarks,
                }
                fetch(api.getWithdraw + "/" + id, {
                    method: "PUT",
                    headers: new Headers({
                        "Content-Type": "application/JSON",
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                    body: JSON.stringify(data)
                }).then(response => response.json())
                    .then(responseJson => {
                        setLoading(false);
                        // console.log(responseJson)
                        if (responseJson.status === 'success') {
                            swal("Request rejected.", {
                                icon: "success",
                            });
                            history.push('/withdraw/list');
                        } else {
                            swal(responseJson.message, {
                                icon: "error",
                            });
                        }
                    }).catch(error => {
                        console.log("Error", error);
                    });

            }
        })
    }
    const onFileChange = (event) => {
        setFile(event.target.files)
    }
    const onInputChange = (e) => {
        setRemarks(e.target.value);
    }

    const completeRequest = () => {
        swal({
            title: "Complete this request?",
            text:
                "Once complete, you will not be able to revert this action anymore!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                setLoading(true);
                let check = true;
                if (!file || file.length < 1) {
                    setError({ file: "Field is required", trackingNo: "" });
                    check = false;
                }
                if (check) {
                    let formData = new FormData();
                    for (let i = 0; i < file.length; i++) {
                        formData.append(
                            "files",
                            file[i],
                            file[i].name
                        );
                    }
                    formData.append("remark", remarks);
                    fetch(api.getWithdraw + "/" + id + '/received', {
                        method: "PUT",
                        headers: new Headers({
                            //"Content-Type":"application/JSON",
                            "Authorization": "Bearer " + props.auth.auth.token
                        }),
                        body: formData,
                    }).then(response => response.json())
                        .then(responseJson => {
                            setLoading(false);
                            console.log(responseJson)
                            if (responseJson.status === 'success') {
                                swal("Withdraw completed.", {
                                    icon: "success",
                                });
                                history.push('/withdraw/list');
                            } else {
                                swal(responseJson.message, {
                                    icon: "error",
                                });
                            }
                        }).catch(error => {
                            console.log("Error", error);
                        });
                }
                setLoading(false);
            }
        })
    }

    const updateDeliveryStatus = (e, status) =>{
        console.log('tracking no: ',content.trackingNo);
        console.log('tracking code: ',content.trackingCode);
        setLoading(true);
        let check = true;
        let err= {trackingNo: "", trackingCode: ""}
        if(status === 'shipped') {
            if(!content.trackingNo){
                check = false;
                err.trackingNo = "This field is required";
            }
        }


        if(content.delivery === 'selfCollect' && !content.trackingCode){
            check = false;
            err.trackingCode = "This field is required";
        }
        
        setError(err);

        if(check) {
            swal({
                title: `Update delivery status to ${status}?`,
                text: "Once update, you will not be able to recover this data anymore!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((result) => {
                if(result){
                    fetch(api.users + `/withdraw/request/${id}/${status}`,{
                        method: "PUT",
                        headers: new Headers({
                            "Content-Type": "application/JSON",
                            "Authorization": "Bearer "+ props.auth.auth.token
                        }),
                        body: JSON.stringify(content),
                    }).then(response => response.json())
                    .then(responseJson => {
                        setLoading(false);

                        if(responseJson.status === 'success'){
                            swal('Updated!', responseJson.message, "success");
                            getDetail();
                        } else{
                            swal('Oops!', responseJson.message, "error");
                        }
                    }).catch(error => {
                        setLoading(false);
                        console.log("Error", error);
                    });
                }
                setLoading(false);
            })

            setLoading(false);
        }

        setLoading(false);
    }

   
    return (
        <>
            <div className="col-12">
                <div className="row page-titles mx-0 shadow">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active">
                            <span>Withdraw</span>
                        </li>
                        <li className="breadcrumb-item">
                            <span>Details</span>
                        </li>
                    </ol>
                </div>

                <div className="card shadow">
                    <div className="card-body">
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-3 col-form-label col-form-label">
                                Withdraw No
                            </label>
                            <div className="col-sm-9">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    name="withdrawNo"
                                    value={content ? content.withdrawNo : ""}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-3 col-form-label col-form-label">
                                Customer
                            </label>
                            <div className="col-sm-9">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    name="name"
                                    value={content ? content.user.fullName : ""}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-3 col-form-label col-form-label">
                                Contact No.
                            </label>
                            <div className="col-sm-9">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    name="phone"
                                    value={content ? content.user.phone : ""}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-3 col-form-label col-form-label">
                                Withdraw Method
                            </label>
                            <div className="col-sm-9">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    name="type"
                                    value={content ? content.type === 'eGold' && content.method === 'Bank In' ? "GSP to Bank In" :
                                        content.type === 'eGold' && content.method === 'gold' ? "GSP to Gold" :
                                            content.type === 'investmentEGold' && content.method === 'Bank In' ? "GSP+ to Bank In" :
                                                content.type === 'investmentEGold' && content.method === 'gold' ? "GSP+ to Gold" :
                                                    content.type === 'investmentGold' && content.method === 'Bank In' ? "GSP+ Physical Gold to Bank In" :
                                                        content.type === 'investmentGold' && content.method === 'gold' ? "GSP+ Physical Gold to Gold" :
                                                            content.type === 'ecash' && content.method === 'points' ? "Cash Wallet to Point Wallet" : "" : ""}

                                    readOnly


                                />
                            </div>
                        </div>

                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-3 col-form-label col-form-label">
                                Date

                            </label>
                            <div className="col-sm-9">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    name="date"
                                    value={content ? moment(content.createdAt).format('YYYY/MM/DD HH:mm:ss') : ""}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-3 col-form-label col-form-label">
                                Status
                            </label>
                            <div className="col-sm-9">
                                <input
                                    type="text"
                                    className="form-control text-capitalize"
                                    placeholder=""
                                    name="status"
                                    value={content ? content.status : ""}
                                    readOnly
                                />
                            </div>
                        </div>


                        {content && content.method === 'gold' ?

                            //If method === Gold
                            <>

                                {content && content.type !== 'investmentGold' ?

                                    <div className="mb-3 row align-items-center">
                                        <label className="col-sm-3 col-form-label col-form-label">
                                            Premium Charges
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                name="premiumPayment"
                                                value={content ? 'RM ' + content.premiumCharges : ""}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    : <></>
                                }

                                <div className="mb-3 row align-items-center">
                                    <label className="col-sm-3 col-form-label col-form-label">
                                        Amount (g)
                                    </label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            className="form-control text-capitalize"
                                            placeholder=""
                                            name="amount"
                                            value={content ? content.amount : 0}
                                            readOnly
                                        />
                                    </div>
                                </div>
                                {content ? content.type === 'ecash' && content.method === 'points' ? <></>
                                    : <>
                                        <div className="mb-3 row align-items-center">
                                            <label className="col-sm-3 col-form-label col-form-label">
                                                Payment Method
                                            </label>
                                            <div className="col-sm-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    name="paymentMethod"
                                                    value={content ? content.paymentType === 'online' ? 'Online' : 'Online And Point' : ""}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </>
                                    : <></>
                                }

                                {
                                    content ? content.type === 'ecash' && content.method === 'points'? <></>
                                        : <>
                                            <div className="mb-3 row align-items-center">
                                                <label className="col-sm-3 col-form-label col-form-label">
                                                    {content ? content.delivery === 'delivery' ? 'Delivery Method' : 'Collect Method' : ""}
                                                </label>
                                                <div className="col-sm-9">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder=""
                                                        name="delivery"
                                                        value={content ? content.delivery === 'delivery' ? 'Delivery' : 'Self Collect' : ""}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            {content ? content.delivery === 'selfCollect' ?
                                                <>
                                                    <div className="mb-3 row align-items-center">
                                                        <label className="col-sm-3 col-form-label col-form-label">
                                                            Collect Location
                                                        </label>
                                                        <div className="col-sm-9">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder=""
                                                                name="delivery"
                                                                value={content && content.branch ? content.branch.name : ""}
                                                                readOnly
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                                : <></> : <></>}
                                            <div className="mb-3 row align-items-center">
                                                <label className="col-sm-3 col-form-label col-form-label">
                                                    {content ? content.delivery === 'delivery' ? 'Delivery Status' : 'Collect Status' : ""}

                                                </label>
                                                <div className="col-sm-9">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder=""
                                                        name="deliveryStatus"
                                                        value={content ? content.deliveryStatus === 'pending' ? 'Pending' : 'Completed' : ""}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3 row align-items-center">
                                                <label className="col-sm-3 col-form-label col-form-label">
                                                    {content ? content.delivery === 'delivery' ? ' Delivery Fee' : 'Packaging Fee' : ""}

                                                </label>
                                                <div className="col-sm-9">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder=""
                                                        name="fee"
                                                        value={content ? 'RM ' + content.fee : ""}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>


                                            {

                                                content ? content.delivery === 'delivery' ?
                                                    <>
                                                        <div className="mb-3 row align-items-center">
                                                            <label className="col-sm-3 col-form-label col-form-label">
                                                                Shipping Address 1
                                                            </label>
                                                            <div className="col-sm-9">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    name="address1"
                                                                    value={content ? content.address1 : '-'}
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-3 row align-items-center">
                                                            <label className="col-sm-3 col-form-label col-form-label">
                                                                Shipping Address 2
                                                            </label>
                                                            <div className="col-sm-9">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    name="address2"
                                                                    value={content ? content.address2 : '-'}
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-3 row align-items-center">
                                                            <label className="col-sm-3 col-form-label col-form-label">
                                                                Post Code
                                                            </label>
                                                            <div className="col-sm-9">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    name="postcode"
                                                                    value={content ? content.postCode : '-'}
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-3 row align-items-center">
                                                            <label className="col-sm-3 col-form-label col-form-label">
                                                                City
                                                            </label>
                                                            <div className="col-sm-9">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    name="city"
                                                                    value={content ? content.city : '-'}
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-3 row align-items-center">
                                                            <label className="col-sm-3 col-form-label col-form-label">
                                                                State
                                                            </label>
                                                            <div className="col-sm-9">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    name="state"
                                                                    value={content ? content.state : '-'}
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                    : <></>
                                                    : <></>

                                            }
                                        </>

                                        : <></>

                                }

                            </>
                            : content && content.method === 'Bank In' ?

                                <>
                                    <div className="mb-3 row align-items-center">
                                        <label className="col-sm-3 col-form-label col-form-label">
                                            Withdraw Amount (g)
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                value={content ? content.amount : ""}
                                                readOnly
                                            />
                                        </div>
                                    </div>

                                    <div className="mb-3 row align-items-center">
                                        <label className="col-sm-3 col-form-label col-form-label">
                                            Gold Type
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                value={content ? content.method === 'Bank In' && (content.type === 'eGold' || content.type === 'investmentEGold') ? "GSP 24K" : content.method === 'Bank In' && content.type === 'investmentGold' ? "24K Wafer" : "" : ""}
                                                readOnly
                                            />
                                        </div>
                                    </div>



                                    <div className="mb-3 row align-items-center">
                                        <label className="col-sm-3 col-form-label col-form-label">
                                            Gold Price (RM)
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                value={content ? content.goldAtCreate.toFixed(2) : ""}
                                                readOnly
                                            />
                                        </div>
                                    </div>

                                    <div className="mb-3 row align-items-center">
                                        <label className="col-sm-3 col-form-label col-form-label">
                                            Total amount bank in (RM)
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                value={content ? (content.amount * content.goldAtCreate).toFixed(2) : ""}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </>

                            : content && content.method === 'points' ?
                                    <>
                                        <div className="mb-3 row align-items-center">
                                            <label className="col-sm-3 col-form-label col-form-label">
                                                Amount
                                            </label>
                                            <div className="col-sm-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    value={content ? content.amount.toFixed(2) : ""}
                                                    readOnly
                                                />
                                            </div>
                                        </div>


                                    </>
                                    : <></>}

                        

                        {content && content.status !== 'pending' ? (
                            <>
                                <div className="mb-3 row align-items-center">
                                    <label className="col-sm-3 col-form-label col-form-label">
                                        Remarks
                                    </label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Remarks"
                                            value={content ? content.remark : " "}
                                            name="remarks"
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </>) : <></>}

                        {content && content.method === 'gold' && content.delivery === 'delivery' ?
                            (<>
                                <div className="mb-3 row align-items-center">
                                    <label className="col-sm-3 col-form-label">Tracking No</label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="trackingNo"
                                            value={content && content.deliveryStatus !== 'pending' ? content.trackingNo : trackingNo}
                                            disabled={content.deliveryStatus !== 'pending' ? true : false}
                                            onChange={(e) => setContent({...content, trackingNo: e.target.value})}
                                            readOnly={content && content.deliveryStatus !== 'pending' ? true: false}
                                        />
                                        <span className="error">{error.trackingNo}</span>
                                    </div>
                                </div>
                            </>) : <></>}

                            {content && content.method === 'gold' && content.delivery === 'selfCollect'?
                                (
                                    <>
                                    <div className="mb-3 row align-items-center">
                                        <label className="col-sm-3 col-form-label">Tracking Code</label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="trackingCode"
                                                value={content && content.deliveryStatus !== 'pending' ? content.trackingCode : trackingCode}
                                                disabled={content.deliveryStatus !== 'pending' ? true : false}
                                                onChange={(e) => setContent({...content, trackingCode: e.target.value})}
                                                readOnly={content && content.deliveryStatus !== 'pending' ? true : false}
                                                />
                                                <span className="error">{error.trackingCode}</span>
                                        </div>
                                    </div>

                                   
                                    </>
                                ) : <></>
                            }

                        
                            <div className="mb-3 row align-items-center">
                                <label className="col-sm-3 col-form-label">Admin Remark</label>
                                <div className="col-sm-9">
                                    <input 
                                        type="text"
                                        className="form-control"
                                        name="adminRemark"
                                        value={content ? content.adminRemarks : ""}
                                        onChange={(e) => setContent({...content, adminRemarks: e.target.value})}
                                        readOnly={content && content.deliveryStatus === 'completed' ? true : false}
                                        />

                                </div>
                            </div>
                        {/* {content && content.status !== 'pending' ? <div className="mb-3 row align-items-center">
                                        <label className="col-sm-3 col-form-label col-form-label">
                                            Attachments
                                        </label>
                                        <div className="col-sm-9">
                                            <div className="row match-height">
                                                {content && content.slipAttachments ? (
                                                    <>
                                                        {content.slipAttachments.map((item, i) => (
                                                            <div key={i} className="col-lg-3 col-md-6">
                                                                <div className="card-img mt-3">
                                                                    <img className="card-img img-fluid" style={{ width: "300px", height: "300px" }} src={url + "/payment/" + item} alt="Images" />
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div> : <></>} */}
                    </div>
                    {content && content.method === "Bank In" && content.status === 'pending' ? (
                        <>
                            <div className="card-header">
                                <h4>Bank Details</h4>
                            </div>
                            {content && content.user.user_bank_accounts.map((item, index) => (
                                <div className="card-body">

                                    <div className="mb-3 row align-items-center">
                                        <label className="col-sm-2 col-form-label col-form-label">
                                            Full Name
                                        </label>
                                        <div className="col-sm-10">
                                            <input
                                                type="text"
                                                className="form-control form-control"
                                                placeholder=""
                                                name="fullname"
                                                value={content ? content.user.fullName : ""}
                                                readOnly
                                            />
                                        </div>
                                    </div>


                                    <div className="mb-3 row align-items-center">
                                        <label className="col-sm-2 col-form-label col-form-label">
                                            Bank
                                        </label>
                                        <div className="col-sm-10">
                                            <input
                                                type="text"
                                                className="form-control form-control"
                                                placeholder=""
                                                name="bankname"
                                                value={content ? content.user.user_bank_accounts[index].bank.bankName : ""}
                                                readOnly
                                            />
                                        </div>
                                    </div>

                                    <div className="mb-3 row align-items-center">
                                        <label className="col-sm-2 col-form-label col-form-label">
                                            Bank Account No.
                                        </label>
                                        <div className="col-sm-10">
                                            <input
                                                type="text"
                                                className="form-control form-control"
                                                placeholder=""
                                                name="bankAccNo"
                                                value={content ? content.user.user_bank_accounts[index].accountNumber : ""}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    ) : null}
                    {content && (content.status === 'pending' || content.status === 'completed') ? (
                        content.status === 'pending' && content.method !== "Bank In" ? (<></>) :
                            (<div className="card-body">
                                <div className="mb-3 row align-items-center">
                                    <label className="col-sm-3 col-form-label col-form-label">
                                        Remarks
                                    </label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Remarks"
                                            value={remarks}
                                            name="remarks"
                                            onChange={e => onInputChange(e)}
                                        />
                                        {/* <span className="error">{error.remarks}</span> */}
                                    </div>
                                </div>
                                <div className="mb-3 row align-items-center">
                                    <label className="col-sm-3 col-form-label col-form-label">
                                        Attachments
                                    </label>
                                    {content && content.slipAttachments === null ?
                                        <div className="col-sm-9">
                                            <input
                                                type="file"
                                                className=""
                                                placeholder=""
                                                name="attachment"
                                                onChange={e => onFileChange(e)}
                                                ref={inputEl}
                                                multiple="multiple"
                                            />
                                            <span className="error">{error.slipAttachments}</span>
                                        </div>
                                        : <></>

                                    }

                                    <div className="row match-height">
                                        {content && content.slipAttachments ? (
                                            <>
                                                {content.slipAttachments.map((item, i) => (
                                                    <div key={i} className="col-lg-3 col-md-6">
                                                        <div className="card-img mt-3">
                                                            <img className="card-img img-fluid" style={{ maxWidth: "10rem" }} src={url + "/upload/payment/" + item} alt="Images" />
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        ) : null}
                                    </div>
                                </div>
                            </div>)) : (<></>)}
                    <div className="card-footer">
                        {content && content.status === 'pending' && content.method === 'Bank In' ? (
                            <>
                                <button className="btn btn-primary profile-button btn-sm text-white me-2" onClick={approveRequest} disabled={loading}>
                                    {loading ? (<i className="fas fa-spinner fa-spin"></i>) : "Approve"}
                                </button>
                                <button className="btn btn-danger profile-button btn-sm text-whit me-2" onClick={rejectRequest} disabled={loading}>Reject</button>
                            </>
                        ) : null}
                        {/* {content && content.status === 'completed' && content.method === 'Bank In' ? (
                            <>
                                <button className="btn btn-primary profile-button btn-sm text-white me-2" onClick={completeRequest} disabled={loading}>
                                    {loading ? (<i className="fas fa-spinner fa-spin"></i>) : "Complete"}
                                </button>
                            </>
                        ) : null} */}

                        {content && content.delivery === 'selfCollect' && content.deliveryStatus !== 'completed' && content.method === 'gold'? (
                            <>
                                <button className="btn btn-primary profile-button btn-sm text-white me-2" onClick={(e) => updateDeliveryStatus(e,'completed')} disabled={loading}>
                                    {loading ? (<i className="fas fa-spinner fa spin"></i>) : "Completed"}
                                </button>
                            
                            </>

                        ): null}

                        {content && content.delivery === 'delivery' && content.deliveryStatus === 'pending' && content.method === 'gold'?(
                           <>
                                <button className="btn btn-primary profile-button btn-sm text-white me-2" onClick={(e) => updateDeliveryStatus(e,'shipped')} disabled={loading}>
                                    {loading ? (<i className="fas fa-spinner fa spin"></i>): "Shipped"}
                                </button>
                           
                           </>
                        ): null}

                        {content && content.deliveryStatus === 'shipped' ? (
                           <>
                                <button className="btn btn-primary profile-button btn-sm text-white me-2" onClick={(e) => updateDeliveryStatus(e, 'completed')} disabled={loading}>
                                    {loading ? (<i className="fas fa-spinner fa spin"></i>): "Completed"}
                                </button>
                           
                           </>
                        ): null}



                        
                        
                        <Link className="btn btn-dark profile-button btn-sm text-white float-end" to="/withdraw/list">Back</Link>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(WithdrawDetails);
