import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux'; 
import swal from "sweetalert";
import Select, { createFilter } from "react-select";
import { api } from "../../../utils/api";
import SortingTable from "../../components/SortingTable";
import moment from 'moment';
import { Table } from "react-bootstrap";

const GoldBuyBack = (props) => {
    const history = useHistory();
    const [addFormData, setAddFormData] = useState({
        gId: 0, 
        marketPrice: 0, 
        buyBackPrice: 0, 
        buyBackAmount: 0
    }); //Add data 
    const [goldOptions, setGoldOptions] = useState([]); // options
    const [goldType, setGoldTypes] = useState([{
        id: 0,
        type: "",
        buyBackRate: 0,
    }]);
    const [marketGold, setMarketGold] = useState(0);
    const [error, setError] = useState({ gId: "", marketPrice: "", buyBackPrice: "", buyBackAmount: "" });  
    const [loading, setLoading] = useState(false);
    const [removeLoading, setRemoveLoading] = useState(false);
    const [list, setList] = useState([]);
    
    useEffect(()=>{
        getGoldOptions();
        // setGoldPrice("24K")
        fetch(api.getMarketPrice, {
            method:"GET",
            headers:new Headers({
                "Content-Type":"application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        }).then(response => response.json())
        .then(responseJson => {
            let data = { ...addFormData };
            if(responseJson.status === "success"){
                setMarketGold(responseJson.data.gsell);
            }
            setAddFormData(data);
        }).catch(error => {
            console.log("Error",error);
        });
    },[props.auth.auth.token])

    useEffect(()=>{
        getList();
    },[props.auth.auth.token])

    function getGoldOptions() {
        setLoading(true);
        fetch(api.goldPrice + `/standards`, {
            method:"GET",
            headers:new Headers({
                "Content-Type":"application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        }).then(response => response.json())
        .then(responseJson => {
            setLoading(false);
            if(responseJson.status === 'success'){
                setGoldTypes(responseJson.data);
                let temp = [];
                responseJson.data.forEach(gs => {
                    temp.push({
                        value: gs.id,
                        label: gs.type
                    });
                })
                setGoldOptions(temp);
                setGoldPrice(0);
            }
        }).catch(error => {
            console.log("Error",error);
        });
    }

    function getList(){
        setLoading(true);
        fetch(api.goldPrice + `/buyback`, {
            method:"GET",
            headers:new Headers({
                "Content-Type":"application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        }).then(response => response.json())
        .then(responseJson => {
            setLoading(false);
            if(responseJson.status === 'success'){
                setList(responseJson.data);
            }
        }).catch(error => {
            console.log("Error",error);
        });
    }

    function resetForm() {
        setAddFormData({
            gId: 0,
            marketPrice: 0, 
            buyBackPrice: 0, 
            buyBackAmount: 0,
        })
    }

    const setGoldPrice = (id) => {
        if (id === 0) return;
        setLoading(true);
        resetForm()
        fetch(api.goldPrice + `/buyback?standardId=${id}`, {
            method:"GET",
            headers:new Headers({
                "Content-Type":"application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        }).then(response => response.json())
        .then(responseJson => {
            setLoading(false);
            if(responseJson.status === 'success'){
                setAddFormData({
                    gId: id,
                    marketPrice: responseJson.data[0].marketPrice ?? 0,
                    buyBackPrice: responseJson.data[0].buyBackPrice ? Math.floor(responseJson.data[0].buyBackPrice) : 0,
                    buyBackAmount: responseJson.data[0].buyBackAmount ?? 0,
                }); 
            } else {
                let buyBackRate = goldType.find(g => g.id === id).buyBackRate;
                setAddFormData({
                    gId: id,
                    marketPrice: (marketGold * buyBackRate).toFixed(2),
                    buyBackPrice: Math.floor(marketGold * buyBackRate),
                    buyBackAmount: 0,
                });
            }
        }).catch(error => {
            console.log("Error",error);
        });
    }

    const handleOptionSelected = (e, name) => {
        const { label, value } = e;
        const info = {...addFormData};
        info[name] = value;
        setGoldPrice(value)
        // setAddFormData(info);
    }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const newFormData = { ...addFormData };
        newFormData[name] = value;
        let amount = newFormData.buyBackAmount;
        if(!amount){
            amount = 0;
        }
        // newFormData.markingPrice = parseFloat(newFormData.marketPrice) + (parseFloat(newFormData.marketPrice) * parseFloat(typeof newFormData.markingPercent == "number" ? newFormData.markingPercent : 0 ) / 100) + parseFloat(typeof newFormData.markingAmount =='number' ? newFormData.markingAmount : 0);
        let total = parseFloat(newFormData.marketPrice) + Math.floor(parseFloat(amount));
        newFormData.buyBackPrice =  Math.floor(total);
        if(isNaN(newFormData.buyBackPrice)){
            newFormData.buyBackPrice = newFormData.marketPrice;
        }
        setAddFormData(newFormData);
    };

    //Add Submit data
    const handleAddFormSubmit = (event) => {
        event.preventDefault();
        swal({
            title: "Confirm changes?",
            text:
              "Once changes, you will not longer using previous amount!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((action) => {
            if (!action) { return false }

            let errors = { gId: "", marketPrice: "", buyBackPrice: "", buyBackAmount: "" };
            let check = true;
            if(!addFormData.gId){
                check = false;
                errors.type = "Field is required"
            }
            if(!addFormData.buyBackAmount){
                check = false;
                errors.buyBackAmount = "Fill in marking amount or marking percent"
            }
            setError(errors);
            if(check){
                let formData = [{
                    "standardId": addFormData.gId,
                    "type": goldType.find(gs => gs.id === addFormData.gId).type,
                    "marketPrice": parseFloat(addFormData.marketPrice) ? parseFloat(addFormData.marketPrice) : 0,
                    "buyBackPrice": parseFloat(addFormData.buyBackPrice) ? parseFloat(addFormData.buyBackPrice) : 0,
                    "buyBackAmount": parseFloat(addFormData.buyBackAmount) ? parseFloat(addFormData.buyBackAmount) : 0,
                }]
                setLoading(true)
                fetch(api.goldPrice + `/buyback`, {
                    method:"POST",
                    headers:new Headers({
                        "Content-Type":"application/JSON",
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                    body: JSON.stringify(formData)
                }).then(response => response.json())
                .then(responseJson => {
                    setLoading(false);
                    if(responseJson.status === 'success'){
                        swal(responseJson.message, {
                            icon: "success",
                        });
                        setGoldPrice(0);
                        getList();
                    }else{
                        swal(responseJson.message, {
                            icon: "error",
                        });
                    }
                }).catch(error => {
                        console.log("Error",error);
                });
            }
        })
    };

    const handleRemoveBuyBack = (event, id) => {
        swal({
            title: "Delete confirmation",
            text:
              "Once deleted, you will require to add again!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                setRemoveLoading(true);
                fetch(api.goldPrice + `/buyback/${id}/remove`, {
                    method:"PUT",
                    headers:new Headers({
                        "Content-Type":"application/JSON",
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                }).then(response => response.json())
                .then(responseJson => {
                    setRemoveLoading(false);
                    if(responseJson.status === 'success'){
                        swal(responseJson.message, {
                            icon: "success",
                        });
                        getList();
                    }else{
                        swal(responseJson.message, {
                            icon: "error",
                        });
                    }
                }).catch(error => {
                    console.log("Error",error);
                });
            } 
        })
    }

    return (
        <>
            <div className="col-12">
                <div className="row page-titles mx-0 shadow">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active">
                            <span>Gold</span>
                        </li>
                        <li className="breadcrumb-item">
                            <span>Buy Back</span>
                        </li>
                    </ol>
                </div>
                <div className="card shadow">
                    {/* <div className="card-header">
                        <span>24K Gold</span>
                    </div> */}
                    <div className="card-body">
                    <div className="mb-3 row align-items-center">
                            <label className="col-sm-3 col-form-label">Gold Type</label>
                            <div className="col-sm-9">
                                <Select
                                    className="form-control"
                                    options={goldOptions}
                                    placeholder="- Select -"
                                    style={{
                                        lineHeight: "40px",
                                        color: "#7e7e7e",
                                        paddingLeft: " 15px",
                                        borderWidth: 0,
                                    }}
                                    value={goldOptions.filter(o => o.value === addFormData.gId)}
                                    onChange={e=> handleOptionSelected(e, 'gId')}
                                />
                                <span className="error">{error.gId}</span>
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-3 col-form-label">Market Price</label>
                            <div className="col-sm-9">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="amount"
                                    name="marketPrice"
                                    value={addFormData.marketPrice}
                                    readOnly
                                />
                                <span className="error">{error.marketPrice}</span>
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-3 col-form-label">Buy Back Markup Amount</label>
                            <div className="col-sm-9">
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Enter Amount"
                                    name="buyBackAmount"
                                    value={addFormData.buyBackAmount}
                                    onChange={e=>handleAddFormChange(e)}   
                                />
                                <span className="error">{error.buyBackAmount}</span>
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-3 col-form-label">Final Buy Back Amount</label>
                            <div className="col-sm-9">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Final Buy Back Amount"
                                    name="buyBackPrice"
                                    value={addFormData.buyBackPrice} 
                                    readOnly
                                />
                                 <span className="error">{error.buyBackPrice}</span>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <button type="submit" className="btn btn-primary btn-sm me-2" onClick={handleAddFormSubmit} disabled={loading} >Submit</button>
                        <button type="button" onClick={resetForm} className="btn btn-danger btn-sm"> Discard</button>
                    </div>
                </div>
                <div className="card shadow">
                    <div className="card-header">
                        <span className="h3 mb-0">Buy Back Price List</span>
                    </div>
                    <div className="card-body">
                        <Table responsive className="w-100">
                            <div className="dataTables_wrapper">
                                <table
                                    id="example"
                                    className="display w-100 dataTable"
                                    role="grid"
                                    aria-describedby="example_info"
                                >
                                    <thead>
                                        <th className="text-center">Type</th>
                                        <th className="text-center">Market Price</th>
                                        <th className="text-center">Marking Amount</th>
                                        <th className="text-center">Buy Back Price</th>
                                        <th className="text-center">Action</th>
                                    </thead>
                                    <tbody>
                                    {list.map((d, i) => (
                                        <tr key={i}>
                                            <td className="text-center">{d.type}</td>
                                            <td className="text-center">{d.marketPrice}</td>
                                            <td className="text-center">{d.buyBackAmount === null ? "-" :d.buyBackAmount}</td>
                                            <td className="text-center">{d.buyBackPrice}</td>
                                            <td className="text-center"><span className="icon-action remove" onClick={(e) => handleRemoveBuyBack(e, d.id)} disabled={removeLoading}><i className="fas fa-trash"></i></span></td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(GoldBuyBack);