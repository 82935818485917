import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import { connect } from 'react-redux';
import { api } from "../../../utils/api";
import moment from "moment";
import { Button, Modal } from "react-bootstrap";
import SortingTable from "../../components/SortingTable";
import { dummyData } from "../../../utils/function";

const UserList = (props) => {
    const COLUMNS = [
        {
            Header: 'No.',
            accessor: (row, i) => {
                return ++i + "."
            },
        },
        {
            Header: 'Email',
            accessor: 'email'
        },
        {
            Header: 'Login Id',
            accessor: 'username'
        },
        {
            Header: 'Member Rank',
            accessor: 'member',
        },
        {
            Header: 'Full Name',
            accessor: 'fullName'
        },
        {
            Header: 'Created Datetime',
            accessor: (row) => {
                return moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss')
            }
        },
        {
            Header: 'KYC Status',
            accessor: (row) => {
                if (row.kycStatus) {
                    return <span className="text-capitalize" style={{ color: row.kycStatus === 'pending' ? 'orange' : row.kycStatus === 'approved' ? 'green' : 'red' }}>{row.kycStatus}</span>
                } else {
                    return "-"
                }
            }
        },
        {
            Header: 'Action',
            accessor: (row) => {
                return (
                    <div className="d-flex">
                        <Link className="btn btn-primary shadow btn-xs sharp me-2"
                            to={{
                                pathname: `/user/detail/${row.id}`
                                // data: row.id // your data array of objects
                            }}
                        >
                            <i className="fas fa-eye"></i>
                        </Link>
                        {/* <Link className="btn btn-danger shadow btn-xs sharp me-2" to={{ pathname: `/user/investment/${row.id}` }}>
                            <i className="fas fa-plus"></i>
                        </Link> */}
                        {/* <span className="btn btn-warning shadow btn-xs sharp me-2" onClick={() => handlePasswordModal(row.id)}>
                            <i className="fas fa-key"></i>
                        </span> */}
                        {/* <span className="btn btn-info shadow btn-xs sharp me-2" onClick={() => handleWalletModal(row.id)}>
                            <i class="fas fa-wallet"></i>
                        </span> */}
                        {/* <Link className="btn btn-secondary shadow btn-xs sharp me-2"
                            to={{
                                pathname: "/user/edit",
                                data: row.id 
                            }}
                        >
                            <i className="fas fa-edit"></i>
                        </Link> */}
                        {/* <Link className="btn btn-danger shadow btn-xs sharp me-2" onClick={() => deleteUser(row.id)}>
                            <i className="fas fa-trash"></i>
                        </Link> */}
                    </div>
                )
            },
        },
    ];
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const history = useHistory();
    const [resetPasswordModal, setResetPasswordModal] = useState(false);
    const [resetPasswordForm, setResetPasswordForm] = useState({
        userId: null,
        newPassword: null,
    })
    const [resetPasswordErrorForm, setResetPasswordErrorForm] = useState({
        newPassword: null,
    })
    const [editWalletModal, setEditWalletModal] = useState(false);
    const [editWalletForm, setEditWalletForm] = useState({
        userId: null,
        cashBalance: 0,
        points: 0,
    })
    const [editWalletErrorForm, setEditWalletErrorForm] = useState({
        cashBalance: null,
        points: null,
    })

    const [fileData, setFileData] = useState({
        name: "Gold_Member_List",
        excelData: [],
        hasCustom: false,

    })

    const handlePasswordModal = (id) => {
        if (!resetPasswordModal && id) {
            setResetPasswordForm({
                ...resetPasswordForm,
                userId: id,
                newPassword: null,
            })
        } else {
            setResetPasswordForm({
                ...resetPasswordForm,
                userId: null,
                newPassword: null,
            })
        }
        setResetPasswordModal(!resetPasswordModal);
    }
    const handleWalletModal = (id) => {
        if (!editWalletModal && id) {
            let user = users.find(u => u.id === id);
            console.log(user.wallets);
            setEditWalletForm({
                ...editWalletForm,
                userId: id,
                cashBalance: user.wallets[0].cashBalance,
                points: user.wallets[0].points,
            })
        } else {
            setEditWalletForm({
                ...editWalletForm,
                userId: null,
                cashBalance: 0,
                points: 0,
            })
        }
        setEditWalletModal(!editWalletModal);
    }

    useEffect(() => {
        getList();
    }, [props.auth]);


    const getList = () => {
        setUsers([...dummyData("members")]);
        // setLoading(true);
        // fetch(api.getAllUsers, {
        //     method:"GET",
        //     headers:new Headers({
        //         "Content-Type":"application/JSON",
        //         "Authorization": "Bearer " + props.auth.auth.token
        //     }),
        // }).then(response => response.json())
        // .then(responseJson => {
        //     setLoading(false);
        //     console.log(responseJson) 
        //     if(responseJson.data){
        //         setUsers(responseJson.data)

        //         let excelTable = responseJson.data.map((i,index) => {

        //             return {
        //                 "No.": index + 1,
        //                 "Email": i.email, 
        //                 "Login Id": i.username, 
        //                 "Password Key": i.passwordKey,
        //                 "Taukeholding member Id": i.memberId,
        //                 "Taukeholding member password": i.memberPasswordKey,
        //                 "Full Name": i.fullName,
        //                 "Created Datetime": moment(i.createdAt).format('YYYY-MM-DD HH:mm:ss'),
        //                 "KYC Status": i.kycStatus,
        //             }
        //         })

        //         setFileData({
        //             ...fileData,
        //             excelData: excelTable,
        //             hasCustom: false,
        //             customRows:[],

        //         });
        //     }
        // }).catch(error => { 
        //         console.log("Error",error);
        // });
    }

    const deleteUser = (contentId) => {
        // swal({
        //     title: "Delete data?",
        //     text:
        //       "Once deleted, you will not be able to recover this data anymore!",
        //     icon: "warning",
        //     buttons: true,
        //     dangerMode: true,
        // }).then((willDelete) => {
        //     if (willDelete) {
        //         fetch(api.users + "/" +contentId, {
        //             method:"DELETE",
        //             headers:new Headers({
        //                 "Content-Type":"application/JSON",
        //                 "Authorization": "Bearer " + props.auth.auth.content.token
        //             }),
        //         }).then(response => response.json())
        //         .then(responseJson => {
        //             getList();
        //         }).catch(error => {
        //                 console.log("Error",error);
        //         });
        //     } 
        // })
    }

    const submitPasswordChange = () => {
        // swal({
        //     title: "Confirm Change password?",
        //     text: "Once change, you will not be able to recover this previous password!",
        //     icon: "warning",
        //     buttons: true,
        //     dangerMode: true,
        // }).then((willDelete) => {
        //     let check = true;
        //     console.log(resetPasswordForm);
        //     let error = {newPassword: null};
        //     if (!resetPasswordForm.userId) {
        //         swal('Oops!', 'User not found', "error");
        //         check = false;
        //         handlePasswordModal();
        //     }
        //     if (!resetPasswordForm.newPassword) {
        //         error.newPassword = 'This is required';
        //         check = false;
        //     }
        //     if (resetPasswordForm.newPassword && resetPasswordForm.newPassword.length < 6) {
        //         error.newPassword = 'Min password length is 6';
        //         check = false;
        //     }
        //     setResetPasswordErrorForm(error);
        //     if (check) {
        //         if (willDelete) {
        //             fetch(api.users + `/users/${resetPasswordForm.userId}/password/update`, {
        //                 method:"PUT",
        //                 headers:new Headers({
        //                     "Content-Type":"application/JSON",
        //                     "Authorization": "Bearer " + props.auth.auth.token
        //                 }),
        //                 body: JSON.stringify(resetPasswordForm),
        //             }).then(response => response.json())
        //             .then(responseJson => {
        //                 swal('Success!', responseJson.message, "success");
        //                 getList();
        //                 handlePasswordModal();
        //             }).catch(error => {
        //                 console.log("Error",error);
        //             });
        //         } 
        //     }
        // })
    }

    const submitWalletChanges = () => {
        // swal({
        //     title: "Confirm change wallet amount?",
        //     text: "Once change, you will not be able to recover previous amount!",
        //     icon: "warning",
        //     buttons: true,
        //     dangerMode: true,
        // }).then((willDelete) => {
        //     if (willDelete) {
        //         let check = true;
        //         let error = {cashBalance: null, points: null};
        //         if (!editWalletForm.userId) {
        //             swal('Oops!', 'User not found', "error");
        //             check = false;
        //             handlePasswordModal();
        //         }
        //         if (!editWalletForm.cashBalance) {
        //             error.cashBalance = 'This is required';
        //             check = false;
        //         }
        //         if (editWalletForm.cashBalance < 0) {
        //             error.cashBalance = 'Amount cannot lower than 0';
        //             check = false;
        //         }
        //         if (!editWalletForm.points) {
        //             error.points = 'This is required';
        //             check = false;
        //         }
        //         if (editWalletForm.points < 0) {
        //             error.points = 'Amount cannot lower than 0';
        //             check = false;
        //         }
        //         setEditWalletErrorForm(error);
        //         if (check) {
        //             fetch(api.users + `/users/${editWalletForm.userId}/wallet/update`, {
        //                 method:"PUT",
        //                 headers:new Headers({
        //                     "Content-Type":"application/JSON",
        //                     "Authorization": "Bearer " + props.auth.auth.token
        //                 }),
        //                 body: JSON.stringify(editWalletForm),
        //             }).then(response => response.json())
        //             .then(responseJson => {
        //                 swal('Success!', responseJson.message, "success");
        //                 getList();
        //                 handleWalletModal();
        //             }).catch(error => {
        //                 console.log("Error",error);
        //             });
        //         } 
        //     }
        // })
    }

    return (
        <div className="col-12">
            <div className="row page-titles mx-0 shadow">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                        <span>Members</span>
                    </li>
                    <li className="breadcrumb-item">
                        <span>List</span>
                    </li>
                </ol>
            </div>
            <div className="card shadow">
                <div className="card-header">
                    <Link className="btn btn-outline-primary btn-sm pull-right" to="/user/new">Add New Member</Link>
                </div>
                <div className="card-body">
                    {loading ? (
                        <div className="text-center">
                            <i className="fas fa-spinner fa-pulse fa-3x"></i>
                        </div>
                    ) : (
                        <div className="w-100">
                            <div id="job_data" className="dataTables_wrapper">
                                <SortingTable columns={COLUMNS} data={users} excelExport={true} fileData={fileData} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Modal show={resetPasswordModal} onHide={handlePasswordModal} centered>
                <Modal.Header>
                    <Modal.Title>Change password</Modal.Title>
                    <div className="ms-auto">
                        <i
                            class="fa fa-times cursor-pointer"
                            aria-hidden="true"
                            onClick={handlePasswordModal}
                        >
                            {" "}
                        </i>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <label className="col-sm-12 col-form-label col-form-label">
                        New Password
                    </label>
                    <input type="password" className="form-control" onChange={(e) => setResetPasswordForm({ ...resetPasswordForm, newPassword: e.target.value })} value={resetPasswordForm.newPassword} />
                    <label className="error">
                        {resetPasswordErrorForm.newPassword}
                    </label>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="btn btn-danger profile-button btn-sm text-white me-2"
                        onClick={submitPasswordChange}
                    >
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={editWalletModal} onHide={handleWalletModal} centered>
                <Modal.Header>
                    <Modal.Title>User wallet</Modal.Title>
                    <div className="ms-auto">
                        <i
                            class="fa fa-times cursor-pointer"
                            aria-hidden="true"
                            onClick={handleWalletModal}
                        >
                            {" "}
                        </i>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <label className="col-sm-12 col-form-label col-form-label">
                        Cash Balance
                    </label>
                    <input type="number" className="form-control" onChange={(e) => setEditWalletForm({ ...editWalletForm, cashBalance: e.target.value })} value={editWalletForm.cashBalance} />
                    <label className="error">
                        {editWalletErrorForm.cashBalance}
                    </label>
                    <label className="col-sm-12 col-form-label col-form-label">
                        Points
                    </label>
                    <input type="number" className="form-control" onChange={(e) => setEditWalletForm({ ...editWalletForm, points: e.target.value })} value={editWalletForm.points} />
                    <label className="error">
                        {editWalletErrorForm.points}
                    </label>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="btn btn-danger profile-button btn-sm text-white me-2"
                        onClick={submitWalletChanges}
                    >
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};


export default connect(mapStateToProps)(UserList);
