import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { connect } from 'react-redux';
import Select, { createFilter } from "react-select";
import swal from "sweetalert";
import { api } from "../../../utils/api";

const periodOption = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10", value: "10" },
    { label: "11", value: "11" },
    { label: "12", value: "12" },
]
function NewDigitalContract(props) {
    const history = useHistory(); 
    const requiredField = ["rate", "period"];
    const [addFormData, setAddFormData] = useState({
        rate: '',
        period: '',
        branchId: '',
        name: '',
        weight: '',
    });
    const [error, setError] = useState({
        rate: '',
        period: '',
        branchId: '',
        name: '',
        weight: '',
    });
    const [loading, setLoading] = useState(false);
    const [branch, setBranch] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        getBranch();
    }, [props.auth]);

    const getBranch = () => {
        setLoading(true);
        fetch(api.branch + "/?id=" + id, {
            method:"GET",
            headers:new Headers({
                "Content-Type":"application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        }).then(response => response.json())
        .then(responseJson => {
            setLoading(false);
            console.log(responseJson); 
            if(responseJson.status === 'success'){
                if(responseJson.data){
                    setBranch( responseJson.data[0]);
                    // let data = {
                    //     name: responseJson.data[0].name,
                    //     location: responseJson.data[0].location,
                    //     address: responseJson.data[0].address,
                    //     email: responseJson.data[0].email,
                    //     phone: responseJson.data[0].phone,
                    //     investmentMax: responseJson.data[0].investmentMax,
                    // }
                    // setAddFormData(data);
                }
            }
            
        }).catch(error => { 
                console.log("Error",error);
        });
    }

    const createContract = () => {
        let check = true;
        let errorMsg = { 
            rate: '',
            period: '',
            branchId: '',
            name: '',
            weight: '',
        };
        requiredField.map((item, i)=>{
            if(!addFormData[item]){
                check = false;
                errorMsg[item] = "This field is required"
            }
        });

        

        setError(errorMsg);
        if(check){
            setLoading(true);
            // let arr = addFormData.weight.split(',');
            // let uniqueWeights = arr.filter((c, index) => {
            //     return arr.indexOf(c) === index && c!=="";
            // });

            const postData = {
                rate: addFormData.rate,
                period: addFormData.period,
                branchId: id,
                type: "digital",
            };
            fetch(api.contracts, {
                method:"POST",
                headers:new Headers({
                    "Content-Type":"application/JSON",
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(postData)
            })
            .then(response => response.json())
            .then(responseJson => {
                setLoading(false);
                if(responseJson.status === 'success'){
                    swal('Good job!', "Digital Program created successful.", "success");
                    history.push(`/branch/${id}/contract/digital`);
                }else{
                    swal(responseJson.message, {
                        icon: "error",
                    });
                }
            }).catch(error => {
                console.log("Error",error);
            });
        }
    }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...addFormData };
        newFormData[fieldName] = fieldValue;
        setAddFormData(newFormData);
    };

    const handleOptionSelected = (e, name) => {
        const { label, value } = e;
        const info = {...addFormData};
        info[name] = value;
        setAddFormData(info);
    }

    function CheckNumeric(e) {
        if((e.charCode<48 || e.charCode > 57)){
            if(e.charCode !== 44 && e.charCode !== 46){
                e.preventDefault();
                return false;
            }
        }
        if (window.event) // IE
        {
            if ((e.keyCode <48 || e.keyCode > 57) & e.keyCode != 8 && e.keyCode != 44  && e.keyCode != 46) {
                e.returnValue = false;
                return false;
            }
        }
        else { // Fire Fox
            if ((e.which <48 || e.which > 57) & e.which != 8 && e.which != 44 && e.which != 46) {
                e.preventDefault();
                return false;
            }
        }
    }     

    return (
        <>
            <div className="col-12">
                <div className="row page-titles mx-0 shadow">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active">
                            <span>Branch</span>
                        </li>
                        <li className="breadcrumb-item active">
                            <span>GSP+ List</span>
                        </li>
                        <li className="breadcrumb-item active">
                            <span>e-Gold</span>
                        </li>
                        <li className="breadcrumb-item">
                            <span>Create</span>
                        </li>
                    </ol>
                </div>

                <div className="card shadow">
                    <div className="card-body">
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                Branch
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control form-control"
                                    placeholder=""
                                    name="branch"
                                    value={branch ? branch.name : ""}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                Period (Month)
                            </label>
                            <div className="col-sm-10">
                                <Select
                                    className="form-control"
                                    options={periodOption}
                                    placeholder="- Select -"
                                    style={{
                                        lineHeight: "40px",
                                        color: "#7e7e7e",
                                        paddingLeft: " 15px",
                                        borderWidth: 0,
                                    }}
                                    onChange={e=> handleOptionSelected(e, 'period')}
                                />
                                <span className="error">{error.period}</span>
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                Investment Percentage (%)
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="number"
                                    className="form-control form-control"
                                    placeholder=""
                                    name="rate"
                                    onChange={handleAddFormChange}
                                    value={addFormData.rate}
                                />
                                <span className="error">{error.rate}</span>
                            </div>
                        </div>
                        {/* <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                Weight of Gold(gram)
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control form-control"
                                    placeholder=""
                                    name="weight"
                                    onChange={handleAddFormChange}
                                    value={addFormData.weight}
                                    onKeyPress={e => CheckNumeric(e)}
                                />
                                <span>Use comma separated value for multiple acceptable of gram.</span>
                                <span className="error">{error.weight}</span>
                            </div>
                        </div> */}
                        
                    </div>
                        
                    <div className="card-footer">
                        <button className="btn btn-primary profile-button btn-sm text-white me-2" disabled={loading} onClick={createContract}>
                            {loading ? (<i className="fas fa-spinner fa-spin"></i>) : "Submit"}
                        </button>
                        <Link className="btn btn-danger profile-button btn-sm text-white" to={`/branch/${id}/contract/digital`}>Discard</Link>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(NewDigitalContract);
