import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import swal from "sweetalert";
import { api } from "../../../utils/api";

function NewAdmin(props) {
    const history = useHistory(); 
    const requiredField = ["firstName", "lastName", "email", "username", "password", "confirmPassword", "phone"];
    const [addFormData, setAddFormData] = useState({
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
    });
    const [error, setError] = useState({
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
    });
    const [loading, setLoading] = useState(false);

    const createAdmin = () => {
        let check = true;
        let errorMsg = { firstName: null, lastName: null, email: null, username: null, password: null, confirmPassword: null, phone: null };
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        requiredField.map((item, i)=>{
            if(!addFormData[item]){
                check = false;
                errorMsg[item] = "This field is required"
            }
        });
        if(addFormData.password){
            if(addFormData.password.length <6){
                check = false;
                errorMsg.password = "The password need at least 6 characters long."
            }
        }

        if(addFormData.password && addFormData.confirmPassword){
            if(addFormData.password !== addFormData.confirmPassword){
                check = false;
                errorMsg.confirmPassword = "Password and confirmed password are not matched";
            }
        }

        if(addFormData.email){
            if(!reg.test(addFormData.email)){
                check = false;
                errorMsg.email = "Invalid email."
            }
        }

        setError(errorMsg);

        if(check){
            setLoading(true);
            const postData = {
                firstName: addFormData.firstName,
                lastName: addFormData.lastName,
                username: addFormData.username,
                email: addFormData.email,
                phone: addFormData.phone,
                password: addFormData.password,
                confirmPassword: addFormData.confirmPassword,
            };
            fetch(api.users, {
                method:"POST",
                headers:new Headers({
                    "Content-Type":"application/JSON",
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(postData)
            })
            .then(response => response.json())
            .then(responseJson => {
                setLoading(false);
                if(responseJson.status === 'success'){
                    swal('Good job!', responseJson.message, "success");
                    history.push('/admin/list');
                }else{
                    swal(responseJson.message, {
                        icon: "error",
                    });
                }
            }).catch(error => {
                console.log("Error",error);
            });
        }
    }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...addFormData };
        newFormData[fieldName] = fieldValue;
        setAddFormData(newFormData);
    };

    return (
        <>
            <div className="col-12">
                <div className="row page-titles mx-0 shadow">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active">
                            <span>Admin</span>
                        </li>
                        <li className="breadcrumb-item">
                            <span>New Admin</span>
                        </li>
                    </ol>
                </div>

                <div className="card shadow">
                    <div className="card-body">
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                First Name
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control form-control"
                                    placeholder=""
                                    name="firstName"
                                    onChange={handleAddFormChange}
                                    value={addFormData.firstName}
                                />
                                <span className="error">{error.firstName}</span>
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                Last Name
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control form-control"
                                    placeholder=""
                                    name="lastName"
                                    onChange={handleAddFormChange}
                                    value={addFormData.lastName}
                                />
                                <span className="error">{error.lastName}</span>
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                Username
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control form-control"
                                    placeholder=""
                                    name="username"
                                    onChange={handleAddFormChange}
                                    value={addFormData.username}
                                />
                                <span className="error">{error.username}</span>
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                            Contact Number
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control form-control"
                                    placeholder=""
                                    name="phone"
                                    onChange={handleAddFormChange}
                                    value={addFormData.phone}
                                />
                                <span className="error">{error.phone}</span>
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                            Email
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="email"
                                    className="form-control form-control"
                                    placeholder=""
                                    name="email"
                                    onChange={handleAddFormChange}
                                    value={addFormData.email}
                                />
                                <span className="error">{error.email}</span>
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                Password
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="password"
                                    className="form-control form-control"
                                    placeholder=""
                                    name="password"
                                    onChange={handleAddFormChange}
                                    value={addFormData.password}
                                />
                                <span className="error">{error.password}</span>
                            </div>
                        </div>
                        
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                            Confirm Password
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="password"
                                    className="form-control form-control"
                                    placeholder=""
                                    name="confirmPassword"
                                    onChange={handleAddFormChange}
                                    value={addFormData.confirmPassword}
                                />
                                <span className="error">{error.confirmPassword}</span>
                            </div>
                        </div>
                        
                    </div>
                    <div className="card-footer">
                        <button className="btn btn-primary profile-button btn-sm text-white me-2" onClick={createAdmin}>
                            {loading ? (<i class="fas fa-spinner fa-spin"></i>) : "Submit"}
                        </button>
                        <Link className="btn btn-danger profile-button btn-sm text-white" to="/admin/list">Discard</Link>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(NewAdmin);
