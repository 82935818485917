import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import swal from "sweetalert";
import { connect } from 'react-redux';
import { api, url } from "../../../utils/api";
import moment from "moment";
import { Button, Dropdown, Modal } from "react-bootstrap";
import Select from "react-select";
import SortingTable from "../../components/SortingTable";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { urlParamsLocalStorage, reactSelectStyle, dummyData, dateTimeStyle, } from "../../../utils/function";

const PurchaseList = (props) => {
    const COLUMNS = [
        {
            Header: 'No.',
            accessor: (row, i) => {
                return i + 1;
            }
        }, {
            Header: 'Inv no.',
            accessor: (row) => {
                return row.invoiceNumber
            },
            Cell: (props) => {
                let data = props.row.original;
                return (
                    <>
                        {
                            data.status === 'active' && data.claimReferId == null ? (
                                // <a className="blue-hover text-nowrap" href={`${url}/pdf/${data.purchaseId}.pdf`} target="_blank">{props.cell.value}</a>
                                <p className="text-nowrap mb-0">{props.cell.value}</p>
                            ) : data.status === 'active' && data.claimReferId !== null ? (
                                <></>
                            ) : <></>
                        }
                    </>
                )
            }
        },
        {
            Header: 'Ref no.',
            accessor: (row) => {
                return row.purchaseId
            }
        },
        {
            Header: 'Member Id',
            accessor: (row) => {
                return row.user.memberId;
            }
        },
        {
            Header: 'Member Name',
            accessor: (row) => {
                return row.user.fullName;
            }
        },
        {
            Header: 'Amount',
            accessor: (row) => {
                return (row.amount ?? 0) + "g";
            }
        },
        {
            Header: 'Type',
            accessor: (row) => {
                return row.type === 'egold' ? 'GSP' : 'FGR';
            }
        },
        {
            Header: 'Collect Method',
            accessor: (row) => {
                return row.delivery === 'delivery' ? 'Delivery' : row.delivery === 'selfCollect' ? 'Self Collect' : 'Wallet'
            }
        },
        {
            Header: 'Self-Collect Location',
            accessor: (row) => {
                return row.branch ? row.branch.name : "-"
            }
        },
        {
            Header: 'Total Payment',
            accessor: (row) => {
                return 'RM ' + (row.totalPrice).toFixed(2);
            },
        },
        {
            Header: 'Payment Method',
            accessor: (row) => {
                return row.paymentType === 'online' ? 'Online' : row.paymentType === 'onlineAndPoint' ? 'Online & Point' : row.paymentType === 'point' ? 'Point' : row.paymentType === 'free' ? 'Free Gold Reward' : '-'
            }
        },
        {
            Header: 'Delivery Status',
            accessor: (row) => {
                return <span className={`text-capitalize status-box ${row.deliveryStatus}`}>{row.deliveryStatus}</span>
            }
        },
        {
            Header: 'Delivery Date',
            accessor: (row) => {
                return row.deliveryDate !== null ? moment(row.deliveryDate).format('YYYY-MM-DD HH:mm:ss') : '-'
            },
            Cell: (props) => {
                return (<span className="text-nowrap">{props.cell.value}</span>)
            }
        },
        {
            Header: 'Created Datetime',
            accessor: (row) => {
                return moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss')
            },
            Cell: (props) => {
                return (<span className="text-nowrap">{props.cell.value}</span>)
            }
        },
        {
            Header: 'Action',
            accessor: (row) => {
                return (
                    <>
                        <Link to={"/purchase/details/" + row.id} onClick={() => storeParams()} className="text-primary">
                            <i className="fas fa-eye"></i>
                        </Link>
                        {/* {row.type === 'egold' ? <></> :
                            //    {row.type === 'egold' || (row.programReferId === null) ? <></> : 
                            (<span className="ms-3 text-warning cursor-pointer" onClick={() => handleSwitchContractModal(row.id, row.user.id, row.amount, row.purchaseId, row.programReferId)}>
                                <i className="fas fa-random"></i>
                            </span>)} */}
                    </>
                )
            }
        },
    ];

    const OUTLETADMINCOLUMNS = [
        {
            Header: 'No.',
            accessor: (row, i) => {
                return i + 1;
            }
        }, {
            Header: 'Inv no.',
            accessor: (row) => {
                return row.invoiceNumber
            }
        },
        {
            Header: 'Ref no.',
            accessor: (row) => {
                return row.purchaseId
            }
        },
        {
            Header: 'Member Id',
            accessor: (row) => {
                return row.user.memberId;
            }
        },
        {
            Header: 'Member Name',
            accessor: (row) => {
                return row.user.fullName;
            }
        },
        {
            Header: 'Amount',
            accessor: (row) => {
                return (row.amount ?? 0) + "g";
            }
        },
        {
            Header: 'Type',
            accessor: (row) => {
                return row.type === 'egold' ? 'GSP' : 'FGR';
            }
        },
        {
            Header: 'Collect Method',
            accessor: (row) => {
                return row.delivery === 'delivery' ? 'Delivery' : row.delivery === 'selfCollect' ? 'Self Collect' : 'Wallet'
            }
        },
        {
            Header: 'Delivery Status',
            accessor: (row) => {
                return <span className={`text-capitalize status-box ${row.deliveryStatus}`}>{row.deliveryStatus}</span>
            }
        },
        {
            Header: 'Delivery Date',
            accessor: (row) => {
                return row.deliveryDate !== null ? moment(row.deliveryDate).format('YYYY-MM-DD HH:mm:ss') : '-'
            },
            Cell: (props) => {
                return (<span className="text-nowrap">{props.cell.value}</span>)
            }
        },
        {
            Header: 'Created Datetime',
            accessor: (row) => {
                return moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss')
            },
            Cell: (props) => {
                return (<span className="text-nowrap">{props.cell.value}</span>)
            }
        },
        {
            Header: 'Action',
            accessor: (row) => {
                return (
                    <>
                        <Link to={"/purchase/details/" + row.id} onClick={() => storeParams()} className="text-primary">
                            <i className="fas fa-eye"></i>
                        </Link>

                    </>
                )
            }
        },
    ];

    const [loading, setLoading] = useState(false);
    const [contents, setContents] = useState([]);
    const [filterOption, setFilterOption] = useState("all");
    const [allData, setAllData] = useState([]);
    const [mainGP, setMainGP] = useState([]);
    const [filteredMainGP, setFilteredMainGP] = useState([]);
    const [error, setError] = useState([]);
    const [dateRange, setDateRange] = useState({
        startDate: moment().startOf('month'),
        endDate: moment().endOf('month'),
    });
    const [fileData, setFileData] = useState({
        name: "Gold_Purchase_List",
        excelData: [],
        hasCustom: false,

    })
    const [filterOptions, setFilterOptions] = useState({
        delivery: {
            name: 'delivery', status: [
                { label: 'all', value: 'all' },
                { label: 'pending', value: 'pending' },
                { label: 'shipped', value: 'shipped' },
                { label: 'completed', value: 'completed' },
            ], value: 'all',
        },
    })
    const [switchContractForm, setSwitchContractForm] = useState({
        fromId: null,
        toId: null,
        fromPurchaseId: null,
        toPurchaseId: null,
        currentReferPurchaseId: null,
        toPurchaseIdRemainingMonth: 0,
    })
    const [switchContractModal, setSwitchContractModal] = useState(false);
    const history = useHistory();
    const [searchText, setSearchText] = useState("");
    const [currentPageNumber, setCurrentPageNumber] = useState(0);

    const handleSwitchContractModal = (id, userid, amount, purchaseId, programReferId) => {
        fetch(api.users + `/golds/maingp?basic=1&userId=${userid}&amount=${amount}`, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        }).then(response => response.json())
            .then(responseJson => {
                setLoading(false);
                if (responseJson.data) {
                    if (!switchContractModal && responseJson.data.length > 0 && id) {
                        let currentReferPurchaseDate = responseJson.data.find(i => i.id == programReferId);
                        let refer = responseJson.data.filter(i => i.purchaseId !== (currentReferPurchaseDate?.purchaseId) && i.purchaseId !== purchaseId).map(i => { return { label: i.purchaseId, value: i.id } });
                        setFilteredMainGP([...refer]);
                        setSwitchContractForm({
                            ...switchContractForm,
                            fromId: id,
                            currentReferPurchaseId: currentReferPurchaseDate && currentReferPurchaseDate.purchaseId ? `${currentReferPurchaseDate.purchaseId} (${moment(currentReferPurchaseDate.createdAt).format('yyyy-MM-DD')}) (${currentReferPurchaseDate.program} months)` : null,
                            fromPurchaseId: `${purchaseId} (${amount}g)`,
                        })
                    } else {
                        setSwitchContractForm({
                            ...switchContractForm,
                            fromId: null,
                            toId: null,
                            fromPurchaseId: null,
                            toPurchaseId: null,
                            currentReferPurchaseId: null,
                            toPurchaseIdRemainingMonth: 0,
                        })
                    }
                    setSwitchContractModal(!switchContractModal);
                    // getList(dateRange.startDate, dateRange.endDate);
                }

            }).catch(error => {
                setLoading(false);
                console.log("Error", error);
            });
    }

    const getSearchText = (e) => {
        setSearchText(e)
    }

    const getTablePageNumber = (e) => {
        setCurrentPageNumber(e);
    }

    const storeParams = () => {
        let urlData = {
            path: history.location.pathname,
            name: "purchaseList",
            query: {
                startDate: dateRange.startDate,
                endDate: dateRange.endDate,
                search: searchText,
                deliveryStatus: filterOption,
                tablePageNumber: currentPageNumber,
            }
        };
        urlParamsLocalStorage('set', urlData);
    }

    const updateFilter = (type, data) => {
        let temp = filterOptions[type];
        temp.value = data;
        if (allData.length > 0) {
            if (filterOptions === 'all') {
                setContents(allData);
            } else {
                let list = allData.filter(arr => arr.deliveryStatus === filterOption);
                setContents(list);
                setFileData({
                    ...fileData,
                    excelData: list,
                    hasCustom: false,
                    customRows: [],

                });
            }
        }
        setFilterOptions({ ...filterOptions, [type]: temp });
    }

    useEffect(() => {
        let urlParams = urlParamsLocalStorage('get', { path: history.location.pathname, name: "purchaseList" });
        if (urlParams) {
            getList(urlParams.query.startDate, urlParams.query.endDate);
            setDateRange({
                startDate: moment(urlParams.query.startDate),
                endDate: moment(urlParams.query.endDate),
            })
            setSearchText(urlParams.query.search);
            setCurrentPageNumber(urlParams.query.tablePageNumber);
        } else {
            getList(dateRange.startDate, dateRange.endDate)
        }
    }, [props.auth]);

    const getList = (start, end) => {
        let data = dummyData("goldPurchase");
        setAllData([...data])
        setContents([...data])
        // setLoading(true);
        // fetch(api.users + `/golds/purchase?basic=1&start=${moment(start).format('YYYY-MM-DD 00:00:00')}&end=${moment(end).format('YYYY-MM-DD 23:59:59')}`, {
        //     method: "GET",
        //     headers: new Headers({
        //         "Content-Type": "application/JSON",
        //         "Authorization": "Bearer " + props.auth.auth.token
        //     }),
        // }).then(response => response.json())
        //     .then(responseJson => {
        //         setLoading(false);
        //         // console.log(responseJson) 
        //         if (responseJson.data) {
        //             setAllData(responseJson.data);
        //             setContents(responseJson.data);
        //             let mGP = responseJson.data.map(dt => {
        //                 if (dt.delivery === 'delivery' && dt.deliveryStatus === 'pending' && dt.type === "gold") { pendingDelivery = pendingDelivery + dt.amount; }
        //                 if ((dt.amount !== null && dt.totalPrice !== null) && (dt.type === "gold" && ['online', 'onlineAndPoint', 'point'].includes(dt.paymentType))) {
        //                     totalGram = totalGram + dt.amount;
        //                     totalPrice = totalPrice + dt.totalPrice;
        //                 }
        //                 if ((dt.amount !== null && dt.totalPrice !== null) && (dt.type === "egold" && ['online', 'onlineAndPoint', 'point'].includes(dt.paymentType))) {
        //                     totalGramGSP = totalGramGSP + dt.amount;
        //                     totalPriceGSP = totalPriceGSP + dt.totalPrice;
        //                 }
        //                 if (dt.amount !== null && dt.totalPrice !== null && dt.deliveryStatus === 'pending' && dt.type === 'gold') {
        //                     pendingGram = pendingGram + dt.amount;
        //                     pendingPrice = pendingPrice + dt.totalPrice;
        //                 }
        //                 if ((dt.pointUsed !== null || dt.pointUsed > 0) && (dt.type === "gold" && dt.paymentType === 'onlineAndPoint' || dt.paymentType === 'point')) {
        //                     totalPoint = totalPoint + dt.pointUsed;
        //                 }
        //                 if ((dt.pointUsed !== null || dt.pointUsed > 0) && (dt.type === "egold" && dt.paymentType === 'onlineAndPoint' || dt.paymentType === 'point')) {
        //                     totalPointGSP = totalPointGSP + dt.pointUsed;
        //                 }
        //             })

        //             let excelTable = responseJson.data.map((i, index) => {
        //                 return {
        //                     "No.": index + 1,
        //                     "Inv no.": i.invoiceNumber,
        //                     "Ref no.": i.purchaseId,
        //                     "Member Id": i.user.memberId,
        //                     "Member Name": i.user.fullName,
        //                     "Amount": i.amount + "g",
        //                     "Type": i.type === 'egold' ? 'GSP' : 'FGR',
        //                     "Collect Method": i.delivery === 'delivery' ? 'Delivery' : i.delivery === 'selfCollect' ? 'Self Collect' : 'Wallet',
        //                     "Self Collect Location": i.branch ? i.branch.name : "-",
        //                     "Total Payment": "RM " + i.totalPrice,
        //                     "Delivery Status": i.deliveryStatus,
        //                     "Delivery Date": i.deliveryDate ? moment(i.deliveryDate).format('YYYY-MM-DD HH:mm:ss') : "-",
        //                     "Created Datetime": moment(i.createdAt).format('YYYY-MM-DD HH:mm:ss')
        //                 }
        //             })
        //             // console.log("Excel Table:",excelTable);
        //             setSummaryData({ ...summaryData, pendingDelivery });
        //             setSummaryTotalSaleData({ ...summaryTotalSaleData, totalGram, totalPrice })
        //             setSummaryTotalSaleGSP({ ...summaryTotalSaleGSP, totalGram: totalGramGSP, totalPrice: totalPriceGSP })
        //             setSummaryTotalPendingFGR({ ...summaryTotalPendingFGR, pendingGram, pendingPrice })
        //             setSummaryTotalPointWallet({ ...summaryTotalPointWallet, totalPoint })
        //             setSummaryTotalSalePointGSP({ ...summaryTotalSalePointGSP, totalPoint: totalPointGSP })
        //             // console.log(mGP, "filter");
        //             //setMainGP([...mGP]);
        //             getDashboardData();
        //             setFileData({
        //                 ...fileData,
        //                 excelData: excelTable,
        //                 hasCustom: false,
        //                 customRows: [],

        //             });
        //         }

        //     }).catch(error => {
        //         setLoading(false);
        //         console.log("Error", error);
        //     });
    }

    const getDashboardData = () => {
        // fetch(api.users + `/dashboard`, {
        //     method: "GET",
        //     headers: new Headers({
        //         "Content-Type": "application/JSON",
        //         "Authorization": "Bearer " + props.auth.auth.token
        //     }),
        // }).then((response) => response.json())
        //     .then((responseJson) => {
        //         let dashboardData = responseJson.data;
        //         setDashboard({ ...dashboardData });
        //     }).catch((error) => {
        //         console.log(error);
        //     })
    }

    function onDateChange(e, name) {
        let temp = { ...dateRange };
        temp[name] = e;
        getList(temp.startDate, temp.endDate);
        setDateRange(temp);
    }

    const handleOptionSelected = (e) => {
        let remaining = 0;
        allData.forEach((item) => {
            if (item.programReferId === e.value.toString()) remaining++;
        });
        setSwitchContractForm({
            ...switchContractForm,
            toId: e.value,
            toPurchaseId: e.label,
            toPurchaseIdRemainingMonth: remaining,
        })
    }

    const submitContractChanges = () => {
        let check = true;
        let id = switchContractForm.fromId;
        if (check) {
            fetch(api.users + `/golds/repurchase/${id}/migrate`, {
                method: "put",
                headers: new Headers({
                    "Content-Type": "application/JSON",
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(switchContractForm)
            }).then(response => response.json())
                .then(responseJson => {
                    setLoading(false);
                    if (responseJson.status === 'success') {
                        swal('Success!', responseJson.message, "success");
                    } else {
                        swal('Oops!', responseJson.message, "error");
                    }
                    getList();
                    handleSwitchContractModal();
                }).catch(error => {
                    setLoading(false);
                    console.log("Error", error);
                });
        }
    }

    return (
        <>
            <div className="row page-titles mx-0 shadow">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                        <span>Purchase</span>
                    </li>
                    <li className="breadcrumb-item">
                        <span>List</span>
                    </li>
                    {/* <Link to="/restock/list" className="ms-auto btn btn-sm btn-primary text-white">Go To Restock</Link> */}
                </ol>
            </div>
            <div className="card shadow card-black">
                {/* {props.auth.auth.branchId > 0 ? <></> :
                    <div className="card-header row">
                        <div className="row">
                            <div className="col-12 col-md-4 h-100">
                                <div className="card card-small box-shadow-light bg-warning h-auto">
                                    <div className="card-body row">
                                        <label className="text-white h5 me-2 mb-0 text-uppercase">
                                            Total Pending Delivery:
                                        </label>
                                        <label className="text-white h5 mb-0">
                                            {summaryData.pendingDelivery} g
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 h-100">
                                <div className="card card-small box-shadow-light bg-warning h-auto">
                                    <div className="card-body">
                                        <label className="text-white h5 me-2 mb-0 text-uppercase">
                                            Total FGR:
                                        </label>
                                        <div className="d-flex flex-row gap-1 flex-wrap">
                                            <label className="text-white h5 mb-0 text-nowrap">
                                                {parseFloat(summaryTotalSaleData.totalGram ?? 0).toFixed(2)} g
                                            </label>
                                            <label className="text-white h5 mb-0 text-nowrap">
                                                (RM {parseFloat(summaryTotalSaleData.totalPrice ?? 0).toFixed(2)})

                                            </label>
                                            <label className="text-white h5 mb-0 text-nowrap">
                                                ({(summaryTotalPointWallet.totalPoint ?? 0).toFixed(2)} Point)
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="card card-small box-shadow-light bg-warning h-auto">
                                    <div className="card-body">
                                        <label className="text-white h5 me-2 mb-0 text-uppercase">
                                            Total GSP:
                                        </label>
                                        <div className="d-flex flex-row gap-1 flex-wrap">
                                            <label className="text-white h5 mb-0 text-nowrap">
                                                {parseFloat(summaryTotalSaleGSP.totalGram ?? 0).toFixed(2)} g
                                            </label>
                                            <label className="text-white h5 mb-0 text-nowrap">
                                                (RM {parseFloat(summaryTotalSaleGSP.totalPrice ?? 0).toFixed(2)})
                                            </label>
                                            <label className="text-white h5 mb-0 text-nowrap">
                                                ({(summaryTotalSalePointGSP.totalPoint ?? 0).toFixed(2)} Point)
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="card card-small box-shadow-light bg-warning h-auto">
                                    <div className="card-body">
                                        <label className="text-white h5 me-2 mb-0 text-uppercase">
                                            Total Pending FGR:
                                        </label>
                                        <div className="d-flex flex-row gap-1 flex-wrap">
                                            <label className="text-white h5 mb-0 text-nowrap">
                                                {parseFloat(summaryTotalPendingFGR.pendingGram ?? 0).toFixed(2)} g
                                            </label>
                                            <label className="text-white h5 mb-0 text-nowrap">
                                                (RM {parseFloat(summaryTotalPendingFGR.pendingPrice ?? 0).toFixed(2)})
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <div className="card card-small box-shadow-light bg-warning h-auto">
                                    <div className="card-body">
                                        <label className="text-white h5 me-2 mb-0 text-uppercase">
                                            Daily Purchase Gold:
                                        </label>
                                        <div className="d-flex flex-row gap-1 flex-wrap">
                                            <label className="text-white h5 mb-0 text-nowrap">
                                                {parseFloat(dashboard.dailyTotalGoldPurchases ?? 0).toFixed(2)}g
                                            </label>
                                            <label className="text-white h5 mb-0 text-nowrap">
                                                (RM {parseFloat(dashboard.dailyTotalGoldPurchasesMYR ?? 0).toFixed(2)})
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="card card-small box-shadow-light bg-warning h-auto">
                                    <div className="card-body">
                                        <label className="text-white h5 me-2 mb-0 text-uppercase">
                                            Monthly Purchase Gold:
                                        </label>
                                        <div className="d-flex flex-row gap-1 flex-wrap">
                                            <label className="text-white h5 mb-0 text-nowrap">
                                                {parseFloat(dashboard.monthlyTotalGoldPurchases ?? 0).toFixed(2)}g
                                            </label>
                                            <label className="text-white h5 mb-0 text-nowrap">
                                                (RM {parseFloat(dashboard.monthlyTotalGoldPurchasesMYR ?? 0).toFixed(2)})
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="card card-small box-shadow-light bg-warning h-auto">
                                    <div className="card-body">
                                        <label className="text-white h5 me-2 mb-0 text-uppercase">
                                            Annual Purchase Gold:
                                        </label>
                                        <div className="d-flex flex-row gap-1 flex-wrap">
                                            <label className="text-white h5 mb-0 text-nowrap">
                                                {parseFloat(dashboard.annualTotalGoldPurchases ?? 0).toFixed(2)}g
                                            </label>
                                            <label className="text-white h5 mb-0 text-nowrap">
                                                (RM {parseFloat(dashboard.annualTotalGoldPurchasesMYR ?? 0).toFixed(2)})
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                } */}
                <div className="card-body">
                    <div className="d-flex flex-column align-items-center mb-3">
                        <div className="d-flex flex-column flex-sm-row gap-1 gap-sm-3 align-items-start align-items-sm-center justify-content-start w-100">
                            <span className="text-nowrap">Filter by delivery status:</span>
                            <Select
                                className="form-control gold-input-box"
                                options={filterOptions.delivery.status}
                                placeholder="- Select -"
                                styles={{ ...reactSelectStyle(0) }}
                                value={filterOptions.delivery.status.find(d => d.value === filterOptions.delivery.value)}
                                onChange={e => updateFilter('delivery', e)}
                            />
                        </div>
                        <div className="d-flex flex-column flex-sm-row gap-1 gap-sm-4 mt-2 align-items-start align-items-sm-center justify-content-start w-100">
                            <span className="text-nowrap me-3">Filter by date:</span>
                            <div className="">
                                <div className="form-control date-picker-container">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            autoOk
                                            disablePast={false}
                                            label=""
                                            clearable={false}
                                            format="dd/MM/yyyy"
                                            value={dateRange.startDate}
                                            onChange={(e) => onDateChange(e, 'startDate')}
                                            className="form-control"
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <span className="error">{error.startDate}</span>
                            </div>
                            <span>To</span>
                            <div className="">
                                <div className="form-control date-picker-container">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            autoOk
                                            disablePast={false}
                                            label=""
                                            clearable={false}
                                            format="dd/MM/yyyy"
                                            value={dateRange.endDate}
                                            onChange={(e) => onDateChange(e, 'endDate')}
                                            className="form-control"
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <span className="error">{error.endDate}</span>
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <div className="text-center">
                            <i className="fas fa-spinner fa-pulse fa-3x"></i>
                        </div>
                    ) : (
                        <div className="">
                            <div className="dataTables_wrapper" id="table_data">
                                {props.auth.auth.branchId > 0 ?
                                    <SortingTable columns={OUTLETADMINCOLUMNS} data={contents} excelExport={false} setSearchText={getSearchText} searchText={searchText} setPageNumber={getTablePageNumber} currentPageNumber={currentPageNumber} />
                                    :
                                    <SortingTable columns={COLUMNS} data={contents} excelExport={true} fileData={fileData} setSearchText={getSearchText} searchText={searchText} setPageNumber={getTablePageNumber} currentPageNumber={currentPageNumber} />
                                }
                            </div>
                        </div>
                    )}
                </div>
                <Modal show={switchContractModal} onHide={handleSwitchContractModal} centered>
                    <Modal.Header>
                        <Modal.Title>Switch Contract</Modal.Title>
                        <div className="ms-auto">
                            <i
                                class="fa fa-times cursor-pointer"
                                aria-hidden="true"
                                onClick={handleSwitchContractModal}
                            >
                                {" "}
                            </i>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        {/* Get Gold purchase non program refer id and not completed */}
                        <label className="col-sm-12 col-form-label col-form-label">
                            Selected Purchase No.
                        </label>
                        <input type="text" className="form-control" readOnly disabled value={switchContractForm.fromPurchaseId} />
                        <label className="col-sm-12 col-form-label col-form-label">
                            Current Referred Purchase No.
                        </label>
                        <input type="text" className="form-control" readOnly disabled value={switchContractForm.currentReferPurchaseId} />
                        <label className="col-sm-12 col-form-label col-form-label">
                            New Referred Purchase No.
                        </label>
                        <Select
                            className="form-control col-12 h-100"
                            value={filteredMainGP.find(opt => opt.value === switchContractForm.toId)}
                            options={filteredMainGP}
                            placeholder="- Select Program -"
                            style={{
                                lineHeight: "40px",
                                color: "#7e7e7e",
                                paddingLeft: " 15px",
                                borderWidth: 0,
                            }}
                            onChange={e => handleOptionSelected(e)}
                        />
                        {/* <label className="text-danger">New Purchase No. remaining repurchase: {switchContractForm.toPurchaseIdRemainingMonth}</label> */}
                        {/* <input
                            type="text"
                            className="form-control form-control mt-2"
                            placeholder="Enter remarks"
                            name="closeCaseRemark"
                            defaultValue=""
                            // onChange={(e) => setCloseCaseRemark(e.target.value)}
                        /> */}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            className="btn btn-danger profile-button btn-sm text-white me-2"
                            onClick={submitContractChanges}
                        >
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
};


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(PurchaseList);
