export const formatImagePath = (path) => {
    let newPath = path.replace('./_static', '');
    return newPath;
}

export const statesWithFee = () => {
    return [
        { 'label': 'Johor', 'value': 'johor', 'fee': 0.00, 'location': 'west' },
        { 'label': 'Kedah', 'value': 'kedah', 'fee': 0.00, 'location': 'west' },
        { 'label': 'Kelatan', 'value': 'kelantan', 'fee': 0.00, 'location': 'west' },
        { 'label': 'Kuala Lumpur', 'value': 'kualaLumpur', 'fee': 0.00, 'location': 'west' },
        { 'label': 'Malacca', 'value': 'malacca', 'fee': 0.00, 'location': 'west' },
        { 'label': 'Negeri Sembilan', 'value': 'negeriSembilan', 'fee': 0.00, 'location': 'west' },
        { 'label': 'Pahang', 'value': 'pahang', 'fee': 0.00, 'location': 'west' },
        { 'label': 'Penang', 'value': 'penang', 'fee': 0.00, 'location': 'west' },
        { 'label': 'Perak', 'value': 'perak', 'fee': 0.00, 'location': 'west' },
        { 'label': 'Perlis', 'value': 'perlis', 'fee': 0.00, 'location': 'west' },
        { 'label': 'Putrajaya', 'value': 'putrajaya', 'fee': 0.00, 'location': 'west' },
        { 'label': 'Sabah', 'value': 'sabah', 'fee': 0.00, 'location': 'east' },
        { 'label': 'Sarawak', 'value': 'sarawak', 'fee': 0.00, 'location': 'east' },
        { 'label': 'Selangor', 'value': 'selangor', 'fee': 0.00, 'location': 'west' },
        { 'label': 'Terengganu', 'value': 'terengganu', 'fee': 0.00, 'location': 'west' },
    ]
}

export const deliveryFeeMY = () => {
    return [
        { 'label': 'West', 'value': 'west', 'fee': 0.00 },
        { 'label': 'East', 'value': 'east', 'fee': 0.00 },
    ]
}

export const goldPurchaseDeliveryFee = () => {
    let arr = [
        { id: 1, label: "1g", value: 1, wCharges: 0.00, eCharges: 0.00, pCharges: 0.00, isSelected: false, locked: false },
        { id: 2, label: "5g", value: 5, wCharges: 0.00, eCharges: 0.00, pCharges: 0.00, isSelected: false, locked: false },
        { id: 3, label: "10g", value: 10, wCharges: 0.00, eCharges: 0.00, pCharges: 0.00, isSelected: false, locked: false },
        { id: 4, label: "20g", value: 20, wCharges: 0.00, eCharges: 0.00, pCharges: 0.00, isSelected: false, locked: false },
        { id: 5, label: "50g", value: 50, wCharges: 0.00, eCharges: 0.00, pCharges: 0.00, isSelected: false, locked: false },
        { id: 6, label: "100g", value: 100, wCharges: 0.00, eCharges: 0.00, pCharges: 0.00, isSelected: false, locked: false },
        { id: 7, label: "250g", value: 250, wCharges: 0.00, eCharges: 0.00, pCharges: 0.00, isSelected: false, locked: false },
        { id: 8, label: "1000g", value: 1000, wCharges: 0.00, eCharges: 0.00, pCharges: 0.00, isSelected: false, locked: false },
        { id: 9, label: "1/2 Dinar", value: 2.125, wCharges: 0.00, eCharges: 0.00, pCharges: 0.00, isSelected: false, locked: true },
        { id: 10, label: "1 Dinar", value: 4.25, wCharges: 0.00, eCharges: 0.00, pCharges: 0.00, isSelected: false, locked: false },
        { id: 11, label: "5 Dinar", value: 21.25, wCharges: 0.00, eCharges: 0.00, pCharges: 0.00, isSelected: false, locked: false },
        { id: 12, label: "10 Dinar", value: 42.50, wCharges: 0.00, eCharges: 0.00, pCharges: 0.00, isSelected: false, locked: false },
    ]
    return arr.filter(i => i.locked === false);
}

export const goldWeightType = () => {
    let arr = [
        { id: 1, label: "1g", value: 1, wCharges: 0.00, eCharges: 0.00, isSelected: false, locked: false },
        { id: 2, label: "5g", value: 5, wCharges: 0.00, eCharges: 0.00, isSelected: false, locked: false },
        { id: 3, label: "10g", value: 10, wCharges: 0.00, eCharges: 0.00, isSelected: false, locked: false },
        { id: 4, label: "20g", value: 20, wCharges: 0.00, eCharges: 0.00, isSelected: false, locked: false },
        { id: 5, label: "50g", value: 50, wCharges: 0.00, eCharges: 0.00, isSelected: false, locked: false },
        { id: 6, label: "100g", value: 100, wCharges: 0.00, eCharges: 0.00, isSelected: false, locked: false },
        { id: 7, label: "250g", value: 250, wCharges: 0.00, eCharges: 0.00, isSelected: false, locked: false },
        { id: 8, label: "1000g", value: 1000, wCharges: 0.00, eCharges: 0.00, isSelected: false, locked: false },
        { id: 9, label: "1/2 Dinar", value: 2.125, wCharges: 0.00, eCharges: 0.00, isSelected: false, locked: true },
        { id: 10, label: "1 Dinar", value: 4.25, wCharges: 0.00, eCharges: 0.00, isSelected: false, locked: false },
        { id: 11, label: "5 Dinar", value: 21.25, wCharges: 0.00, eCharges: 0.00, isSelected: false, locked: false },
        { id: 12, label: "10 Dinar", value: 42.50, wCharges: 0.00, eCharges: 0.00, isSelected: false, locked: false },
    ]
    return arr.filter(i => i.locked === false);
}

export const countryWithCode = () => [
    { label: "Malaysia", value: "Malaysia", Code: "MY", isSelected: true, hidden: false, },
]

export const termLists = (id) => {
    let defaultTerm = { id: 0, label: "Default", value: "default", fileName: "", path: "/terms/" };
    let arr = [
        { id: 0, label: "Purchase GSP", value: "buyGSP", fileName: "", path: "/terms/", isSelected: false },
        { id: 1, label: "Purchase FGR", value: "buyFGR", fileName: "", path: "/terms/", isSelected: false },
        { id: 2, label: "GSP to GSP+", value: "GSP2GSPPLUS", fileName: "", path: "/terms/", isSelected: false },
        { id: 3, label: "Physical Gold to GSP+", value: "Gold2GSPPLUS", fileName: "", path: "/terms/", isSelected: false },
        { id: 4, label: "GSP to Physical Gold", value: "GSP2Gold", fileName: "", path: "/terms/", isSelected: false },
        { id: 5, label: "GSP to Sell Back Company", value: "GSP2Company", fileName: "", path: "/terms/", isSelected: false },
        { id: 6, label: "Sign Up", value: "SIGNUP", fileName: "", path: "/terms/", isSelected: false },
    ]

    // return defaultTerm;
    return id ? arr.filter(item => item.id === id) : arr;
}

export const giftItems = () => {
    let gifts = [
        { id: 1, label: "MA 00000001", value: "MA 00000001", price: 300, isSelected: true, hidden: false, },
        { id: 2, label: "CA 00000001", value: "CA 00000001", price: 300, isSelected: false, hidden: false, },
        { id: 3, label: "MB 00000001", value: "MB 00000001", price: 600, isSelected: false, hidden: false, },
        { id: 4, label: "CB 00000001", value: "CB 00000001", price: 600, isSelected: false, hidden: false, },
        { id: 5, label: "CC 00000001", value: "CC 00000001", price: 900, isSelected: false, hidden: false, },
        // {id: 6, label: "MA 00000001", value: "MA 00000001", price: 300,},
    ]
    return gifts.filter(gift => gift.hidden === false);
}

export const giftItems2 = () => {
    let gifts = [
        { id: 0, label: "No Purchase (Sign up as free member)", value: "Free", price: 0, isSelected: true, hidden: false, },
        { id: 1, label: "MA 00000001 (RM 300)", value: "MA 00000001", price: 300, isSelected: false, hidden: false, },
        { id: 2, label: "CA 00000001 (RM 300)", value: "CA 00000001", price: 300, isSelected: false, hidden: false, },
        { id: 3, label: "MB 00000001 (RM 600)", value: "MB 00000001", price: 600, isSelected: false, hidden: false, },
        { id: 4, label: "CB 00000001 (RM 600)", value: "CB 00000001", price: 600, isSelected: false, hidden: false, },
        { id: 5, label: "CC 00000001 (RM 900)", value: "CC 00000001", price: 900, isSelected: false, hidden: false, },
        { id: 6, label: "MC 00000001 (RM 900)", value: "MC 00000001", price: 900, isSelected: false, hidden: false, },
    ]
    return gifts.filter(gift => gift.hidden === false);
}

export const settingList = () => {
    let settings = [
        { id: 1, label: "Shop Business", code: "bh", value: 1, remark: "", isSelected: true, hidden: false, },
        // {id: 2, label: "CA 00000001", value: "CA 00000001", price: 300, isSelected: false, hidden: false,},
        // {id: 3, label: "MB 00000001", value: "MB 00000001", price: 600, isSelected: false, hidden: false,},
        // {id: 4, label: "CB 00000001", value: "CB 00000001", price: 600, isSelected: false, hidden: false,},
        // {id: 5, label: "CC 00000001", value: "CC 00000001", price: 900, isSelected: false, hidden: false,},
        // {id: 6, label: "MA 00000001", value: "MA 00000001", price: 300,},
    ]
    return settings.filter(setting => setting.hidden === false);
}

export const withdrawMethodList = (type, method) => {
    let arr = [
        { type: 'eGold', method: 'bankIn', message: 'GSP to Bank In' },
        { type: 'eGold', method: 'gold', message: 'GSP to Gold' },
        { type: 'investmentEGold', method: 'bankIn', message: 'GSP+ to Bank In' },
        { type: 'investmentEGold', method: 'gold', message: 'GSP+ to Gold' },
        { type: 'investmentGold', method: 'bankIn', message: 'GSP+ Physical Gold to Bank In' },
        { type: 'investmentGold', method: 'gold', message: 'GSP+ Physical Gold to Gold' },
        { type: 'ecash', method: 'points', message: 'Cash Wallet to Point Wallet' },
    ];
    let result = arr.find(item => item.type === type && item.method === method);
    return result ? result.message : "-"
}

export const urlParamsLocalStorage = (func = 'get', data = { path: "", query: {}, name: "" }) => {
    let ls = localStorage.getItem('admin-gold-URL-params');
    // console.log(ls); // [{path: "", query: {}}}]
    if (ls) {
        let parsed = JSON.parse(ls);
        if (parsed.length && parsed.length > 0) {
            let result = parsed.find(p => p.path === data.path && p.name === data.name);
            if (func === 'get') {
                if (result) {
                    return result;
                }
            } else if (func === 'set') {
                let newJSON = '';
                if (result) {
                    result.query = data.query;
                    newJSON = JSON.stringify(parsed);
                    localStorage.setItem('admin-gold-URL-params', newJSON);
                } else {
                    result = data;
                    parsed.push(data);
                    newJSON = JSON.stringify(parsed);
                    localStorage.setItem('admin-gold-URL-params', newJSON);
                }
                return result;
            } else if (func === 'clear') {

            }
        } else {
            if (func === 'set') {
                let newJSON = [];
                newJSON.push(data);
                localStorage.setItem('admin-gold-URL-params', JSON.stringify(newJSON));
                return data;
            }
            return false
        }
    } else {
        if (func === 'set') {
            let newJSON = [];
            newJSON.push(data);
            localStorage.setItem('admin-gold-URL-params', JSON.stringify(newJSON));
            return data;
        }
    }
    return false;
}

//Convert amount, example: 450000.00 to 450,000.00
export const toLocaleStringFormat = (value) => {
    const formattedValue = (value ?? 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return formattedValue;
}

export const reactSelectStyle = (index) => {
    let style = [
        {
            input: (base, state) => ({
                ...base,
                color: "#000000",
                textTransform: "capitalize",
            }),
            placeholder: (base, state) => ({
                ...base,
                color: "#000000",
                textTransform: "capitalize",
            }),
            control: (base, state) => ({
                ...base,
                minHeight: "100%",
                height: "100%",
                border: 0,
                borderColor: "transparent",
                boxShadow: "none",
                "&:hover": {
                    borderColor: "transparent",
                    boxShadow: "none",
                },
                backgroundColor: "transparent",
            }),
            valueContainer: (base, state) => ({
                ...base,
                paddingLeft: 0,
                paddingRight: 0,
                color: "#000000",
                borderColor: "transparent",
                textTransform: "capitalize",
            }),
            singleValue: (base, state) => ({
                ...base,
                color: "#000000",
            }),
            menu: (base, state) => ({
                ...base,
                backgroundColor: "#FFFFFF",
                border: "1px solid #000000",
                boxShadow: "none",
                width: "95%"
            }),
            option: (base, state) => ({
                ...base,
                backgroundColor: "transparent",
                cursor: 'pointer',
                color: !state.isSelected ? '#969ba0' : "#000000",
                background: "transparent",
                textTransform: "capitalize",
                "&:hover": {
                    color: '#000000',
                    backgroundColor: "tranparent",
                    background: "transparent",
                },
            })
        }
    ];
    return style[index];
}

export const dummyData = (type) => {
    let all = {
        user: {
            "id": 8,
            "email": "admin@gmail.com",
            "username": "admin",
            "firstName": "admin",
            "lastName": "admin",
            "phone": "1234567890",
            "status": "active",
            "recType": 1,
            "isMaster": 1,
            "branchId": null,
            "createdBy": "admin@gmail.com",
            "updatedBy": null,
            "createdAt": "2022-12-12T03:38:30.000Z",
            "updatedAt": "2022-12-12T03:38:30.000Z",
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZG1pbklkIjo4LCJlbWFpbCI6ImFkbWluQGdtYWlsLmNvbSIsImlzTWFzdGVyIjp0cnVlLCJicmFuY2hJZCI6bnVsbCwiaWF0IjoxNzAxODQ3OTI2LCJleHAiOjE3MDMwNTc1MjZ9.OXMA_mLnHXh3LENQrz5rxzE7dWPoP2mKJzXEUWOvRI8"
        },
        goldPurchase: [
            {
                "id": 3808,
                "userId": 1042,
                "purchaseId": "432342345",
                "invoiceNumber": "INVC 000459",
                "type": "gold",
                "program": 0,
                "programEndDate": null,
                "programPayback": 0,
                "programStatus": null,
                "programReactiveBy": null,
                "programReferId": "3483",
                "trackingNo": null,
                "trackingCode": "353842",
                "nonce": "117a9f6cdf0bd6dbcccb0623140657",
                "purchaseRate": 344.85,
                "amount": 1,
                "pointUsed": 0,
                "totalPrice": 349.85,
                "paymentType": "online",
                "delivery": "selfCollect",
                "selfCollectLocation": 3,
                "fee": 5,
                "address1": "",
                "address2": "",
                "city": "",
                "state": "",
                "postCode": "",
                "country": "Malaysia",
                "remarks": "",
                "adminRemarks": null,
                "deliveryStatus": "completed",
                "isClaim": 0,
                "claimReferId": null,
                "claimDate": null,
                "claimApproval": null,
                "claimApprovalBy": null,
                "status": "active",
                "createdBy": "testing.ew@mail.com",
                "updatedBy": "testing.ew@mail.com",
                "isRestock": 0,
                "deliveryDate": "2023-07-20T02:03:43.000Z",
                "createdAt": "2023-06-14T06:57:22.000Z",
                "updatedAt": "2023-07-20T02:03:43.000Z",
                "user": {
                    "id": 1042,
                    "fullName": "test",
                    "memberId": "test",
                    "phone": "012333333"
                },
                "branch": null
            },
            {
                "id": 3800,
                "userId": 1042,
                "purchaseId": "1231231234",
                "invoiceNumber": "INVC 000455",
                "type": "gold",
                "program": 12,
                "programEndDate": "2024-06-14T02:58:09.000Z",
                "programPayback": 1,
                "programStatus": "ended",
                "programReactiveBy": null,
                "programReferId": null,
                "trackingNo": null,
                "trackingCode": "B6BC92",
                "nonce": "d958a6455f414944757b0623140257",
                "purchaseRate": 344.85,
                "amount": 1,
                "pointUsed": 0,
                "totalPrice": 359.85,
                "paymentType": "online",
                "delivery": "delivery",
                "selfCollectLocation": null,
                "fee": 15,
                "address1": "Tseting",
                "address2": "dfgdf",
                "city": "Bayan Lepas",
                "state": "malacca",
                "postCode": "admin",
                "country": "Malaysia",
                "remarks": "testing",
                "adminRemarks": null,
                "deliveryStatus": "pending",
                "isClaim": 0,
                "claimReferId": null,
                "claimDate": null,
                "claimApproval": null,
                "claimApprovalBy": null,
                "status": "active",
                "createdBy": "testing.ew@mail.com",
                "updatedBy": null,
                "isRestock": 0,
                "deliveryDate": null,
                "createdAt": "2023-06-14T02:57:27.000Z",
                "updatedAt": "2023-08-31T16:00:02.000Z",
                "user": {
                    "id": 1042,
                    "fullName": "test",
                    "memberId": "test",
                    "phone": "012333333"
                },
                "branch": null
            },
        ],
        goldPurchaseDetails: [

        ],
        branches: [
            {
                "id": 2,
                "name": "SOMEHWERE AROUND HERE",
                "location": "KL",
                "address": "123123 , Area 51 , KL",
                "phone": "123123",
                "email": "customercare@1234.com",
                "investmentMax": 5000,
                "status": "active",
                "createdBy": "admin@gmail.com",
                "updatedBy": "admin@gmail.com",
                "createdAt": "2022-12-20T14:55:20.000Z",
                "updatedAt": "2022-12-22T13:05:26.000Z"
            }
        ],
        members: [
            {
                "id": 1047,
                "email": "test456@gmail.com",
                "passwordKey": "12312345",
                "fullName": "test456",
                "identityCard": "741005095001324545",
                "phone": "0194310599",
                "kycName": null,
                "kycIC": null,
                "kycFrontICPath": null,
                "kycBackICPath": null,
                "kycStatus": null,
                "member": "VIP",
                "memberPasswordHash": "$2a$10$dYuqRPtPTlx4.nK/sybge.K3lGS8aUMwkCSwiE2FA1tZZXTCoEKIe",
                "memberPasswordKey": "123123",
                "memberDiscount": 5,
                "memberId": "test456",
                "referralId": null,
                "referralCode": "dd4e85a342",
                "placement": "left",
                "address1": "dfgdf",
                "address2": "dfgdf",
                "city": "sadas",
                "state": "kualaLumpur",
                "postCode": "02600",
                "country": "Malaysia",
                "status": "active",
                "recType": 1,
                "createdBy": "admin@gmail.com",
                "updatedBy": null,
                "username": "test456",
                "createdAt": "2023-06-15T06:18:34.000Z",
                "updatedAt": "2023-06-15T06:18:34.000Z",
                "user_bank_accounts": [],
                "wallets": [
                    {
                        "id": 490,
                        "userId": 1047,
                        "goldBalance": 0,
                        "eGoldBalance": 0,
                        "investmentBalance": 0,
                        "eInvestmentBalance": 0,
                        "cashBalance": 0,
                        "walletKey": "$2a$10$cjPghR6FEO4PMIq/NBZyluB9uBE4dwtu44woiX3L1EtEGtmXuuQG2",
                        "points": 0,
                        "status": "active",
                        "recType": 1,
                        "createdBy": "test456@gmail.com",
                        "updatedBy": null,
                        "createdAt": "2023-06-15T06:18:34.000Z",
                        "updatedAt": "2023-06-15T06:18:34.000Z"
                    }
                ],
                "gifts_purchases": [
                    {
                        "id": 16,
                        "userId": 1047,
                        "purchaseId": "062315061886046562",
                        "invoiceNumber": "M 000001",
                        "type": "gift",
                        "giftModal": "MA 00000001",
                        "trackingNo": null,
                        "trackingCode": "FD55F4",
                        "nonce": "1686809914531-AdminPurchase",
                        "amount": 300,
                        "paymentType": "Direct Purchase",
                        "delivery": "selfCollect",
                        "selfCollectLocation": null,
                        "fee": 0,
                        "address1": "dfgdf",
                        "address2": "dfgdf",
                        "city": "sadas",
                        "state": "kualaLumpur",
                        "postCode": "02600",
                        "country": "Malaysia",
                        "remarks": null,
                        "adminRemarks": null,
                        "deliveryStatus": "pending",
                        "status": "active",
                        "recType": 1,
                        "createdBy": "admin@gmail.com",
                        "updatedBy": null,
                        "createdAt": "2023-06-15T06:18:34.000Z",
                        "updatedAt": "2023-06-15T06:18:34.000Z"
                    }
                ]
            },
            {
                "id": 1046,
                "email": "test123@gmail.com",
                "passwordKey": "123123",
                "fullName": "test123",
                "identityCard": "741005095001334",
                "phone": "0194310599",
                "kycName": null,
                "kycIC": null,
                "kycFrontICPath": null,
                "kycBackICPath": null,
                "kycStatus": null,
                "member": "MEMBER",
                "memberPasswordHash": "$2a$10$YNtq5KZmyhwpOy.Qu/r1JuhRBLNwc03gfGciL9eBGzswLbYCkAm3.",
                "memberPasswordKey": "123123",
                "memberDiscount": 0,
                "memberId": "test123",
                "referralId": null,
                "referralCode": "257c75dd2a",
                "placement": "left",
                "address1": "79, JALAN HAJI MOHAMAD KAMPUNG JEJAWI ALOR LANCHANG",
                "address2": "dfgdf",
                "city": "Bayan Lepas",
                "state": "kedah",
                "postCode": "02600",
                "country": "Malaysia",
                "status": "active",
                "recType": 1,
                "createdBy": "admin@gmail.com",
                "updatedBy": null,
                "username": "test123",
                "createdAt": "2023-06-15T06:17:04.000Z",
                "updatedAt": "2023-06-15T06:17:04.000Z",
                "user_bank_accounts": [],
                "wallets": [
                    {
                        "id": 489,
                        "userId": 1046,
                        "goldBalance": 0,
                        "eGoldBalance": 0,
                        "investmentBalance": 0,
                        "eInvestmentBalance": 0,
                        "cashBalance": 0,
                        "walletKey": "$2a$10$yp7lVdiZ3XWKL60Ivo2fbulx0w85bWGSD6fv0w2iEF18ye/w5QAHe",
                        "points": 0,
                        "status": "active",
                        "recType": 1,
                        "createdBy": "test123@gmail.com",
                        "updatedBy": null,
                        "createdAt": "2023-06-15T06:17:04.000Z",
                        "updatedAt": "2023-06-15T06:17:04.000Z"
                    }
                ],
                "gifts_purchases": []
            },
        ],
        transaction: [
            {
                "createdAt": "2022-12-19T06:55:33.000Z",
                "title": "Gold Purchase",
                "refNo": "12345678900",
                "userId": 65,
                "username": "customer1",
                "type": "gold",
                "gram": "1",
                "point": "0",
                "BankIn": "",
                "Amount": "322.68",
                "recType": 1,
                "invoiceNumber": null,
                "status": "active"
            },
            {
                "createdAt": "2022-12-20T05:06:29.000Z",
                "title": "Gold Purchase",
                "refNo": "1234567890",
                "userId": 69,
                "username": "customer2",
                "type": "gold",
                "gram": "1",
                "point": "0",
                "BankIn": "",
                "Amount": "306.85",
                "recType": 1,
                "invoiceNumber": null,
                "status": "active"
            },
        ]
    };
    return all[type];
}
