import axios from 'axios';
import swal from "sweetalert";
import {
    loginConfirmedAction,
    logout,
} from '../store/actions/AuthActions';
import { api } from "../utils/api";

export function signUp(usernameEmail, password) {
    //axios call
    const postData = {
        usernameEmail,
        password,
        returnSecureToken: true,
    };
    return axios.post(
        api.users,
        postData,
    );
}

export function login(email, password) {
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
    return axios.post(
        api.users + "/signin",
        postData,
    );
}

export function formatError(errorResponse) {
    switch (errorResponse.message) {
        case 'User Already Exist. Please Login':
            swal("Oops", "User already exists", "error");
            break;
        case 'User does not exist':
            //return 'Email not found';
            swal("Oops", "User does not exist", "error", { button: "Try Again!", });
            break;
        case '"email" must be a valid email':
            //return 'Invalid Password';
            swal("Oops", "Email must be a valid emaild", "error", { button: "Try Again!", });
            break;
        case 'Invalid Password':
            //return 'Invalid Password';
            swal("Oops", "Invalid Password", "error", { button: "Try Again!", });
            break;
        case 'This account has no active role':
            //return 'Invalid Password';
            swal("Oops", "This account has no active role", "error", { button: "Try Again!", });
            break;
        case 'USER_DISABLED':
            return 'User Disabled';

        default:
            return '';
    }
}

export function saveTokenInLocalStorage(tokenDetails) {
    localStorage.setItem('admin_gold', JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, history) {
    setTimeout(() => {
        dispatch(logout(history));
    }, timer);
}

export function checkAutoLogin(dispatch, history, pathname) {
    const tokenDetailsString = localStorage.getItem('admin_gold');
    let tokenDetails = '';
    if (!tokenDetailsString) {
        dispatch(logout(history, pathname));
        return;
    }

    tokenDetails = JSON.parse(tokenDetailsString);
    // let expireDate = new Date(tokenDetails.expireDate);
    // let todaysDate = new Date();

    // if (todaysDate > expireDate) {
    //     dispatch(logout(history));
    //     return;
    // }
    dispatch(loginConfirmedAction(tokenDetails));

    // const timer = expireDate.getTime() - todaysDate.getTime();
    // runLogoutTimer(dispatch, timer, history);
}
