import React, {useEffect, useState} from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from 'react-redux';
import { url } from '../../../utils/api';
import { formatImagePath } from "../../../utils/function";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Image
import profile from "../../../images/avatar/pic1.jpg";
import avatar from "../../../images/avatar/1.png";
import { Dropdown } from "react-bootstrap";
import LogoutPage from './Logout';

const Header = (props) => {
  const [image, setImage] = useState(null)
  const [path, setPath] = useState(null);
  const location = useLocation();
  // var path = window.location.pathname.split("/");
  // var name = path[path.length - 1].split("-");
  // var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  // var finalName = filterName.includes("app")
  //   ? filterName.filter((f) => f !== "app")
  //   : filterName.includes("ui")
  //   ? filterName.filter((f) => f !== "ui")
  //   : filterName.includes("uc")
  //   ? filterName.filter((f) => f !== "uc")
  //   : filterName.includes("basic")
  //   ? filterName.filter((f) => f !== "basic")
  //   : filterName.includes("jquery")
  //   ? filterName.filter((f) => f !== "jquery")
  //   : filterName.includes("table")
  //   ? filterName.filter((f) => f !== "table")
  //   : filterName.includes("page")
  //   ? filterName.filter((f) => f !== "page")
  //   : filterName.includes("email")
  //   ? filterName.filter((f) => f !== "email")
  //   : filterName.includes("ecom")
  //   ? filterName.filter((f) => f !== "ecom")
  //   : filterName.includes("chart")
  //   ? filterName.filter((f) => f !== "chart")
  //   : filterName.includes("editor")
  //   ? filterName.filter((f) => f !== "editor")
  //   : filterName; 

  useEffect(()=>{
    var currentPath = window.location.pathname.split("/");
    currentPath.shift();
    let title = currentPath.join(" / ");
    setPath(title);
  },[location])

  useEffect(()=>{
    setImage(props.auth.auth.profileImage)
  },[props.auth.auth.profileImage])

  return (
    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
                >
                {/* {finalName.join(" ").length === 0
                  ? "Dashboard"
                  : finalName.join(" ") === "dashboard dark"
                  ? "Dashboard"
                  : finalName.join(" ")} */}
                {/* {path} */}
              </div> 	
				
            </div>
            <ul className="navbar-nav header-right main-notification">
              <Dropdown as="li" className="nav-item dropdown header-profile">
                <Dropdown.Toggle variant="" as="a" className="nav-link i-false c-pointer">
                  {image ? (
                    <img src={url + formatImagePath(image.path)} width={20} alt="" />
                  ) : (
                    <img src={avatar} width={20} alt="" />
                  )}
                  
                  <div className="header-info ms-3">
                    <span>{props.auth.auth.firstName + " " + props.auth.auth.lastName}</span>
                    {/* <small>Superadmin</small> */}
                  </div> 
                </Dropdown.Toggle>

                <Dropdown.Menu align="right" className="mt-3 dropdown-menu dropdown-menu-end">
                  <Link to="/account" className="dropdown-item ai-icon">
                    <i className="flaticon-381-user text-warning"></i>
                  <span className="ms-2">Profile </span>
                  </Link>
                  {/* <Link to="/account/change-password" className="dropdown-item ai-icon">
                    <i className="flaticon-381-padlock text-secondary"></i>
                  <span className="ms-2">Change Password </span>
                  </Link> */}
                  <LogoutPage />
                </Dropdown.Menu>
              </Dropdown> 
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

// export default Header;

const mapStateToProps = (state) => {
  return {
      auth: state.auth,
  };
};


export default connect(mapStateToProps)(Header);