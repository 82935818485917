import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux'; 
import swal from "sweetalert";
import Select, { createFilter } from "react-select";
import { api, url } from "../../../utils/api";
import SortingTable from "../../components/SortingTable";
import moment from 'moment';
import { Table } from "react-bootstrap";
import { settingList, termLists } from "../../../utils/function";
import Switch from "react-switch";

const ShopSettings = (props) => {
    const history = useHistory();
    const [list, setList] = useState(settingList());
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState([]);
    const [error, setError] = useState({
        tnc: "", usage: "",
    });
    const [listValue, setListValue] = useState([
        {code: 'bh', label: "Business hour", value: true},
    ])

    useEffect(()=>{
        getList();
    },[props.auth.auth.token]);

    function getList(){
        setLoading(true);
        fetch(api.common + `/shop/all`, {
            method:"GET",
            headers:new Headers({
                "Content-Type":"application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        }).then(response => response.json())
        .then(responseJson => {
            setLoading(false);
            if(responseJson.status === 'success'){
                setListValue(responseJson.data.map(i => {return {code: i.settingsCode, label: i.settings, value: i.value}}));
            }
        }).catch(error => {
            setLoading(false);
            console.log("Error",error);
        });
    }

    const onFileChange = (event) => {
        setFile(event.target.files);
    }

    const onOptionSelected = (e) => {
        const { label, value } = e;
        let temp = list;
        temp = temp.map(item => {item.value !== value ? item.isSelected = false : item.isSelected = true; return item;})
        setList(temp)
    }

    const toggleSettings = (e, code) => {
        let temp = [...listValue];
        temp = temp.map((i) => {
            if (i.code === code) {
                i.value = e;
            }
            return i;
        })
        setListValue(temp)
    }

    const updateNewSettings = () => {
        setLoading(true);
        let check = true;
        if (check) {
            swal({
                title: "Update Settings?",
                text: "Once complete, this setting will be updated!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((result) => {
                if (result) {
                    let temp = [...listValue];
                    fetch(api.users + `/shop`,  {
                        method:"PUT",
                        headers:new Headers({
                            "Content-Type":"application/JSON",
                            "Authorization": "Bearer " + props.auth.auth.token
                        }),
                        body: JSON.stringify(temp),
                    }).then(response => response.json())
                    .then(responseJson => {
                        setLoading(false);
                        if(responseJson.status === 'success'){
                            swal(responseJson.message, {
                                icon: "success",
                            });
                            // getSettings();
                            //history.push('/withdraw/list');
                        }else{
                            swal(responseJson.message, {
                                icon: "error",
                            });
                        }
                    }).catch(error => { 
                            console.log("Error",error);
                    });
                } 
                setLoading(false);
            })
        }
    }

    const getSettingsFromList = (code, returnValue) => {
        let setting = listValue.find((i) => i.code === code);
        return setting ? returnValue ? setting.value : setting : false;
    }

    return (
        <>
            <div className="col-12">
                <div className="row page-titles mx-0 shadow">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active">
                            <span>Shop Settings</span>
                        </li>
                        <li className="breadcrumb-item">
                            <span>List</span>
                        </li>
                    </ol>
                </div>
                <div className="d-flex flex-column">
                    <div className="card col-12 shadow">
                        <div className="card-header">
                            <span className="h4">Settings</span>
                        </div>
                        <div className="card-body">
                            <div className="mb-3 row-cols-1 align-items-center">
                                <label className="col-form-label">Shop Business <b className="text-primary">{getSettingsFromList('bh', true) ? "Open" : "Close"}</b></label>
                                <div className="">
                                    <Switch onChange={(e) => toggleSettings(e, 'bh')} checked={getSettingsFromList('bh', true)} />
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <button type="submit" className="btn btn-primary btn-sm me-2" disabled={loading} onClick={(e) => updateNewSettings(e)}>Update</button>

                            {/* <button type="submit" className="btn btn-primary btn-sm me-2" onClick={handleAddFormSubmit} disabled={loading} >Submit</button>
                            <button type="button" onClick={resetForm} className="btn btn-danger btn-sm"> Discard</button> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(ShopSettings);