import React from 'react';
import { useTable, useSortBy, useFilters, useGlobalFilter, useAsyncDebounce, usePagination } from 'react-table';
import * as XLSX from 'xlsx';
import moment from "moment";
import { set_cptable } from "xlsx";
import * as cptable from 'xlsx/dist/cpexcel.full.mjs';

set_cptable(cptable);

function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    setSearchText,
    searchText,
}) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter);
    const [firstLoad, setFirstLoad] = React.useState(true);
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
        // console.log(searchText, value);
        setSearchText(value || '')
    }, 200)
    // console.log(firstLoad, value, searchText);
    if(firstLoad && !value && value !== null && value !== " " && searchText) {
        console.log('first', value, searchText)
        setValue(searchText)
        setFirstLoad(false)
        onChange(searchText)
    }

    return (
        <div className="row align-items-center">
            <div className="col-sm-12">
                <input
                    className="form-control form-control-sm"
                    value={value || ""}
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder="Search..."
                />
            </div>
        </div>
    )
}

// function DefaultColumnFilter({
//     column: { filterValue, preFilteredRows, setFilter },
// }) {
//     const count = preFilteredRows.length

//     return (
//         <input
//             className="form-control"
//             value={filterValue || ''}
//             onChange={e => {
//                 setFilter(e.target.value || undefined)
//             }}
//             placeholder={`Search ${count} records...`}
//         />
//     )
// }

function SortingTable({ columns, data, lang, disable_filter = false, excelExport = true, fileData = {}, setSearchText, searchText, setPageNumber, currentPageNumber = 0 }) {
    // const defaultColumn = React.useMemo(
    //     () => ({
    //         // Default Filter UI
    //         Filter: DefaultColumnFilter,
    //     }),
    //     []
    // )

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        prepareRow,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: currentPageNumber },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
    )
    
    React.useEffect(() => {
        // console.log((state.pageIndex + 1) < 2, currentPageNumber);
        // if ((state.pageIndex + 1) < 2 && currentPageNumber) {
        //     gotoPage(currentPageNumber);
        // } else if (state.paageIndex !== currentPageNumber) {
        //     setPageNumber(state.pageIndex);
        // }
        if (!currentPageNumber || (currentPageNumber + 1 < 1)) {
            // no currentPage in LS
            // ignore
        } else {
            if (!state.pageIndex || (state.pageIndex + 1 < 1)) {
                gotoPage(currentPageNumber);
            } else if (state.pageIndex !== currentPageNumber) {
                setPageNumber(currentPageNumber);
            } else if (pageCount > 0 && currentPageNumber > pageCount - 1) {
                setPageNumber(pageCount - 1);
            }
        }
    }, [currentPageNumber, pageCount])

    const exportExcel = () => {
        let exFileName = `${fileData.name}_${moment().unix().toString()}.xlsx`;
        // console.log(fileData);

        const wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(fileData.excelData && fileData.excelData.length > 0 ? fileData.excelData : []);
        if (fileData.hasCustom) {
            fileData.customRows.map((cr) => {
                XLSX.utils.sheet_add_aoa(ws, [cr.customData], { origin: cr.origin ?? -1 })
            })

        }
        XLSX.utils.book_append_sheet(wb, ws, "Report");
        XLSX.writeFile(wb, exFileName)
    }

    // Render the UI for your table
    return (
        <>
            <div className='d-flex flex-column flex-md-row mt-3'>
                <div className='table-plugin-top-left flex-grow-1 col-12 col-md-6'>
                    <div>
                        {
                            excelExport ? (
                                <>
                                    <button className="btn table-export-button-excel btn-sm" onClick={(e) => exportExcel(e)}>Export Excel</button>
                                </>
                            ) : <></>
                        }
                    </div>
                </div>
                <div className='table-plugin-top-right flex-grow-1 flex-md-grow-0 ms-auto col-12 col-md-3'>
                    {
                        disable_filter ? (
                            <></>
                        ) : (
                            <GlobalFilter
                                preGlobalFilteredRows={preGlobalFilteredRows}
                                globalFilter={state.globalFilter}
                                setGlobalFilter={setGlobalFilter}
                                setSearchText={setSearchText}
                                searchText={searchText}
                                lang={lang}
                            />
                        )
                    }
                </div>
            </div>

            <div className="table-responsive">
                <table className="table table-responsive dataTable display" {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header')}
                                        <span className="ml-1">
                                            {column.isSorted ? (column.isSortedDesc ? <i className="fa fa-arrow-down" /> : <i className="fa fa-arrow-up" />) : ''}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    })}
                                </tr>
                            )
                        })}
                        {rows.length === 0 ? (
                            <tr role="row">
                                <td colSpan={columns.length}>No result found</td>
                            </tr>
                        ) : null}
                        {/* {rows.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        )
                                    })}
                                </tr>
                            )
                        })} */}
                    </tbody>
                    <tfoot>
                        {footerGroups.map(footerGroup => (
                            <tr {...footerGroup.getFooterGroupProps()}>
                                {footerGroup.headers.map(column => (
                                    // <th {...column.getFooterProps(column.getSortByToggleProps())}>
                                    <th>
                                        {column.render('Footer')}
                                        {/* <span className="ml-1">
                                        {column.isSorted ? (column.isSortedDesc ? <i className="fa fa-arrow-down" /> : <i className="fa fa-arrow-up" />) : ''}
                                    </span> */}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </tfoot>

                </table>
            </div>
            <div className="d-flex flex-column flex-md-row">
                <div className="dataTables_paginate paging_simple_numbers pagination">
                    <button className="paginate_button previous me-1 flex-grow-1 flex-md-grow-0 d-flex align-items-center justify-content-center" onClick={() => {gotoPage(0); setPageNumber(0)}} disabled={!canPreviousPage}>
                        <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                    </button>
                    <button className="paginate_button previous flex-grow-1 flex-md-grow-0 d-flex align-items-center justify-content-center" onClick={() => {previousPage(); setPageNumber(currentPageNumber - 1)}} disabled={!canPreviousPage}>
                        <i className="fa fa-angle-left" aria-hidden="true"></i>
                    </button>
                    <div className="dataTables_info mx-1 pt-2">
                        Page <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>
                    </div>
                    <button className="paginate_button next me-1 flex-grow-1 flex-md-grow-0 d-flex align-items-center justify-content-center" onClick={() => {nextPage(); setPageNumber(currentPageNumber + 1)}} disabled={!canNextPage}>
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </button>
                    <button className="paginate_button next flex-grow-1 flex-md-grow-0 d-flex align-items-center justify-content-center" onClick={() => {gotoPage(pageCount - 1); setPageNumber(pageCount - 1)}} disabled={!canNextPage}>
                        <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                    </button>

                    {/* <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                </span> */}
                </div>
                <div className="d-flex flex-row ms-0 ms-md-auto">
                    <div className="me-2 align-items-center mt-1 ">
                        <span style={{ backgroundColor: "transparent" }}>Go to page:</span>{' '}
                        <input
                            className="form-control-sm"
                            type="number"
                            defaultValue={pageIndex + 1}
                            max={pageOptions.length}
                            min={1}
                            onChange={e => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0
                                gotoPage(page)
                            }}
                            style={{ width: '50px', borderWidth: 1, borderColor: '#dddddd' }}
                        />
                    </div>
                    <select
                        className="ms-auto"
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value))
                        }}
                    >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </>
    )
}

export default SortingTable;