import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import swal from "sweetalert";
import { connect } from 'react-redux';
import { Dropdown } from "react-bootstrap";
import { api } from "../../../utils/api";
import SortingTable from "../../components/SortingTable";
import moment from 'moment';


const AddContractToBranch = (props) => {
    const [content, setContent] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const COLUMNS = [
        {
            Header : 'No.',
            accessor: (row, i) => {
                return ++i + "."
            },
        },
        {
            Header : 'Program Period (Month)',
            accessor: 'period'
        },
        {
            Header : 'Percentage (%)',
            accessor: 'rate'
        },
        {
            Header : 'Created Datetime',
            accessor: (row) => {
               return moment(row.createdAt).format('YYYY-MM-DD HH:MM:ss')
            }
        },
        {
            Header : 'Action',
            accessor: (row) => {
                return (
                    <>
                        <Dropdown>
                            <Dropdown.Toggle
                            as="button"
                            variant=""
                            className="btn sharp btn-primary tp-btn mt-1"
                            id="tp-btn"
                            >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="18px"
                                height="18px"
                                viewBox="0 0 24 24"
                                version="1.1"
                            >
                                <g
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                                >
                                <rect x="0" y="0" width="24" height="24" />
                                <circle fill="#000000" cx="12" cy="5" r="2" />
                                <circle fill="#000000" cx="12" cy="12" r="2" />
                                <circle fill="#000000" cx="12" cy="19" r="2" />
                                </g>
                            </svg>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href={"/branch/" + id + "/contract/edit/" + row.id }><Link to={"/branch/" + id + "/contract/edit/" + row.id }>Edit</Link></Dropdown.Item>
                                <Dropdown.Item onClick={()=>removeContract(row.id)}>Remove</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </>
                )
            },
        },
    ];

    //use effect
    useEffect(() => {
        getList();
    }, [props.auth, id]);

    const getList = () => {
        setLoading(true);
        fetch(api.contracts + "/?type=physical&branchId=" + id, {
            method:"GET",
            headers:new Headers({
                "Content-Type":"application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        }).then(response => response.json())
        .then(responseJson => {
            setLoading(false);
            console.log(responseJson) 
            if(responseJson.status === 'success'){
                if(responseJson.data){
                    setContent(responseJson.data)
                }else{
                    setContent([])
                }
            }else{
                setContent([])
            }
            
        }).catch(error => { 
            console.log("Error",error);
        });
    }

    const removeContract = (contractId) => {
        swal({
            title: "Delete data?",
            text:
              "Once deleted, you will not be able to recover this data anymore!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                fetch(api.contracts + "/" +contractId + "/remove", {
                    method:"PUT",
                    headers:new Headers({
                        "Content-Type":"application/JSON",
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                }).then(response => response.json())
                .then(responseJson => {
                    if(responseJson.status === 'success'){
                        getList();
                    }
                }).catch(error => {
                    console.log("Error",error);
                });
            } 
        })
    }

    return (
        <div className="col-12">
              <div className="row page-titles mx-0 shadow">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                        <span>Branch</span>
                    </li>
                    <li className="breadcrumb-item active">
                        <span>GSP+ List</span>
                    </li>
                    <li className="breadcrumb-item">
                        <span>Physical Gold</span>
                    </li>
                </ol>
            </div>
            <div className="card shadow">
                <div className="card-header">
                    <Link className="btn btn-outline-primary btn-sm pull-right" to={"/branch/" + id + "/contract/new"}>Create New Program</Link>
                </div>
                <div className="card-body">
                    {loading ? (
                    <div className="text-center">
                        <i className="fas fa-spinner fa-pulse fa-3x"></i>
                    </div>
                    ) : (
                    <div className="w-100">
                        <div className="dataTables_wrapper">
                            <SortingTable columns={COLUMNS} data={content} />
                        </div>
                    </div>
                    )}
                </div>
                <div className="card-footer">
                    <Link className="btn btn-danger profile-button btn-sm text-white" to="/branch/list">Back</Link>
                </div>
            </div>
        </div>
   );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};


export default connect(mapStateToProps)(AddContractToBranch);
