import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import { connect } from 'react-redux';
import { api, url } from "../../../utils/api";
import moment from "moment";
import { Button, Dropdown, Modal } from "react-bootstrap";
import Select from "react-select";
import SortingTable from "../../components/SortingTable";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { dummyData, reactSelectStyle } from '../../../utils/function';
import DateFnsUtils from "@date-io/date-fns";

const TransactionList = (props) => {
    const COLUMNS = [
        {
            Header: 'No.',
            accessor: (row, i) => {
                return i + 1;
            }
        },
        {
            Header: "Receipt / Inv No.",
            accessor: (row) => {
                return row.invoiceNumber ?? "-"
            },
            Cell: (props) => {
                let data = props.row.original;
                return (
                    <>
                        {/* {
                        row.title === filterOption && row.status === 'active' && (row.type === 'gold' || row.type === 'egold') ? (
                            
                            <a className="blue-hover" href={`${url}/pdf/${row.refNo}.pdf`} target="_blank">{row.invoiceNumber}</a>
                        ) : <></> 
                      }

                      {
                        row.title === filterOption && (row.status === 'active' || row.status === 'completed') && row.method === 'bankIn' && row.type !== 'ecash' ? (
                            <a className="btn shadow btn-xs btn-gold text-white me-2">
                            Receipt
                         </a>
                        ) : (<></>)
                      } */}
                        {
                            data.status === 'active' || data.status === 'completed' ?
                                data.method === 'gold' && data.type !== 'ecash' ? (<>
                                    <a className="blue-hover" href={url + data.path} target="_blank">{props.cell.value}</a>
                                </>) : data.method === 'bankIn' && data.type !== 'ecash' ? (<>
                                    <a className="btn shadow btn-xs btn-gold text-white me-2">
                                        Receipt
                                    </a>
                                </>) : "-" : "-"
                        }
                    </>
                )
            }
        },
        {
            Header: 'Date',
            accessor: (row) => {
                return moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss')
            },
            Cell: (props) => {
                return <span className="text-nowrap">{props.cell.value}</span>
            }
        },
        {
            Header: 'Title',
            accessor: (row) => {
                return row.title
            },
            Cell: (props) => {
                return <span className="text-nowrap">{props.cell.value}</span>
            }
        },
        {
            Header: 'Reference No.',
            accessor: (row) => {
                return row.refNo;
            },
            Cell: (props) => {
                return <span className="text-nowrap">{props.cell.value}</span>
            }
        },
        {
            Header: 'Username',
            accessor: (row) => {
                return row.username;
            }
        },
        {
            Header: 'Type',
            accessor: (row) => {
                return row.type;
            }
        },
        {
            Header: 'Gram',
            accessor: (row) => {
                return (row.gram !== "" ? row.gram : 0) + "g";
            }
        },
        {
            Header: 'Point',
            accessor: (row) => {
                return row.point !== "" ? row.point : 0;
            }
        },
        {
            Header: 'Bank In',
            accessor: (row) => {
                return row.BankIn !== "" ? row.BankIn : 0;
            }
        },
        {
            Header: 'Amount',
            accessor: (row) => {
                return row.Amount !== "" ? row.Amount : 0;
            }
        },
    ];

    const [loading, setLoading] = useState(false);
    const [contents, setContents] = useState([]);
    const [filterOption, setFilterOption] = useState("All");
    const [allData, setAllData] = useState([]);
    const [mainGP, setMainGP] = useState([]);
    const [filteredMainGP, setFilteredMainGP] = useState([]);
    const [error, setError] = useState([]);
    const [dateRange, setDateRange] = useState({
        startDate: moment().startOf('month'),
        endDate: moment().endOf('month'),
    });
    const [filterOptions, setFilterOptions] = useState({
        delivery: {
            name: 'delivery', status: [
                { label: 'all', value: 'all' },
                { label: 'pending', value: 'pending' },
                { label: 'shipped', value: 'shipped' },
                { label: 'completed', value: 'completed' },
            ], value: 'all',
        },
    })
    const [fileData, setFileData] = useState({
        name: "Gold_Transaction_List",
        excelData: [],
        hasCustom: false,
    })

    const updateFilter = (type, data) => {
        let temp = filterOptions[type];
        temp.value = data;
        if (allData.length > 0) {
            if (filterOption === 'All') {
                setContents(allData);
            } else {
                let list = allData.filter(arr => arr.title === filterOption);
                console.log('list: ', list);
                setContents(list);
                setFileData({
                    ...fileData,
                    excelData: list,
                    hasCustom: false,
                    customRows: [],

                });
            }
        }
        setFilterOptions({ ...filterOptions, [type]: temp });
    }

    useEffect(() => {
        getList(dateRange.startDate, dateRange.endDate);
    }, [props.auth]);


    const getList = (start, end) => {
        let data = [...dummyData('transaction')];
        setAllData(data);
        setContents(data);
        // setLoading(true);
        // fetch(api.users + `/transaction/logs?start=${moment(start).format('YYYY-MM-DD 00:00:00')}&end=${moment(end).format('YYYY-MM-DD 23:59:59')}`, {
        //     method: "GET",
        //     headers: new Headers({
        //         "Content-Type": "application/JSON",
        //         "Authorization": "Bearer " + props.auth.auth.token
        //     }),
        // }).then(response => response.json())
        //     .then(responseJson => {
        //         setLoading(false);

        //         // console.log(responseJson) 
        //         if (responseJson.data) {
        //             setAllData(responseJson.data);
        //             setContents(responseJson.data);

        //             let excelTable = responseJson.data.map((i, index) => {

        //                 return {
        //                     "No.": index + 1,
        //                     "Date": moment(i.createdAt).format('YYYY-MM-DD HH:mm:ss'),
        //                     "Title": i.title,
        //                     "Reference No.": i.refNo,
        //                     "Username": i.username,
        //                     "Type": i.type,
        //                     "Gram": i.gram ?? 0 + "g",
        //                     "Point": i.point ?? 0,
        //                     "Bank In": i.BankIn ?? 0,
        //                     "Amount": i.Amount,
        //                 }
        //             })

        //             setFileData({
        //                 ...fileData,
        //                 excelData: excelTable,
        //                 hasCustom: false,
        //                 customRows: [],

        //             });

        //         }

        //     }).catch(error => {
        //         setLoading(false);
        //         console.log("Error", error);
        //     });
    }



    function onDateChange(e, name) {
        console.log(e);
        let temp = { ...dateRange };
        temp[name] = e;
        getList(temp.startDate, temp.endDate);
        // if (temp.startDate < /)
        // const data = { ...formData };
        // dateRange = moment(e).format("YYYY-MM-DD");
        setDateRange(temp);
    }

    // const handleOptionSelected = (e) => {
    //     let remaining = 0;
    //     allData.forEach((item) => {
    //         if (item.programReferId === e.value.toString()) remaining++;
    //     });
    //     setSwitchContractForm({
    //         ...switchContractForm,
    //         toId: e.value,
    //         toPurchaseId: e.label,
    //         toPurchaseIdRemainingMonth: remaining,
    //     })
    // }



    return (
        <>
            <div className="row page-titles mx-0 shadow">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                        <span>Transaction</span>
                    </li>
                    <li className="breadcrumb-item">
                        <span>List</span>
                    </li>
                    {/* <Link to="/restock/list" className="ms-auto btn btn-sm btn-primary text-white">Go To Restock</Link> */}
                </ol>
            </div>
            <div className="card shadow card-black">


                <div className="card-body">
                    <div className="d-flex flex-row gap-1 gap-sm-3 align-items-center mb-3">
                        <span className="text-nowrap">Filter by delivery status:</span>
                        <Select
                            className="form-control gold-input-box"
                            options={filterOptions.delivery.status}
                            placeholder="- Select -"
                            styles={{ ...reactSelectStyle(0) }}
                            value={filterOptions.delivery.status.find(d => d.value === filterOptions.delivery.value)}
                            onChange={e => updateFilter('delivery', e)}
                        />
                    </div>
                    <div className="d-flex flex-column flex-sm-row gap-1 gap-sm-4 mt-2 align-items-start align-items-sm-center justify-content-start w-100">
                        <span className="text-nowrap me-3">Filter by date:</span>
                        <div className="">
                            <div className="form-control date-picker-container">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        autoOk
                                        disablePast={false}
                                        label=""
                                        clearable={false}
                                        format="dd/MM/yyyy"
                                        value={dateRange.startDate}
                                        onChange={(e) => onDateChange(e, 'startDate')}
                                        className="form-control"
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                            <span className="error">{error.startDate}</span>
                        </div>
                        <span>To</span>
                        <div className="">
                            <div className="form-control date-picker-container">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        autoOk
                                        disablePast={false}
                                        label=""
                                        clearable={false}
                                        format="dd/MM/yyyy"
                                        value={dateRange.endDate}
                                        onChange={(e) => onDateChange(e, 'endDate')}
                                        className="form-control"
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                            <span className="error">{error.endDate}</span>
                        </div>
                    </div>
                    {loading ? (
                        <div className="text-center">
                            <i className="fas fa-spinner fa-pulse fa-3x"></i>
                        </div>
                    ) : (
                        <div className="">
                            <div className="dataTables_wrapper" id="table_data">
                                <SortingTable columns={COLUMNS} data={contents} excelExport={true} fileData={fileData} />
                            </div>
                        </div>
                    )}
                </div>

            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(TransactionList);
