import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import { connect } from 'react-redux';
import {  api } from "../../../utils/api";
import moment from "moment";
import SortingTable from "../../components/SortingTable";

const AdminList = (props) => {
    const COLUMNS = [
        {
            Header : 'No.',
            accessor: (row, i) => {
                return ++i + "."
            },
        },
        {
            Header : 'Email',
            accessor: 'email'
        },
        {
            Header : 'First Name',
            accessor: 'firstName'
        },
        {
            Header : 'Last Name',
            accessor: 'lastName'
        },
        {
            Header : 'Phone',
            accessor: 'phone'
        },
        {
            Header : 'Created Datetime',
            accessor: (row) => {
               return moment(row.createdAt).format('YYYY-MM-DD HH:MM:ss')
            }
        },
        {
            Header : 'Action',
            accessor: (row) => {
                return (
                    <>
                        <Link className="btn btn-primary shadow btn-xs sharp me-2"
                            to={{
                                pathname: "/admin/details",
                                data: row.id // your data array of objects
                            }}
                        >
                            <i className="fas fa-eye"></i>
                        </Link>
                        {/* <Link className="btn btn-secondary shadow btn-xs sharp me-2"
                            to={{
                                pathname: "/user/edit",
                                data: row.id 
                            }}
                        >
                            <i className="fas fa-edit"></i>
                        </Link> */}
                        {props.auth.auth.email === 'root@root.root' ? (
                        <Link className="btn btn-danger shadow btn-xs sharp me-2" onClick={() => deleteAdmin(row.id)} disable={actionLoading}>
                            {actionLoading ? (<i class="fas fa-spinner fa-spin"></i>) : <i className="fas fa-trash"></i>}
                        </Link>
                        ) : null}
                        
                    </>
                )
            },
        },
    ];
    const [loading, setLoading] = useState(false);
    const [actionLoading, setActionLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const history = useHistory();

    useEffect(() => {
        getList();
    }, [props.auth]);

    const getList = () => {
        setLoading(true);
        fetch(api.users, {
            method:"GET",
            headers:new Headers({
                "Content-Type":"application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        }).then(response => response.json())
        .then(responseJson => {
            setLoading(false);
            console.log(responseJson) 
            if(responseJson.data){
                setUsers(responseJson.data)
            }
        }).catch(error => { 
                console.log("Error",error);
        });
    }

    const deleteAdmin = (id) => {
        swal({
            title: "Delete data?",
            text:
              "Once deleted, you will not be able to recover this data anymore!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                setActionLoading(true);
                fetch(api.removeAdmin + "/" +id, {
                    method:"PUT",
                    headers:new Headers({
                        "Content-Type":"application/JSON",
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                }).then(response => response.json())
                .then(responseJson => {
                    setActionLoading(false);
                    if(responseJson.status === 'success'){
                        swal(responseJson.message, {
                            icon: "success",
                        });
                        getList();
                    }else{
                        swal(responseJson.message, {
                            icon: "error",
                        });
                    }
                }).catch(error => {
                    console.log("Error",error);
                });
            } 
        })
    }

    return (
        <div className="col-12">
            <div className="row page-titles mx-0 shadow">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                        <span>Admin</span>
                    </li>
                    <li className="breadcrumb-item">
                        <span>List</span>
                    </li>
                </ol>
            </div>
            <div className="card shadow">
                <div className="card-header">
                    <Link className="btn btn-outline-primary btn-sm pull-right" to="/admin/create">Create New</Link>
                </div>
                <div className="card-body">
                    {loading ? (
                    <div className="text-center">
                        <i className="fas fa-spinner fa-pulse fa-3x"></i>
                    </div>
                    ) : (
                    <div className="w-100 table-responsive">
                        <div id="job_data" className="dataTables_wrapper">
                            <SortingTable columns={COLUMNS} data={users} />
                        </div>
                    </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};


export default connect(mapStateToProps)(AdminList);
