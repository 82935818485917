/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link, useHistory } from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { connect } from 'react-redux';

/// Image
//import profile from "../../../images/profile/pic1.jpg";
//import plus from "../../../images/plus.png";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = (props) => {
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
  } = useContext(ThemeContext);
  const [path, setPath] = useState('');
  const history = useHistory();
  const [pathHeader, setPathHeader] = useState("");

  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);


  }, []);
  let scrollPosition = useScrollPosition();
  /// Path
  // let path = window.location.pathname;
  // path = path.split("/");
  // path = path[path.length - 1];

  useEffect(() => {
    history.listen((location) => {
      setPathHeader(location.pathname.split('/')[1]);
      setPath(location.pathname);

      console.log(location.pathname.split('/')[1]);
      // console.log(location.pathname)
    })
    if (!path) {
      setPath(history.location.pathname);
    }
    if (!pathHeader) {
      setPathHeader(history.location.pathname.split('/')[1]);
    }
  }, [history])

  /// Active menu
  const dashboard = [
    "",
    "dashboard"
  ];
  const admin = [
    "admin"
  ];
  const branch = [
    "branch"
  ];
  const announcement = [
    "announcement",
  ];
  const purchase = [
    "purchase",
  ];
  const transaction = [
    "transaction",
  ];
  const member = [
    "user",
  ];
  return (
    <div
      className={`deznav ${iconHover} ${sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
        ? scrollPosition > 120
          ? "fixed"
          : ""
        : ""
        }`}
    >
      <PerfectScrollbar className="deznav-scroll">
        <MM className="metismenu" id="menu">

          {props.auth.auth.branchId ? null : (
            <li className={`${dashboard.indexOf(pathHeader) > -1 ? "mm-active" : ""}`}>
              <Link to="/dashboard" className="ai-icon" >
                <i className="flaticon-381-home-2"></i>
                <span className="nav-text">Dashboard</span>
              </Link>
            </li>)}
          {/* <li className={`${gift.includes(path) ? "mm-active" : ""}`}>
            <Link to="/gift/list" className="ai-icon" >
              <i className="mdi mdi-gift"></i>
              <span className="nav-text">Purchase Gift</span>
            </Link>
          </li> */}
          {props.auth.auth.isMaster || props.auth.auth.branchId ? (
            <li className={`${purchase.indexOf(pathHeader) > -1 ? "mm-active" : ""}`}>
              <Link to="/purchase/list" className="ai-icon" >
                <i className="mdi mdi-cart"></i>
                <span className="nav-text">Purchase Gold</span>
              </Link>
            </li>) : null}
          {props.auth.auth.email === 'root@root.root' ? (
            <li className={`${admin.indexOf(pathHeader) > -1 ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="mdi mdi-wallet"></i>
                <span className="nav-text forms">Admin</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "/admin/list" ? "mm-active" : ""}`} to="/admin/list">
                    List
                  </Link>
                </li>
              </ul>
            </li>
          ) : null}
          {/* <li className={`${withdraw.includes(path) ? "mm-active" : ""}`}>
            <Link to="/withdraw/list" className="ai-icon" >
              <i className="mdi mdi-wallet"></i>
              <span className="nav-text">Withdrawal</span>
            </Link>
          </li> */}
          {/* <li className={`${investment.includes(path) ? "mm-active" : ""}`}>
            <Link to="/investment/list" className="ai-icon" >
              <i className="flaticon-381-layer-1"></i>
              <span className="nav-text">GSP+</span>
            </Link>
          </li> */}
          {/* {props.auth.auth.isMaster ? (
          <li className={`${inventory.includes(path) ? "mm-active" : ""}`}>
            <Link to="/inventory/list" className="ai-icon" >
              <i className="flaticon-381-list"></i>
              <span className="nav-text">Inventory</span>
            </Link>
          </li>
          ):null} */}
          {props.auth.auth.isMaster ? (
            <li className={`${transaction.indexOf(pathHeader) > -1 ? "mm-active" : ""}`}>
              <Link to="/transaction/list" className="ai-icon" >
                {/* <i className="flaticon-381-share-1"></i> */}
                <i className="mdi mdi-transfer"></i>
                <span className="nav-text">Transaction</span>
              </Link>
            </li>
          ) : null}

          {/* <li className={`${contract.includes(path) ? "mm-active" : ""}`}>
            <Link to="/contract/list" className="ai-icon" >
              <i className="flaticon-381-home-2"></i>
              <span className="nav-text">Contract</span>
            </Link>
          </li> */}
          {/* <li className={`${account.includes(path) ? "mm-active" : ""}`}>
            <Link to="/account" className="ai-icon" >
              <i className="flaticon-381-user-7"></i>
              <span className="nav-text">My Account</span>
            </Link>
          </li> */}
          <li className={`${member.indexOf(pathHeader) > -1 ? "mm-active" : ""}`}>
            <Link to="#" className="ai-icon" >
              <i className="flaticon-381-user-9"></i>
              <span className="nav-text">Member</span>
            </Link>
            <ul >
              <li>
                <Link className={`${member.indexOf(pathHeader) > -1 ? "mm-active" : ""}`} to="/user/list">
                  Member
                </Link>
              </li>
              {/* {props.auth.auth.isMaster ? (<li>
                <Link className={`${path === "/bank/list" ? "mm-active" : ""}`} to="/bank/list">
                  Bank
                </Link>
              </li>):null} */}
            </ul>
          </li>
          {/* {props.auth.auth.isMaster == 2? (
          <li className={`${hierarchy.includes(path) ? "mm-active" : ""}`}>
            <Link to="/hierarchy/list" className="ai-icon" >
              <i className="fas fa-sitemap"></i>
              <span className="nav-text">Hierarchy</span>
            </Link>
          </li>
          ):null} */}
          {/* {props.auth.auth.isMaster ? (
          <li className={`${outletAdmin.includes(path) ? "mm-active" : ""}`}>
            <Link to="/outlet-admin/list" className="ai-icon" >
              <i className="flaticon-381-user-3"></i>
              <span className="nav-text">Outlet Admin</span>
            </Link>
          </li>
          ):null} */}
          {props.auth.auth.isMaster ? (
            <li className={`${branch.indexOf(pathHeader) > -1 ? "mm-active" : ""}`}>
              <Link to="/branch/list" className="ai-icon" >
                <i className="flaticon-381-share-1"></i>
                <span className="nav-text">Branch</span>
              </Link>
              {/* <Link to="/branch/list" className="has-arrow ai-icon" >
              <i className="flaticon-381-location-1"></i>
              <span className="nav-text">Branch</span>
            </Link>
            <ul >
              <li>
                <Link className={`${path === "/branch/list" ? "mm-active" : ""}`} to="/branch/list">
                  Branch List
                </Link>
              </li>
              <li>
                <Link className={`${path === "/contract/digital" ? "mm-active" : ""}`} to="/contract/digital">
                  Digital Contract list
                </Link>
              </li>
            </ul> */}
            </li>) : null}
          {/* {props.auth.auth.isMaster ? (
          <li className={`${setting.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" >
              <i className="flaticon-381-settings-7"></i>
              <span className="nav-text forms">Setting</span>
            </Link>
            <ul >
              <li>
                <Link className={`${path === "/gold/standards" ? "mm-active" : ""}`} to="/gold/standards">
                  Gold Standards
                </Link>
              </li>
              <li>
                <Link className={`${path === "/gold/price" ? "mm-active" : ""}`} to="/gold/price">
                  Gold Price
                </Link>
              </li>
              <li>
                <Link className={`${path === "/gold/buyback" ? "mm-active" : ""}`} to="/gold/buyback">
                  Gold Buy Back
                </Link>
              </li>
              <li>
                <Link className={`${path === "/gold/premium" ? "mm-active" : ""}`} to="/gold/premium">
                  Gold Premium
                </Link>
              </li>
              <li>
                <Link className={`${path === "/purchase/setting" ? "mm-active" : ""}`} to="/purchase/setting">
                  Purchase Setting
                </Link>
              </li>
              <li>
                <Link className={`${path === "/shop/settings" ? "mm-active" : ""}`} to="/shop/settings">
                  Shop Setting
                </Link>
              </li>
              <li>
                <Link className={`${path === "/terms/list" ? "mm-active" : ""}`} to="/terms/list">
                  Terms List
                </Link>
              </li>
            </ul>
          </li>
          ):null} */}
          {props.auth.auth.isMaster ? (
            <li className={`${announcement.indexOf(pathHeader) > -1 ? "mm-active" : ""}`}>
              <Link to="/announcement/list" className="ai-icon" >
                <i className="flaticon-381-promotion"></i>
                <span className="nav-text">Announcement</span>
              </Link>
            </li>
          ) : null}
        </MM>
      </PerfectScrollbar>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};


export default connect(mapStateToProps)(SideBar);