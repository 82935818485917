import React, { useState, useEffect } from "react";
import { Link, Router, useHistory } from "react-router-dom";
import swal from "sweetalert";
import { connect } from 'react-redux';
import {  api } from "../../../utils/api";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import SortingTable from "../../components/SortingTable";
import Switch from "react-switch";

const OutletAdminList = (props) => {
    const COLUMNS = [
        {
            Header : 'No.',
            accessor: (row, i) => {
                return ++i + "."
            },
        },
        {
            Header : 'First Name',
            accessor: 'firstName'
        },
        {
            Header : 'Branch',
            accessor: 'lastName'
        },
        {
            Header : 'Status',
            accessor: (row) => {
                return row.status
             }
        },
        {
            Header : 'Active',
            accessor: (row,i) => {
                return (
                    <>
                        <Switch onChange={(e) => handleChangeSwitch(e, row)} checked={users[i].status === "active"? true: false} />
                    </>
                )
            },
        },
        
        {
            Header : 'Created Datetime',
            accessor: (row) => {
               return moment(row.createdAt).format('YYYY-MM-DD HH:MM:ss')
            }
        },
        {
            Header : 'Action',
            accessor: (row) => {
                return (
                    <>
                        <Link className="btn btn-primary shadow btn-xs sharp me-2"
                            to={{
                                pathname: `/outlet-admin/edit/${row.id}`
                                // data: row.id // your data array of objects
                            }}
                        >
                            <i className="fas fa-eye"></i>
                        </Link>
                        <a className="btn btn-danger shadow btn-xs sharp me-2" onClick={() =>deleteUser(row.id)}>
                            <i className="fas fa-trash"></i>
                        </a>
                        {/* <Dropdown hidden>
                            <Dropdown.Toggle
                            as="button"
                            variant=""
                            className="btn sharp btn-primary tp-btn mt-1"
                            id="tp-btn"
                            >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="18px"
                                height="18px"
                                viewBox="0 0 24 24"
                                version="1.1"
                            >
                                <g
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                                >
                                <rect x="0" y="0" width="24" height="24" />
                                <circle fill="#000000" cx="12" cy="5" r="2" />
                                <circle fill="#000000" cx="12" cy="12" r="2" />
                                <circle fill="#000000" cx="12" cy="19" r="2" />
                                </g>
                            </svg>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={()=>editUser(row.id)}>Edit</Dropdown.Item>
                                <Dropdown.Item onClick={()=>disableUser(row.id)}>Disable</Dropdown.Item>
                                <Dropdown.Item onClick={()=>deleteUser(row.id)} disabled={actionLoading && actionId === row.id}> {actionLoading && actionId === row.id ? (<i class="fas fa-spinner fa-spin"></i>) : "Remove"}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> */}
                    </>
                )
            },
        },
    ];
    const [loading, setLoading] = useState(false);
    const [actionLoading, setActionLoading] = useState(false);
    const [actionId, setActionId] = useState(null);
    const [users, setUsers] = useState([]);
    const history = useHistory();
    const [switch1, setSwitch1] = useState(false);

    const [fileData, setFileData] = useState({
        name: "Gold_Outlet_Admin_List",
        excelData: [],
        hasCustom: false,
        
    })


    useEffect(() => {
        getList();
    }, [props.auth]);

    useEffect(() => {
        if(users.length > 0) getList(); 
    }, [switch1])

    const editUser = (id) =>{
        history.push("/outlet-admin/edit/" + id)
    }
    const getList = () => {
        setLoading(true);
        fetch(api.users, {
            method:"GET",
            headers:new Headers({
                "Content-Type":"application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        }).then(response => response.json())
        .then(responseJson => {
            setLoading(false);
            console.log(responseJson) 
            if(responseJson.data){
                let data = responseJson.data.filter(i=> i.branchId !== null)
                setUsers(data)

                let excelTable = responseJson.data.map((i,index) => {
                        
                    return {
                        "No.": index + 1,
                        "First Name": i.firstName, 
                        "Branch": i.lastName, 
                        "Status": i.status,
                        "Created Datetime": moment(i.createdAt).format('YYYY-MM-DD HH:mm:ss')
                    }
                })
                setFileData({
                    ...fileData,
                    excelData: excelTable,
                    hasCustom: false,
                    customRows:[],
                    
                });
            }
        }).catch(error => { 
                console.log("Error",error);
        });
    }

    const handleChangeSwitch = (e, row) =>{
        let postData = {
            status : e ? "active" : "deactive"
        }
        fetch(api.users + "/" + row.id, {
            method:"Put",
            headers:new Headers({
                "Content-Type":"application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
            body: JSON.stringify(postData)
        })
        .then(response => response.json())
        .then(responseJson => {
            setLoading(false);
            console.log(responseJson)
            if(responseJson.status === 'success'){ 
                setSwitch1(!switch1)
                swal('Good job!', 'Successfully Updated', "success");
            }
        }).catch(error => {
            swal("Oops", "Internal Server Error!", "error")
        });  
    }

    const deleteUser = (id) => {
        swal({
            title: "Delete data?",
            text:
              "Once deleted, you will not be able to recover this data anymore!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                setActionId(id);
                setActionLoading(true);
                fetch(api.users + "/" +id  + "/remove", {
                    method:"PUT",
                    headers:new Headers({
                        "Content-Type":"application/JSON",
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                }).then(response => response.json())
                .then(responseJson => {
                    setActionLoading(false);
                    setActionId(null);
                    if(responseJson.status === 'success'){
                        swal(responseJson.message, {
                            icon: "success",
                        });
                        getList();

                        
                    }else{
                        swal(responseJson.message, {
                            icon: "error",
                        });
                    }
                }).catch(error => {
                    console.log("Error",error);
                });
            } 
        })
    }

    return (
        <div className="col-12">
            <div className="row page-titles mx-0 shadow">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                        <span>Outlet Admin</span>
                    </li>
                    <li className="breadcrumb-item">
                        <span>List</span>
                    </li>
                </ol>
            </div>
            <div className="card shadow">
                <div className="card-header">
                    <Link className="btn btn-outline-primary btn-sm pull-right" to="/outlet-admin/create">Create New</Link>
                </div>
                <div className="card-body">
                    {loading ? (
                    <div className="text-center">
                        <i className="fas fa-spinner fa-pulse fa-3x"></i>
                    </div>
                    ) : (
                    <div className="w-100">
                        <div id="job_data" className="dataTables_wrapper">
                            <SortingTable columns={COLUMNS} data={users} excelExport={true} fileData={fileData}/>
                        </div>
                    </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};


export default connect(mapStateToProps)(OutletAdminList);
