import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import { connect } from 'react-redux';
import { api } from "../../../utils/api";
import moment from "moment";
import { Button, Modal } from "react-bootstrap";
import SortingTable from "../../components/SortingTable";

const HierarchyList = (props) => {

    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    const [filterOption, setFilterOption] = useState("all");
    const history = useHistory();

    const [contents, setContents] = useState({
        memberId: "",
    });
    useEffect(() => {
        // getList();

    }, [props.auth]);

    const [error, setError] = useState({
        memberId: null,
    });

    const checkForm = () => {
        let check = true;
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        let error = {
            memberId: null,
        };
        // if(!formData.firstName){
        //     check = false;
        //     error.firstName = "This field is required."
        // }
        // if(!formData.lastName){
        //     check = false;
        //     error.lastName = "This field is required."
        // }
        if (!contents.memberId) {
            check = false;
            error.memberId = "This field is required."
        }

        setError(error);
        // setFormData({...formData, referralId: token ?? ""})
        return check;
    }

    const onInputChanges = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...contents };
        newFormData[fieldName] = fieldValue;
        setContents(newFormData);
    };



    const searchHierarchy = (event) => {
        event.preventDefault();
        let check = checkForm();
        console.log("memberId", contents)
        if (check) {
            setLoading(true)
            fetch(api.users + `/hierarchy/list/${contents.memberId}`, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/JSON",
                    "Authorization": "Bearer " + props.auth.auth.token
                }),


            }).then(response => response.json())
                .then(responseJson => {
                    setLoading(false);
                    if (responseJson.status === 'success') {

                        setList(responseJson.data);

                        // swal(responseJson.message, {
                        //     icon: "success",
                        // });
                        // resetForm();
                        history.push('/hierarchy/list')
                    } else {
                        swal(responseJson.message, {
                            icon: "error",
                        });

                        setList([]);
                    }
                }).catch(error => {
                    console.log("Error", error);
                });
        }
    };







    return (
        <div className="col-12">
            <div className="row page-titles mx-0 shadow">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                        <span>Hierarchy</span>
                    </li>
                    <li className="breadcrumb-item">
                        <span>List</span>
                    </li>
                </ol>
            </div>
            <div className="card shadow">

                <div className="card-body">
                   
                        <div className="row w-100 m-0">
                            <div id="job_data" className="col-8 col-md-10 dataTables_wrapper">
                                <input
                                    type="text"
                                    className=" form-control"
                                    placeholder="Please enter agent Id"
                                    name="memberId"
                                    onChange={(e) => onInputChanges(e)}
                                    value={contents ? contents.memberId : ""}
                                />
                                <span className="error">{error.memberId}</span>

                                
                            </div>
                            <button className="col-4 col-md-2 btn btn-primary profile-button btn-sm text-white" onClick={(e) => searchHierarchy(e)} disabled={loading}>{loading ? (<i class="fas fa-spinner fa-spin"></i>) : "Search"}</button>
                        </div>
                    
                </div>
                <div className="card-body">
                    {loading ? (
                        <div className="text-center">
                            <i className="fas fa-spinner fa-pulse fa-3x"></i>
                        </div>
                    ) : (
                        <>
                            {list.slice(0).reverse().map((d, i) => (
                                <div className="">
                                    <div className="d-flex flex-column">
                                        <div className=" mx-auto px-2 icon-transistion">


                                            <div className={`card card-bx box-shadow-light ${d.color} hierarchy-card`}>
                                                <div className="card-body d-flex align-items-center">
                                                    <div className="me-auto text-white mx-auto text-center">
                                                        <h2 className="text-white">{d.MemberId}</h2>

                                                        <h4 className="text-white">{d.Rank}</h4>

                                                    </div>
                                                   

                                                </div>
                                            </div>



                                        </div>
                                        {d.color === "bg-primary" ? (


                                            <></>


                                        ) : (


                                            <div className="mx-auto">
                                                <i class="arrow up"></i>
                                            </div>
                                        )}

                                    </div>

                                </div>
                            ))}
                        </>
                    )}
                </div>
            </div>

        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};


export default connect(mapStateToProps)(HierarchyList);
