import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import swal from "sweetalert";
import Select, { createFilter } from "react-select";
import { api } from "../../../utils/api";

function NewOutletAdmin(props) {
    const history = useHistory(); 
    const requiredField = ["firstName", "lastName", "email", "username", "password", "confirmPassword", "phone", "branchId"];
    const [addFormData, setAddFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        username: '',
        phone: '',
        password: '',
        confirmPassword: '',
        branchId: '',
    });
    const [error, setError] = useState({
        firstName: '',
        lastName: '',
        email: '',
        username: '',
        phone: '',
        password: '',
        confirmPassword: '',
        branchId: '',
    });
    const [loading, setLoading] = useState(false);
    const [branchOption, setBranchOption] = useState([]);

    useEffect(() => {
        getBranch();
    }, [props.auth]);

    const getBranch = () => {
        fetch(api.branch, {
            method:"GET",
            headers:new Headers({
                "Content-Type":"application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        }).then(response => response.json())
        .then(responseJson => {
            setLoading(false);
            console.log(responseJson) 
            if(responseJson.status === 'success'){
                if(responseJson.data){
                    let data = responseJson.data.filter(i=> i.branchId !== null)
                    setBranchOption(data)
                }
            }
        }).catch(error => { 
                console.log("Error",error);
        });
    }

    const createAdmin = () => {
        let check = true;
        let errorMsg = { firstName: null, lastName: null, email: null, username: null, password: null, confirmPassword: null, phone: null };
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        requiredField.map((item, i)=>{
            if(!addFormData[item]){
                check = false;
                errorMsg[item] = "This field is required"
            }
        });
        if(addFormData.password){
            if(addFormData.password.length <6){
                check = false;
                errorMsg.password = "The password need at least 6 characters long."
            }
        }

        if(addFormData.password && addFormData.confirmPassword){
            if(addFormData.password !== addFormData.confirmPassword){
                check = false;
                errorMsg.confirmPassword = "Password and confirmed password are not matched";
            }
        }

        if(addFormData.email){
            if(!reg.test(addFormData.email)){
                check = false;
                errorMsg.email = "Invalid email."
            }
        }

        setError(errorMsg);

        if(check){
            setLoading(true);
            const postData = {
                firstName: addFormData.firstName,
                lastName: addFormData.lastName,
                username: addFormData.username,
                email: addFormData.email,
                phone: addFormData.phone,
                password: addFormData.password,
                confirmPassword: addFormData.confirmPassword,
                branchId: addFormData.branchId
            };
            fetch(api.users, {
                method:"POST",
                headers:new Headers({
                    "Content-Type":"application/JSON",
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(postData)
            })
            .then(response => response.json())
            .then(responseJson => {
                setLoading(false);
                if(responseJson.status === 'success'){
                    swal('Good job!', responseJson.message, "success");
                    history.push('/outlet-admin/list');
                }else{
                    swal(responseJson.message, {
                        icon: "error",
                    });
                }
            }).catch(error => {
                console.log("Error",error);
            });
        }
    }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...addFormData };
        newFormData[fieldName] = fieldValue;
        setAddFormData(newFormData);
    };

    const handleOptionSelected = (e, name) => {
        const { label, value } = e;
        const info = {...addFormData};
        info[name] = value;
        setAddFormData(info);
    }

    return (
        <>
            <div className="col-12">
                <div className="row page-titles mx-0 shadow">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active">
                            <span>Outlet Admin</span>
                        </li>
                        <li className="breadcrumb-item">
                            <span>Create</span>
                        </li>
                    </ol>
                </div>

                <div className="card shadow">
                    <div className="card-body">
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                First Name
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    name="firstName"
                                    onChange={handleAddFormChange}
                                    value={addFormData.firstName}
                                />
                                <span className="error">{error.firstName}</span>
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                Last Name
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    name="lastName"
                                    onChange={handleAddFormChange}
                                    value={addFormData.lastName}
                                />
                                <span className="error">{error.lastName}</span>
                            </div>
                        </div>
                        
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                Contact Number
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    name="phone"
                                    onChange={handleAddFormChange}
                                    value={addFormData.phone}
                                />
                                <span className="error">{error.phone}</span>
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                Email
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder=""
                                    name="email"
                                    onChange={handleAddFormChange}
                                    value={addFormData.email}
                                />
                                <span className="error">{error.email}</span>
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                Username
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    name="username"
                                    onChange={handleAddFormChange}
                                    value={addFormData.username}
                                />
                                <span className="error">{error.username}</span>
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                Password
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder=""
                                    name="password"
                                    onChange={handleAddFormChange}
                                    value={addFormData.password}
                                />
                                <span className="error">{error.password}</span>
                            </div>
                        </div>
                        
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                Confirm Password
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder=""
                                    name="confirmPassword"
                                    onChange={handleAddFormChange}
                                    value={addFormData.confirmPassword}
                                />
                                <span className="error">{error.confirmPassword}</span>
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                Branch
                            </label>
                            <div className="col-sm-10">
                                <Select
                                    className="form-control"
                                    value = {
                                        (branchOption.map(({ id, name }) => ({ value: id, label: name })))
                                        .filter(option => option.value === addFormData.branchId)
                                    }
                                    options={branchOption.map(({ id, name }) => ({ value: id, label: name }))}
                                    placeholder="- Select -"
                                    style={{
                                        lineHeight: "40px",
                                        color: "#7e7e7e",
                                        paddingLeft: " 15px",
                                        borderWidth: 0,
                                    }}
                                    onChange={e=> handleOptionSelected(e, 'branchId')}
                                />
                                <span className="error">{error.branchId}</span>
                            </div>
                        </div>
                        
                    </div>
                    <div className="card-footer">
                        <button className="btn btn-primary profile-button btn-sm text-white me-2" disabled={loading} onClick={createAdmin}>
                            {loading ? (<i class="fas fa-spinner fa-spin"></i>) : "Submit"}
                        </button>
                        <Link className="btn btn-danger profile-button btn-sm text-white" to="/outlet-admin/list">Discard</Link>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(NewOutletAdmin);
