import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import { connect } from 'react-redux';
import { api } from "../../../utils/api";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import SortingTable from "../../components/SortingTable";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { urlParamsLocalStorage } from "../../../utils/function";

const InvestmentList = (props) => {
    const COLUMNS = [
        {
            Header: 'No.',
            accessor: (row, i) => {
                return i + 1;
            }
        },
        {
            Header: 'Reference No',
            accessor: 'investmentNo'

        },
        {
            Header: 'Gold Weight(gram)',
            accessor: 'weight'
        },
        {
            Header: 'Created Datetime',
            accessor: (row) => {
                return moment(row.createdAt).format('YYYY-MM-DD HH:MM:ss')
            }
        },
        {
            Header: 'Status',
            accessor: (row) => {
                return <span className="text-capitalize">{row.status}</span>
            }
        },
        {
            Header: 'Action',
            accessor: (row) => {
                return (
                    <>
                        <Link to={"/investment/details/" + row.id} onClick={() => storeParams()} className="text-primary">
                            <i className="fas fa-eye"></i>
                        </Link>
                    </>
                )
            }
        },
    ];

    const [loading, setLoading] = useState(false);
    const [contents, setContents] = useState([]);
    const [filterOption, setFilterOption] = useState("all");
    const [allData, setAllData] = useState([]);
    const [fileData, setFileData] = useState({
        name: "Gold_Storage_Program_Plus_List",
        excelData: [],
        hasCustom: false,
    });
    const [error, setError] = useState([]);
    const [dateRange, setDateRange] = useState({
        startDate: moment().startOf('month'),
        endDate: moment().endOf('month'),
    });
    const history = useHistory();
    const [searchText, setSearchText] = useState("");
    const [currentPageNumber, setCurrentPageNumber] = useState(0);
    useEffect(() => {
        if (allData.length > 0) {
            if (filterOption === 'all') {
                setContents(allData);
            } else {
                let list = allData.filter(arr => arr.status === filterOption);
                setContents(list);

                setFileData({
                    ...fileData,
                    excelData: list,
                    hasCustom: false,
                    customRows: [],

                });
            }
        }
    }, [filterOption]);

    useEffect(() => {
        let urlParams = urlParamsLocalStorage('get', { path: history.location.pathname, name: "withdrawList" });
        if (urlParams) {
            getList(urlParams.query.startDate, urlParams.query.endDate);
            setDateRange({
                startDate: moment(urlParams.query.startDate),
                endDate: moment(urlParams.query.endDate),
            })
            setSearchText(urlParams.query.search);
            setCurrentPageNumber(urlParams.query.tablePageNumber);
        } else {
            getList(dateRange.startDate, dateRange.endDate)
        }
    }, [props.auth]);

    const getList = (start, end) => {
        setLoading(true);
        fetch(api.investment + `?start=${moment(start).format('YYYY-MM-DD 00:00:00')}&end=${moment(end).format('YYYY-MM-DD 23:59:59')}`, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        }).then(response => response.json())
            .then(responseJson => {
                setLoading(false);
                if (responseJson.data) {
                    let filter = new URLSearchParams(props.location.search).get('filter');
                    if (filter) {
                        responseJson.data = responseJson.data.filter(i => i.status === filter);
                    }
                    setAllData(responseJson.data);
                    setContents(responseJson.data);
                    let excelTable = responseJson.data.map((i, index) => {
                        return {
                            "No.": index + 1,
                            "Reference no.": i.investmentNo,
                            "Gold Weight(gram)": i.weight,
                            "Created Datetime": moment(i.createdAt).format('YYYY-MM-DD HH:MM:ss'),
                            "Status": i.status,

                        }
                    })
                    setFileData({
                        ...fileData,
                        excelData: excelTable,
                        hasCustom: false,
                        customRows: [],
                    });
                }
            }).catch(error => {
                console.log("Error", error);
            });
    }

    const getSearchText = (e) => {
        setSearchText(e)
    }

    const getTablePageNumber = (e) => {
        setCurrentPageNumber(e);
    }

    const storeParams = () => {
        let urlData = {
            path: history.location.pathname,
            name: "withdrawList",
            query: {
                startDate: dateRange.startDate,
                endDate: dateRange.endDate,
                search: searchText,
                deliveryStatus: filterOption,
                tablePageNumber: currentPageNumber,
            }
        };
        urlParamsLocalStorage('set', urlData);
    }

    function onDateChange(e, name) {
        let temp = { ...dateRange };
        temp[name] = e;
        getList(temp.startDate, temp.endDate);
        setDateRange(temp);
    }

    return (
        <>
            <div className="row page-titles mx-0 shadow">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                        <span>Gold Storage Program +</span>
                    </li>
                    <li className="breadcrumb-item">
                        <span>Gold List</span>
                    </li>
                </ol>
            </div>
            <div className="card shadow card-black">
                <div className="card-body">
                    <div className="row align-items-center mb-3">
                        <div className="col-md-2">
                            <span className="text-nowrap">Filter by status:</span>
                        </div>
                        <div className="col-md-6">
                            <button className={`${filterOption === 'all' ? "btn-primary btn btn-sm me-2" : "btn-outline-primary btn btn-sm me-2"}`} onClick={() => setFilterOption('all')}>All</button>
                            <button className={`${filterOption === 'pending' ? "btn-primary btn btn-sm me-2" : "btn-outline-primary btn btn-sm me-2"}`} onClick={() => setFilterOption('pending')}>Pending</button>
                            <button className={`${filterOption === 'awaiting delivery' ? "btn-primary btn btn-sm me-2" : "btn-outline-primary btn btn-sm me-2"}`} onClick={() => setFilterOption('awaiting delivery')}>Awaiting Delivery</button>
                            <button className={`${filterOption === 'approved' ? "btn-primary btn btn-sm me-2" : "btn-outline-primary btn btn-sm me-2"}`} onClick={() => setFilterOption('approved')}>Approved</button>
                            <button className={`${filterOption === 'cancelled' ? "btn-primary btn btn-sm me-2" : "btn-outline-primary btn btn-sm me-2"}`} onClick={() => setFilterOption('cancelled')}>Cancelled</button>
                            <button className={`${filterOption === 'rejected' ? "btn-primary btn btn-sm me-2" : "btn-outline-primary btn btn-sm me-2"}`} onClick={() => setFilterOption('rejected')}>Rejected</button>
                            <button className={`${filterOption === 'redeemed' ? "btn-primary btn btn-sm me-2" : "btn-outline-primary btn btn-sm me-2"}`} onClick={() => setFilterOption('redeemed')}>Redeemed</button>
                        </div>
                        <div className="col-md-4">
                            <Link className="btn btn-outline-primary btn-sm pull-right float-end" to="/investment/digital">View e-Gold List</Link>
                        </div>
                        <div className="row mt-2 align-items-center">
                            <div className="col-md-2">
                                <span className="text-nowrap">Filter by date:</span>
                            </div>
                            <div className="col-md-2">
                                <div className="form-control date-picker-container">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            autoOk
                                            disablePast={false}
                                            label=""
                                            clearable={false}
                                            format="dd/MM/yyyy"
                                            value={dateRange.startDate}
                                            onChange={(e) => onDateChange(e, 'startDate')}
                                            className="form-control"
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <span className="error">{error.startDate}</span>
                            </div>
                            <div className="col-md-2">
                                <div className="form-control date-picker-container">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            autoOk
                                            disablePast={false}
                                            label=""
                                            clearable={false}
                                            format="dd/MM/yyyy"
                                            value={dateRange.endDate}
                                            onChange={(e) => onDateChange(e, 'endDate')}
                                            className="form-control"
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <span className="error">{error.endDate}</span>
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <div className="text-center">
                            <i className="fas fa-spinner fa-pulse fa-3x"></i>
                        </div>
                    ) : (
                        <div className="">
                            <div className="dataTables_wrapper" id="table_data">
                                <SortingTable columns={COLUMNS} data={contents} excelExport={true} fileData={fileData} setSearchText={getSearchText} searchText={searchText} setPageNumber={getTablePageNumber} currentPageNumber={currentPageNumber}/>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(InvestmentList);
