import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import swal from "sweetalert";
import { imageUrl, api } from "../../../utils/api";

const GiftDetails = (props) => {
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const [formData, setFormData] = useState({
        purchaseId: "",
        customer: "",
        amount: "",
        product:  "",
        collectMethod: "",
        address1: "",
        address2: "",
        country: "",
        state: "",
        city: "",
        postCode: "",
        deliveryStatus: "",
        remarks: "",
        adminRemarks: "",
        trackingNo: "",
        trackingCode: "",
    });
    const [error, setError] = useState({
        trackingNo: "",
        trackingCode: "",
        adminRemarks: "",
    });
    const [investment, setInvestment] = useState(null)

    useEffect(() => {
        getDetails();
    }, [props.auth]);

    const getDetails = () => {
        setLoading(true);
        fetch(api.users + "/gift/purchase?id=" + id, {
            method:"GET",
            headers:new Headers({
                "Content-Type":"application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        }).then(response => response.json())
        .then(responseJson => {
            setLoading(false);
            if(responseJson.data){ 
                let data = {
                    customer: responseJson.data[0].user.fullName,
                    member: responseJson.data[0].user.member,
                    purchaseId: responseJson.data[0].purchaseId,
                    amount: responseJson.data[0].amount,
                    product:  responseJson.data[0].giftModal,
                    collectMethod: responseJson.data[0].delivery,
                    address1: responseJson.data[0].address1,
                    address2: responseJson.data[0].address2,
                    country: responseJson.data[0].country,
                    state: responseJson.data[0].state,
                    city: responseJson.data[0].city,
                    postCode: responseJson.data[0].postCode,
                    deliveryStatus: responseJson.data[0].deliveryStatus,
                    trackingNo: responseJson.data[0].trackingNo,
                    adminRemarks: responseJson.data[0].adminRemarks,
                    // trackingCode: responseJson.data[0].trackingCode,
                }
                // setInvestment(responseJson.data[0]);
                setFormData(data)
            }
            
        }).catch(error => { 
            setLoading(false);
            console.log("Error",error);
        });
    }
    
    const updateDeliveryStatus = (e, status) => {
        setLoading(true);
        let check = true;
        let err = {trackingNo: "", trackingCode: ""};
        if (status === 'shipped') {
            if(!formData.trackingNo) {
                check = false;
                err.trackingNo = "This field is required";
            }
        }
        if (formData.collectMethod === 'selfCollect' && !formData.trackingCode) {
            check = false;
            err.trackingCode = "This field is required";
        }
        setError(err);
        if (check) {
            swal({
                title: `Update delivery status to ${status}?`,
                text: "Once update, you will not be able to recover this data anymore!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((result) => {
                if (result) {
                    fetch(api.users + `/gift/purchase/${id}/${status}`, {
                        method:"PUT",
                        headers:new Headers({
                            "Content-Type":"application/JSON",
                            "Authorization": "Bearer " + props.auth.auth.token
                        }),
                        body: JSON.stringify(formData),
                    }).then(response => response.json())
                    .then(responseJson => {
                        setLoading(false);
                        getDetails();
                        if (responseJson.status === 'success') {
                            swal('Updated!', responseJson.message, "success");
                        } else{
                            swal('Oops!', responseJson.message, "error");
                        }                    }).catch(error => { 
                        setLoading(false);
                        console.log("Error",error);
                    });
                } 
                setLoading(false);
            })
        }
        setLoading(false);
    }

    return (
        <>
            <div className="row page-titles mx-0 shadow">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                        <span>Purchase Gift</span>
                    </li>
                    <li className="breadcrumb-item">
                        <span>Details</span>
                    </li>
                </ol>
            </div>
            <div className="card shadow">
                <div className="card-body">
                    <div className="mb-3 row align-items-center">
                        <label className="col-sm-2 col-form-label">Reference No.</label>
                        <div className="col-sm-8">
                            <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="refNo"
                            value={formData.purchaseId}
                            readOnly
                            />
                        </div>
                    </div>
                    <div className="mb-3 row align-items-center">
                        <label className="col-sm-2 col-form-label">Customer</label>
                        <div className="col-sm-8">
                            <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="name"
                            value={formData.customer}
                            readOnly
                            />
                        </div>
                    </div>
                    <div className="mb-3 row align-items-center">
                        <label className="col-sm-2 col-form-label">Rank</label>
                        <div className="col-sm-8">
                            <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="member"
                            value={formData.member}
                            readOnly
                            />
                        </div>
                    </div>
                    <div className="mb-3 row align-items-center">
                        <label className="col-sm-2 col-form-label">Amount (RM)</label>
                        <div className="col-sm-8">
                            <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="amount"
                            value={formData.amount}
                            readOnly
                            />
                        </div>
                    </div>
                    <div className="mb-3 row align-items-center">
                        <label className="col-sm-2 col-form-label">Product</label>
                        <div className="col-sm-8">
                            <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="product"
                            value={formData.product}
                            readOnly
                            />
                        </div>
                    </div>
                    <div className="mb-3 row align-items-center">
                        <label className="col-sm-2 col-form-label">Collect Method</label>
                        <div className="col-sm-8">
                            <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="delivery"
                            value={formData.collectMethod}
                            readOnly
                            />
                        </div>
                    </div>
                    <div className="mb-3 row align-items-center">
                        <label className="col-sm-2 col-form-label">Delivery Status</label>
                        <div className="col-sm-8">
                            <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="trackingCode"
                            value={formData.deliveryStatus}
                            readOnly
                            />
                        </div>
                    </div>
                    {
                        formData.collectMethod === 'delivery' ? 
                        <>
                        <div className="mb-3 row align-items-center">
                        <label className="col-sm-2 col-form-label">Shipping Address 1</label>
                            <div className="col-sm-8">
                                <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                name="address1"
                                value={formData.address1}
                                readOnly
                                />
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label">Shipping Address 2</label>
                            <div className="col-sm-8">
                                <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                name="address2"
                                value={formData.address2}
                                readOnly
                                />
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label">City</label>
                            <div className="col-sm-8">
                                <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                name="city"
                                value={formData.city}
                                readOnly
                                />
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label">State</label>
                            <div className="col-sm-8">
                                <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                name="state"
                                value={formData.state}
                                readOnly
                                />
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label">Postcode</label>
                            <div className="col-sm-8">
                                <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                name="postCode"
                                value={formData.postCode}
                                readOnly
                                />
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label">Country</label>
                            <div className="col-sm-8">
                                <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                name="country"
                                value={formData.country}
                                readOnly
                                />
                            </div>
                        </div>
                    </>: <></>
                    }
                    {/* <div className="mb-3 row align-items-center">
                        <label className="col-sm-2 col-form-label">Tracking Code</label>
                        <div className="col-sm-8">
                            <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="trackingCode"
                            value={formData.trackingCode}
                            readOnly
                            />
                        </div>
                    </div> */}
                    {formData.collectMethod === 'delivery' ? 
                    <div className="mb-3 row align-items-center">
                        <label className="col-sm-2 col-form-label">Tracking No</label>
                        <div className="col-sm-8">
                            <input
                            type="text"
                            className="form-control"
                            name="trackingNo"
                            value={formData.trackingNo}
                            onChange={(e) => setFormData({...formData, trackingNo: e.target.value})}
                            readOnly={formData.deliveryStatus !== 'pending'}
                            />
                            <span className="error">{error.trackingNo}</span>
                        </div>
                    </div>: <></>}
                    {formData.delivery === 'selfCollect' && formData.deliveryStatus === 'pending' ? (<div className="mb-3 row align-items-center">
                        <label className="col-sm-2 col-form-label">Tracking Code</label>
                        <div className="col-sm-8">
                            <input
                            type="text"
                            className="form-control"
                            name="remark"
                            value={formData.trackingCode}
                            onChange={(e) => setFormData({...formData, trackingCode: e.target.value})}
                            />
                            <span className="error">{error.trackingCode}</span>
                        </div>
                    </div>) : (<></>)}
                    <div className="mb-3 row align-items-center">
                        <label className="col-sm-2 col-form-label">Admin Remark</label>
                        <div className="col-sm-8">
                            <input
                            type="text"
                            className="form-control"
                            name="remark"
                            value={formData.adminRemarks}
                            onChange={(e) => setFormData({...formData, adminRemarks: e.target.value})}
                            readOnly={formData.deliveryStatus === 'completed' ? true : false}
                            />
                        </div>
                    </div>
                </div>
                <div className="card-footer d-flex flex-row">
                    {   
                        formData.collectMethod === 'selfCollect' && formData.deliveryStatus !== 'completed' ? 
                            <button type="button" className="btn btn-primary btn-sm me-2"  disabled={loading} onClick={(e) => updateDeliveryStatus(e, 'completed')}>Completed</button>:
                        formData.deliveryStatus === 'pending' ? 
                            <button type="button" className="btn btn-primary btn-sm me-2"  disabled={loading} onClick={(e) => updateDeliveryStatus(e, 'shipped')}>Shipped</button>:
                        formData.deliveryStatus === 'shipped' ?
                            <button type="button" className="btn btn-primary btn-sm me-2"  disabled={loading} onClick={(e) => updateDeliveryStatus(e, 'completed')}>Completed</button>:
                        <></>
                    }
                    {/* <div className="">
                    </div> */}
                    <Link className="btn btn-dark profile-button btn-sm me-2" to="/gift/list">Back</Link>
                    {/* <button type="submit" className="btn btn-primary btn-sm me-2"  disabled={loading} >Update</button> */}                    
                </div>
            </div>
        </>
   );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(GiftDetails);

