import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import Select, { createFilter } from "react-select";
import swal from "sweetalert";
import { api } from "../../../utils/api";

const stateOption = [
    { value: "Sabah", label: "Sabah"},
    { value: "Kedah", label: "Kedah"},
    { value: "Negeri Sembilan", label: "Negeri Sembilan"},
    { value: "Pahang", label: "Pahang"},
    { value: "Johor", label: "Johor"},
    { value: "Selangor", label: "Selangor"},
    { value: "Sarawak", label: "Sarawak"},
    { value: "Perak", label: "Perak"},
    { value: "Perlis", label: "Perlis"},
    { value: "Malacca", label: "Malacca"},
    { value: "Terengganu", label: "Terengganu"},
    { value: "Penang", label: "Penang"},
    { value: "Kelantan", label: "Kelantan"},
    { value: "Kuala Lumpur", label: "Kuala Lumpur"},
    { value: "Putrajaya", label: "Putrajaya"},
]

function NewBranch(props) {
    const history = useHistory(); 
    const requiredField = ["name", "location", "address", "email", "phone", "investmentMax"];
    const [addFormData, setAddFormData] = useState({
        name: '',
        location: '',
        address: '',
        email: '',
        phone: '',
        investmentMax: '',
    });
    const [error, setError] = useState({
        name: '',
        address: '',
        email: '',
        phone: '',
        investmentMax: '',
    });
    const [loading, setLoading] = useState(false);

    const createBranch = () => {
        let check = true;
        let errorMsg = { 
            name: '',
            address: '',
            email: '',
            phone: '',
            investmentMax: '',
        };
        requiredField.map((item, i)=>{
            if(!addFormData[item]){
                check = false;
                errorMsg[item] = "This field is required"
            }
        });

        setError(errorMsg);
        if(check){
            setLoading(true);
            fetch(api.branch, {
                method:"POST",
                headers:new Headers({
                    "Content-Type":"application/JSON",
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(addFormData)
            })
            .then(response => response.json())
            .then(responseJson => {
                setLoading(false);
                if(responseJson.status === 'success'){
                    swal('Good job!', "Branch created successful.", "success");
                    history.push('/branch/list');
                }else{
                    swal(responseJson.message, {
                        icon: "error",
                    });
                }
            }).catch(error => {
                console.log("Error",error);
            });
        }
    }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...addFormData };
        newFormData[fieldName] = fieldValue;
        setAddFormData(newFormData);
    };

    const handleOptionSelected = (e, name) => {
        const { label, value } = e;
        const info = {...addFormData};
        info[name] = value;
        setAddFormData(info);
    }

    return (
        <>
            <div className="col-12">
                <div className="row page-titles mx-0 shadow">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active">
                            <span>Branch</span>
                        </li>
                        <li className="breadcrumb-item">
                            <span>Create</span>
                        </li>
                    </ol>
                </div>

                <div className="card shadow">
                    <div className="card-body">
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                Branch Name
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control form-control"
                                    placeholder=""
                                    name="name"
                                    onChange={handleAddFormChange}
                                    value={addFormData.name}
                                />
                                <span className="error">{error.name}</span>
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                Location
                            </label>
                            <div className="col-sm-10">
                                <Select
                                    className="form-control"
                                    value = {
                                        stateOption.filter(option => 
                                            option.value === addFormData.location)
                                    }
                                    options={stateOption}
                                    placeholder="- Select -"
                                    style={{
                                        lineHeight: "40px",
                                        color: "#7e7e7e",
                                        paddingLeft: " 15px",
                                        borderWidth: 0,
                                    }}
                                    onChange={e=> handleOptionSelected(e, 'location')}
                                />
                                <span className="error">{error.location}</span>
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                Address
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control form-control"
                                    placeholder=""
                                    name="address"
                                    onChange={handleAddFormChange}
                                    value={addFormData.address}
                                />
                                <span className="error">{error.address}</span>
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                Contact No.
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control form-control"
                                    placeholder=""
                                    name="phone"
                                    onChange={handleAddFormChange}
                                    value={addFormData.phone}
                                />
                                <span className="error">{error.phone}</span>
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                Email
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control form-control"
                                    placeholder=""
                                    name="email"
                                    onChange={handleAddFormChange}
                                    value={addFormData.email}
                                />
                                <span className="error">{error.email}</span>
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                Maximum GSP
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="number"
                                    className="form-control form-control"
                                    placeholder=""
                                    name="investmentMax"
                                    onChange={handleAddFormChange}
                                    value={addFormData.investmentMax}
                                />
                                <span className="error">{error.investmentMax}</span>
                            </div>
                        </div>
                    </div>
                        
                    <div className="card-footer">
                        <button className="btn btn-primary profile-button btn-sm text-white me-2" disabled={loading} onClick={createBranch}>
                            {loading ? (<i className="fas fa-spinner fa-spin"></i>) : "Submit"}
                        </button>
                        <Link className="btn btn-danger profile-button btn-sm text-white" to="/branch/list">Discard</Link>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(NewBranch);
