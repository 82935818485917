import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom'
import { loadingToggleAction,loginAction,
} from '../../store/actions/AuthActions';

// image
import logo from "../../images/logo-black.png";

function Login (props) {
	const [emailUsername, setEmail] = useState('');
    let errorsObj = { emailUsername: '', password: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [password, setPassword] = useState('');
	const history = useHistory();
    const dispatch = useDispatch();

    function onLogin(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (emailUsername === '') {
            errorObj.emailUsername = 'Email/Username is Required';
            error = true;
        }
        if (password === '') {
            errorObj.password = 'Password is Required';
            error = true;
        }
		if (emailUsername !== 'admin') {
			errorObj.emailUsername = 'Username is incorrect';
            error = true;
        }
        if (password !== '123123') {
			errorObj.password = 'Password is incorrect';
            error = true;
        }
        setErrors(errorObj);
        if (error) {
			return ;
		}
		dispatch(loadingToggleAction(true));	
        dispatch(loginAction(emailUsername, password, props.history));
    }

	return (
		<div className="gradient-custom h-100">
			<div className="container py-5 h-100">
				<div className="row d-flex justify-content-center align-items-center h-100">
					<div className="col-12 col-md-8 col-lg-6 col-xl-5">
						<div className="border-gold px-5 pt-3">
							<div className="mb-md-5 mt-md-4 pb-5">
								<div className="col-md-12 text-center">
									<img src={logo} className="img-fluid" style={{ width: '50%', height: undefined }}/>
								</div>
								<div className="form-validate">
									<h3 className="text-center my-4 text-dark">Admin Login</h3>
									<div className="form-group mb-3">
										<label className="mb-1 text-dark"  htmlFor="val-email"><strong>Username</strong></label>
										<div>
											<input 
												type="text" 
												className="form-control"
												value={emailUsername}
												onChange={(e) => setEmail(e.target.value)}
												placeholder="Enter Username"
											/>
										</div>
										{errors.emailUsername && <div className="text-danger fs-12">{errors.emailUsername}</div>}
									</div>
									<div className="form-group mb-3">
										<label className="mb-1 text-dark"><strong>Password</strong></label>
										<input
											type="password"
											className="form-control"
											value={password}
											placeholder="Enter Password"
											onChange={(e) =>
												setPassword(e.target.value)
											}
										/>
										{errors.password && <div className="text-danger fs-12">{errors.password}</div>}
									</div>
									{/* <div className="mt-2 mb-4">
										<Link className="text-gold text-right" to="./forget-password">Forget Password?</Link>
									</div> */}

									
									<div className="text-center form-group mb-3">
										<button onClick={onLogin} disabled={props.loading} className="btn btn-primary btn-block">
											{props.showLoading ? (<i className="fas fa-spinner fa-spin"></i>) : "Sign In"}
										</button>
									</div>
								</div>
								
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};
export default connect(mapStateToProps)(Login);
