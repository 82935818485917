import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { api } from "../../../utils/api";

const AdminDetails = (props) => {
    const [contents, setContents] = useState([]);
    const { data } = props.location;

    // use effect
    useEffect(() => {
        getDetails();
    }, [props.auth, data]);

    const getDetails = () => {
        fetch(api.users, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/JSON",
                "Authorization": "Bearer " +  props.auth.auth.token
            }),
        })
        .then(response => response.json())
        .then(responseJson => {
            console.log(responseJson.data)
            if(responseJson.status === 'success'){
                let admin = responseJson.data.filter(e => e.id === data);
                setContents(admin[0]);
            }
        }).catch(error => {
            console.log("Error:", error);
        });
    }

    return (
        <>
            <div className="col-12">
                <div className="row page-titles mx-0 shadow">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active">
                            <span>Admin Info</span>
                        </li>
                        <li className="breadcrumb-item">
                            <span>View</span>
                        </li>
                    </ol>
                </div>
                <div className="card shadow">
                <div className="card-body">
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                First Name
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control form-control"
                                    placeholder=""
                                    name="firstName"
                                    value={contents ? contents.firstName : ""}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                Last Name
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control form-control"
                                    placeholder=""
                                    name="lastName"
                                    value={contents ? contents.lastName : ""}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                Username
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control form-control"
                                    placeholder=""
                                    name="username"
                                    value={contents ? contents.username : ""}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                            Contact Number
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control form-control"
                                    placeholder=""
                                    name="phone"
                                    value={contents ? contents.phone : ""}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                            Email
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="email"
                                    className="form-control form-control"
                                    placeholder=""
                                    name="email"
                                    value={contents ? contents.email : ""}
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <Link className="btn btn-danger profile-button btn-sm text-white" to="/admin/list">Back</Link>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(AdminDetails);