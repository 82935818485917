import React, { Fragment, useEffect, useState } from "react";
import {  useHistory, Link, useParams } from "react-router-dom";
import { connect, } from 'react-redux';
import swal from "sweetalert";
import { formatImagePath } from "../../../utils/function";
import { api, url } from "../../../utils/api";

const UserDetail = (props) => {
    const history = useHistory();
    const { id } = useParams();
    const [contents, setContents] = useState(null);
    const [remark, setRemark] = useState("");
    const [loading, setLoading] = useState(false);
    const [action, setAction] = useState(null);
    const [currentICPhoto, setCurrentICPhoto] = useState(null);
    const [formData, setFormData] = useState({
        username: "",
        memberId: "",
        fullName: "",
        email: "",
        identityCard: "",
        phone: "",
        address1: "",
        address2: "",
        country: "",
        state: "",
        city: "",
        postCode: "",
        member: "",

    });
    const [error, setError] = useState({
        username: "",
        memberId: "",
        
    });
    // use effect
    useEffect(() => {
        if(id){
            getUserAPI();
        }
    }, [props.auth, id]);

    const getUserAPI = () => {
        fetch(api.getAllUsers + "/?id=" + id, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
        .then(response => response.json())
        .then(responseJson => {
            console.log(responseJson)
            // setContents(responseJson.data[0]);
            let data = {
                username: responseJson.data[0].username,
                memberId: responseJson.data[0].memberId,
                fullName: responseJson.data[0].fullName,
                email: responseJson.data[0].email,
                identityCard: responseJson.data[0].identityCard,
                phone: responseJson.data[0].phone,
                address1: responseJson.data[0].address1,
                address2: responseJson.data[0].address2,
                country: responseJson.data[0].country,
                state: responseJson.data[0].state,
                city: responseJson.data[0].city,
                postCode: responseJson.data[0].postCode,
                member: responseJson.data[0].member,
               
            }

            setFormData(data)
        }).catch(error => {
            console.log("Error:", error);
        });
    }

    function updateStatus(status){
        let link = api.update_kyc;
        link = link.replace(':id', id);
        let check = true;
       
        if(status === 'rejected'){
            if(remark=== ""){
                check = false;
                swal("Please fill in rejected reason at remark.", {
                    icon: "error",
                });
            }
        }
        if(check){
            let jsonData = {
                "action": status,
                "reason": remark
            }
            setAction(status);
            setLoading(true)
            fetch(link, {
                method: "PUT",
                headers: new Headers({
                    "Content-Type": "application/JSON",
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(jsonData)
            })
            .then(response => response.json())
            .then(responseJson => {
                setLoading(false);
                setAction(false);
                // history.push('/user/list');
                if(responseJson.status === 'success'){
                    swal(responseJson.message, {
                        icon: "success",
                    });
                    // getUserAPI();
                    history.push('/user/list')
                }else{
                    swal(responseJson.message, {
                        icon: "error",
                    });
                }
            }).catch(error => {
                console.log("Error:", error);
            });
        }
    }

    const updateUserInfo = () =>{
        setLoading(true);
        let check = true;
        let err = {username: "", memberId: ""}
        if(!formData.username){
            check = false;
            err.username = "This field is required";
        }
        if(!formData.memberId){
            check = false;
            err.memberId = "This field is required";
        }

        setError(err);
        if(check){
            swal({
                title: `User Information`,
                text: "Confirmation of updating user information",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((result) => {
                if(result) {
                    fetch(api.users + `/updateUsersInfo/${id}`, {
                        method:"PUT",
                        headers:new Headers({
                            "Content-Type":"application/JSON",
                            "Authorization": "Bearer " + props.auth.auth.token
                        }),
                        body: JSON.stringify(formData),
                    }).then(response => response.json())
                    .then(responseJson => {
                        setLoading(false);
                        getUserAPI();
                        if (responseJson.status === 'success') {
                            swal('Updated!', responseJson.message, "success");
                        } else{
                            swal('Oops!', responseJson.message, "error");
                        }
                    }).catch(error => { 
                        setLoading(false);
                        console.log("Error",error);
                    });
                }
                setLoading(false);
            })
        }
        setLoading(false);
    }

    return (
        <>
            <Fragment>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <Link to="/user/list">Back to List</Link>
                            </div>
                            <div className="card-body">
                            <div className="mb-3 row align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label">
                                        Username
                                    </label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            name="username"
                                            value={formData.username}
                                            onChange={(e) => setFormData({...formData, username: e.target.value})}
                                        />
                                        <span className="error">{error.username}</span>
                                    </div>
                                </div>
                                {/* <div className="mb-3 row align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label">
                                        MemberId
                                    </label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            name="memberId"
                                            value={formData.memberId} 
                                            onChange={(e) => setFormData({...formData, memberId: e.target.value})}
                                        />
                                        <span className="error">{error.memberId}</span>
                                    </div>
                                </div> */}
                                <div className="mb-3 row align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label">
                                        Member Rank
                                    </label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            name="memberId"
                                            value={formData.member} 
                                            readOnly
                                        />
                                        <span className="error">{error.memberId}</span>
                                    </div>
                                </div>
                                <div className="mb-3 row align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label">
                                        Full Name
                                    </label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            name="fullName"
                                            value={formData.fullName} 
                                            onChange={(e) => setFormData({...formData, fullName: e.target.value})}
                                        />
                                    </div>
                                </div>
                                <div className="mb-3 row align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label">
                                        Email
                                    </label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            name="email"
                                            value={formData.email} 
                                            onChange={(e) => setFormData({...formData, email: e.target.value})}
                                        />
                                    </div>
                                </div>
                                <div className="mb-3 row align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label">
                                        IC
                                    </label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            name="identityCard"
                                            value={formData.identityCard} 
                                            onChange={(e) => setFormData({...formData, identityCard: e.target.value})}
                                        />
                                    </div>
                                </div>
                                <div className="mb-3 row align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label">
                                        phone
                                    </label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            name="phone"
                                            value={formData.phone}
                                            onChange={(e) => setFormData({...formData, phone: e.target.value})}

                                        />
                                    </div>
                                </div>
                                <div className="mb-3 row align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label">
                                        Address 1
                                    </label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            name="address1"
                                            value={formData.address1} 
                                            onChange={(e) => setFormData({...formData, address1: e.target.value})}
                                        />
                                    </div>
                                </div>
                                <div className="mb-3 row align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label">
                                        Address 2
                                    </label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            name="address2"
                                            value={formData.address2} 
                                            onChange={(e) => setFormData({...formData, address2: e.target.value})}
                                        />
                                    </div>
                                </div>
                                <div className="mb-3 row align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label">
                                        Postcode
                                    </label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            name="postCode"
                                            value={formData.postCode} 
                                            onChange={(e) => setFormData({...formData, postCode: e.target.value})}
                                        />
                                    </div>
                                </div> 
                                <div className="mb-3 row align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label">
                                        City
                                    </label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            name="city"
                                            value={formData.city} 
                                            onChange={(e) => setFormData({...formData, city: e.target.value})}
                                        />
                                    </div>
                                </div>
                                
                                <div className="mb-3 row align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label">
                                        State
                                    </label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            name="state"
                                            value={formData.state} 
                                            onChange={(e) => setFormData({...formData, state: e.target.value})}
                                        />
                                    </div>
                                </div>
                                
                                <div className="mb-3 row align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label">
                                        Country
                                    </label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            name="country"
                                            value={formData.country} 
                                            onChange={(e) => setFormData({...formData, country: e.target.value})}
                                        />
                                    </div>
                                </div>
                                
                            </div>
                            <div className="card-footer d-flex flex-row">
                            <button type="button" className="btn btn-primary btn-sm me-2"  disabled={loading} onClick={(e) => updateUserInfo()}>Submit</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h6>Bank Details</h6>
                            </div>
                            <div className="card-body">
                                <div className="mb-3 row align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label">
                                        Bank Name
                                    </label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            className="form-control form-control"
                                            placeholder=""
                                            name="bankName"
                                            value={contents && contents.user_bank_accounts.length ? contents.user_bank_accounts[0].bank.bankName : ""} disabled readOnly
                                        />
                                    </div>
                                </div>
                                <div className="mb-3 row align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label">
                                        Bank Account Number
                                    </label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            className="form-control form-control"
                                            placeholder=""
                                            name="bankAccountNumber"
                                            value={contents && contents.user_bank_accounts.length ? contents.user_bank_accounts[0].accountNumber : ""} disabled readOnly
                                        />
                                    </div>
                                </div>
                                <div className="mb-3 row align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label">
                                        Bank Account Type
                                    </label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            className="form-control form-control"
                                            placeholder=""
                                            name="bankAccountType"
                                            value={contents && contents.user_bank_accounts.length ? contents.user_bank_accounts[0].accountType : ""} disabled readOnly
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h6>KYC Details</h6>
                            </div>
                            <div className="card-body">
                                <div className="mb-3 row align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label">
                                        Full Name as per IC
                                    </label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            className="form-control form-control"
                                            placeholder=""
                                            name="kycName"
                                            value={contents ? contents.fullName : ""} disabled readOnly
                                        />
                                    </div>
                                </div>
                                <div className="mb-3 row align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label">
                                        IC
                                    </label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            className="form-control form-control"
                                            placeholder=""
                                            name="kycPhone"
                                            value={contents ? contents.identityCard : ""} disabled readOnly
                                        />
                                    </div>
                                </div>
                                <div className="mb-3 row align-items-center">
                                    <div className="col-6 row">
                                        <div className="col-12 row mb-3">
                                            <label className="col-6 col-form-label col-form-label">
                                                Front Photo of MyKad
                                            </label>
                                            <div className="col-6 cursor-pointer">
                                                {contents ? contents.kycFrontICPath ? (
                                                    <span onClick={() => setCurrentICPhoto(url + formatImagePath(contents.kycFrontICPath))}> 
                                                        <img src={url + formatImagePath(contents.kycFrontICPath)} style={{ width: '5rem', height: '100%'}} />
                                                    </span>
                                                ) : "-" : "-"}
                                            </div>
                                        </div>
                                        <div className="col-12 row">
                                            <label className="col-6 col-form-label col-form-label">
                                                Back Photo of MyKad
                                            </label>
                                            <div className="col-6 cursor-pointer">
                                                {contents ? contents.kycBackICPath ? (
                                                    <span onClick={() => setCurrentICPhoto(url + formatImagePath(contents.kycBackICPath))}> 
                                                        <img src={url + formatImagePath(contents.kycBackICPath)} style={{ width: '5rem', height: '100%'}} />
                                                    </span>
                                                ) : "-" : "-"}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <img src={currentICPhoto} style={{ width: '50%'}} />
                                    </div>
                                </div>
                                <div className="mb-3 row align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label">
                                        Status
                                    </label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            className="form-control form-control"
                                            placeholder=""
                                            name="status"
                                            value={contents ? contents.kycStatus : ""} disabled readOnly
                                        />
                                    </div>
                                </div>
                                <div className="mb-3 row align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label">
                                        Remark
                                    </label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            className="form-control form-control"
                                            placeholder=""
                                            name="remark"
                                            onChange={e => setRemark(e.target.value)}
                                            readOnly={contents ? contents.kycStatus === 'pending' ? false : true : true}
                                        />
                                    </div>
                                </div>
                            </div>
                            {contents && contents.kycStatus === 'pending' ? (
                                <div className="card-footer">
                                    <button className="btn btn-primary profile-button btn-sm text-white me-2" onClick={()=>updateStatus('approved')} disabled={loading}>{action === 'approved' ? (<i class="fas fa-spinner fa-spin"></i>) : "Approve"}</button>
                                    <button className="btn btn-danger profile-button btn-sm text-white" onClick={()=>updateStatus('rejected')}  disabled={loading} >{action === 'rejected' ? (<i class="fas fa-spinner fa-spin"></i>) : "Reject"}</button>
                                </div>
                            ) : null} 
                        </div>
                    </div>
                </div>
            </Fragment>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(UserDetail);