import React, { Fragment, useEffect, useState } from "react";
import { useHistory, Link, useParams } from "react-router-dom";
import { connect, } from 'react-redux';
import swal from "sweetalert";
import { countryWithCode, formatImagePath, giftItems2, statesWithFee } from "../../../utils/function";
import { api, url } from "../../../utils/api";
import Select, { createFilter } from 'react-select';

const AddUserPurchase = (props) => {
    const history = useHistory();
    const { id } = useParams();
    const [contents, setContents] = useState(null);
    const [formData, setFormData] = useState({


        username: "",
        memberId: "",
        fullName: "",
        email: "",
        identityCard: "",
        phone: "",
        address1: "",
        address2: "",
        country: "Malaysia",
        state: "",
        city: "",
        postCode: "",
        type: "",
        typeOption: "",
        amount: 300,
        delivery: "",
        giftModal: "",
        member: "",
        
       
      
    })
    const [error, setError] = useState({
        type: "",
        typeOption: "",
        delivery: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        postCode: "",
        country: "",
    })
    const [loading, setLoading] = useState(false);
    const [isSelfCollect, setIsSelfCollect] = useState(false);
    const [statesOptions, setStatesOptions] = useState(statesWithFee);
    const [countryOptions, setCountryOptions] = useState(countryWithCode);
    const [typeOptions, setTypeOptions] = useState(giftItems2);
    const [purchaseOptions, setPurchaseOptions] = useState([
        { id: 0, label: 'Gift', value: 'gift', isSelected: true, hidden: false },
        // {id: 1, label: 'FGR', value: 'FGR', isSelected: false, hidden: true},
        // {id: 2, label: 'GSP', value: 'GSP', isSelected: false, hidden: true},
    ])
    const [deliveryOptions, setDeliveryOptions] = useState([
        { label: 'Self Collect', value: 'selfCollect' },
        { label: 'Delivery', value: 'delivery' }
    ]);

    // use effect
    useEffect(() => {
        if (id) {
            getUserAPI();
        }
    }, [props.auth, id]);

    const getUserAPI = () => {
        fetch(api.getAllUsers + "/?id=" + id, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                console.log("asd: ",responseJson);
                //setContents(responseJson.data[0]);
                let data = {
                    username: responseJson.data[0].username,
                    memberId: responseJson.data[0].memberId,
                    fullName: responseJson.data[0].fullName,
                    email: responseJson.data[0].email,
                    identityCard: responseJson.data[0].identityCard,
                    phone: responseJson.data[0].phone,
                    address1: responseJson.data[0].address1,
                    address2: responseJson.data[0].address2,
                    country: responseJson.data[0].country,
                    state: responseJson.data[0].state,
                    city: responseJson.data[0].city,
                    postCode: responseJson.data[0].postCode,
                    delivery: responseJson.data[0].gifts_purchases[0]?.delivery,
                    giftModal: responseJson.data[0].gifts_purchases[0]?.giftModal,
                    selfCollectLocation: responseJson.data[0].gifts_purchases[0].selfCollectLocation,
                    member:responseJson.data[0].member,
                }
                console.log("data: ",data);
                setFormData(data)
            }).catch(error => {
                console.log("Error:", error);
            });
    };

    const onInputChanges = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    };

    const handleOptionSelected = (e, type) => {
        const { label, value } = e;
        let info = { ...formData };
        info[type] = value;
        // handle purchase options 
        if (type === 'type') {
            let temp = purchaseOptions;
            temp = temp.map(item => { item.value !== e.value ? item.isSelected = false : item.isSelected = true; return item });
            setPurchaseOptions(temp)
        } else if (type === 'typeOption') {
            let temp = typeOptions;
            temp = temp.map(item => { item.value !== e.value ? item.isSelected = false : item.isSelected = true; return item });
            info.amount = temp.find(i => i.isSelected).price;
            setTypeOptions(temp)
        } else if (type === 'state') {
            let temp = statesOptions;
            temp = temp.map(item => { item.value !== e.value ? item.isSelected = false : item.isSelected = true; return item });
            setStatesOptions(temp)
        } else if (type === 'country') {
            let temp = countryOptions;
            temp = temp.map(item => { item.value !== e.value ? item.isSelected = false : item.isSelected = true; return item });
            setCountryOptions(temp)
        } else if (type === 'delivery') {
            
            // let temp = deliveryOptions;
            // temp = temp.map(item => { item.value !== e.value ? item.isSelected = false : item.isSelected = true; return item });
            // setDeliveryOptions(temp)
            setIsSelfCollect(e.value === 'selfCollect' ? true : false);
        }
        setFormData(info);
    };

    const checkForm = () => {
        let check = true;
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        let error = {
            type: null, typeOption: null, delivery: null,
            address1: null, address2: null, city: null,
            state: null, postCode: null, country: null,
        };
        if (!formData.type) {
            check = false;
            error.type = "This field is required."
        }
        if (!formData.typeOption) {
            check = false;
            error.typeOption = "This field is required."
        }
        if (!formData.delivery) {
            check = false;
            error.delivery = "This field is required."
        }
        if (formData.delivery === 'delivery') {
            if (!formData.address1) {
                check = false;
                error.address1 = "This field is required."
            }
            if (!formData.city) {
                check = false;
                error.city = "This field is required."
            }
            if (!formData.state) {
                check = false;
                error.state = "This field is required."
            }
            if (!formData.postCode) {
                check = false;
                error.postCode = "This field is required."
            }
            if (!formData.country) {
                check = false;
                error.country = "This field is required."
            }
        }
        setError(error);
        // setFormData({...formData, referralId: token ?? ""})
        return check;
    };

    const submitForm = (event) => {
        event.preventDefault();
        let check = checkForm();
        if (check) {
            if (formData.delivery === 'selfCollect') {
                delete formData.address1;
                delete formData.address2;
                delete formData.city;
                delete formData.state;
                delete formData.postCode;
                delete formData.country;
            }
            setLoading(true)
            fetch(api.users + `/users/investment/${id}`, {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/JSON",
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(formData)
            }).then(response => response.json())
                .then(responseJson => {
                    setLoading(false);
                    if (responseJson.status === 'success') {
                        swal(responseJson.message, {
                            icon: "success",
                        });
                        // resetForm();
                        history.push('/user/list')
                    } else {
                        swal(responseJson.message, {
                            icon: "error",
                        });
                    }
                }).catch(error => {
                    console.log("Error", error);
                });
        }
    };

    return (
        <>
            <Fragment>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="row page-titles mx-0 shadow">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active">
                                    <span>Members</span>
                                </li>
                                <li className="breadcrumb-item active">
                                    <span>Investment</span>
                                </li>
                                <li className="breadcrumb-item">
                                    <span>Create</span>
                                </li>
                            </ol>
                        </div>
                        <div className="d-flex flex-column">
                            <div className="card shadow">
                                <div className="card-header">
                                    <Link to="/user/list">Back to List</Link>
                                </div>
                                <div className="card-body">
                                <div className="mb-3 row align-items-center">
                                        <label className="col-sm-2 col-form-label">
                                            Username
                                        </label>
                                        <div className="col-sm-10">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                name="username"
                                                value={formData ? formData.username : ""} 
                                                disabled 
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="mb-3 row align-items-center">
                                        <label className="col-sm-2 col-form-label">
                                            MemberId
                                        </label>
                                        <div className="col-sm-10">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                name="memberId"
                                                value={formData ? formData.memberId : ""} 
                                                disabled 
                                                readOnly
                                            />
                                        </div>
                                    </div> */}
                                    <div className="mb-3 row align-items-center">
                                        <label className="col-sm-2 col-form-label">
                                            Member Rank
                                        </label>
                                        <div className="col-sm-10">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                name="member"
                                                value={formData ? formData.member : ""} 
                                                disabled 
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-3 row align-items-center">
                                        <label className="col-sm-2 col-form-label">
                                            Full Name
                                        </label>
                                        <div className="col-sm-10">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                name="fullName"
                                                value={formData ? formData.fullName : ""} 
                                                disabled 
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-3 row align-items-center">
                                        <label className="col-sm-2 col-form-label">
                                            IC
                                        </label>
                                        <div className="col-sm-10">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                name="identityCard"
                                                value={formData ? formData.identityCard : ""} 
                                                disabled 
                                                readOnly
                                            />
                                        </div>
                                    </div>


                                
                                   
                                   
                                      
                                    <div className="mb-3 row align-items-center">
                                        <label className="col-sm-2 col-form-label">
                                            Purchase Type
                                        </label>
                                        <div className="col-sm-10">
                                            <Select
                                                className="form-control"
                                                options={purchaseOptions}
                                                placeholder="- Select -"
                                                style={{
                                                    lineHeight: "40px",
                                                    color: "#7e7e7e",
                                                    paddingLeft: " 15px",
                                                    borderWidth: 0,
                                                }}
                                                value={purchaseOptions.filter(g => g.value === formData.type)}
                                                onChange={e => handleOptionSelected(e, 'type')}
                                                isDisabled={formData.member.toLowerCase() === "member" || formData.member.toLowerCase() === "free" ? false : true}
                                                isReadOnly={formData.member.toLowerCase() === "member" || formData.member.toLowerCase() === "free" ? false : true}
                                            />
                                          
                                        </div>
                                    </div>
                                    <div className="mb-3 row align-items-center">
                                        <label className="col-sm-2 col-form-label">
                                            Purchase Options
                                        </label>
                                        <div className="col-sm-10">
                                            <Select
                                                className="form-control"
                                                options={typeOptions}
                                                placeholder="- Select -"
                                                style={{
                                                    lineHeight: "40px",
                                                    color: "#7e7e7e",
                                                    paddingLeft: " 15px",
                                                    borderWidth: 0,
                                                }}
                                                value={typeOptions.filter(g => g.value === formData.typeOption)}
                                                onChange={e => handleOptionSelected(e, 'typeOption')}
                                                isDisabled={formData.member.toLowerCase() === "member" || formData.member.toLowerCase() === "free" ? false : true}
                                                isReadOnly={formData.member.toLowerCase() === "member" || formData.member.toLowerCase() === "free" ? false : true}
                                            />
                                            
                                        </div>
                                    </div>
                                    <div className="mb-3 row align-items-center">
                                        <label className="col-sm-2 col-form-label">
                                            Delivery Options
                                        </label>
                                        <div className="col-sm-10">
                                            <Select
                                                className="form-control"
                                                options={deliveryOptions}
                                                placeholder="-- Select --"
                                                style={{
                                                    lineHeight: "40px",
                                                    color: "#7e7e7e",
                                                    paddingLeft: " 15px",
                                                    borderWidth: 0,
                                                }}
                                                value={deliveryOptions.filter(g => g.value === formData.delivery)}
                                                onChange={e => handleOptionSelected(e, 'delivery')}
                                                isDisabled={formData.member.toLowerCase() === "member" || formData.member.toLowerCase() === "free" ? false : true}
                                                isReadOnly={formData.member.toLowerCase() === "member" || formData.member.toLowerCase() === "free" ? false : true}
                                            />
                                            
                                        </div>
                                    </div>
                                  

                                    
                                   
                                    {isSelfCollect ? 
                                     <div className="mb-3 row align-items-center">
                                     <label className="col-sm-2 col-form-label">Self Collect Location</label>
                                     <div className="col-sm-8">
                                         <input
                                         type="text"
                                         className="form-control"
                                         name="selfCollectLocation"
                                         value={formData.selfCollectLocation ?? ""}
                                         readOnly
                                         />
                                     </div>
                                 </div> : (<>
                                        <div className="mb-3 row align-items-center">
                                            <label className="col-sm-2 col-form-label">
                                                Address 1
                                            </label>
                                            <div className="col-sm-10">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    name="address1"
                                                    disabled={isSelfCollect}
                                                    onChange={(e) => onInputChanges({...formData,address1: e.target.value})}
                                                    value={formData ? formData.address1 : ""}
                                                />
                                                <span className="error">{error.address1}</span>
                                            </div>
                                        </div>
                                        <div className="mb-3 row align-items-center">
                                            <label className="col-sm-2 col-form-label">
                                                Address 2
                                            </label>
                                            <div className="col-sm-10">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    name="address2"
                                                    disabled={isSelfCollect}
                                                    onChange={(e) => onInputChanges({...formData, address2: e.target.value})}
                                                    value={formData ? formData.address2 : ""}
                                                />
                                                <span className="error">{error.address2}</span>
                                            </div>
                                        </div>
                                        <div className="mb-3 row align-items-center">
                                            <label className="col-sm-2 col-form-label">
                                                City
                                            </label>
                                            <div className="col-sm-10">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    name="city"
                                                    disabled={isSelfCollect}
                                                    onChange={(e) => onInputChanges({...formData, city: e.target.value})}
                                                    value={formData ? formData.city : ""}
                                                />
                                                <span className="error">{error.city}</span>
                                            </div>
                                        </div>
                                        <div className="mb-3 row align-items-center">
                                            <label className="col-sm-2 col-form-label">
                                                State
                                            </label>
                                            <div className="col-sm-10">
                                                <Select
                                                    className="form-control"
                                                    options={statesOptions}
                                                    placeholder="- Select -"
                                                    style={{
                                                        lineHeight: "40px",
                                                        color: "#7e7e7e",
                                                        paddingLeft: " 15px",
                                                        borderWidth: 0,
                                                    }}
                                                    isDisabled={isSelfCollect}
                                                    value={statesOptions.filter(o => o.value === formData.state)}
                                                    onChange={e => handleOptionSelected(e, 'state')}
                                                />
                                                <span className="error">{error.state}</span>
                                            </div>
                                        </div>
                                        <div className="mb-3 row align-items-center">
                                            <label className="col-sm-2 col-form-label">
                                                Postcode
                                            </label>
                                            <div className="col-sm-10">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    name="postCode"
                                                    disabled={isSelfCollect}
                                                    onChange={(e) => onInputChanges({...formData, postCode: e.target.value})}
                                                    value={formData ? formData.postCode : ""}
                                                />
                                                <span className="error">{error.postCode}</span>
                                            </div>
                                        </div>
                                        <div className="mb-3 row align-items-center">
                                            <label className="col-sm-2 col-form-label">
                                                Country
                                            </label>
                                            <div className="col-sm-10">
                                                <Select
                                                    className="form-control"
                                                    options={countryOptions}
                                                    placeholder="- Select -"
                                                    style={{
                                                        lineHeight: "40px",
                                                        color: "#7e7e7e",
                                                        paddingLeft: " 15px",
                                                        borderWidth: 0,
                                                    }}
                                                    isDisabled={isSelfCollect}
                                                    value={countryOptions.filter(o => o.value === formData.country)}
                                                    onChange={e => handleOptionSelected(e, 'country')}
                                                />
                                                <span className="error">{error.country}</span>
                                            </div>
                                        </div></>)}
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-primary profile-button btn-sm text-white me-2" onClick={(e) => submitForm(e)} disabled={loading}>{loading ? (<i class="fas fa-spinner fa-spin"></i>) : "Create"}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(AddUserPurchase);