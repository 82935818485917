import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import { connect } from 'react-redux';
import { api } from "../../../utils/api";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import SortingTable from "../../components/SortingTable";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { urlParamsLocalStorage, reactSelectStyle } from "../../../utils/function";
import Select from 'react-select';

const GiftList = (props) => {
    const COLUMNS = [
        {
            Header: 'No.',
            accessor: (row, i) => {
                return i + 1;
            }
        },
        {
            Header: 'Ref no.',
            accessor: (row) => {
                return row.purchaseId
            }
        },
        {
            Header: 'Customer Name',
            accessor: (row) => {
                return row.user.fullName;
            }
        },
        {
            Header: 'Customer Rank',
            accessor: (row) => {
                return row.user.member;
            }
        },
        {
            Header: 'Gift Type',
            accessor: (row) => {
                return row.giftModal;
            },
            Cell: (props) => {
                return <span className="text-nowrap">{props.cell.value}</span>
            }
        },
        {
            Header: 'Amount',
            accessor: (row) => {
                return "RM " + (row.amount ?? 0).toFixed(2);
            },
            Cell: (props) => {
                return <span className="text-nowrap">{props.cell.value}</span>
            }
        },
        {
            Header: 'Collect Method',
            accessor: (row) => {
                return row.delivery === 'delivery' ? 'Delivery' : row.delivery === 'selfCollect' ? 'Self Collect' : 'Wallet'
            }
        },
        {
            Header: 'Self-Collect Location',
            accessor: (row) => {
                return row.branch ? row.branch.name : "-"
            }
        },
        {
            Header: 'Delivery Status',
            accessor: (row) => {
                return <span className={`text-capitalize status-box ${row.deliveryStatus}`}>{row.deliveryStatus}</span>
            }
        },
        {
            Header: 'Tracking no',
            accessor: (row) => {
                return row.trackingNo ?? "-"
            }
        },
        {
            Header: 'Tracking code',
            accessor: (row) => {
                return row.trackingCode
            }
        },
        {
            Header: 'Created Datetime',
            accessor: (row) => {
                return moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss')
            },
            Cell: (props) => {
                return <span className="text-nowrap">{props.cell.value}</span>
            }
        },
        {
            Header: 'Action',
            accessor: (row) => {
                return (
                    <>
                        <Link to={"/gift/details/" + row.id} onClick={() => storeParams()} className="text-primary">
                            <i className="fas fa-eye"></i>
                        </Link>
                    </>
                )
            }
        },
    ];

    const [loading, setLoading] = useState(false);
    const [contents, setContents] = useState([]);
    const [filterOption, setFilterOption] = useState("all");
    const [allData, setAllData] = useState([]);
    const [fileData, setFileData] = useState({
        name: "Gold_Gift_Purchase_List",
        excelData: [],
        hasCustom: false,

    })
    const [filterOptions, setFilterOptions] = useState({
        delivery: {
            name: 'delivery', status: [
                { label: 'all', value: 'all' },
                { label: 'pending', value: 'pending' },
                { label: 'shipped', value: 'shipped' },
                { label: 'completed', value: 'completed' },
            ], value: 'all',
        },
    })
    const history = useHistory();
    const [searchText, setSearchText] = useState("");
    const [currentPageNumber, setCurrentPageNumber] = useState(0);
    const [error, setError] = useState([]);
    const [dateRange, setDateRange] = useState({
        startDate: moment().startOf('month'),
        endDate: moment().endOf('month'),
    });

    useEffect(() => {
        if (allData.length > 0) {
            if (filterOption === 'all') {
                setContents(allData);
            } else {
                let list = allData.filter(arr => arr.deliveryStatus === filterOption);
                setContents(list);

                setFileData({
                    ...fileData,
                    excelData: list,
                    hasCustom: false,
                    customRows: [],

                });
            }
        }
    }, [filterOptions]);

    const updateFilter = (type, data) => {
        let temp = filterOptions[type];
        temp.value = data;
        setFilterOptions({ ...filterOptions, [type]: temp });
    }

    useEffect(() => {
        let urlParams = urlParamsLocalStorage('get', { path: history.location.pathname, name: "purchaseGiftList" });
        if (urlParams) {
            getList(urlParams.query.startDate, urlParams.query.endDate);
            setDateRange({
                startDate: moment(urlParams.query.startDate),
                endDate: moment(urlParams.query.endDate),
            })
            setSearchText(urlParams.query.search);
            setCurrentPageNumber(urlParams.query.tablePageNumber);
        } else {
            getList(dateRange.startDate, dateRange.endDate)
        }
    }, [props.auth]);

    const getList = (start, end) => {
        setLoading(true);
        fetch(api.users + `/gift/purchase?start=${moment(start).format('YYYY-MM-DD 00:00:00')}&end=${moment(end).format('YYYY-MM-DD 23:59:59')}`, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        }).then(response => response.json())
            .then(responseJson => {
                setLoading(false);
                // console.log(responseJson) 
                if (responseJson.data) {
                    setAllData(responseJson.data);
                    setContents(responseJson.data);

                    let excelTable = responseJson.data.map((i, index) => {

                        return {
                            "No.": index + 1,
                            "Ref no.": i.purchaseId,
                            "Customer Name": i.user.fullName,
                            "Customer Rank": i.user.member,
                            "Gift Type": i.giftModal,
                            "Amount": "RM" + i.amount ?? 0,
                            "Collect Method": i.delivery === 'delivery' ? 'Delivery' : i.delivery === 'selfCollect' ? 'Self Collect' : 'Wallet',
                            "Self Collect Location": i.branch ? i.branch.name : "-",
                            "Delivery Status": i.deliveryStatus,
                            "Tracking no": i.trackingNo ?? "-",
                            "Tracking code": i.trackingCode,
                            "Created Datetime": moment(i.createdAt).format('YYYY-MM-DD HH:mm:ss')
                        }
                    })
                    setFileData({
                        ...fileData,
                        excelData: excelTable,
                        hasCustom: false,
                        customRows: [],
                    });
                }
            }).catch(error => {
                setLoading(false);
                console.log("Error", error);
            });
    }

    const getSearchText = (e) => {
        // console.log(history);
        setSearchText(e)
    }

    const getTablePageNumber = (e) => {
        setCurrentPageNumber(e);
    }

    const storeParams = () => {
        let urlData = {
            path: history.location.pathname,
            name: "purchaseGiftList",
            query: {
                startDate: dateRange.startDate,
                endDate: dateRange.endDate,
                search: searchText,
                deliveryStatus: filterOption,
                tablePageNumber: currentPageNumber,
            }
        };
        urlParamsLocalStorage('set', urlData);
    }

    function onDateChange(e, name) {
        let temp = { ...dateRange };
        temp[name] = e;
        getList(temp.startDate, temp.endDate);
        setDateRange(temp);
    }

    return (
        <>
            <div className="row page-titles mx-0 shadow">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                        <span>Purchase Gift</span>
                    </li>
                    <li className="breadcrumb-item">
                        <span>List</span>
                    </li>
                </ol>
            </div>
            <div className="card shadow card-black">
                <div className="card-body">
                    <div className="row align-items-center mb-3">
                        <div className="col-md-2">
                            <span className="text-nowrap">Filter by delivery status:</span>
                        </div>
                        <div className="col-md-10">
                            <Select
                                className="form-control gold-input-box"
                                options={filterOptions.delivery.status}
                                placeholder="- Select -"
                                styles={{ ...reactSelectStyle(0) }}
                                value={filterOptions.delivery.status.find(d => d.value === filterOptions.delivery.value)}
                                onChange={e => updateFilter('delivery', e)}
                            />{/* <button className={`${filterOption === 'cancelled' ? "btn-primary btn btn-sm me-2" : "btn-outline-primary btn btn-sm me-2"}`} onClick={() => setFilterOption('cancelled')}>Cancelled</button> */}
                        </div>
                        <div className="row mt-2 align-items-center">
                            <div className="col-md-2">
                                <span className="text-nowrap">Filter by date:</span>
                            </div>
                            <div className="col-md-2">
                                <div className="form-control date-picker-container">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            autoOk
                                            disablePast={false}
                                            label=""
                                            clearable={false}
                                            format="dd/MM/yyyy"
                                            value={dateRange.startDate}
                                            onChange={(e) => onDateChange(e, 'startDate')}
                                            className="form-control"
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <span className="error">{error.startDate}</span>
                            </div>
                            <div className="col-md-2">
                                <div className="form-control date-picker-container">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            autoOk
                                            disablePast={false}
                                            label=""
                                            clearable={false}
                                            format="dd/MM/yyyy"
                                            value={dateRange.endDate}
                                            onChange={(e) => onDateChange(e, 'endDate')}
                                            className="form-control"
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <span className="error">{error.endDate}</span>
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <div className="text-center">
                            <i className="fas fa-spinner fa-pulse fa-3x"></i>
                        </div>
                    ) : (
                        <div className="">
                            <div className="dataTables_wrapper" id="table_data">
                                <SortingTable columns={COLUMNS} data={contents} excelExport={true} fileData={fileData} setSearchText={getSearchText} searchText={searchText} setPageNumber={getTablePageNumber} currentPageNumber={currentPageNumber} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(GiftList);
