import {
    formatError,
    login,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,
} from '../../services/AuthService';
import swal from "sweetalert";
import { api } from '../../utils/api';
import { dummyData } from '../../utils/function';

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';

export function signupAction(email, password, history) {
    return (dispatch) => {
        signUp(email, password)
            .then((response) => {
                saveTokenInLocalStorage(response.data);
                runLogoutTimer(
                    dispatch,
                    response.data.expiresIn * 1000,
                    history,
                );
                dispatch(confirmedSignupAction(response.data));
                history.push('/dashboard');
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(signupFailedAction(errorMessage));
            });
    };
}

export function logout(history, path) {
    if (path) {
        if (path === '/login' || path === '/register' || path === '/reset-password' || path === '/forget-password') {
        } else {
            history.push('/login');
        }
    } else {
        history.push('/login');
    }

    localStorage.removeItem('admin-gold-URL-params');
    localStorage.removeItem('admin_gold');
    return {
        type: LOGOUT_ACTION,
    };
}

export function loginAction(emailUsername, password, history) {
    return (dispatch) => {
        let userInfo = dummyData("user");
        saveTokenInLocalStorage(userInfo);
        dispatch(loginConfirmedAction(userInfo));
        history.push('/dashboard');
        // let data = {
        //     "emailUsername": emailUsername,
        //     "password": password
        // };
        // fetch(api.users + "/signin", {
        //     method: "POST",
        //     headers: new Headers({
        //         "Content-Type": "application/JSON", 
        //     }),
        //     body: JSON.stringify(data)
        // })
        // .then(response => response.json())
        // .then(responseJson => {
        //     dispatch(loadingToggleAction(false));	
        //     if(responseJson.status === 'success'){
        //         let userInfo = responseJson.data;
        //         let media = responseJson.data.user_media;
        //         // if(media.length>0){
        //         //     let profileImage = media.filter(item => item.type === 'profileImage');
        //         //     userInfo.profileImage = profileImage[0];
        //         // }else{
        //             userInfo.profileImage = "";
        //         // }

        //         saveTokenInLocalStorage(userInfo);
        //         runLogoutTimer(
        //             dispatch,
        //             3600 * 1000,
        //             history,
        //         );
        //         dispatch(loginConfirmedAction(userInfo));
        //         //console.log(userInfo.isMaster);
        //         // if(userInfo.isMaster == 2)
        //         // {
        //         //     history.push('/hierarchy/list'); 
        //         //     return
        //         // }
        //         if(userInfo.branchId > 0){
        //             history.push('/purchase/list');
        //             return
        //         }
        //         history.push('/dashboard'); 

        //     }else{
        //         swal('Opps', responseJson.message, "error");
        //         const errorMessage = formatError(responseJson.message);
        //         dispatch(loginFailedAction(errorMessage));
        //     }
        // }).catch(error => {
        //     console.error("error",error);
        // });

        // login(email, password)
        //     .then((response) => {
        //         console.log(response)
        //         let userInfo = response.data.data;
        //         let media = response.data.data.user_media;
        //         if(media.length>0){
        //             let profileImage = media.filter(item => item.type === 'profileImage');
        //             userInfo.profileImage = profileImage[0];
        //         }else{
        //             userInfo.profileImage = "";
        //         }

        //         saveTokenInLocalStorage(userInfo);
        //         runLogoutTimer(
        //             dispatch,
        //             3600 * 1000,
        //             history,
        //         );
        //         dispatch(loginConfirmedAction(userInfo));
        // 		history.push('/dashboard');                
        //     })
        //     .catch((error) => {
        //         console.log("error1",error)
        //         // swal('Opps', error.response.data.message, "error");
        //         // const errorMessage = formatError(error.response.data.message);
        //         // dispatch(loginFailedAction(errorMessage));
        //     });
    };
}

// export function updateLocalStorage(newUserInfo) {
//     let tokenDetailsString = localStorage.getItem('admin_gold');
//     // newUserInfo = {firstName: "bla bla", lastName: "jaja"}
//     let jsonP = JSON.parse(tokenDetailsString);
//     console.log(jsonP, 'before');
//     Object.assign(jsonP, newUserInfo);
//     console.log(jsonP, 'asdasd');
//     saveTokenInLocalStorage(jsonP);
// }

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}

export function update_profile(data) {
    return {
        type: UPDATE_PROFILE,
        payload: data,
    };
}

