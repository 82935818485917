import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux'; 
import swal from "sweetalert";
import Select, { createFilter } from "react-select";
import { api } from "../../../utils/api";
import SortingTable from "../../components/SortingTable";
import moment from 'moment';
import { Table } from "react-bootstrap";


const GoldCustom = (props) => {
    const history = useHistory();
    const [addFormData, setAddFormData] = useState({
        name: "", 
        rate: 0,
    }); //Add data 
    const [goldType, setGoldTypes] = useState([
        {value: '24K', label: '24K'}
    ]);
    const [error, setError] = useState({ name: "", rate: "" });  
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    
    useEffect(()=>{
        getGoldTypes();
        setGoldPrice("24K");
        // fetch(api.getMarketPrice, {
        //     method:"GET",
        //     headers:new Headers({
        //         "Content-Type":"application/JSON",
        //         "Authorization": "Bearer " + props.auth.auth.token
        //     }),
        // }).then(response => response.json())
        // .then(responseJson => {
        //     let data = { ...addFormData };
        //     if(responseJson.status === "success"){
        //         data.marketPrice = responseJson.data.sellGM2;
        //         data.markingPrice = Math.ceil(responseJson.data.sellGM2);
        //     }
        //     setAddFormData(data);
        // }).catch(error => {
        //     console.log("Error",error);
        // });
    },[props.auth.auth.token])

    // useEffect(()=>{
    //     getList();
    // },[props.auth.auth.token])

    function getGoldTypes() {
        setLoading(true);
        fetch(api.goldPrice + `/standards`, {
            method:"GET",
            headers:new Headers({
                "Content-Type":"application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        }).then(response => response.json())
        .then(responseJson => {
            setLoading(false);
            console.log(responseJson)
            if(responseJson.status === 'success'){
                setList(responseJson.data);
                responseJson.data.forEach(g => {
                    setGoldTypes({
                        value: g.type,
                        label: g.type
                    });
                })
            }
        }).catch(error => {
            console.log("Error",error);
        });
    }

    function getList(){
        setLoading(true);
        fetch(api.goldPrice, {
            method:"GET",
            headers:new Headers({
                "Content-Type":"application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        }).then(response => response.json())
        .then(responseJson => {
            setLoading(false);
            console.log(responseJson)
            if(responseJson.status === 'success'){
                setList(responseJson.data);
            }
        }).catch(error => {
            console.log("Error",error);
        });
    }

    function resetForm() {
        let data = { ...addFormData };
        setAddFormData({
            marketPrice: "", 
            markingPrice: "", 
            markingPercent: "", 
            markingAmount: ""
        })
    }
    const setGoldPrice = (type) => {
        setLoading(true);
        let temp = list.filter(i => i.type = type)[0];
        setAddFormData(temp);
        // resetForm();
        // fetch(api.goldPrice + "?type=" + type, {
        //     method:"GET",
        //     headers:new Headers({
        //         "Content-Type":"application/JSON",
        //         "Authorization": "Bearer " + props.auth.auth.token
        //     }),
        // }).then(response => response.json())
        // .then(responseJson => {
        //     setLoading(false);
        //     console.log(responseJson.data)
        //     if(responseJson.status === 'success'){
        //         if(!responseJson.data[0].marketPrice)
        //         {
        //             responseJson.data[0].marketPrice = responseJson.data[0].markingPrice
        //         }
        //         setAddFormData(responseJson.data[0])  
        //     }
        // }).catch(error => {
        //     console.log("Error",error);
        // });
    }

    const handleOptionSelected = (e, name) => {
        const { label, value } = e;
        const info = {...addFormData};
        info[name] = value;
        setGoldPrice(info["type"])
        // setAddFormData(info);
    }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const newFormData = { ...addFormData };
        newFormData[name] = value;
        // console.log(newFormData,"Form")
        // if(newFormData.markingAmount>0 || newFormData.markingPercent >0){
        //     let newPriceInPercentage = parseFloat(newFormData.marketPrice)* parseFloat(newFormData.markingPercent)/100;
        //     console.log(newPriceInPercentage)
        //     let newPriceInAmount = parseFloat(newFormData.markingAmount);
        //     newFormData.markingPrice = parseFloat(newFormData.marketPrice) + parseFloat(newPriceInPercentage);
        //     newFormData.markingPrice = newFormData.markingPrice +  + newPriceInAmount;
        // }else{
        //     newFormData.markingPrice = parseFloat(newFormData.marketPrice);
        // }
        // if(name == 'markingAmount'){
        //     if(inputValue){
        //         newFormData.markingAmount = inputValue;
        //     }
        //     newPriceInAmount = Number(inputValue);
        // }
        // if(name == 'markingPercent'){
        //     if(inputValue){
        //         newFormData.markingPercent = inputValue;
        //     }
        //     newPriceInPercentage = Number(newFormData.marketPrice)*(Number(newFormData.markingPercent)/100);
        // }
        
        console.log(newFormData.markingPercent,"Amount")
        let percent= newFormData.markingPercent;
        let amount= newFormData.markingAmount;
        if(!percent){
            percent = 0;
        }
        if(!amount){
            amount = 0;
        }
        // newFormData.markingPrice = parseFloat(newFormData.marketPrice) + (parseFloat(newFormData.marketPrice) * parseFloat(typeof newFormData.markingPercent == "number" ? newFormData.markingPercent : 0 ) / 100) + parseFloat(typeof newFormData.markingAmount =='number' ? newFormData.markingAmount : 0);
        newFormData.markingPrice = parseFloat(newFormData.marketPrice) + ((parseFloat(newFormData.marketPrice) * parseFloat(percent) / 100))+ parseFloat(amount);
        
        if(isNaN(newFormData.markingPrice)){
            newFormData.markingPrice = newFormData.marketPrice;
        }
        // newFormData.markingPrice = newFormData.marketPrice + newFormData.markingAmount;
        // console.log("market price",Number(newFormData.marketPrice));
        // console.log("markingAmount",Number(newFormData.markingAmount));
        // if(newFormData.markingPercent>0){
        //     newFormData.markingPrice = Number(newFormData.markingPrice) + (Number(newFormData.markingPrice)*Number(newFormData.markingPercent)/100);
        // }
        console.log("markingPercent",Number(newFormData.markingPercent));
        console.log("markingPrice",Number(newFormData.markingPrice));
        
        
        // (+newFormData.marketPrice) + newPriceInPercentage + newFormData.markingAmount;

        // if(name === 'markingAmount'){
        //     newFormData.markingAmount =value;
        //     if(value === ""){
        //         newFormData.markingPrice = parseFloat(newFormData.marketPrice);
        //     }else{
        //         newFormData.markingPercent = "";
        //         newFormData.markingPrice = (parseFloat(newFormData.marketPrice) + parseFloat(value)).toFixed(2);
        //     }
        // }
        // if(name === 'markingPercent'){
        //     newFormData.markingPercent = value;
        //     if(value === ""){
        //         newFormData.markingPrice = parseFloat(newFormData.marketPrice).toFixed(2);
        //     }else{
        //         newFormData.markingAmount = "";
        //         let newPrice = parseFloat(newFormData.marketPrice) + (parseFloat(newFormData.marketPrice)*value/100);
        //         newFormData.markingPrice = newPrice.toFixed(2);
        //     }
        // }

        newFormData.markingPrice =  Math.ceil(newFormData.markingPrice);
        console.log("round up markingPrice",Number(newFormData.markingPrice));
        setAddFormData(newFormData)
    };

    //Add Submit data
    const handleAddFormSubmit = (event) => {
        event.preventDefault();
        let errors = { type: "", marketPrice: "", markingPrice: "", markingPercent: "", markingAmount: "" };
        let check = true;
        if(!addFormData.type){
            check = false;
            errors.type = "Field is required"
        }
        if(!addFormData.markingPercent){
            if(!addFormData.markingAmount){
                check = false;
                errors.markingPercent = "Fill in marking amount or marking percent"
            }
        }else{
            if(parseFloat(addFormData.markingPercent)<=0){
                check = false;
                errors.markingPercent = "Percent must bigger than 0"
            }
        }
        if(!addFormData.markingAmount){
            if(!addFormData.markingPercent){
                check = false;
                errors.markingAmount = "Fill in marking amount or marking percent"
            }
        }
        setError(errors);
        if(check){
            let formData = [{
                "type": addFormData.type,
                "marketPrice": parseFloat(addFormData.marketPrice) ? parseFloat(addFormData.marketPrice) : 0,
                "markingPrice": parseFloat(addFormData.markingPrice) ? parseFloat(addFormData.markingPrice) : 0,
                "markingPercent": parseFloat(addFormData.markingPercent) ? parseFloat(addFormData.markingPercent) : 0,
                "markingAmount": parseFloat(addFormData.markingAmount) ? parseFloat(addFormData.markingAmount) : 0,
            }]
            setLoading(true)
            fetch(api.goldPrice, {
                method:"POST",
                headers:new Headers({
                    "Content-Type":"application/JSON",
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(formData)
            }).then(response => response.json())
            .then(responseJson => {
                setLoading(false);
                if(responseJson.status === 'success'){
                    swal(responseJson.message, {
                        icon: "success",
                    });
                    setGoldPrice("24K");
                    getList();
                }else{
                    swal(responseJson.message, {
                        icon: "error",
                    });
                }
            }).catch(error => {
                    console.log("Error",error);
            });
        }
    };

    return (
        <>
            <div className="col-12">
                <div className="row page-titles mx-0 shadow">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active">
                            <span>Gold Price</span>
                        </li>
                        <li className="breadcrumb-item">
                            <span>New</span>
                        </li>
                    </ol>
                </div>
                <div className="card shadow">
                    {/* <div className="card-header">
                        <span>24K Gold</span>
                    </div> */}
                    <div className="card-body">
                    <div className="mb-3 row align-items-center">
                            <label className="col-sm-3 col-form-label">Gold Type</label>
                            <div className="col-sm-9">
                                <Select
                                    className="form-control"
                                    options={goldType}
                                    placeholder="- Select -"
                                    style={{
                                        lineHeight: "40px",
                                        color: "#7e7e7e",
                                        paddingLeft: " 15px",
                                        borderWidth: 0,
                                    }}
                                    value={goldType.filter(o => o.value === addFormData.type)}
                                    onChange={e=> handleOptionSelected(e, 'type')}
                                />
                                {/* <span className="error">{error.type}</span> */}
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-3 col-form-label">Current Gold Price</label>
                            <div className="col-sm-9">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="amount"
                                    name="marketPrice"
                                    value={addFormData.marketPrice ? addFormData.marketPrice : addFormData.markingPrice}
                                    readOnly
                                />
                                <span className="error">{error.marketPrice}</span>
                            </div>
                        </div>
                     
                        {addFormData.type === "24K" ? (
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-3 col-form-label">Markup Percent</label>
                            <div className="col-sm-9">
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Enter percent"
                                    name="markingPercent"
                                    value={addFormData.markingPercent}
                                    onChange={e=>handleAddFormChange(e)}     
                                />
                            <span className="error">{error.markingPercent}</span>
                            </div>
                        </div>
                        ): ""}
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-3 col-form-label">Markup Amount</label>
                            <div className="col-sm-9">
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Enter Amount"
                                    name="markingAmount"
                                    value={addFormData.markingAmount}
                                    onChange={e=>handleAddFormChange(e)}   
                                />
                                <span className="error">{error.markingAmount}</span>
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-3 col-form-label">After Markup Amount</label>
                            <div className="col-sm-9">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="After Markup Amount"
                                    name="markingPrice"
                                    value={addFormData.markingPrice} 
                                    readOnly
                                />
                                 <span className="error">{error.markingPrice}</span>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <button type="submit" className="btn btn-primary btn-sm me-2" onClick={handleAddFormSubmit} disabled={loading} >Submit</button>
                        <button type="button" onClick={resetForm} className="btn btn-danger btn-sm"> Discard</button>
                    </div>
                </div>
                {/* <div className="card shadow">
                    <div className="card-header">
                        <span className="h3 mb-0">Price List</span>
                    </div>
                    <div className="card-body">
                        <Table responsive className="w-100">
                            <div className="dataTables_wrapper">
                                <table
                                    id="example"
                                    className="display w-100 dataTable"
                                    role="grid"
                                    aria-describedby="example_info"
                                >
                                    <thead>
                                        <th className="text-center">Type</th>
                                        <th className="text-center">Market Price</th>
                                        <th className="text-center">Markup Percent</th>
                                        <th className="text-center">Markup Amount</th>
                                        <th className="text-center">New Price</th>
                                    </thead>
                                    <tbody>
                                    {list.map((d, i) => (
                                        <tr key={i}>
                                            <td className="text-center">{d.type}</td>
                                            <td className="text-center">{d.marketPrice === null ? "-" :d.marketPrice}</td>
                                            <td className="text-center">{d.markingPercent === null ? "-" :d.markingPercent}</td>
                                            <td className="text-center">{d.markingAmount === null ? "-" :d.markingAmount}</td>
                                            <td className="text-center">{d.markingPrice}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </Table>
                    </div>
                </div> */}
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(GoldCustom);