import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux'; 
import swal from "sweetalert";
import Select, { createFilter } from "react-select";
import { api, url } from "../../../utils/api";
import SortingTable from "../../components/SortingTable";
import moment from 'moment';
import { Table } from "react-bootstrap";
import { statesWithFee, deliveryFeeMY, goldPurchaseDeliveryFee } from '../../../utils/function';

const PurchaseSetting = (props) => {
    const history = useHistory();
    const [data, setData] = useState({
        type: true, // true is delivery
        location: "",
        weight: null,
        wCharges: parseFloat("0").toFixed(2),
        eCharges: parseFloat("0").toFixed(2),
        pCharges: parseFloat("0").toFixed(2),
        fee: parseFloat("0").toFixed(2),
        vipFee: parseFloat("0").toFixed(2),
        packaging: parseFloat("0").toFixed(2),
    });
    const COLUMNS = [
        {
            Header : 'Weight',
            accessor: (row) => {
                return row.label
            }
        },
        {
            Header : 'Delivery Charges (West)',
            accessor: (row) => {
                return "RM " + row.wCharges
             }
        },
        {
            Header : 'Delivery Charges (East)',
            accessor: (row) => {
                return "RM " + row.eCharges
             }
        },
    ];
    const PACKINGCOLUMNS = [
        {
            Header : 'Weight',
            accessor: (row) => {
                return row.label
            }
        },
        {
            Header : 'Packing Charges',
            accessor: (row) => {
                return "RM " + row.pCharges
             }
        }
    ];
    const [locationOptions, setLocationOptions] = useState(goldPurchaseDeliveryFee);
    const [file, setFile] = useState([]);
    const [tnc, setTNC] = useState(false);
    const [deliveryFee, setDeliveryFee] = useState({west: parseFloat("0").toFixed(2), east: parseFloat("0").toFixed(2)});  
    const [error, setError] = useState({ delivery: "", packaging: "", tnc: "", });  
    const [loading, setLoading] = useState(false);
 
    useEffect(() => {
        getSettings();
        // getTerms();
    }, [])

    const toggleFee = () => {
        let parse = {
            type: !data.type,
            location: "",
            eCharges: parseFloat("0").toFixed(2),
            wCharges: parseFloat("0").toFixed(2),
            packaging: parseFloat("0").toFixed(2),
            fee: !data.type ? parseFloat("0").toFixed(2) : parseFloat("0").toFixed(2)
        }
        setData({...data, ...parse});
    }

    const getSettings = () => {
        fetch(api.users + `/delivery/settings`, {
            method:"GET",
            headers:new Headers({
                "Content-Type":"application/json",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        }).then(response => response.json())
        .then(responseJson => {
            let temp = {...data};
            let deliveryTemp = {...deliveryFee};
            let loc = locationOptions;
            let dt = responseJson.data;
            // responseJson.data.forEach(res => {
            for(let x = 0; x < dt.length; x++) {
                if (dt[x].type === 'delivery' && dt[x].weight > 0) {
                    let newOption = loc.find(opt => opt.value == dt[x].weight);
                    if (newOption) {
                        // console.log(dt[x].wCharges, dt[x].eCharges, newOption)
                        newOption.wCharges = parseFloat(dt[x].wCharges).toFixed(2);
                        newOption.eCharges = parseFloat(dt[x].eCharges).toFixed(2);
                    }
                    // console.log(newOption, loc);\
                    // deliveryTemp[res.location] = res.fee.toFixed(2) ?? parseFloat("0").toFixed(2);
                } else if (dt[x].type === 'selfCollect') {
                    // self collect
                    let newOption = loc.find(opt => opt.value == dt[x].weight);
                    if (newOption) {
                        // console.log(dt[x].pCharges, newOption)
                        newOption.pCharges = parseFloat(dt[x].wCharges).toFixed(2);
                    }
                    temp.packaging = parseFloat("0").toFixed(2);
                }
            }
            // })
            setDeliveryFee(deliveryTemp);
            setLocationOptions(loc);
            setData(temp);
        }).catch(error => {
            console.log("Error",error);
        });
    }

    // const getTerms = () => {
    //     fetch(url + `/terms/terms-and-conditions.pdf`, {
    //         method:"GET",
    //         headers:new Headers({
    //             "Content-Type":"application/json",
    //             "Authorization": "Bearer " + props.auth.auth.token
    //         }),
    //     }).then(response => response.status === 200 ? setTNC(true) : setTNC(false))
    //     .catch(error => {
    //         setTNC(false);
    //         console.log("Error",error);
    //     });
    // }

    const onInputChange = (event, field) => {
        event.preventDefault();
        const fieldValue = event.target.value;
        console.log(fieldValue);
        // if (!fieldValue) { setError({...error, error[field]:`${field} incorrect number`}); return; }
        const newFormData = { ...data };
        newFormData[field] = fieldValue;
        setData(newFormData);
    }

    const onOptionSelected = (e, type) => {
        const { label, value } = e;
        //let temp = locationOptions;
        let filtered = locationOptions.filter(d => d.value === value);
        console.log(filtered[0]);
        // setData({...data, location: filtered[0].value, fee: parseFloat(filtered[0].fee).toFixed(2)});
        let tempData = {...data};
        tempData.location = filtered[0].value; 
        tempData.weight = filtered[0].value;
        if (type === 'delivery') {
            tempData.wCharges = parseFloat(filtered[0].wCharges).toFixed(2); 
            tempData.eCharges = parseFloat(filtered[0].eCharges).toFixed(2);
        } else if (type === 'selfCollect') {
            tempData.packaging = parseFloat(filtered[0].pCharges).toFixed(2);
        }
        setData({...tempData})
        // setData({...data, 
        //     location: filtered[0].value, 
        //     weight: filtered[0].value,
        //     wCharges: parseFloat(filtered[0].wCharges).toFixed(2), 
        //     eCharges: parseFloat(filtered[0].eCharges).toFixed(2)
        // });
        // info[name] = value;
        // setGoldPrice(value)
    }

    const onFileChange = (event) => {
        setFile(event.target.files)
    }

    const checkIsNumber = (text) => {
        const isNotNumber = text.match(/[^0-9\.]+/g);
        if (isNotNumber) {
            return false
        }
        return true;
    }

    const updateFees = () => {
        swal({
            title: "Update Fees?",
            text: "Once complete, you will not be able to revert this action anymore!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((result) => {
            if (result) {
                setLoading(true);
                setError({...error, delivery: "", packaging: ""})
                let check = true;
                if (data.type && !checkIsNumber(data.fee)) {
                    check = false;
                    setError({...error, delivery: "Input is not number"})
                }
                if (!data.type && !checkIsNumber(data.fee)) {
                    check = false;
                    setError({...error, packaging: "Input is not number"})
                }
                if (check) {
                    let temp = {...data};
                    if (temp.type) { temp.type = 'delivery'}
                    else if (!temp.type) { temp.type = 'selfCollect'}
                    fetch(api.users + `/delivery/settings`,  {
                        method:"POST",
                        headers:new Headers({
                            "Content-Type":"application/JSON",
                            "Authorization": "Bearer " + props.auth.auth.token
                        }),
                        body: JSON.stringify(temp),
                    }).then(response => response.json())
                    .then(responseJson => {
                        setLoading(false);
                        if(responseJson.status === 'success'){
                            swal(responseJson.message, {
                                icon: "success",
                            });
                            getSettings();
                            //history.push('/withdraw/list');
                        }else{
                            swal(responseJson.message, {
                                icon: "error",
                            });
                        }
                    }).catch(error => { 
                            console.log("Error",error);
                    });
                }
                setLoading(false);
            } 
        })
    }

    // const updateTNC = () => {
    //     swal({
    //         title: "Update Terms and Conditions?",
    //         text:
    //           "Once complete, you will not be able to revert this action anymore!",
    //         icon: "warning",
    //         buttons: true,
    //         dangerMode: true,
    //     }).then((result) => {
    //         if (result) {
    //             setLoading(true);
    //             let check = true;
    //             setError({...error, tnc: ""});
    //             if (!file || file.length < 1) {
    //                 setError({...error, tnc: "Attachment is required"});
    //                 check = false;
    //             }
    //             else if (file[0].type !== 'application/pdf') {
    //                 setError({...error, tnc: "Only PDF is accepted"});
    //                 check = false;
    //             }
    //             if (check) {
    //                 let formData = new FormData();
    //                 for(let i=0;i<file.length;i++) {
    //                     formData.append(
    //                         "files",
    //                         file[i],
    //                         file[i].name
    //                     );
    //                 }        
    //                 fetch(api.users + "/tnc",  {
    //                     method:"PUT",
    //                     headers:new Headers({
    //                         //"Content-Type":"application/JSON",
    //                         "Authorization": "Bearer " + props.auth.auth.token
    //                     }),
    //                     body: formData,
    //                 }).then(response => response.json())
    //                 .then(responseJson => {
    //                     setLoading(false);
    //                     if(responseJson.status === 'success'){
    //                         swal(responseJson.message, {
    //                             icon: "success",
    //                         });
    //                         document.getElementById('tncFile').value = "";
    //                         getTerms();
    //                         //history.push('/withdraw/list');
    //                     }else{
    //                         swal(responseJson.message, {
    //                             icon: "error",
    //                         });
    //                     }
    //                 }).catch(error => { 
    //                         console.log("Error",error);
    //                 });
    //             }
    //             setLoading(false);
    //         } 
    //     })
    // }

    return (
        <>
            <div className="col-12">
                <div className="row page-titles mx-0 shadow">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active">
                            <span>Purchase</span>
                        </li>
                        <li className="breadcrumb-item">
                            <span>Setting</span>
                        </li>
                    </ol>
                </div>
                <div className="d-flex flex-column">
                    <div className="card shadow col-12">
                        <div className="card-header">
                            <span className="h4">Fees</span>
                            <span className="text-sm text-toggle-fee float-end" onClick={toggleFee}>{data.type ? "Packaging" : "Delivery"} <i className="fa fa-arrow-right"></i></span>
                        </div>
                        <div className="card-body">
                            {data.type ? 
                            <div className="d-flex flex-column flex-md-row">
                                <div className="mb-3 row align-items-center d-none">
                                    <label className="col-sm-4 col-form-label">Location</label>
                                    <div className="col-sm-8">
                                        {/* <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            name="location"
                                            value={data.location}
                                            onChange={(e) => onInputChange(e, "delivery")}
                                            /> */}
                                             <Select
                                                className="form-control"
                                                options={locationOptions}
                                                placeholder="- Select -"
                                                style={{
                                                    lineHeight: "40px",
                                                    color: "#7e7e7e",
                                                    paddingLeft: " 15px",
                                                    borderWidth: 0,
                                                }}
                                                value={data.location ? 
                                                    locationOptions.filter(l => l.location === data.location)[0]: 
                                                    null}
                                                onChange={e=> onOptionSelected(e, 'location')}
                                            />
                                        {/* <span className="error">{error.delivery}</span> */}
                                    </div>
                                </div>
                                <div className="mb-3 col-12 col-md-4 me-0 me-md-3 row align-items-center">
                                    <label className="col-12 col-form-label">Weight</label>
                                    <div className="col-12">
                                             <Select
                                                className="form-control"
                                                options={locationOptions}
                                                placeholder="- Select -"
                                                style={{
                                                    lineHeight: "40px",
                                                    color: "#7e7e7e",
                                                    paddingLeft: " 15px",
                                                    borderWidth: 0,
                                                }}
                                                value={data.weight ? 
                                                    locationOptions.filter(l => l.weight === data.weight)[0]: 
                                                    null}
                                                onChange={e=> onOptionSelected(e, 'delivery')}
                                            />
                                        {/* <span className="error">{error.delivery}</span> */}
                                    </div>
                                </div>
                                <div className="mb-3 col-12 col-md-4 me-0 me-md-3 row align-items-center">
                                    <label className="col-12 col-form-label">Delivery Fee (West)</label>
                                    <div className="col-12">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            name="wCharges"
                                            value={data.wCharges}
                                            onChange={(e) => onInputChange(e, "wCharges")}
                                            />
                                        <span className="error">{error.wCharges}</span>
                                    </div>
                                </div>
                                <div className="mb-3 col-12 col-md-4 me-0 me-md-3 row align-items-center">
                                    <label className="col-12 col-form-label">Delivery Fee (East)</label>
                                    <div className="col-12">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            name="eCharges"
                                            value={data.eCharges}
                                            onChange={(e) => onInputChange(e, "eCharges")}
                                            />
                                        <span className="error">{error.eCharges}</span>
                                    </div>
                                </div>
                            </div>:
                            <div className="d-flex flex-column flex-md-row">
                                <div className="mb-3 col-12 col-md-4 me-0 me-md-3 row align-items-center">
                                    <label className="col-12 col-form-label">Weight</label>
                                    <div className="col-12">
                                             <Select
                                                className="form-control"
                                                options={locationOptions}
                                                placeholder="- Select -"
                                                style={{
                                                    lineHeight: "40px",
                                                    color: "#7e7e7e",
                                                    paddingLeft: " 15px",
                                                    borderWidth: 0,
                                                }}
                                                value={data.weight ? 
                                                    locationOptions.filter(l => l.weight === data.weight)[0]: 
                                                    null}
                                                onChange={e=> onOptionSelected(e, 'selfCollect')}
                                            />
                                        {/* <span className="error">{error.delivery}</span> */}
                                    </div>
                                </div>
                                <div className="mb-3 col-12 col-md-4 me-0 me-md-3 row align-items-center">
                                    <label className="col-sm-12 col-form-label">Packaging Fee</label>
                                    <div className="col-sm-12">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            name="packagingFee"
                                            value={data.packaging}
                                            onChange={(e) => onInputChange(e, "packaging")}
                                        />
                                        <span className="error">{error.packaging}</span>
                                    </div>
                                </div>                            
                            </div>}
                            {/* <div className="mb-3 row align-items-center">
                                <label className="col-sm-3 col-form-label">Terms and Condition Attachment</label>
                                <div className="col-sm-9">
                                    <input
                                        type="file"
                                        placeholder=""
                                        name="attachment"
                                    
                                    />
                                    <span className="error"></span>
                                </div>
                            </div> */}
                        </div>
                        <div className="card-footer">
                            <button type="submit" className="btn btn-primary btn-sm me-2" disabled={loading} onClick={() => updateFees()}>Update</button>
                            {/* <button type="button" className="btn btn-danger btn-sm"> Discard</button> */}
                        </div>
                    </div>
                    <div className="card shadow col-12">
                        {/* <div className="card-header">
                            <span className="h4">Terms and Conditions</span>
                        </div>
                        <div className="card-body">
                            <div className="mb-3 row-cols-1 align-items-center">
                                <label className="col-form-label">Attachment</label>
                                <div className="">
                                    <input
                                        className="form-control"
                                        id="tncFile"
                                        type="file"
                                        placeholder=""
                                        name="attachment"
                                        onChange={(e) => onFileChange(e)}
                                    />
                                    <span className="error">{error.tnc}</span>
                                </div>
                            </div>
                            <div className="mb-3 row align-items-center">
                                <label className="col-sm-4 col-form-label">Current</label>
                                <div className="col-sm-8">
                                    {tnc ? 
                                        <a href={url + `/terms/terms-and-conditions.pdf`} target="_blank">
                                            <span>Click to view</span>
                                        </a> 
                                    : "No file uploaded"}
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <button type="submit" className="btn btn-primary btn-sm me-2" disabled={loading} onClick={() => updateTNC()}>Update</button>
                        </div> */}
                        <div className="card-header">
                            <span className="h4">Delivery Price List</span>
                        </div>
                        <div className="card-body">
                            {loading ? (
                                <div className="text-center">
                                    <i className="fas fa-spinner fa-pulse fa-3x"></i>
                                </div>
                                ) : (
                                <div className="w-100">
                                    <div id="job_data" className="dataTables_wrapper">
                                        {data.type ? 
                                        (<SortingTable columns={COLUMNS} data={locationOptions} excelExport={false}/>):
                                        (<SortingTable columns={PACKINGCOLUMNS} data={locationOptions} excelExport={false}/>)}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {/* <div className="card shadow">
                    <div className="card-body">
                        <Table responsive className="w-100">
                            <div className="dataTables_wrapper">
                                <table
                                    id="example"
                                    className="display w-100 dataTable"
                                    role="grid"
                                    aria-describedby="example_info">
                                    <tbody>
                                        <tr>
                                            <td>Delivery Fee ( West )</td>
                                            <td>RM {deliveryFee.west}</td>
                                        </tr>
                                        <tr>
                                            <td>Delivery Fee ( East )</td>
                                            <td>RM {deliveryFee.east}</td>
                                        </tr>
                                        <tr>
                                            <td>Packaging Fee</td>
                                            <td>RM {data.packaging ? data.packaging : 0}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Table>
                    </div>
                </div> */}
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(PurchaseSetting);