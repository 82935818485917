import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import { connect } from 'react-redux';
import {  api } from "../../../utils/api";
import SortingTable from "../../components/SortingTable";
import { dummyData } from "../../../utils/function";

const BranchList = (props) => {
    const COLUMNS = [
        {
            Header : 'No.',
            accessor: (row, i) => {
                return ++i + "."
            },
        },
        {
            Header : 'Branch Name',
            accessor: 'name'
        },
        {
            Header : 'Location',
            accessor: 'location'
        },
        {
            Header : 'Maximum GSP',
            accessor: 'investmentMax'
        },
        {
            Header : 'Action',
            accessor: (row) => {
                return (
                    <>
                        {/* <Dropdown>
                            <Dropdown.Toggled
                            as="button"
                            variant=""
                            className="btn sharp btn-primary tp-btn mt-1"
                            id="tp-btn"
                            >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="18px"
                                height="18px"
                                viewBox="0 0 24 24"
                                version="1.1"
                            >
                                <g
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                                >
                                <rect x="0" y="0" width="24" height="24" />
                                <circle fill="#000000" cx="12" cy="5" r="2" />
                                <circle fill="#000000" cx="12" cy="12" r="2" />
                                <circle fill="#000000" cx="12" cy="19" r="2" />
                                </g>
                            </svg>
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="right">
                                <Dropdown.Item href={"/branch/" + row.id + "/contract"}><div>Add Gold Program</div></Dropdown.Item>
                                <Dropdown.Item href={"/branch/" + row.id + "/contract/digital"}><div>Add eGold Program</div></Dropdown.Item>
                                <Dropdown.Item href={"/branch/edit/" + row.id}><div>Edit</div></Dropdown.Item>
                                <Dropdown.Item onClick={()=>removeBranch(row.id)}>Remove</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> */}
                        <Link className="btn btn-success shadow btn-xs sharp me-2"
                            to={{
                                pathname: `/branch/${row.id}/contract`
                            }}
                        >
                            <i className="fas fa-coins"></i>
                        </Link>
                        <Link className="btn btn-warning shadow btn-xs sharp me-2"
                            to={{
                                pathname: `/branch/${row.id}/contract/digital`
                            }}
                        >
                            <i className="fas fa-file-alt"></i>
                        </Link>
                        <Link className="btn btn-secondary shadow btn-xs sharp me-2"
                            to={{
                                pathname: `/branch/edit/${row.id}`
                            }}
                        >
                            <i className="fas fa-pen"></i>
                        </Link>
                        <a className="btn btn-danger shadow btn-xs sharp me-2" onClick={() =>removeBranch(row.id)}>
                            <i className="fas fa-trash"></i>
                        </a>
                    </>
                )
            },
        },
    ];
    const [loading, setLoading] = useState(false);
    const [branch, setBranch] = useState([]);
    const history = useHistory();

    const [fileData, setFileData] = useState({
        name: "Gold_Branch_List",
        excelData: [],
        hasCustom: false,
        
    })
    useEffect(() => {
        getList();
    }, [props.auth]);

    const getList = () => {
        setBranch([...dummyData("branches")])
        // setLoading(true);
        // fetch(api.branch, {
        //     method:"GET",
        //     headers:new Headers({
        //         "Content-Type":"application/JSON",
        //         "Authorization": "Bearer " + props.auth.auth.token
        //     }),
        // }).then(response => response.json())
        // .then(responseJson => {
        //     setLoading(false);
        //     console.log(responseJson) 
        //     if(responseJson.data){
        //         setBranch(responseJson.data)
        //         let excelTable = responseJson.data.map((i,index) => {
                        
        //             return {
        //                 "No.": index + 1,
        //                 "Branch Name": i.name, 
        //                 "Location": i.location, 
        //                 "Maximum GSP": i.investmentMax,
        //             }
        //         })
        //         setFileData({
        //             ...fileData,
        //             excelData: excelTable,
        //             hasCustom: false,
        //             customRows:[],
                    
        //         });

        //     }else{
        //         setBranch([])
        //     }
        // }).catch(error => { 
        //     console.log("Error",error);
        // });
    }

    const removeBranch = (id) => {
        // swal({
        //     title: "Delete data?",
        //     text:
        //       "Once deleted, you will not be able to recover this data anymore!",
        //     icon: "warning",
        //     buttons: true,
        //     dangerMode: true,
        // }).then((willDelete) => {
        //     if (willDelete) {
        //         fetch(api.branch + "/" +id + "/remove", {
        //             method:"PUT",
        //             headers:new Headers({
        //                 "Content-Type":"application/JSON",
        //                 "Authorization": "Bearer " + props.auth.auth.token
        //             }),
        //         }).then(response => response.json())
        //         .then(responseJson => {
        //             getList();
        //         }).catch(error => {
        //             console.log("Error",error);
        //         });
        //     } 
        // })
    }

    return (
        <div className="col-12">
            <div className="row page-titles mx-0 shadow">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                        <span>Branch</span>
                    </li>
                    <li className="breadcrumb-item">
                        <span>List</span>
                    </li>
                </ol>
            </div>
            <div className="card shadow">
                <div className="card-header">
                    <Link className="btn btn-outline-primary btn-sm pull-right" to="/branch/create">Create New</Link>
                    {/* <Link className="btn btn-outline-primary btn-sm pull-right float-end" to="/contract/digital">Digital Contract List</Link> */}
                </div>
                <div className="card-body">
                    {loading ? (
                    <div className="text-center">
                        <i className="fas fa-spinner fa-pulse fa-3x"></i>
                    </div>
                    ) : (
                    <div className="w-100">
                        <div id="job_data" className="dataTables_wrapper">
                            <SortingTable columns={COLUMNS} data={branch} excelExport={true} fileData={fileData}/>
                        </div>
                    </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};


export default connect(mapStateToProps)(BranchList);
