import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux'; 
import swal from "sweetalert";
import Select, { createFilter } from "react-select";
import { api } from "../../../utils/api";
import SortingTable from "../../components/SortingTable";
import moment from 'moment';
import { Table } from "react-bootstrap";
import { goldWeightType } from "../../../utils/function";

const GoldPremium = (props) => {
    const history = useHistory();
    const [addFormData, setAddFormData] = useState({
        weight: 0,
        wCharges: 0,
    }); //Add data 
    const [goldOptions, setGoldOptions] = useState(goldWeightType); // options
    const [error, setError] = useState({ weight: "", wCharges: "" });  
    const [loading, setLoading] = useState(false);
    // const [list, setList] = useState([]);
    
    useEffect(()=>{
        getList();
    },[props.auth.auth.token]);

    function getList(){
        setLoading(true);
        fetch(api.goldPrice + `/premium`, {
            method:"GET",
            headers:new Headers({
                "Content-Type":"application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        }).then(response => response.json())
        .then(responseJson => {
            setLoading(false);
            if(responseJson.status === 'success'){
                // setList(responseJson.data);
                let tempList = [...goldOptions]
                tempList = tempList.map(temp => {
                    if (responseJson.data.find(dt => temp.value === dt.weight)) {
                        temp.wCharges = responseJson.data.find(dt => temp.value === dt.weight).wCharges;
                    }
                    return temp;
                })
                console.log(tempList);
                setGoldOptions(tempList)
            }
        }).catch(error => {
            setLoading(false);
            console.log("Error",error);
        });
    }

    function resetForm() {
        setAddFormData({
            weight: 0,
            wCharges: 0,
        })
    }

    const handleOptionSelected = (e, name) => {
        const { label, value } = e;
        const info = {...addFormData};
        info[name] = value;
        setAddFormData(info);
    }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const newFormData = { ...addFormData };
        newFormData[name] = value;
        setAddFormData(newFormData);
    };

    //Add Submit data
    const handleAddFormSubmit = (event) => {
        event.preventDefault();
        swal({
            title: "Confirm changes?",
            text:
              "Once changes, you will not longer using previous amount!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((action) => {
            if (!action) { return false }

            let errors = { weight: "", wCharges: "",};
            let check = true;
            if(!addFormData.weight){
                check = false;
                errors.weight = "Field is required"
            }
            if(!addFormData.wCharges){
                check = false;
                errors.wCharges = "This Field is required"
            }
            setError(errors);
            if(check){
                let formData = {
                    "weight": addFormData.weight,
                    "wCharges": addFormData.wCharges,
                };
                setLoading(true)
                fetch(api.goldPrice + `/premium`, {
                    method:"POST",
                    headers:new Headers({
                        "Content-Type":"application/JSON",
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                    body: JSON.stringify(formData)
                }).then(response => response.json())
                .then(responseJson => {
                    setLoading(false);
                    if(responseJson.status === 'success'){
                        swal(responseJson.message, {
                            icon: "success",
                        });
                        resetForm();
                        getList();
                    }else{
                        swal(responseJson.message, {
                            icon: "error",
                        });
                    }
                }).catch(error => {
                        console.log("Error",error);
                });
            }
        })
    };

    return (
        <>
            <div className="col-12">
                <div className="row page-titles mx-0 shadow">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active">
                            <span>Gold</span>
                        </li>
                        <li className="breadcrumb-item">
                            <span>Premium Price</span>
                        </li>
                    </ol>
                </div>
                <div className="d-flex flex-column">
                    <div className="card col-12 shadow">
                        {/* <div className="card-header">
                            <span>24K Gold</span>
                        </div> */}
                        <div className="card-body">
                        <div className="mb-3 row align-items-center">
                                <label className="col-sm-12 col-form-label">Gold Weight</label>
                                <div className="col-sm-12">
                                    <Select
                                        className="form-control"
                                        options={goldOptions}
                                        placeholder="- Select -"
                                        style={{
                                            lineHeight: "40px",
                                            color: "#7e7e7e",
                                            paddingLeft: " 15px",
                                            borderWidth: 0,
                                        }}
                                        value={goldOptions.filter(o => o.value === addFormData.weight)}
                                        onChange={e=> handleOptionSelected(e, 'weight')}
                                    />
                                    <span className="error">{error.weight}</span>
                                </div>
                            </div>
                            <div className="mb-3 row align-items-center">
                                <label className="col-sm-12 col-form-label">Premium Charges Amount (RM)</label>
                                <div className="col-sm-12">
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Enter Amount"
                                        name="wCharges"
                                        value={addFormData.wCharges}
                                        onChange={e=>handleAddFormChange(e)}   
                                    />
                                    <span className="error">{error.wCharges}</span>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <button type="submit" className="btn btn-primary btn-sm me-2" onClick={handleAddFormSubmit} disabled={loading} >Submit</button>
                            <button type="button" onClick={resetForm} className="btn btn-danger btn-sm"> Discard</button>
                        </div>
                    </div>
                    <div className="card col-12 shadow">
                        <div className="card-header">
                            <span className="h3 mb-0">Premium Charges List</span>
                        </div>
                        <div className="card-body p-0">
                            <Table responsive className="w-100">
                                <div className="dataTables_wrapper">
                                    <table
                                        id="example"
                                        className="display w-100 dataTable"
                                        role="grid"
                                        aria-describedby="example_info"
                                    >
                                        <thead>
                                            <th className="text-center">Weight</th>
                                            <th className="text-center">Premium Price (RM)</th>
                                        </thead>
                                        <tbody>
                                        {goldOptions.map((d, i) => (
                                            <tr key={i}>
                                                <td className="text-center">{d.label}</td>
                                                <td className="text-center">{parseFloat(d.wCharges).toFixed(2)}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(GoldPremium);