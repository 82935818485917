import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import { connect } from 'react-redux';
import { api } from "../../../utils/api";
import moment from "moment";
import { Button, Dropdown, Modal } from "react-bootstrap";
import Select from "react-select";
import SortingTable from "../../components/SortingTable";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { urlParamsLocalStorage } from "../../../utils/function";

const list = [
    { id: 1, name: 'cc', weight: '10', type: 'physical', amount: '330', method: 'delivery', status: 'pending', createdDatetime: '2022-10-10 22:00:00' }
]
const InventoryList = (props) => {
    let history = useHistory();
    const [searchText, setSearchText] = useState("");
    const [currentPageNumber, setCurrentPageNumber] = useState(0);
    const [currentSelectAllPage, setCurrentSelectAllPage] = useState([]);
    const COLUMNS = [
        {
            id: 'headerCheckbox',
            Header: (props) => {
                let showHide;
                if (filterOption === 'all') {
                    showHide = '';
                } if (filterOption === 'pending') {
                    showHide = (
                        <input type="checkbox" style={{ width: "25px", height: "25px" }} onClick={(e) => selectAllCurrentPageBuyBack(e, props)}
                            checked={currentSelectAllPage.includes(props.state.pageIndex) ? true : false}
                        />
                    );
                } if (filterOption === 'completed') {
                    showHide = '';
                }
                return showHide;
            },
            disableSortBy: true,
            accessor: (row) => {
                return row;
            },
            Cell: (props) => {
                let showHide;
                if (filterOption === 'all') {
                    showHide = '';
                } if (filterOption === 'pending') {
                    showHide = (
                        <input type="checkbox" style={{ width: "25px", height: "25px" }} onClick={(e) => selectBuyBack(e, props)} checked={buyBackList.includes(props.cell.value.id)} />
                    );
                } if (filterOption === 'completed') {
                    showHide = '';
                }

                return showHide;
            },
        },
        {
            Header: 'No.',
            Footer: " ",
            accessor: (row, i) => {
                return i + 1;
            }
        },
        {
            Header: 'Ref no.',
            Footer: " ",
            accessor: (row) => {
                return row.purchaseId
            }
        },
        {
            Header: 'Member Id',
            Footer: " ",
            accessor: (row) => {
                return row.user.memberId;
            }
        },
        {
            Header: 'Member Name',
            Footer: () => { return (<><h4 className="text-end">Total: </h4></>) },
            accessor: (row) => {
                return row.user.fullName;
            }
        },
        {
            Header: 'Amount (g)',
            accessor: 'amount',
            Footer: (info) => {
                const total = React.useMemo(
                    () =>

                        info.page.reduce((sum, row) => row.values.amount + sum, 0),
                    [info.page]
                    //info.page - use for calculate total based on the particular page
                    //info.rows - use for calculate total based on how many rows that the table has
                );

                return (<><h4>{`${total}g`}</h4></>);
            }
        },
        {
            Header: 'Type',
            Footer: " ",
            accessor: (row) => {
                return row.type === 'egold' ? 'GSP' : 'FGR';
            }
        },
        {
            Header: 'Restock Status',
            Footer: " ",
            accessor: (row) => {
                return row.isRestock == 0 ? <span className={`text-capitalize status-box ${'pending'}`}>{'pending'}</span> : <span className={`text-capitalize status-box ${'completed'}`}>{'completed'}</span>;
            }
        },
        {
            Header: 'Created Datetime',
            Footer: " ",
            accessor: (row) => {
                return moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss')
            }
        },
        {
            Header: 'Action',
            Footer: " ",
            accessor: (row) => {
                return (
                    <>
                        <Link to={"/inventory/details/" + row.id} onClick={() => storeParams()} className="text-primary">
                            <i className="fas fa-eye"></i>
                        </Link>
                        {/* {row.type === 'egold' ? <></> : 
                    //    {row.type === 'egold' || (row.programReferId === null) ? <></> : 
                       (<span className="ms-3 text-warning cursor-pointer" onClick={() => handleSwitchContractModal(row.id, row.purchaseId)}>
                            <i className="fas fa-random"></i>
                        </span>)} */}
                    </>
                )
            }
        },
    ];

    const [loading, setLoading] = useState(false);
    const [contents, setContents] = useState([]);
    const [filterOption, setFilterOption] = useState("pending");
    const [allData, setAllData] = useState([]);
    const [mainGP, setMainGP] = useState([]);
    //const [buyBackList, setBuyBackList] = useState([]);
    const [buyBackList, setBuyBackList] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [error, setError] = useState([]);
    const [dateRange, setDateRange] = useState({
        startDate: moment().startOf('month'),
        endDate: moment().endOf('month'),
    });
    const [fileData, setFileData] = useState({
        name: "Gold_Inventory_List",
        excelData: [],
        hasCustom: false,

    })
    useEffect(() => {
        if (allData.length > 0) {
            if (filterOption === 'all') {
                setContents(allData);
            } if (filterOption === "pending") {
                let pendingList = allData.filter(arr => arr.isRestock == 0);
                setContents(pendingList);
                setFileData({
                    ...fileData,
                    excelData: pendingList,
                    hasCustom: false,
                    customRows: [],
                });
            } if (filterOption === "completed") {
                let completedList = allData.filter(arr => arr.isRestock == 1);
                setContents(completedList);

                setFileData({
                    ...fileData,
                    excelData: completedList,
                    hasCustom: false,
                    customRows: [],

                });
            }
        }
    }, [filterOption]);

    useEffect(() => {
        let urlParams = urlParamsLocalStorage('get', {path: history.location.pathname, name: "inventoryList"});
        if (urlParams) {
            getList(urlParams.query.startDate, urlParams.query.endDate);
            setDateRange({
                startDate: moment(urlParams.query.startDate),
                endDate: moment(urlParams.query.endDate),
            })
            setSearchText(urlParams.query.search);
            setCurrentPageNumber(urlParams.query.tablePageNumber);
        } else {
            getList(dateRange.startDate, dateRange.endDate)
        }
    }, [props.auth]);

    function onDateChange(e, name) {
        let temp = { ...dateRange };
        temp[name] = e;
        getList(temp.startDate, temp.endDate);
        setDateRange(temp);
    }

    const getList = (start, end) => {
        setLoading(true);
        fetch(api.users + `/golds/inventory?basic=1&start=${moment(start).format('YYYY-MM-DD 00:00:00')}&end=${moment(end).format('YYYY-MM-DD 23:59:59')}`, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        }).then(response => response.json())
            .then(responseJson => {
                setLoading(false);
                if (responseJson.data) {
                    setBuyBackList([]);
                    setCurrentSelectAllPage([]);
                    setAllData(responseJson.data);
                    let filtered = responseJson.data;
                    if (filterOption === 'all') {
                    }
                    if (filterOption === "pending") {
                        filtered = responseJson.data.filter(arr => arr.isRestock == 0);
                    }
                    if (filterOption === "completed") {
                        filtered = responseJson.data.filter(arr => arr.isRestock == 1);
                    }
                    setContents(filtered);
                    // let mGP = responseJson.data.filter(dt => dt.type === 'gold')
                    //     .map((dt) => {
                    //         return {
                    //             label: `${dt.purchaseId} (${moment(dt.createdAt).format('yyyy-MM-DD')}) (${dt.program} months)`, 
                    //             value: dt.id, 
                    //             referId: dt.programReferId, 
                    //             amount: dt.amount, 
                    //             customer: dt.userId,
                    //         }
                    //     });
                    // console.log(mGP, "filter");
                    // setMainGP([...mGP]);

                    let excelTable = responseJson.data.map((i, index) => {

                        return {
                            "No.": index + 1,
                            "Ref no.": i.purchaseId,
                            "Member Id": i.user.memberId,
                            "Member Name": i.user.fullName,
                            "Amount": i.amount ?? 0 + "g",
                            "Type": i.type === 'egold' ? 'GSP' : 'FGR',
                            "Restock Status": i.isRestock == 0 ? 'pending' : 'completed',
                            "Created Datetime": moment(i.createdAt).format('YYYY-MM-DD HH:mm:ss')
                        }
                    })

                    setFileData({
                        ...fileData,
                        excelData: excelTable,
                        hasCustom: false,
                        customRows: [],

                    });
                }

            }).catch(error => {
                setLoading(false);
                console.log("Error", error);
            });
    }


    const selectAllCurrentPageBuyBack = (e, pages) => {
        let currPageSelected = currentSelectAllPage;
        let bbList = buyBackList;
        if (e.target.checked) {
            currPageSelected.push(pages.state.pageIndex);
            // Add all items to the selected items list
            pages.page.map((li, i) => {
                if (!bbList.includes(li.original.id)) {
                    bbList.push(li.original.id)
                }
            });
        } else {
            currPageSelected = currPageSelected.filter(v => v !== pages.state.pageIndex);    
            // Clear the selected items list
            pages.page.map((li, i) => {
                bbList = bbList.filter(v => v !== li.original.id)
            });
        }
        setBuyBackList([...bbList]);
        setCurrentSelectAllPage([...currPageSelected])
    };

    const selectAllBuyBack = (e, page) => {
        if (e.target.checked) {
            // Add all items to the selected items list
            const allIds = page.pages.map((li, i) => li.original.id);
            setBuyBackList([ ...buyBackList, allIds]);
        } else {
            // Clear the selected items list
            setBuyBackList([]);
        }
    };

    const selectBuyBack = (e, props) => {
        let tempList = [...buyBackList];
        let row = props.row;
        let pageIndex = props.state.pageIndex;
        if (e.target.checked) {
            tempList.push(row.original.id);
        } else {
            tempList = tempList.filter((tmp) => tmp !== row.original.id);
            let currPageSelected = currentSelectAllPage;
            currPageSelected = currPageSelected.filter(v => v !== pageIndex);    
            setCurrentSelectAllPage([...currPageSelected])
        }
        setBuyBackList([...tempList]);
    };

    const getSearchText = (e) => {
        setSearchText(e)
    }

    const getTablePageNumber = (e) => {
        setCurrentPageNumber(e);
    }

    const storeParams = () => {
        let urlData = {
            path: history.location.pathname,
            name: "inventoryList",
            query: {
                startDate: dateRange.startDate,
                endDate: dateRange.endDate,
                search: searchText,
                deliveryStatus: filterOption,
                tablePageNumber: currentPageNumber,
            }
        };
        urlParamsLocalStorage('set', urlData);
    }

    // const selectBuyBack = (e, row) => {

    //         let tempList = [...buyBackList];
    //         if (e.target.checked) {
    //             tempList.push(row.id)
    //         }
    //         else {
    //             tempList = tempList.filter(tmp => tmp !== row.id)
    //         }
    //         setBuyBackList([...tempList]);
    //         console.log("list: ",[...tempList]);



    // }

    const buyBack = () => {

        let newData = [{
            "id": buyBackList,
        }]
        // console.log(newData);
        fetch(api.users + `/golds/goldRestock`, {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
            body: JSON.stringify(newData)

        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    swal('Success!', 'Successfully Added', "success");
                    getList();
                }
                else {
                    swal('Opps', responseJson.message, "error");
                }

            }).catch(error => {
                console.log("Error:", error);
            });
    }

    return (
        <>
            <div className="row page-titles mx-0 shadow">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                        <span>Inventory List</span>
                    </li>
                    <li className="breadcrumb-item">
                        <span>List</span>
                    </li>
                    {/* <Link to="/restock/list" className="ms-auto btn btn-sm btn-primary text-white">Go To Restock</Link> */}
                </ol>
            </div>
            <div className="card shadow card-black">
                <div className="card-body">
                    <div className="row align-items-center mb-3">
                        <div className="col-md-2">
                            <span className="text-nowrap">Filter by delivery status:</span>
                        </div>
                        <div className="col-md-10">
                            <button className={`${filterOption === 'all' ? "btn-primary btn btn-sm me-2" : "btn-outline-primary btn btn-sm me-2"}`} onClick={() => setFilterOption('all')}>All</button>
                            <button className={`${filterOption === 'pending' ? "btn-primary btn btn-sm me-2" : "btn-outline-primary btn btn-sm me-2"}`} onClick={() => setFilterOption('pending')}>Pending Restock</button>
                            <button className={`${filterOption === 'completed' ? "btn-primary btn btn-sm me-2" : "btn-outline-primary btn btn-sm me-2"}`} onClick={() => setFilterOption('completed')}>Restock Completed</button>
                        </div>
                        <div className="row mt-2 align-items-center">
                            <div className="col-md-2">
                                <span className="text-nowrap">Filter by date:</span>
                            </div>
                            <div className="col-md-2">
                                <div className="form-control date-picker-container">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            autoOk
                                            disablePast={false}
                                            label=""
                                            clearable={false}
                                            format="dd/MM/yyyy"
                                            value={dateRange.startDate}
                                            onChange={(e) => onDateChange(e, 'startDate')}
                                            className="form-control"
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <span className="error">{error.startDate}</span>
                            </div>
                            <div className="col-md-2">
                                <div className="form-control date-picker-container">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            autoOk
                                            disablePast={false}
                                            label=""
                                            clearable={false}
                                            format="dd/MM/yyyy"
                                            value={dateRange.endDate}
                                            onChange={(e) => onDateChange(e, 'endDate')}
                                            className="form-control"
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <span className="error">{error.endDate}</span>
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <div className="text-center">
                            <i className="fas fa-spinner fa-pulse fa-3x"></i>
                        </div>
                    ) : (
                        <div className="">
                            <div className="dataTables_wrapper" id="table_data">
                                <SortingTable columns={COLUMNS} data={contents} excelExport={true} fileData={fileData} setSearchText={getSearchText} searchText={searchText} setPageNumber={getTablePageNumber} currentPageNumber={currentPageNumber}/>
                            </div>
                        </div>
                    )}
                </div>

                <div className="card-footer">
                    {
                        filterOption === 'pending' ? (
                            <button type="submit" className="btn btn-primary btn-sm me-2" onClick={buyBack}>Restock</button>
                        ) : null
                    }

                </div>

            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(InventoryList);
