import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { api } from "../../utils/api";
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { toLocaleStringFormat } from "../../utils/function";

const Dashboard = (props) => {
	const history = useHistory();
	const [users, setUsers] = useState([]);
	const [status, setStatus] = useState({
		totalInvestment: 123445,
		maxInvestment: 123445,
	});
	const [withdraw, setWithdraw] = useState({
		totalPendingWithdraw: 123445,
	})
	const [investment, setInvestment] = useState({
		totalPendingInvestment: 123445,
	})
	const [announcement, setAnnouncement] = useState([])
	const [dashboard, setDashboard] = useState({
		dailyTotalGoldPurchases: 123445,
		monthlyTotalGoldPurchases: 123445,
		annualTotalGoldPurchases: 123445,
		dailyTotalGoldPurchasesMYR: 123445,
		monthlyTotalGoldPurchasesMYR: 123445,
		annualTotalGoldPurchasesMYR: 123445,
	})
	const getAnnouncements = () => {
		fetch(api.getAnnouncements, {
			method: "GET",
			headers: new Headers({
				"Content-Type": "application/JSON",
			}),
		})
			.then(response => response.json())
			.then(responseJson => {
				setAnnouncement(responseJson.data);
			}).catch(error => {
				console.log("Error:", error);
			});
	}
	const [dashboardSummary, setDashboardSummary] = useState([
		{
			items: [
				{ label: "Total Member", value: 123123, prefix: "", postfix: "", color: 'gradient-17', },
			]
		},
		{
			items: [
				{ label: "Daily Purchase Gold", value: 1113, prefix: "", postfix: "g", color: 'gradient-4', },
				{ label: "Monthly Purchase Gold", value: 12334, prefix: "", postfix: "g", color: 'gradient-4', },
				{ label: "Annual Purchase Gold", value: 123123, prefix: "", postfix: "g", color: 'gradient-4', },
			]
		},
	])

	useEffect(() => {
		// user
		// fetch(api.getAllUsers, {
		// 	method:"GET",
		// 	headers:new Headers({
		// 		"Content-Type":"application/JSON",
		// 		"Authorization": "Bearer " + props.auth.auth.token
		// 	}),
		// }).then(response => response.json())
		// .then(responseJson => {
		// 	if(responseJson.data){
		// 		setUsers(responseJson.data)
		// 	}
		// }).catch(error => { 
		// 	console.log("Error",error);
		// });
		// // branch
		// fetch(api.branch + '?max=0', {
		// 	method:"GET",
		// 	headers:new Headers({
		// 		"Content-Type":"application/JSON",
		// 		"Authorization": "Bearer " + props.auth.auth.token
		// 	}),
		// }).then(response => response.json())
		// .then(responseJson => {
		// 	if(responseJson.data){
		// 		let temp = {...status};
		// 		responseJson.data.forEach(res => {
		// 			temp.totalInvestment += res.totalWeight;
		// 			temp.maxInvestment += res.investmentMax;
		// 		})
		// 		setStatus(temp)
		// 	}
		// }).catch(error => { 
		// 	console.log("Error",error);
		// });
		// //withdraw
		// fetch(api.users + `/withdraw/request?status=pending`, {
		// 	method:"GET",
		// 	headers:new Headers({
		// 		"Content-Type":"application/JSON",
		// 		"Authorization": "Bearer " + props.auth.auth.token
		// 	}),
		// }).then(response => response.json())
		// .then(responseJson => {
		// 	if(responseJson.data){
		// 		let temp = {...withdraw};
		// 		temp.totalPendingWithdraw = responseJson.data.length;
		// 		setWithdraw(temp);
		// 	}
		// }).catch(error => { 
		// 	console.log("Error",error);
		// });
		// //investment
		// fetch(api.investment + `?status=pending`, {
		// 	method:"GET",
		// 	headers:new Headers({
		// 		"Content-Type":"application/JSON",
		// 		"Authorization": "Bearer " + props.auth.auth.token
		// 	}),
		// }).then(response => response.json())
		// .then(responseJson => {
		// 	if(responseJson.data){
		// 		let temp = {...investment};
		// 		temp.totalPendingInvestment = responseJson.data.length;
		// 		setInvestment(temp);
		// 	}
		// }).catch(error => { 
		// 	console.log("Error",error);
		// });
		// getAnnouncements();
		// getDashboardData();
	}, [props.auth.auth.token])

	const getDashboardData = () => {
		fetch(api.users + `/dashboard`, {
			method: "GET",
			headers: new Headers({
				"Content-Type": "application/JSON",
				"Authorization": "Bearer " + props.auth.auth.token
			}),
		}).then((response) => response.json())
			.then((responseJson) => {
				let dashboardData = responseJson.data;
				setDashboard({ ...dashboardData });
			}).catch((error) => {
				console.log(error);
			})
	}

	return (
		<>
			<div className="">
				<div className="d-flex flex-column flex-md-row flex-md-wrap">
					{dashboardSummary.map(summary => {
						if (summary.items.length > 0) {
							return (
								<div className="col-12 d-flex flex-column flex-md-row">
									{
										summary.items.map((item, index) => {
											return (
												<div className="col-md-4 px-2">
													<div className={`card card-bx box-shadow-light ${item.color}`}>
														<div className="card-body p-4 d-flex align-items-center">
															<div className="me-auto text-white">
																<h2 className="text-white">{item.prefix}{toLocaleStringFormat(item.value)}{item.postfix}</h2>
																<span className="fs-18">{item.label}</span>
															</div>
														</div>
													</div>
												</div>
											)
										})
									}
								</div>
							)
						}
					})}
					{/* {props.auth.auth.isMaster ? (<div className="col-xl-6 col-sm-6">
					<div className="card card-bx gradient-2">
						<div className="card-body d-flex align-items-center">
							<div className="me-auto text-white">
								<h2 className="text-white">{}</h2>
								<span className="fs-18">Total Withdrawal</span>
							</div>
						</div>
					</div>
				</div>):null} */}
					{/* <div className="col-md-4 px-2 icon-transistion" style={{cursor: 'pointer'}}>
					<Link to="/investment/list?filter=pending" as="/investment/list">
						<div className="card card-bx box-shadow-light bg-danger">
							<div className="card-body d-flex align-items-center">
								<div className="me-auto text-white flex-1">
									<h2 className="text-white">{investment.totalPendingInvestment??"0"}{investment.totalPendingInvestment?(<span className="dashboard-data-icon ms-1"><i class="fas fa-exclamation-circle"></i></span>):''}</h2>
									<span className="fs-18">Total Pending GSP+</span>
								</div>
								<div className="text-white-50 flex-1 zoom-out ms-md-5">
									<span className="fs-18"><i className="fas fa-chevron-right "></i></span>
								</div>
							</div>
						</div>
					</Link>
				</div> */}
					{/* <div className="col-md-4 px-2 icon-transistion" style={{cursor: 'pointer'}}>
					<Link to="/withdraw/list?filter=pending" as="/withdraw/list">
						<div className="card card-bx box-shadow-light bg-warning">
							<div className="card-body d-flex align-items-center">
								<div className="me-auto text-white">
									<h2 className="text-white">{withdraw.totalPendingWithdraw??"0"}{withdraw.totalPendingWithdraw?(<span className="dashboard-data-icon ms-1"><i class="fas fa-exclamation-circle"></i></span>):''}</h2>
									<span className="fs-18">Total Pending Withdraw</span>
								</div>
								<div className="text-white-50 flex-1 zoom-out ms-md-5">
									<span className="fs-18"><i className="fas fa-chevron-right "></i></span>
								</div>
							</div>
						</div>
					</Link>
				</div> */}
					{/* <div className="col-md-4 px-2">
						<div className="card card-bx box-shadow-light gradient-17">
							<div className="card-body p-4 d-flex align-items-center">
								<div className="me-auto text-white">
									<h2 className="text-white">{users.length}</h2>
									<span className="fs-18">Total Member</span>
								</div>
							</div>
						</div>
					</div> */}
					{/* <div className="col-md-4 px-2">
					<div className="card card-bx box-shadow-light gradient-2">
						<div className="card-body d-flex align-items-center">
							<div className="me-auto text-white">
								<h2 className="text-white">{status.maxInvestment ?? "0"}g</h2>
								<span className="fs-18">Maximum GSP+</span>
							</div>
						</div>
					</div>
				</div> */}
					{/* <div className="col-md-4 px-2">
					<div className="card card-bx box-shadow-light gradient-6">
						<div className="card-body d-flex align-items-center">
							<div className="me-auto text-white">
								<h2 className="text-white">{status.totalInvestment ? parseFloat(status.totalInvestment).toFixed(2) : "0"}g</h2>
								<span className="fs-18">Total GSP+</span>
							</div>
						</div>
					</div>
				</div> */}
					{/* <div className="col-12 d-flex flex-column flex-md-row">
						<div className="col-md-4 px-2">
							<div className="card card-bx box-shadow-light gradient-4">
								<div className="card-body d-flex align-items-center">
									<div className="me-auto text-white">
										<p className="h2 text-white">{parseFloat(dashboard.dailyTotalGoldPurchases ?? 0).toFixed(2)}g</p>
										<span className="fs-18">Daily Purchase Gold By (g)</span>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-4 px-2">
							<div className="card card-bx box-shadow-light gradient-4">
								<div className="card-body d-flex align-items-center">
									<div className="me-auto text-white">
										<p className="h2 text-white">{parseFloat(dashboard.monthlyTotalGoldPurchases ?? 0).toFixed(2)}g</p>
										<span className="fs-18">Monthly Purchase Gold By (g)</span>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-4 px-2">
							<div className="card card-bx box-shadow-light gradient-4">
								<div className="card-body d-flex align-items-center">
									<div className="me-auto text-white">
										<p className="h2 text-white">{parseFloat(dashboard.annualTotalGoldPurchases ?? 0).toFixed(2)}g</p>
										<span className="fs-18">Annual Purchase Gold By (g)</span>
									</div>
								</div>
							</div>
						</div>
					</div> */}
				</div>
				<div className="card card-announcement box-shadow-light">
					<div className="card-header bg-info-light">
						<h3>Announcement</h3>
					</div>
					<div className="card-body px-0 py-0">
						{announcement.length > 0 ?
							announcement.map((item, i) => (
								<>
									<div key={i} className="px-3 py-3 card-item align-items-center">
										<div className="col-md-12">
											<h5 className="mb-1">{item.title}<span className="float-end">{moment(item.createdAt).format('DD/MMM/YYYY HH:mm')}</span></h5>
											<p className="mb-0">{item.description}</p>
										</div>
									</div>
								</>
							)) :
							<>
								<div className="px-3 py-3 card-item align-items-center">
									<div className="col-md-12">
										No Announcement Yet.
									</div>
								</div>
							</>
						}
					</div>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};

export default connect(mapStateToProps)(Dashboard);
