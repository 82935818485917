import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import swal from "sweetalert";
import { api } from "../../../utils/api";

function NewAnnouncement(props) {
    const history = useHistory(); 
    const requiredField = ["title", "description"];
    const [addFormData, setAddFormData] = useState({
        title: '',
        description: '',
    });
    const [error, setError] = useState({
        title: '',
        description: '',
    });
    const [loading, setLoading] = useState(false);

    const createAnnouncement = () => {
        let check = true;
        let errorMsg = { title: null, description: null};
        requiredField.map((item, i)=>{
            if(!addFormData[item]){
                check = false;
                errorMsg[item] = "This field is required"
            }
        });

        setError(errorMsg);

        if(check){
            setLoading(true);
            const postData = {
                title: addFormData.title,
                description: addFormData.description,
            };
            fetch(api.actionAnnouncement, {
                method:"POST",
                headers:new Headers({
                    "Content-Type":"application/JSON",
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(postData)
            })
            .then(response => response.json())
            .then(responseJson => {
                setLoading(false);
                if(responseJson.status === 'success'){
                    swal('Good job!', responseJson.message, "success");
                    history.push('/announcement/list');
                }else{
                    swal(responseJson.message, {
                        icon: "error",
                    });
                }
            }).catch(error => {
                console.log("Error",error);
            });
        }
    }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...addFormData };
        newFormData[fieldName] = fieldValue;
        setAddFormData(newFormData);
    };

    return (
        <>
            <div className="col-12">
                <div className="row page-titles mx-0 shadow">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active">
                            <span>Announcement</span>
                        </li>
                        <li className="breadcrumb-item">
                            <span>Create</span>
                        </li>
                    </ol>
                </div>

                <div className="card shadow">
                    <div className="card-body">
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                Title
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Title"
                                    name="title"
                                    onChange={handleAddFormChange}
                                    value={addFormData.title}
                                />
                                <span className="error">{error.title}</span>
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                Description
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Description"
                                    name="description"
                                    onChange={handleAddFormChange}
                                    value={addFormData.description}
                                />
                                <span className="error">{error.description}</span>
                            </div>
                        </div>                        
                    </div>
                    <div className="card-footer">
                        <button className="btn btn-primary profile-button btn-sm text-white me-2" onClick={createAnnouncement}>
                            {loading ? (<i class="fas fa-spinner fa-spin"></i>) : "Submit"}
                        </button>
                        <Link className="btn btn-danger profile-button btn-sm text-white" to="/announcement/list">Discard</Link>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(NewAnnouncement);
