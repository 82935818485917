import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { api, url } from "../../../utils/api";
import moment from "moment";
import { Modal, Button } from "react-bootstrap";
import SortingTable from "../../components/SortingTable";
import { urlParamsLocalStorage, withdrawMethodList } from "../../../utils/function";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const WithdrawList = (props) => {
    const COLUMNS = [
        {
            Header: 'No.',
            accessor: (row, i) => {
                return ++i + "."
            },
        },
        {
            Header: 'Receipt / Inv No.',
            accessor: (row, i) => {
                return row.invoiceNumber ?? "-"
            },
            Cell: (props) => {
                let data = props.row.original;
                return (
                    <>
                        {/* {
                            (data.status === 'active' || data.status === 'completed') && data.method === 'gold' && data.type !== 'ecash' ? (
                                <a className="blue-hover" href={url + data.path} target="_blank">{props.cell.value}</a>
                            ) : <></>
                        }

                        {
                            (data.status === 'active' || data.status === 'completed') && data.method === 'bankIn' && data.type !== 'ecash' ? (
                                <a className="btn shadow btn-xs btn-gold text-white me-2" onClick={() => handleShowReceiptModal(data.id, data.slipAttachments)}>
                                    Receipt
                                </a>
                            ) : <></>
                        } */}
                        {
                            data.status === 'active' || data.status === 'completed' ?
                                data.method === 'gold' && data.type !== 'ecash' ? (<>
                                    <a className="blue-hover" href={url + data.path} target="_blank">{props.cell.value}</a>
                                </>) : data.method === 'bankIn' && data.type !== 'ecash' ? (<>
                                    <a className="btn shadow btn-xs btn-gold text-white me-2" onClick={() => handleShowReceiptModal(data.id, data.slipAttachments)}>
                                        Receipt
                                    </a>
                                </>) : "-" : "-"
                        }
                    </>
                )
            }
        },
        {
            Header: 'Datetime',
            accessor: (row) => {
                return moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss')
            },
            Cell: (props) => {
                return (<span className="text-nowrap">{props.cell.value}</span>)
            }
        },
        {
            Header: 'Member Id',
            accessor: (row) => {
                return row.user.memberId
            }
        },
        {
            Header: 'Withdraw No',
            accessor: (row) => {
                return row.withdrawNo
            }
        },
        {
            Header: 'Method',
            accessor: (row) => {
                return (withdrawMethodList(row.type, row.method))
            },
            Cell: (props) => {
                return (<span className="text-nowrap">{props.cell.value}</span>)
            }
        },
        {
            Header: 'Amount',
            accessor: (row) => {
                return (row.amount ?? 0) + (row.method === 'points' ? " points" : " g");
            },
            Cell: (props) => {
                return (<span className="text-nowrap">{props.cell.value}</span>)
            }
        },
        {
            Header: 'Tracking Code',
            accessor: (row) => {
                return (
                    row.method === 'gold' ? row.trackingCode : "-"
                )
            }
        },
        {
            Header: 'Delivery Method',
            accessor: (row) => {
                return (
                    row.method === 'bankIn' || row.method === 'points' ? '-' :
                        (row.method !== 'bankIn' || row.method !== 'points') && row.delivery === 'delivery' ? 'Delivery' : 'Self Collect'
                )
            }
        },
        {
            Header: 'Self-Collect Location',
            accessor: (row) => {
                return row.branch ? row.branch.name : "-"
            }
        },
        {
            Header: 'Delivery Status',
            accessor: (row) => {
                return (
                    row.method === 'bankIn' || row.method === 'points' ? '-' :
                        (row.method !== 'bankIn' || row.method !== 'points') && row.deliveryStatus === 'pending' ? 'Pending' : 'Completed'
                )
            },
            Cell: (props) => {
                return (<span className={`text-capitalize status-box ${props.cell.value.toLowerCase()}`}>{props.cell.value}</span>)
            }
        },
        {
            Header: 'Status',
            accessor: (row) => {
                return row.status
            },
            Cell: (props) => {
                return <span className={`text-capitalize status-box ${props.cell.value}`}>{props.cell.value}</span>;
            }
        },
        {
            Header: 'Action',
            accessor: (row) => {
                return (
                    <>
                        <Link to={"/withdraw/details/" + row.id} onClick={() => storeParams()} className="text-primary">
                            <i className="fas fa-eye"></i>
                        </Link>
                    </>
                )
            },
        },
    ];
    const [loading, setLoading] = useState(false);
    const [contents, setContents] = useState([]);
    const [filterOption, setFilterOption] = useState("all");
    const history = useHistory();
    const [allData, setAllData] = useState([]);
    const [showReceiptModal, setShowReceiptModal] = useState(false);
    const [error, setError] = useState([]);
    const [displayReceipts, setDisplayReceipt] = useState({
        id: null,
        receipts: [],
    })
    const [dateRange, setDateRange] = useState({
        startDate: moment().startOf('month'),
        endDate: moment().endOf('month'),
    });
    const [fileData, setFileData] = useState({
        name: "Gold_Withdraw_List",
        excelData: [],
        hasCustom: false,

    })
    const [searchText, setSearchText] = useState("");
    const [currentPageNumber, setCurrentPageNumber] = useState(0);

    useEffect(() => {
        if (allData.length > 0) {
            if (filterOption === 'all') {
                setContents(allData);
            } else {
                let list = allData.filter(arr => arr.status === filterOption);
                setContents(list);
                setFileData({
                    ...fileData,
                    excelData: list,
                    hasCustom: false,
                    customRows: [],
                });
            }
        }
    }, [filterOption]);

    useEffect(() => {
        let urlParams = urlParamsLocalStorage('get', {path: history.location.pathname, name: "withdrawList"});
        if (urlParams) {
            getList(urlParams.query.startDate, urlParams.query.endDate);
            setDateRange({
                startDate: moment(urlParams.query.startDate),
                endDate: moment(urlParams.query.endDate),
            })
            setSearchText(urlParams.query.search);
            setCurrentPageNumber(urlParams.query.tablePageNumber);
        } else {
            getList(dateRange.startDate, dateRange.endDate)
        }
    }, [props.auth]);

    const getList = (start, end) => {
        setLoading(true);
        fetch(api.getWithdraw + `?start=${moment(start).format('YYYY-MM-DD 00:00:00')}&end=${moment(end).format('YYYY-MM-DD 23:59:59')}`, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        }).then(response => response.json())
            .then(responseJson => {
                setLoading(false);
                if (responseJson.data) {
                    let filter = new URLSearchParams(props.location.search).get('filter');
                    if (filter) {
                        responseJson.data = responseJson.data.filter(w => w.status === filter);
                        console.log(responseJson.data);
                    }
                    setAllData(responseJson.data);
                    setContents(responseJson.data);
                    let excelTable = responseJson.data.map((i, index) => {
                        return {
                            "No.": index + 1,
                            "Ref no.": i.withdrawNo,
                            "Name": i.user.fullName,
                            "MemberId": i.user.memberId,
                            "Method": withdrawMethodList(i.type, i.method),
                            "Amount": i.amount ?? 0 + "g",
                            "Tracking Code": i.method === 'gold' ? i.trackingCode ?? "-" : "-",
                            "Delivery Method": i.method === 'bankIn' || i.method === 'points' ? '-' : (i.method !== 'bankIn' || i.method !== 'points') && i.delivery === 'delivery' ? 'Delivery' : 'Self Collect',
                            "Self Collect Location": i.branch ? i.branch.name : "-",
                            "Delivery Status": i.deliveryStatus,
                            "Requested Datetime": moment(i.createdAt).format('YYYY-MM-DD HH:mm:ss'),
                            "Status": i.status,
                        }
                    })
                    setFileData({
                        ...fileData,
                        excelData: excelTable,
                        hasCustom: false,
                        customRows: [],
                    });
                }
            }).catch(error => {
                console.log("Error", error);
            });
    }

    const getSearchText = (e) => {
        setSearchText(e)
    }

    const getTablePageNumber = (e) => {
        setCurrentPageNumber(e);
    }

    const storeParams = () => {
        let urlData = {
            path: history.location.pathname,
            name: "withdrawList",
            query: {
                startDate: dateRange.startDate,
                endDate: dateRange.endDate,
                search: searchText,
                deliveryStatus: filterOption,
                tablePageNumber: currentPageNumber,
            }
        };
        urlParamsLocalStorage('set', urlData);
    }

    const handleShowReceiptModal = (id, slipAttachments) => {
        setShowReceiptModal(true);
        let counter = 0;
        let attachments = JSON.parse(slipAttachments);
        setDisplayReceipt({
            ...displayReceipts,
            id: id,
            receipts: attachments,
        })
        // const map1 = attachments.map((x) => x);
        console.log("attachments: ", attachments);

    }

    const handleClose = () => {
        setShowReceiptModal(false);
    };

    function onDateChange(e, name) {
        console.log(e);
        let temp = { ...dateRange };
        temp[name] = e;
        getList(temp.startDate, temp.endDate);
        setDateRange(temp);
    }

    return (
        <>
            <div className="col-12">
                <div className="row page-titles mx-0 shadow">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active">
                            <span>Withdrawal</span>
                        </li>
                        <li className="breadcrumb-item">
                            <span>List</span>
                        </li>
                    </ol>
                </div>
                <div className="card shadow">
                    <div className="card-body">
                        <div className="row align-items-center mb-3">
                            <div className="col-md-2">
                                <span className="text-nowrap">Filter by status:</span>
                            </div>
                            <div className="col-md-10">
                                <button className={`${filterOption === 'all' ? "btn-primary btn btn-sm me-2" : "btn-outline-primary btn btn-sm me-2"}`} onClick={() => setFilterOption('all')}>All</button>
                                <button className={`${filterOption === 'pending' ? "btn-primary btn btn-sm me-2" : "btn-outline-primary btn btn-sm me-2"}`} onClick={() => setFilterOption('pending')}>Pending</button>
                                <button className={`${filterOption === 'active' ? "btn-primary btn btn-sm me-2" : "btn-outline-primary btn btn-sm me-2"}`} onClick={() => setFilterOption('active')}>Active</button>
                                <button className={`${filterOption === 'rejected' ? "btn-primary btn btn-sm me-2" : "btn-outline-primary btn btn-sm me-2"}`} onClick={() => setFilterOption('rejected')}>Rejected</button>
                                <button className={`${filterOption === 'completed' ? "btn-primary btn btn-sm me-2" : "btn-outline-primary btn btn-sm me-2"}`} onClick={() => setFilterOption('completed')}>Completed</button>
                            </div>
                        </div>
                        <div className="row align-items-center mb-3">
                            <div className="col-md-2">
                                <span className="text-nowrap">Filter by date:</span>
                            </div>
                            <div className="col-md-2">
                                <div className="form-control date-picker-container">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            autoOk
                                            disablePast={false}
                                            label=""
                                            clearable={false}
                                            format="dd/MM/yyyy"
                                            value={dateRange.startDate}
                                            onChange={(e) => onDateChange(e, 'startDate')}
                                            className="form-control"
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <span className="error">{error.startDate}</span>
                            </div>
                            <div className="col-md-2">
                                <div className="form-control date-picker-container">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            autoOk
                                            disablePast={false}
                                            label=""
                                            clearable={false}
                                            format="dd/MM/yyyy"
                                            value={dateRange.endDate}
                                            onChange={(e) => onDateChange(e, 'endDate')}
                                            className="form-control"
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <span className="error">{error.endDate}</span>
                            </div>
                        </div>
                        {loading ? (
                            <div className="text-center">
                                <i className="fas fa-spinner fa-pulse fa-3x"></i>
                            </div>
                        ) : (
                            <div className="w-100">
                                <div id="job_data" className="dataTables_wrapper">
                                    <SortingTable columns={COLUMNS} data={contents} excelExport={true} fileData={fileData} setSearchText={getSearchText} searchText={searchText} setPageNumber={getTablePageNumber} currentPageNumber={currentPageNumber}/>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Modal size="lg" show={showReceiptModal} onHide={handleShowReceiptModal} centered>
                <Modal.Header>
                    <Modal.Title className="text-gold">Receipts</Modal.Title>
                    <div className="ms-auto">
                        <i
                            class="fa fa-times cursor-pointer"
                            aria-hidden="true"
                            onClick={handleClose}
                        >
                            {""}
                        </i>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {
                        <div>
                            {
                                displayReceipts.receipts.map((x, index) => (
                                    <>
                                        <label className="text-gold me-2">{++index}.<a className="text-gold me-2" href={url + `/payment/${x}`} target="_blank"> {x}</a></label>
                                    </>
                                ))
                            }
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="btn btn-danger profile-button btn-sm text-white me-2"
                        onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};


export default connect(mapStateToProps)(WithdrawList);
