import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { connect } from 'react-redux';
import Select, { createFilter } from "react-select";
import swal from "sweetalert";
import { api } from "../../../utils/api";

const periodOption = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10", value: "10" },
    { label: "11", value: "11" },
    { label: "12", value: "12" },
    // { label: "13", value: "13" },
    // { label: "14", value: "14" },
    // { label: "15", value: "15" },
    // { label: "16", value: "16" },
    // { label: "17", value: "17" },
    // { label: "18", value: "18" },
    // { label: "19", value: "19" },
    // { label: "20", value: "20" },
    // { label: "21", value: "21" },
    // { label: "22", value: "22" },
    // { label: "23", value: "23" },
    // { label: "24", value: "24" },
]
function EditDigitalContract(props) {
    const history = useHistory(); 
    const requiredField = ["rate", "period"];
    const [addFormData, setAddFormData] = useState({
        rate: '',
        period: '',
        branchId: '',
        name: '',
    });
    const [error, setError] = useState({
        rate: '',
        period: '',
        branchId: '',
        name: '',
    });
    const [loading, setLoading] = useState(false);
    const [branch, setBranch] = useState(null);
    const { id, contractId } = useParams();

    useEffect(() => {
        getContract();
    }, [props.auth]);

    const getContract = () => {
        setLoading(true);
        fetch(api.contracts + "/?id=" + id, {
            method:"GET",
            headers:new Headers({
                "Content-Type":"application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        }).then(response => response.json())
        .then(responseJson => {
            setLoading(false);
            console.log("getContract",responseJson); 
            if(responseJson.status === 'success'){
                if(responseJson.data){
                    let data = {
                        rate: responseJson.data[0].rate,
                        period: responseJson.data[0].period.toString(),
                        name: responseJson.data[0].name,
                    }
                    setAddFormData(data);
                }
            }
            
        }).catch(error => { 
                console.log("Error",error);
        });
    }

    const updateContract = () => {
        let check = true;
        let errorMsg = { 
            rate: '',
            period: '',
            branchId: '',
            name: '',
        };
        requiredField.map((item, i)=>{
            if(!addFormData[item]){
                check = false;
                errorMsg[item] = "This field is required"
            }
        });

        setError(errorMsg);
        if(check){
            setLoading(true);
            const postData = {
                rate: addFormData.rate,
                period: addFormData.period,
                type: 'digital',
            };
            fetch(api.contracts + "/" + contractId, {
                method:"PUT",
                headers:new Headers({
                    "Content-Type":"application/JSON",
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(postData)
            })
            .then(response => response.json())
            .then(responseJson => {
                setLoading(false);
                if(responseJson.status === 'success'){
                    swal('Good job!', "Digital program update successful.", "success");
                    postData.
                    history.push('/contract/digital');
                }else{
                    swal(responseJson.message, {
                        icon: "error",
                    });
                }
            }).catch(error => {
                console.log("Error",error);
            });
        }
    }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...addFormData };
        newFormData[fieldName] = fieldValue;
        setAddFormData(newFormData);
    };

    const handleOptionSelected = (e, name) => {
        const { label, value } = e;
        const info = {...addFormData};
        info[name] = value;
        setAddFormData(info);
    }

    return (
        <>
            <div className="col-12">
                <div className="row page-titles mx-0 shadow">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active">
                            <span>Program</span>
                        </li>
                        <li className="breadcrumb-item">
                            <span>Edit digital contract</span>
                        </li>
                    </ol>
                </div>

                <div className="card shadow">
                    <div className="card-body">
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                Period (Month)
                            </label>
                            <div className="col-sm-10">
                                <Select
                                    value = {
                                        periodOption.filter(option => option.value === addFormData.period)
                                    }
                                    className="form-control"
                                    options={periodOption}
                                    placeholder="- Select -"
                                    style={{
                                        lineHeight: "40px",
                                        color: "#7e7e7e",
                                        paddingLeft: " 15px",
                                        borderWidth: 0,
                                    }}
                                    onChange={e=> handleOptionSelected(e, 'period')}
                                />
                                <span className="error">{error.period}</span>
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                Investment Percentage (%)
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="number"
                                    className="form-control form-control"
                                    placeholder=""
                                    name="rate"
                                    onChange={handleAddFormChange}
                                    value={addFormData.rate}
                                />
                                <span className="error">{error.rate}</span>
                            </div>
                        </div>
                    </div>
                        
                    <div className="card-footer">
                        <button className="btn btn-primary profile-button btn-sm text-white me-2" disabled={loading} onClick={updateContract}>
                            {loading ? (<i className="fas fa-spinner fa-spin"></i>) : "Update"}
                        </button>
                        <Link className="btn btn-danger profile-button btn-sm text-white" to={'/contract/digital'}>Discard</Link>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(EditDigitalContract);
