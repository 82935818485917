import React, { useContext } from "react";

/// React router dom
import { Switch, Route } from "react-router-dom";
import { connect } from 'react-redux';

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
/// Dashboard

/// Pages
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import Setting from "./layouts/Setting";
import { ThemeContext } from "../context/ThemeContext";

//Admin
import AdminList from "./pages/Admin/AdminList";
import NewAdmin from "./pages/Admin/NewAdmin";
import AdminDetails from "./pages/Admin/AdminDetails";

//User
import UserList from "./pages/User/UserList";
import UserDetail from "./pages/User/UserDetail";
// import UserUpdate from "./pages/User/UserUpdate";

//Setting
import GoldPrice from "./pages/Setting/GoldPrice";

//Home
import Dashboard from "./pages/Dashboard";
import BranchList from "./pages/Branch/BranchList";
import NewBranch from "./pages/Branch/NewBranch";
import NewContract from "./pages/Contract/NewContract";
import ContractList from "./pages/Contract/ContractList";
import EditBranch from "./pages/Branch/EditBranch";
import EditContract from "./pages/Contract/EditContract";
import OutletAdminList from "./pages/OutletAdmin/OutletAdminList";
import NewOutletAdmin from "./pages/OutletAdmin/NewOutletAdmin";
import EditOutletAdmin from "./pages/OutletAdmin/EditOutletAdmin";
import AddContractToBranch from "./pages/Branch/AddContractToBranch";
import WithdrawList from "./pages/Withdraw/WithdrawList";
import WithdrawDetails from "./pages/Withdraw/WithdrawDetails";
import Account from "./pages/Account/Account";
import InvestmentList from "./pages/Investment/InvestmentList";
import InvestmentDetails from "./pages/Investment/InvestmentDetails";
import BankList from "./pages/Bank/BankList";
import GoldCustom from "./pages/Setting/GoldCustom";
import GoldStandards from "./pages/Setting/GoldStandards";
import GoldBuyBack from "./pages/Setting/GoldBuyBack";
import AnnouncementList from "./pages/Announcement/AnnouncementList";
import NewAnnouncement from "./pages/Announcement/NewAnnouncement";
import PurchaseSetting from "./pages/Setting/PurchaseSetting";
import PurchaseList from "./pages/Purchase/PurchaseList";
import DigitalInvestment from "./pages/Investment/DigitalInvestment";
import PurchaseInfo from "./pages/Purchase/PurchaseInfo";
import DigitalContractList from "./pages/Contract/DigitalContractList";
import NewDigitalContract from "./pages/Contract/NewDigitalContract";
import EditDigitalContract from "./pages/Contract/EditDigitalContract";
import GoldPremium from "./pages/Setting/GoldPremium";
import GiftDetails from "./pages/Gift/GiftDetails";
import GiftList from "./pages/Gift/GiftList";
import TermList from "./pages/Setting/TermList";
import AddUser from "./pages/User/AddUser";
import AddUserPurchase from "./pages/User/AddUserPurchase";
import ShopSettings from "./pages/Setting/ShopSettings";
import RestockList from "./pages/Purchase/RestockList";
import InventoryList from "./pages/Inventory/List";
import InventoryInfo from "./pages/Inventory/InventoryInfo";
import TransactionList from "./pages/Transaction/TransactionList";
import HierarchyList from "./pages/Hierarchy/HierarchyList";
import { isAuthenticated } from "../store/selectors/AuthSelectors";
const Markup = (props) => {
  const { menuToggle } = useContext(ThemeContext);
  const routes = [
    //Hierarchy 
    // { url: "hierarchy/list", component: HierarchyList },
    //Transaction
    { url: "transaction/list", component: TransactionList },
    //Inventory
    // { url: "inventory/list", component: InventoryList },
    // { url: "inventory/details/:id", component: InventoryInfo },
    /// Dashboard
    { url: "", component: Dashboard },
    { url: "dashboard", component: Dashboard },
    { url: "user/list", component: UserList },
    { url: "user/detail/:id", component: UserDetail },
    { url: "user/new", component: AddUser },
    // { url: "user/investment/:id", component: AddUserPurchase },
    // { url: "user/update", component: UserUpdate },
    // { url: "gold/price", component: GoldPrice },
    // { url: "gold/premium", component: GoldPremium },
    // { url: "gold/custom", component: GoldCustom },
    // { url: "gold/buyback", component: GoldBuyBack },
    // { url: "gold/standards", component: GoldStandards },
    { url: "branch/list", component: BranchList },
    { url: "branch/create", component: NewBranch },
    { url: "branch/edit/:id", component: EditBranch },
    { url: "branch/:id/contract", component: AddContractToBranch },

    // { url: "branch/:id/contract/new", component: NewContract },
    // { url: "branch/:id/contract/list", component: ContractList },
    // { url: "branch/:id/contract/edit/:contractId", component: EditContract },
    // { url: "branch/:id/contract/digital/edit/:contractId", component: EditDigitalContract },
    // { url: "branch/:id/contract/digital", component: DigitalContractList },
    // { url: "branch/:id/contract/digital/create", component: NewDigitalContract },
    // { url: "contract/list", component: ContractList },
    // { url: "contract/create", component: NewContract },

    // { url: "outlet-admin/list", component: OutletAdminList },
    // { url: "outlet-admin/create", component: NewOutletAdmin },
    // { url: "outlet-admin/edit/:id", component: EditOutletAdmin },

    // { url: "withdraw/list", component: WithdrawList },
    // { url: "withdraw/details/:id", component: WithdrawDetails },

    { url: "account", component: Account },

    // { url: "investment/list", component: InvestmentList },
    // { url: "investment/details/:id", component: InvestmentDetails },
    // { url: "investment/digital", component: DigitalInvestment },

    { url: "bank/list", component: BankList },

    { url: "announcement/list", component: AnnouncementList },
    { url: "announcement/create", component: NewAnnouncement },

    { url: "purchase/setting", component: PurchaseSetting },
    { url: "purchase/list", component: PurchaseList },
    { url: "purchase/details/:id", component: PurchaseInfo },

    // { url: "restock/list", component: RestockList },


    // { url: "gift/list", component: GiftList },
    // { url: "gift/details/:id", component: GiftDetails },

    // { url: "terms/list", component: TermList },
    // { url: "shop/settings", component: ShopSettings },
  ];

  const rootRoute = [
    { url: "admin/list", component: AdminList },
    { url: "admin/create", component: NewAdmin },
    { url: "admin/details", component: AdminDetails },

    { url: "bank/list", component: BankList },
    { url: "purchase/setting", component: PurchaseSetting },
    { url: "purchase/list", component: PurchaseList },
  ]

  const outletAdminRoute = [
    { url: "gift/list", component: GiftList },
    { url: "gift/details/:id", component: GiftDetails },
    { url: "purchase/list", component: PurchaseList },
    { url: "purchase/details/:id", component: PurchaseInfo },
    { url: "withdraw/list", component: WithdrawList },
    { url: "withdraw/details/:id", component: WithdrawDetails },
    { url: "withdraw/list", component: WithdrawList },
    { url: "withdraw/details/:id", component: WithdrawDetails },
    { url: "investment/list", component: InvestmentList },
    { url: "investment/details/:id", component: InvestmentDetails },
    { url: "investment/digital", component: DigitalInvestment },
    { url: "user/list", component: UserList },
    { url: "user/detail/:id", component: UserDetail },
    { url: "user/new", component: AddUser },
    { url: "user/investment/:id", component: AddUserPurchase },

  ]

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  return (
    <>
      {props.isAuthenticate ?
        <div
          id={`${!pagePath ? "main-wrapper" : ""}`}
          className={`${!pagePath ? "show" : "vh-100"}  ${menuToggle ? "menu-toggle" : ""
            }`}
        >
          {!pagePath && <Nav />}

          <div className={`${!pagePath ? "content-body" : ""}`}>
            <div
              className={`${!pagePath ? "container-fluid" : ""}`}
              style={{ minHeight: window.screen.height - 60 }}
            >
              <Switch>
                {props.auth.auth.username === 'admin' ? (
                  rootRoute.map((data, i) => (
                    <Route
                      key={i}
                      exact
                      path={`/${data.url}`}
                      component={data.component}
                    />
                  ))
                ) : null}

                {props.auth.auth.branchId > 0 ? (
                  outletAdminRoute.map((data, i) => (
                    <Route
                      key={i}
                      exact
                      path={`/${data.url}`}
                      component={data.component}
                    />
                  ))
                ) : routes.map((data, i) => (
                  <Route
                    key={i}
                    exact
                    path={`/${data.url}`}
                    component={data.component}
                  />
                ))}


                <Route component={Error404} />
              </Switch>
            </div>
          </div>
          {!pagePath && <Footer />}
        </div>
        : routes.map((data, i) => (
          <Route
            key={i}
            exact
            path={`/${data.url}`}
            component={data.component}
          />
        ))
      }
      {/* <Setting /> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    isAuthenticate: isAuthenticated(state),
  };
};


export default connect(mapStateToProps)(Markup);
