import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import { connect } from 'react-redux';
import {  api } from "../../../utils/api";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import SortingTable from "../../components/SortingTable";

const AnnouncementList = (props) => {
    const COLUMNS = [
        {
            Header : 'No.',
            accessor: (row, i) => {
                return i+1;
             }
        },
        {
            Header : 'Title',
            accessor: 'title'

        },
        {
            Header : 'Description',
            accessor: 'description'
        },
        {
            Header : 'Created Datetime',
            accessor: (row) => {
               return moment(row.createdAt).format('YYYY-MM-DD HH:MM:ss')
            }
        },
        {
            Header : 'Action',
            accessor: (row) => {
                return (
                    <>
                        <Link className="btn btn-danger shadow btn-xs sharp me-2" onClick={() => deleteAnnouncement(row.id)} disable={actionLoading}>
                            {actionLoading ? (<i class="fas fa-spinner fa-spin"></i>) : <i className="fas fa-trash"></i>}
                        </Link>
                    </>
                )
            }
        },
    ];

    const [actionLoading, setActionLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [contents, setContents] = useState([]);

    useEffect(() => {
        getList();
    }, [props.auth]);

    const getList = () => {
        setLoading(true);
        fetch(api.getAnnouncements, {
            method:"GET",
            headers:new Headers({
                "Content-Type":"application/JSON",
            }),
        }).then(response => response.json())
        .then(responseJson => {
            setLoading(false);
            if(responseJson.data){
                setContents(responseJson.data);
            }
            
        }).catch(error => { 
            console.log("Error",error);
        });
    }

    const deleteAnnouncement = (id) => {
        swal({
            title: "Delete data?",
            text:
              "Once deleted, you will not be able to recover this data anymore!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                setActionLoading(true);
                fetch(api.actionAnnouncement + `/${id}/remove`, {
                    method:"PUT",
                    headers:new Headers({
                        "Content-Type":"application/JSON",
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                }).then(response => response.json())
                .then(responseJson => {
                    setActionLoading(false);
                    if(responseJson.status === 'success'){
                        swal(responseJson.message, {
                            icon: "success",
                        });
                        getList();
                    }else{
                        swal(responseJson.message, {
                            icon: "error",
                        });
                    }
                }).catch(error => {
                    console.log("Error",error);
                });
            } 
        })
    }

    return (
        <>
            <div className="row page-titles mx-0 shadow">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                        <span>Announcement</span>
                    </li>
                    <li className="breadcrumb-item">
                        <span>List</span>
                    </li>
                </ol>
            </div>
            <div className="card shadow card-black">
                <div className="card-header">
                    <Link className="btn btn-outline-primary btn-sm pull-right" to="/announcement/create">Create New</Link>
                </div>
                <div className="card-body">
                    {loading ? (
                    <div className="text-center">
                        <i className="fas fa-spinner fa-pulse fa-3x"></i>
                    </div>
                    ) : (
                    <div className="">
                        <div className="dataTables_wrapper" id="table_data">
                            <SortingTable columns={COLUMNS} data={contents} />
                        </div>
                    </div>
                    )}
                </div>
            </div>
        </>
   );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(AnnouncementList);
