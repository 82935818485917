import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import Select, { createFilter } from "react-select";
import swal from "sweetalert";
import { api } from "../../../utils/api";
import { logout, updateLocalStorage } from '../../../store/actions/AuthActions';

function Account(props) {
    const history = useHistory(); 
    const dispatch = useDispatch();
    const [detailsFormData, setDetailsFormData] = useState({
        firstName: props.auth.auth.firstName,
        lastName: props.auth.auth.lastName,
        name: '',
        address: '',
        email: '',
        phone: '',
        maxInvestment: '',
        oldPassword: '',
        password: '',
        confirmPassword: '',
    });
    // const [passwordFormData, setPasswordFormData] = useState({
    //     oldPassword: '',
    //     password: '',
    //     confirmPassword: '',
    // })
    const [error, setError] = useState({
        firstName: '',
        lastName: '',
        name: '',
        address: '',
        email: '',
        phone: '',
        maxInvestment: '',
        oldPassword: '',
        password: '',
        confirmPassword: '',
    });
    const [loading, setLoading] = useState(false);
    const [passwordLoading, setPasswordLoading] = useState(false);
    const [branchLoading, setBranchLoading] = useState(false);

    const handleDetailsFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...detailsFormData };
        newFormData[fieldName] = fieldValue;
        if (fieldName === 'confirmPassword' || fieldName === 'password') {
            if (newFormData['password'] !== newFormData['confirmPassword']) {
                let err = {...error};
                err.password = 'Password not match'; 
                err.confirmPassword = 'Password not match'; 
                setError(err)
            } else if (newFormData['password'].length < 6) {
                let err = {...error};
                err.password = 'Password too short'; 
                setError(err)
            } else {
                let err = {...error};
                err.password = ''; 
                err.confirmPassword = ''; 
                setError(err)
            }
            setDetailsFormData(newFormData);
        } else {
            setDetailsFormData(newFormData);
        }
    };

    const updateAccount = () => {
        setLoading(true);
        let payload = {
            firstName: detailsFormData.firstName, 
            lastName: detailsFormData.lastName
        };
        fetch(api.users + "/", {
            method:"PUT",
            headers:new Headers({
                "Content-Type":"application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
            body: JSON.stringify(payload)
        })
        .then(response => response.json())
        .then(responseJson => {
            setLoading(false);
            if(responseJson.status === 'success'){
                // dispatch(updateLocalStorage(payload));
                swal('Success!', "Account updated successful.", "success");

                let data = {...props.auth};
               
                data.auth.firstName =  detailsFormData.firstName;
                data.auth.lastName =  detailsFormData.lastName;
            
                dispatch({
                    type: "UPDATE_PROFILE",
                    auth: data.auth,
                });
                let localstorageInfo = JSON.parse(localStorage.getItem('admin_gold'));
                localstorageInfo.data.firstName = detailsFormData.firstName;
                localstorageInfo.data.lastName = detailsFormData.lastName;
                localStorage.setItem('admin_gold', JSON.stringify(localstorageInfo));
                history.push('/account');
            }else{
                swal(responseJson.message, {
                    icon: "error",
                });
            }
        }).catch(error => {
            console.log("Error",error);
        });
    }

    const updatePassword = () => {
        setPasswordLoading(true);
        let payload = {
            oldPassword: detailsFormData.oldPassword,
            confirmPassword: detailsFormData.confirmPassword, 
            password: detailsFormData.password
        };
        fetch(api.users + "/resetPassword", {
            method:"PUT",
            headers:new Headers({
                "Content-Type":"application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
            body: JSON.stringify(payload)
        })
        .then(response => response.json())
        .then(responseJson => {
            setPasswordLoading(false);
            if(responseJson.status === 'success'){
                swal('Success!', "Password updated successful. Please relogin.", "success");
                dispatch(logout(props.history))
            }else{
                swal(responseJson.message, {
                    icon: "error",
                });
            }
        }).catch(error => {
            console.log("Error",error);
        });
    }

    const updateBranch = () => {
        let payload = {...detailsFormData};
        delete payload.firstName;
        delete payload.lastName;
        delete payload.oldPassword;
        delete payload.password;
        delete payload.confirmPassword;

        setBranchLoading(true);
        fetch(api.branch + "/" + props.auth.auth.branchId, {
            method:"PUT",
            headers:new Headers({
                "Content-Type":"application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
            body: JSON.stringify(payload)
        })
        .then(response => response.json())
        .then(responseJson => {
            setBranchLoading(false);
            if(responseJson.status === 'success'){
                swal('Success!', "Branch updated successful.", "success");
                history.push('/account');
            }else{
                swal(responseJson.message, {
                    icon: "error",
                });
            }
        }).catch(error => {
            console.log("Error",error);
        });
    }

    const getBranchDetails = () => {
        setLoading(true);
        fetch(api.branch + '?id='+props.auth.auth.branchId , {
            method:"GET",
            headers:new Headers({
                "Content-Type":"application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            })
        }).then(response => response.json())
        .then(responseJson => {
            setLoading(false);
            console.log(responseJson) 
            if(responseJson.data){
                let temp = {...detailsFormData};
                Object.assign(temp, responseJson.data[0]);
                setDetailsFormData(temp);
            }
            
        }).catch(error => { 
            console.log("Error",error);
        });
    }

    useEffect(() => {
        if (!props.auth.auth.isMaster) {
            getBranchDetails();
        }
    }, [props.auth])

    return (
        <>
            <div className="col-12">
                <div className="row page-titles mx-0 shadow">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active">
                            <span>Account</span>
                        </li>
                        <li className="breadcrumb-item">
                            <span>Edit</span>
                        </li>
                    </ol>
                </div>
                <div className="card shadow">
                    <div className="card-body">
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                First Name
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control form-control"
                                    placeholder=""
                                    name="firstName"
                                    onChange={handleDetailsFormChange}
                                    value={detailsFormData.firstName}
                                />
                                <span className="error">{error.firstName}</span>
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                Last Name
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control form-control"
                                    placeholder=""
                                    name="lastName"
                                    onChange={handleDetailsFormChange}
                                    value={detailsFormData.lastName}
                                />
                                <span className="error">{error.lastName}</span>
                            </div>
                        </div>
                    </div>
                        
                    <div className="card-footer">
                        <button className="btn btn-primary profile-button btn-sm text-white me-2" onClick={updateAccount}>
                            {loading ? (<i className="fas fa-spinner fa-spin"></i>) : "Update"}
                        </button>
                    </div>
                </div>

                <div className="card shadow">
                    <div className="card-body">
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                Old password
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="password"
                                    className="form-control form-control"
                                    placeholder=""
                                    name="oldPassword"
                                    onChange={handleDetailsFormChange}
                                    value={detailsFormData.oldPassword}
                                />
                                <span className="error">{error.oldPassword}</span>
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                Password
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="password"
                                    className="form-control form-control"
                                    placeholder=""
                                    name="password"
                                    onChange={handleDetailsFormChange}
                                    value={detailsFormData.password}
                                />
                                <span className="error">{error.password}</span>
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                Confirm password
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="password"
                                    className="form-control form-control"
                                    placeholder=""
                                    name="confirmPassword"
                                    onChange={handleDetailsFormChange}
                                    value={detailsFormData.confirmPassword}
                                />
                                <span className="error">{error.confirmPassword}</span>
                            </div>
                        </div>
                    </div>
                        
                    <div className="card-footer">
                        <button className="btn btn-primary profile-button btn-sm text-white me-2" onClick={updatePassword}>
                            {passwordLoading ? (<i className="fas fa-spinner fa-spin"></i>) : "Update"}
                        </button>
                    </div>
                </div>

                {!props.auth.auth.isMaster ? (<div className="card shadow">
                    <div className="card-header">
                        <h4>Branch Details</h4>
                    </div>
                    <div className="card-body">
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                Branch Name
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control form-control"
                                    placeholder=""
                                    name="name"
                                    onChange={handleDetailsFormChange}
                                    value={detailsFormData.name}
                                    readOnly
                                />
                                <span className="error">{error.name}</span>
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                Address
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control form-control"
                                    placeholder=""
                                    name="address"
                                    onChange={handleDetailsFormChange}
                                    value={detailsFormData.address}
                                />
                                <span className="error">{error.address}</span>
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                Email
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control form-control"
                                    placeholder=""
                                    name="email"
                                    onChange={handleDetailsFormChange}
                                    value={detailsFormData.email}
                                />
                                <span className="error">{error.email}</span>
                            </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label">
                                Contact No.
                            </label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control form-control"
                                    placeholder=""
                                    name="name"
                                    onChange={handleDetailsFormChange}
                                    value={detailsFormData.phone}
                                />
                                <span className="error">{error.phone}</span>
                            </div>
                        </div>
                    </div>
                    
                    <div className="card-footer">
                        <button className="btn btn-primary profile-button btn-sm text-white me-2" disabled={branchLoading} onClick={updateBranch}>
                            {branchLoading ? (<i className="fas fa-spinner fa-spin"></i>) : "Update"}
                        </button>
                    </div>
                </div>):null}
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(Account);
