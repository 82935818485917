import React, { Fragment, useEffect, useState } from "react";
import {  useHistory, Link, useParams } from "react-router-dom";
import { connect, } from 'react-redux';
import swal from "sweetalert";
import { countryWithCode, formatImagePath, statesWithFee, giftItems2 } from "../../../utils/function";
import { api, url } from "../../../utils/api";
import Select, { createFilter } from 'react-select';

const AddUser = (props) => {
    const history = useHistory();
    const [contents, setContents] = useState({
        fullName: "", 
        username: "",
        email: "",
        identityCard: "",
        phone: "",
        password: "",
        confirmPassword: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        postCode: "",
        country: "Malaysia",
        referralId: "",
        placement: "",
        delivery: "",
        gift: "",
        product: "",
        totalPayment: "",
        type: "gift",
        
    });
    const [statesOptions, setStatesOptions] = useState(statesWithFee);
    const [countryOptions, setCountryOptions] = useState(countryWithCode);
    const [purchaseOptions, setPurchaseOptions] = useState([
        {id: 0, label: 'Gift', value: 'gift', isSelected: true, hidden: false},
        // {id: 1, label: 'FGR', value: 'FGR', isSelected: false, hidden: true},
        // {id: 2, label: 'GSP', value: 'GSP', isSelected: false, hidden: true},
    ])
    const [placementOption, setPlacementOption] = useState([
        { label: 'Far Left', value: 'left' },
        { label: 'Far Right', value: 'right' }
    ]);
    const [deliveryOptions, setDeliveryOptions] = useState([
        {label: 'Self Collect', value: 'selfCollect'}, 
        {label: 'Delivery', value: 'delivery'}
    ]);
    const [isSelfCollect, setIsSelfCollect] = useState(false);
    const [giftsOptions, setGiftsOptions] = useState(giftItems2);
    const [remark, setRemark] = useState("");
    const [loading, setLoading] = useState(false);
    const [action, setAction] = useState(null);
    const [error, setError] = useState({
        fullName: null, email: null, identityCard: null, phone: null, username: null,
        password: null, confirmPassword: null, address1:null, address2: null, city: null, 
        state: null, postCode: null, country: null, 
    });

    // use effect
    useEffect(() => {
    }, [props.auth]);

    const checkForm = () => {
        let check = true;
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        let error = {
            fullName: null, email: null, identityCard: null, phone: null, username: null,
            password: null, confirmPassword: null, address1:null, address2: null, city: null, 
            state: null, postCode: null, country: null, 
        };
        // if(!formData.firstName){
        //     check = false;
        //     error.firstName = "This field is required."
        // }
        // if(!formData.lastName){
        //     check = false;
        //     error.lastName = "This field is required."
        // }
        if(!contents.fullName){
            check = false;
            error.fullName = "This field is required."
        }
        if(!contents.email){
            check = false;
            error.email = "This field is required."
        }
        if(!contents.username){
            check = false;
            error.email = "This field is required."
        }
        if(!contents.identityCard){
            check = false;
            error.identityCard = "This field is required."
        }
        if(!contents.phone){
            check = false;
            error.phone = "This field is required."
        }

        if(!contents.password){
            check = false;
            error.password = "This field is required."
        }

        if(!contents.confirmPassword){
            check = false;
            error.confirmPassword = "This field is required."
        }
        if(contents.password){
            if(contents.password.length <6){
                check = false;
                error.password = "The password need at least 6 characters long."
            }
        }

        if(contents.password && contents.confirmPassword){
            if(contents.password !== contents.confirmPassword){
                check = false;
                error.confirmPassword = "Password and confirmed password are not matched";
            }
        }

        if(!contents.placement){
            check = false;
            error.placement = "This field is required."
        }
        setError(error);
        // setFormData({...formData, referralId: token ?? ""})
        return check;
    }
    
    function resetForm() {
        setContents({
            fullName: "", 
            username: "",
            email: "",
            identityCard: "",
            phone: "",
            password: "",
            confirmPassword: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            postCode: "",
            country: "Malaysia",
            referralId: "",
            placement: "",
            delivery: "",
            gift: "",
            product: "",
            totalPayment: "",
            type: "gift",
        })
    }

    const submitForm = (event) => {
        event.preventDefault();
        let check = checkForm();
        if (check) {
            setLoading(true)
            fetch(api.users + `/users`, {
                method:"POST",
                headers:new Headers({
                    "Content-Type":"application/JSON",
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(contents)
            }).then(response => response.json())
            .then(responseJson => {
                setLoading(false);
                if(responseJson.status === 'success'){
                    swal(responseJson.message, {
                        icon: "success",
                    });
                    // resetForm();
                    history.push('/user/list')
                }else{
                    swal(responseJson.message, {
                        icon: "error",
                    });
                }
            }).catch(error => {
                    console.log("Error",error);
            });
        }
    };

    const onInputChanges = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...contents };
        newFormData[fieldName] = fieldValue;
        setContents(newFormData);
    };

    const handleOptionSelected = (e, name) => {
        const { label, value } = e;
        const info = {...contents};
        info[name] = value;
        if (name === 'type') {
            let temp = purchaseOptions;
            temp = temp.map(item =>{item.value !== e.value ? item.isSelected = false : item.isSelected = true; return item});
            setPurchaseOptions(temp)
        } else if(name === 'gift'){
            let temp = giftsOptions;
            temp.map(item => { item.value !== e.value ? item.isSelected = false : item.isSelected = true; return item });
            info.totalPayment = e.price;
            info.product = e.value;
            setGiftsOptions(temp)
        }else if (name === 'delivery') {
            setIsSelfCollect(e.value === 'selfCollect' ? true : false);
        }
        setContents(info);
    }

    return (
        <>
            <Fragment>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="row page-titles mx-0 shadow">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active">
                                    <span>Members</span>
                                </li>
                                <li className="breadcrumb-item">
                                    <span>Create</span>
                                </li>
                            </ol>
                        </div>
                        <div className="d-flex flex-column">
                            <div className="card shadow">
                                <div className="card-header">
                                    <Link to="/user/list">Back to List</Link>
                                </div>
                                <div className="card-body">
                                    <div className="mb-3 row align-items-center">
                                        <label className="col-sm-2 col-form-label">
                                            Full Name
                                        </label>
                                        <div className="col-sm-10">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                name="fullName"
                                                onChange={(e) => onInputChanges(e)}
                                                value={contents ? contents.fullName : ""} 
                                            />                                    
                                            <span className="error">{error.fullName}</span>
                                        </div>
                                    </div>
                                    <div className="mb-3 row align-items-center">
                                        <label className="col-sm-2 col-form-label">
                                            Username
                                        </label>
                                        <div className="col-sm-10">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                name="username"
                                                onChange={(e) => onInputChanges(e)}
                                                value={contents ? contents.username : ""} 
                                            />
                                            <span className="error">{error.username}</span>
                                        </div>
                                    </div>
                                    <div className="mb-3 row align-items-center">
                                        <label className="col-sm-2 col-form-label">
                                            Email
                                        </label>
                                        <div className="col-sm-10">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                name="email"
                                                onChange={(e) => onInputChanges(e)}
                                                value={contents ? contents.email : ""} 
                                            />
                                            <span className="error">{error.email}</span>
                                        </div>
                                    </div>
                                    <div className="mb-3 row align-items-center">
                                        <label className="col-sm-2 col-form-label">
                                            IC
                                        </label>
                                        <div className="col-sm-10">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                name="identityCard"
                                                onChange={(e) => onInputChanges(e)}
                                                value={contents ? contents.identityCard : ""} 
                                            />
                                            <span className="error">{error.identityCard}</span>
                                        </div>
                                    </div>
                                    <div className="mb-3 row align-items-center">
                                        <label className="col-sm-2 col-form-label">
                                            Purchase Type 
                                        </label>
                                        <div className="col-sm-10">
                                            <Select 
                                                className="form-control"
                                                options={purchaseOptions}
                                                placeholder="- Select -"
                                                style={{
                                                    lineHeight: "40px",
                                                    color: "#7e7e7e",
                                                    paddingLeft: " 15px",
                                                    borderWidth: 0,
                                                }}
                                                value={purchaseOptions.filter(o => o.isSelected)}
                                                onChange={e => handleOptionSelected(e , 'type')}
                                                />
                                        </div>
                                    </div>
                                    
                                    <div className="mb-3 row align-items-center">
                                        <label className="col-sm-2 col-form-label">
                                            Purchase Options
                                        </label>
                                        <div className="col-sm-10">
                                            <Select 
                                                className="form-control"
                                                value={giftsOptions.filter(g => g.isSelected)}
                                                options={giftsOptions}
                                                placeholder="-- Select --"
                                                style={{
                                                    lineHeight: "40px",
                                                    color: "#7e7e7e",
                                                    paddingLeft: " 15px",
                                                    borderWidth: 0,
                                                }}
                                                onChange={e => handleOptionSelected(e,'gift')}
                                                />
                                        </div>
                                    </div>
                                    <div className="mb-3 row align-items-center">
                                        <label className="col-sm-2 col-form-label">
                                            Delivery Options 
                                        </label>
                                        <div className="col-sm-10">
                                            <Select 
                                                className="form-control"
                                                value={deliveryOptions.filter(g => g.value === contents.delivery)}
                                                options={deliveryOptions}
                                                placeholder="-- Select --"
                                                style={{
                                                    lineHeight: "40px",
                                                    color: "#7e7e7e",
                                                    paddingLeft: " 15px",
                                                    borderWidth: 0,
                                                }}
                                                onChange={e => handleOptionSelected(e, 'delivery')}
                                                />
                                        </div>
                                    </div>
                                    <div className="mb-3 row align-items-center">
                                        <label className="col-sm-2 col-form-label">
                                            Phone
                                        </label>
                                        <div className="col-sm-10">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                name="phone"
                                                onChange={(e) => onInputChanges(e)}
                                                value={contents ? contents.phone : ""} 
                                            />
                                            <span className="error">{error.phone}</span>
                                        </div>
                                    </div>
                                    <div className="mb-3 row align-items-center">
                                        <label className="col-sm-2 col-form-label">
                                            Address 1
                                        </label>
                                        <div className="col-sm-10">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                name="address1"
                                                onChange={(e) => onInputChanges(e)}
                                                value={contents ? contents.address1 : ""} 
                                            />
                                            <span className="error">{error.address1}</span>
                                        </div>
                                    </div>
                                    <div className="mb-3 row align-items-center">
                                        <label className="col-sm-2 col-form-label">
                                            Address 2
                                        </label>
                                        <div className="col-sm-10">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                name="address2"
                                                onChange={(e) => onInputChanges(e)}
                                                value={contents ? contents.address2 : ""} 
                                            />
                                            <span className="error">{error.address2}</span>
                                        </div>
                                    </div>
                                    <div className="mb-3 row align-items-center">
                                        <label className="col-sm-2 col-form-label">
                                            City
                                        </label>
                                        <div className="col-sm-10">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                name="city"
                                                onChange={(e) => onInputChanges(e)}
                                                value={contents ? contents.city : ""}
                                            />
                                            <span className="error">{error.city}</span>
                                        </div>
                                    </div>
                                    <div className="mb-3 row align-items-center">
                                        <label className="col-sm-2 col-form-label">
                                            State
                                        </label>
                                        <div className="col-sm-10">
                                            <Select
                                                className="form-control"
                                                options={statesOptions}
                                                placeholder="- Select -"
                                                style={{
                                                    lineHeight: "40px",
                                                    color: "#7e7e7e",
                                                    paddingLeft: " 15px",
                                                    borderWidth: 0,
                                                }}
                                                value={statesOptions.filter(o => o.value === contents.state)}
                                                onChange={e=> handleOptionSelected(e, 'state')}
                                            />
                                            <span className="error">{error.state}</span>
                                        </div>
                                    </div>
                                    <div className="mb-3 row align-items-center">
                                        <label className="col-sm-2 col-form-label">
                                            Postcode
                                        </label>
                                        <div className="col-sm-10">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                name="postCode"
                                                onChange={(e) => onInputChanges(e)}
                                                value={contents ? contents.postCode : ""} 
                                            />
                                            <span className="error">{error.postCode}</span>
                                        </div>
                                    </div> 
                                    <div className="mb-3 row align-items-center">
                                        <label className="col-sm-2 col-form-label">
                                            Country
                                        </label>
                                        <div className="col-sm-10">
                                            <Select
                                                className="form-control"
                                                options={countryOptions}
                                                placeholder="- Select -"
                                                style={{
                                                    lineHeight: "40px",
                                                    color: "#7e7e7e",
                                                    paddingLeft: " 15px",
                                                    borderWidth: 0,
                                                }}
                                                value={countryOptions.filter(o => o.value === contents.country)}
                                                onChange={e=> handleOptionSelected(e, 'country')}
                                            />
                                            <span className="error">{error.country}</span>
                                        </div>
                                    </div>
                                    <div className="mb-3 row align-items-center">
                                        <label className="col-sm-2 col-form-label">
                                            Password
                                        </label>
                                        <div className="col-sm-10">
                                            <input
                                                type="password"
                                                className="form-control"
                                                placeholder=""
                                                name="password"
                                                onChange={(e) => onInputChanges(e)}
                                                value={contents ? contents.password : ""} 
                                            />
                                            <span className="error">{error.password}</span>
                                        </div>
                                    </div> 
                                    <div className="mb-3 row align-items-center">
                                        <label className="col-sm-2 col-form-label">
                                            Confirm Password
                                        </label>
                                        <div className="col-sm-10">
                                            <input
                                                type="password"
                                                className="form-control"
                                                placeholder=""
                                                name="confirmPassword"
                                                onChange={(e) => onInputChanges(e)}
                                                value={contents ? contents.confirmPassword : ""} 
                                            />
                                            <span className="error">{error.confirmPassword}</span>
                                        </div>
                                    </div>      
                                    <div className="mb-3 row align-items-center">
                                        <label className="col-sm-2 col-form-label">
                                            Referral Code 
                                        </label>
                                        <div className="col-sm-10">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="referral code"
                                                name="referralId"
                                                value={contents ? contents.referralId : ""}
                                                onChange={(e) => onInputChanges(e)}/>

                                        </div>
                                    </div>  
                                    <div className="mb-3 row align-items-center">
                                        <label className="col-sm-2 col-form-label">
                                            Placement Position
                                        </label>
                                        <div className="col-sm-10">
                                            <Select 
                                                className="form-control"
                                                options={placementOption}
                                                placeholder="- Select -"
                                                style={{
                                                    lineHeight: "40px",
                                                    color: "#7e7e7e",
                                                    paddingLeft: " 15px",
                                                    borderWidth: 0,
                                                }}
                                                value={placementOption.find(d => d.value === contents.placement)}
                                                onChange={e => handleOptionSelected(e, 'placement')}
                                                />
                                                <span className="error">{error.placement}</span>
                                        </div>
                                    </div>                     
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-primary profile-button btn-sm text-white me-2" onClick={(e) => submitForm(e)} disabled={loading}>{loading ? (<i class="fas fa-spinner fa-spin"></i>) : "Create"}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(AddUser);