import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import { connect } from 'react-redux';
import {  api } from "../../../utils/api";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import SortingTable from "../../components/SortingTable";

const BankList = (props) => {
    const COLUMNS = [
        {
            Header : 'No.',
            accessor: (row, i) => {
                return i+1;
             }
        },
        {
            Header : 'Fullname',
            accessor: (row) => {
                return row.user.fullName;
            }
        },
        {
            Header : 'Name',
            accessor: (row) => {
                return row.bank.bankName;
            }
        },
        {
            Header : 'Bank Account No.',
            accessor: 'accountNumber'
        },
        {
            Header : 'Created Datetime',
            accessor: (row) => {
               return moment(row.createdAt).format('YYYY-MM-DD HH:MM:ss')
            }
        },
        // {
        //     Header : 'Action',
        //     accessor: (row) => {
        //         return (
        //             <>
        //                 <Link to={"/bank/details/" + row.id} className="text-primary">
        //                     <i className="fas fa-eye"></i>
        //                 </Link>
        //             </>
        //         )
        //     }
        // },
    ];

    const [loading, setLoading] = useState(false);
    const [contents, setContents] = useState([]);
    const [fileData, setFileData] = useState({
        name: "Gold_Bank_List",
        excelData: [],
        hasCustom: false,
        
    })
    useEffect(() => {
        getList();
    }, [props.auth]);

    const getList = () => {
        setLoading(true);
        fetch(api.banks + "/accounts", {
            method:"GET",
            headers:new Headers({
                "Content-Type":"application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        }).then(response => response.json())
        .then(responseJson => {
            setLoading(false);
            console.log(responseJson) 
            if(responseJson.data){
                setContents(responseJson.data);

                let excelTable = responseJson.data.map((i,index) => {
                        
                    return {
                        "No.": index + 1,
                        "Fullname": i.user.fullName, 
                        "Name": i.bank.bankName, 
                        "Bank Account No.": i.accountNumber,                     
                        "Created Datetime": moment(i.createdAt).format('YYYY-MM-DD HH:mm:ss')
                    }
                })

                setFileData({
                    ...fileData,
                    excelData: excelTable,
                    hasCustom: false,
                    customRows:[],
                    
                });
            }
            
        }).catch(error => { 
            console.log("Error",error);
        });
    }

    return (
        <>
            <div className="row page-titles mx-0 shadow">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                        <span>Bank</span>
                    </li>
                    <li className="breadcrumb-item">
                        <span>List</span>
                    </li>
                </ol>
            </div>
            <div className="card shadow card-black">
                <div className="card-body">
                    {loading ? (
                    <div className="text-center">
                        <i className="fas fa-spinner fa-pulse fa-3x"></i>
                    </div>
                    ) : (
                    <div className="">
                        <div className="dataTables_wrapper" id="table_data">
                            <SortingTable columns={COLUMNS} data={contents} excelExport={true} fileData={fileData}/>
                        </div>
                    </div>
                    )}
                </div>
            </div>
        </>
   );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(BankList);
