import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import { connect } from 'react-redux';
import {  api } from "../../../utils/api";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import SortingTable from "../../components/SortingTable";

const ContractList = (props) => {
    const COLUMNS = [
        {
            Header : 'No.',
            accessor: (row, i) => {
                return ++i + "."
            },
        },
        {
            Header : 'Program Name',
            accessor: 'firstName'
        },
        {
            Header : 'Period(Month)',
            accessor: (row) => {
                return "12"
             }
        },
        {
            Header : 'Investment Percentage(%)',
            accessor: (row) => {
                return "10"
             }
        },
        {
            Header : 'Action',
            accessor: (row) => {
                return (
                    <>
                        <Dropdown>
                            <Dropdown.Toggle
                            as="button"
                            variant=""
                            className="btn sharp btn-primary tp-btn mt-1"
                            id="tp-btn"
                            >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="18px"
                                height="18px"
                                viewBox="0 0 24 24"
                                version="1.1"
                            >
                                <g
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                                >
                                <rect x="0" y="0" width="24" height="24" />
                                <circle fill="#000000" cx="12" cy="5" r="2" />
                                <circle fill="#000000" cx="12" cy="12" r="2" />
                                <circle fill="#000000" cx="12" cy="19" r="2" />
                                </g>
                            </svg>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item><Link to={"/contract/edit/" + row.id }>Edit</Link></Dropdown.Item>
                                <Dropdown.Item onClick={()=>deleteContract()}>Remove</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </>
                )
            },
        },
    ];
    const [loading, setLoading] = useState(false);
    const [contents, setContents] = useState([]);
    const [users, setUsers] = useState([]);
    const [filterOption, setFilterOption] = useState("all");
    const history = useHistory();

    useEffect(() => {
        getList();
    }, [props.auth]);


    const getList = () => {
        setLoading(true);
        fetch(api.contracts, {
            method:"GET",
            headers:new Headers({
                "Content-Type":"application/JSON",
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        }).then(response => response.json())
        .then(responseJson => {
            setLoading(false);
            console.log(responseJson) 
            // if(responseJson.data){
            //     setUsers(responseJson.data)
            // }
        }).catch(error => { 
                console.log("Error",error);
        });
    }

    const deleteContract = (contentId) => {
        swal({
            title: "Delete data?",
            text:
              "Once deleted, you will not be able to recover this data anymore!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                // fetch(api.users + "/" +contentId, {
                //     method:"DELETE",
                //     headers:new Headers({
                //         "Content-Type":"application/JSON",
                //         "Authorization": "Bearer " + props.auth.auth.content.token
                //     }),
                // }).then(response => response.json())
                // .then(responseJson => {
                //     getList();
                // }).catch(error => {
                //         console.log("Error",error);
                // });
            } 
        })
    }

    return (
        <div className="col-12">
            <div className="row page-titles mx-0 shadow">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                        <span>Program</span>
                    </li>
                    <li className="breadcrumb-item">
                        <span>List</span>
                    </li>
                </ol>
            </div>
            <div className="card shadow">
                <div className="card-header">
                    <Link className="btn btn-outline-primary btn-sm pull-right" to="/contract/create">Create New</Link>
                </div>
                <div className="card-body">
                    {loading ? (
                    <div className="text-center">
                        <i className="fas fa-spinner fa-pulse fa-3x"></i>
                    </div>
                    ) : (
                    <div className="w-100">
                        <div id="job_data" className="dataTables_wrapper">
                            <SortingTable columns={COLUMNS} data={users} />
                        </div>
                    </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};


export default connect(mapStateToProps)(ContractList);
