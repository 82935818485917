const url = 'http://localhost:4001';
// const url = "https://api.test.bgzone.asia:8081";
const version = '1.0';
const imageUrl = url + "/image/gold/";

const api = {
    users: url + '/admins',
    getAllUsers: url + '/admins/users',
    goldPrice: url + '/admins/golds',
    getMarketPrice: url + '/admins/golds/market',
    update_kyc: url + '/admins/users/:id/kyc/update',
    removeAdmin: url + '/admins/remove',
    branch: url + '/branches',
    contracts: url + '/investments/contracts',
    investment: url + '/investments',
    getWithdraw: url + '/admins/withdraw/request',
    updateInvestmentRequest: url + '/investments/:id/request',
    verifyInvestmentGold: url + '/investments/:id/verify',
    banks: url + '/banks',
    getAnnouncements: url + '/common/announcements',
    common: url + '/common',
    actionAnnouncement: url + '/admins/announcements',
    
    // update_profile_pic: url + '/users/upload/profile',
    // forgot_password: url + '/common/password/forget',
    // reset_password: url + '/common/password/reset',
}

export  { api, url, version, imageUrl };